import React from "react";
import TravelItem from "../../components/TravelPilgrimage";
import backgroundImage from "../../assets/images/pilgrimage.png";
import Banner from "../../components/ProductBanner";

const Index = () => {
  const bannerPayload = {
    title: "Pilgrimage Protection ",
    descriptions: [
      "Embarking on a pilgrimage is a unique and spiritual journey. Our Pilgrimage Protection insurance plans are specifically designed to cater to the needs of individuals travelling for religious purposes.",
    ],
    backgroundImage,
    // linkPath: "https://www.youtube.com/channel/UCDrC_N0SMI_ZYtgxhQ24HwQ",
    linkPath: "#travel",
    // linkText: "Watch how it works",
    linkText: "See Details",
  };
  return (
    <div>
      <Banner {...bannerPayload} />

      <TravelItem />
    </div>
  );
};

export default Index;
