import React, { useEffect, useState } from "react";
import Banner from "./Banner";
import Plans from "../../components/PlansList";
import Testimonial from "../../components/Testimonial";
import CallBack from "../../components/CallBack";
import WhyChooseHeirs from "../../components/WhyChooseHeirs";
// import BlogSection from "../../components/BlogSection";
import backgroundImage from "../../assets/images/home-banner.png";
import { Mixpanel } from "./../../Mixpanel";
import DiscountModal from "./DiscountModal";

const Index = () => {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const isDiscount = localStorage.getItem("discount");

  useEffect(() => {
    Mixpanel.track("HGI Home");
    if (isDiscount === true) {
      setVisible(false);
    } else {
      setVisible(true);
    }
  }, [isDiscount]);

  const bannerPayload = {
    title: "Simple & affordable protection for the things you cherish",
    descriptions: [],
    backgroundImage,
    linkPath: "#planchoice",
    linkText: "Get Started",
  };

  return (
    <div>
      <Banner {...bannerPayload} />

      <Plans />

      <WhyChooseHeirs />

      <Testimonial />

      {/* <BlogSection /> */}

      {/* <CallBack /> */}
      {/* {visible ? (
        <DiscountModal
          visible={visible}
          loading={confirmLoading}
          visibleState={setVisible}
          loadingState={setConfirmLoading}
        />
      ) : null} */}
    </div>
  );
};

export default Index;
