import React, { useEffect, useState } from "react";
import { Row, Col, Checkbox, Modal } from "antd";
import {
  ComplaintFormContentWrapper,
  FormWrapper,
  Title,
} from "../../screens/Contact/styles";
import FormInput from "../Input";
import { Select, OptionWrapper } from "../Input";
import Button from "../Button";
import { Link } from "react-router-dom";
import axios from "axios";
import { Mixpanel } from "./../../Mixpanel";
import { HeirsEmailUrl } from "../../utils/constants";

const Form = () => {
  const [value, setValue] = useState({
    firstName: "",
    lastName: "",
    email: "",
    type: "",
    phoneNumber: "",
    destination: "wecare@heirsgeneralinsurance.com",
    source: "Website",
    message: "",
  });
  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    type: "",
    phoneNumber: "",
    message: "",
  });

  // const { Option } = Select;

  const [isChecked, setChecked] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);

  const [status, setStatus] = useState({
    submitted: false,
    submitting: false,
    info: { error: false, msg: null },
  });

  useEffect(() => {
    if (
      isChecked &&
      value.firstName &&
      value.lastName &&
      value.email &&
      value.phoneNumber &&
      value.message &&
      !error.firstName &&
      !error.lastName &&
      !error.email &&
      !error.phoneNumber &&
      !error.message
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [value, error, isChecked]);

  const handleServerResponse = (ok, msg) => {
    if (ok) {
      Modal.success({
        content: msg,
      });
      setStatus({
        submitted: true,
        submitting: false,
        info: { error: false },
      });
      Array.from(document.querySelectorAll("input")).forEach(
        (input) => (input.value = "")
      );
      Array.from(document.querySelectorAll("textarea")).forEach(
        (textarea) => (textarea.value = "")
      );
      setIsDisabled(false);
    } else {
      setStatus({
        info: { error: true, msg: msg },
      });
    }
  };

  const handleSubmit = (e) => {
    Mixpanel.track("Send Message");
    const data = { ...value };
    e.preventDefault();
    setStatus((prevStatus) => ({ ...prevStatus, submitting: true }));
    axios({
      method: "POST",
      url: `${HeirsEmailUrl}/email/complaint`,
      data: data,
    })
      .then((response) => {
        if (response.status === 200) {
          handleServerResponse(true, response.data.message);
        } else {
          handleServerResponse(false, response.data.message);
        }
      })
      .catch((error) => {
        handleServerResponse(false, "An internal error occured");
      });
  };

  const handleChange = (e) => {
    const val = e.target.value;
    setValue({
      ...value,
      message: val,
    });

    if (val.length === 0) {
      setError({ ...error, message: "message cannot be blank" });
    } else {
      setError({ ...error, message: "" });
    }
  };

  const handleSelectChange = (e) => {
    const val = e;
    console.log(val);
    setValue({
      ...value,
      type: val,
    });
  };

  const handleNameChange = (e) => {
    const val = e.target.value;
    if (!/[^a-zA-Z]/.test(val)) {
      setValue({
        ...value,
        [e.target.name]: val,
      });
    } else {
      setValue({
        ...value,
        [e.target.name]: "",
      });
    }

    if (val.length < 3) {
      setError({
        ...error,
        [e.target.name]: "name cannot be less than three characters",
      });
    } else {
      setError({
        ...error,
        [e.target.name]: "",
      });
    }
  };

  const handleEmailChange = (e) => {
    const reg = /\S+@\S+\.\S+/;
    const val = e.target.value;
    setValue({ ...value, email: val });

    if (reg.test(String(val).toLowerCase())) {
      setError({ ...error, email: "" });
    } else {
      setError({
        ...error,
        email: "please enter a valid email address",
      });
    }
  };

  const handlePhoneNumberChange = (e) => {
    const val = e.target.value;
    const reg = /^[0-9]+$/;

    if (reg.test(val)) {
      setValue({ ...value, phoneNumber: val });
    }

    if (val.length < 11 || val.length > 11) {
      setError({
        ...error,
        phoneNumber:
          "your phone number should not be more or less than 11 digits",
      });
    } else {
      setError({
        ...error,
        phoneNumber: "",
      });
    }
  };

  return (
    <FormWrapper>
      <ComplaintFormContentWrapper>
        <Title>Send us a message</Title>
        <Row gutter={[32, 16]}>
          <Col span={24} md={12}>
            <FormInput
              placeholder="First name"
              name="firstName"
              type="text"
              onChange={handleNameChange}
              value={value.firstName}
            />
            <small>{error.firstName}</small>
          </Col>
          <Col span={24} md={12}>
            <FormInput
              placeholder="Last name"
              name="lastName"
              type="text"
              onChange={handleNameChange}
              value={value.lastName}
            />
            <small>{error.lastName}</small>
          </Col>
          <Col span={24} md={12}>
            <FormInput
              placeholder="Email Address"
              name="email"
              type="email"
              onChange={handleEmailChange}
              value={value.email}
            />
            <small>{error.email}</small>
          </Col>
          <Col span={24} md={12}>
            <FormInput
              placeholder="Phone number"
              name="phoneNumber"
              type="text"
              onChange={handlePhoneNumberChange}
              value={value.phoneNumber}
            />
            <small>{error.phoneNumber}</small>
          </Col>
          <Col span={24}>
            <Select
              placeholder="Type of Complaint"
              name="type"
              type="select"
              onChange={handleSelectChange}
              value={value.type}
            >
              <OptionWrapper value="" disabled selected>
                Select type of Complaint
              </OptionWrapper>
              <OptionWrapper value="Delay in feedback">
                Delay in feedback
              </OptionWrapper>
              <OptionWrapper value="Delay in Policy document/Certificate">
                Delay in Policy document/Certificate
              </OptionWrapper>
              <OptionWrapper value="Direct Debit Issue">
                Direct Debit Issue
              </OptionWrapper>
              <OptionWrapper value="Premium Payment Issue">
                Premium Payment Issue
              </OptionWrapper>
              <OptionWrapper value="Poor Service Delivery">
                Poor Service Delivery
              </OptionWrapper>
              <OptionWrapper value="Claims Complaint">
                Claims Complaint
              </OptionWrapper>
              <OptionWrapper value="Other">Other</OptionWrapper>
            </Select>
            {/* <small>{error.phoneNumber}</small> */}
          </Col>
          <Col span={24}>
            <textarea
              rows={4}
              placeholder="Leave a message"
              name="message"
              onChange={handleChange}
              value={value.message}
            />
            <small>{error.message}</small>
          </Col>
          <Col span={24}>
            <Checkbox
              checked={isChecked}
              onChange={() => setChecked(!isChecked)}
            >
              I agree to the company’s{" "}
              <Link to="/terms">Terms and Conditions</Link>
            </Checkbox>
          </Col>
        </Row>
        <Button onClick={handleSubmit} disabled={isDisabled}>
          Send Message
        </Button>
      </ComplaintFormContentWrapper>
      {status.info.error && (
        <div className="error">Error: {status.info.msg}</div>
      )}
      {!status.info.error && status.info.msg && <p>{status.info.msg}</p>}
    </FormWrapper>
  );
};

export default Form;
