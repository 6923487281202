import React from "react";
import Section from "../Section";
import { Row, Col } from "antd";
import Icon from "@ant-design/icons";
import {
  Wrapper,
  Title,
  Content,
  Text,
  ContentWrapper,
  IconWrapper,
} from "./style";
import { ReactComponent as AlertFill } from "../../assets/images/alert-fill.svg";
import { Video } from "../ProductBanner/styles";
import ReactCountryFlag from "react-country-flag";

const imgcol = {
  height: "200px",
  width: "100%",
  overflow: "hidden",
};
const cols = {
  background: "#fff",
  border: "1px solid #cdcdcd",
};
const pstyle = {
  lineHeight: "18pt",
};

const StoryTeller = () => {
  return (
    <Wrapper>
      <ContentWrapper>
        <Title> </Title>
        <Row gutter={[24, 0]}>
          <Col lg={5} style={cols}>
            <Text>
              <table>
                <tr>
                  <td>
                    <div style={imgcol}>
                      <img
                        src="https://heirsdigitalform.azurewebsites.net/wp-content/uploads/2024/01/nasir-ahmed.jpeg"
                        width="100%"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingBottom: "15px" }}>
                    <span style={{ paddingLeft: "5px", fontWeight: "bold" }}>
                      Nasir Ahmed Achile
                    </span>
                    <span style={{ display: "block" }}>
                      <ReactCountryFlag countryCode="NG" svg /> Nigeria
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingBottom: "15px" }}>
                    <p style={pstyle}>
                      <b>Gender:</b>{" "}
                    </p>
                    <p style={pstyle}>Male</p>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingBottom: "15px" }}>
                    <p style={pstyle}>
                      <b>Project Type:</b>{" "}
                    </p>
                    <p style={pstyle}>Research</p>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingBottom: "15px" }}>
                    <p style={pstyle}>
                      <b>Project Title:</b>{" "}
                    </p>
                    <p style={pstyle}>
                      Reshaping Nigeria's Visual Production Landscape with LL&B
                    </p>
                  </td>
                </tr>
              </table>
            </Text>
          </Col>
          <Col offset={0} lg={10}>
            <Text>
              <b>About: </b> <br />I spent a day with Innovative production
              company Ladder, Lex & Booker, to uncover the process behind their
              groundbreaking work in the visual production and advertisement
              space in Nigeria. Who they are, what led them here, how it feels
              like to operate in this space, triumphs and challenges, as well as
              advice for other young people looking to enter this space.
              Production company, Ladder, Lex & Booker (LL&B) founded by two
              brothers and two friends have been responsible for some of the
              most innovative adverts to air on Nigerian television in recent
              times. Some of their projects include commercials for Guinness
              Nigeria, PiggyVest, Big Brother Nigeria, OctaFX, ThePocketApp &
              more. And music videos for Tems, Ayra Starr, Ckay, an NPR
              production for Tems, and more. LL&B serves as a beacon of hope to
              younger innovators aspiring to reshape the future of commercial
              visual campaigns in Nigeria.
            </Text>
            {/* <Text>
              <b>Description of Project: </b>
              <br /> The Untrapped Series is a short three-part documentary
              series which follows Khutso Moleko, an entrepreneur who is
              differently abled and living life to the fullest and making
              strides in her career despite society's limitations.
            </Text> */}
            <Text>
              <b>Creative Thought Behind Project: </b>
              <br />
              The purpose of this project was to sit for a session with them,
              peeling back the layers of their process & success story,
              introducing Nigerians to the minds behind these visual
              productions, and illuminating the challenges and opportunities
              that exist for individuals in this space. It stems from a place of
              wanting to encapsulate the youthful energy that translates into
              the company culture, showing young people that they can remain who
              they are and build successful companies. I delved deep into their
              story from inception to the present moment to illustrate the
              different parts of the process, as well as their highs and lows to
              paint a vivid picture.
            </Text>
          </Col>
          <Col offset={0} lg={9}>
            <Text>
              <b>Project: </b>
              <a href="https://www.morebranches.com/ladder-lex-booker-young-entrepreneurs-elevating-visual-productions-in-nigeria/">
                <img
                  src="https://www.morebranches.com/wp-content/uploads/2023/09/MG_8763-346-2048x1365.jpeg"
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                    boxShadow: "5px 5px",
                  }}
                />
              </a>
            </Text>
            {/* <Text>
              <Video>
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/a0-FuNiliX8?si=QhoUAEnvM8ZGfRQJ"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </Video>
            </Text> */}
            <Text>
              <b>Impact: </b>
              <br /> The project was published on news platform More Branches on
              web and social media, it has a web reach of 4,100 reads and
              counting, while it was seen over 17,000 times on Instagram and on
              facebook.
            </Text>
            <Text>
              <b>Lesson Learnt While Working on Project: </b>
              <br /> Sometimes on set, not everything goes according to plan,
              and visual producers have to improvise and think on their feet to
              make good use of the limited time they are allotted on set. Also,
              young people across the continent are pushing the envelope and
              breaking boundaries across fields, despite all the structural
              blocks and inconsistencies they face, as well as the stigma from
              society surrounding young Africans, and their ability to
              contribute positively to their regions and economies at large.
            </Text>
          </Col>
        </Row>
      </ContentWrapper>
    </Wrapper>
  );
};

export default StoryTeller;
