import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Form, Checkbox } from "antd";
import FormInput from "../../components/Input";
import { Select, OptionWrapper } from "../../components/Input";
import Button from "../../components/Button";
import axios from "axios";
import { HeirsFetchPostsUrl } from "../../utils/constants";
import { Link } from "react-router-dom";
import img1 from "../../assets/images/gated/gate1.png";
import img2 from "../../assets/images/gated/gate2.png";
import img3 from "../../assets/images/gated/gate3.png";
import img4 from "../../assets/images/gated/gate4.png";
import img5 from "../../assets/images/gated/gate5.png";

import {
  Wrapper,
  Title,
  Text,
  BannerContentWrapper,
  LinkWrapper,
  Overlay,
  Video,
  Content,
  ContentWrapper,
} from "./stylebanner";
import { CaretRightFilled } from "@ant-design/icons";

const Banner = ({
  title,
  descriptions = [],
  linkPath,
  productURL,
  linkText,
  children,
  backgroundImage,
  link_Path,
  link_Text,
}) => {
  const [show, setShow] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);

  const showModal = () => {
    setModalVisible(true);
    // setShow(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const [value, setValue] = useState({
    firstName: "",
    emailAddress: "",
    phoneNumber: "",
    // destination: "wecare@heirslifeassurance.com",
  });
  const [error, setError] = useState({
    firstName: "",
    emailAddress: "",
    phoneNumber: "",
  });

  const [isChecked, setChecked] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);

  const [status, setStatus] = useState({
    submitted: false,
    submitting: false,
    info: { error: false, msg: null },
  });

  useEffect(() => {
    if (
      isChecked &&
      value.firstName &&
      value.emailAddress &&
      value.phoneNumber &&
      !error.firstName &&
      !error.emailAddress &&
      !error.phoneNumber
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [value, error, isChecked]);

  const handleServerResponse = (ok, msg) => {
    if (ok) {
      setStatus({
        submitted: true,
        submitting: false,
        info: { error: false, msg: "" },
      });
      Modal.success({
        content: msg,
      });
      Array.from(document.querySelectorAll("input")).forEach(
        (input) => (input.value = "")
      );
      /* Array.from(document.querySelectorAll("textarea")).forEach(
        (textarea) => (textarea.value = "")
      ); */
      setIsDisabled(false);
    } else {
      setStatus({
        info: { error: true, msg: msg },
      });
    }
  };

  const handleSubmit = (e) => {
    const data = { ...value };
    e.preventDefault();
    setStatus((prevStatus) => ({ ...prevStatus, submitting: true }));
    setIsDisabled(true);
    axios({
      method: "POST",
      // url: `${HeirsEmailUrl}/email`,
      url: `${HeirsFetchPostsUrl}/newsletter/insurance`,
      data: data,
    })
      .then((response) => {
        if (response.status === 200) {
          setIsDisabled(false);
          handleCancel();
          handleServerResponse(true, response.data.message);
          setShow(true);
        } else {
          setIsDisabled(false);
          handleServerResponse(
            false,
            response.data.message ||
              "Sorry, we're having a problem submitting your data"
          );
        }
      })
      .catch((error) => {
        setIsDisabled(false);
        handleServerResponse(false, "An internal error occured");
      });
  };

  const handleChange = (e) => {
    const val = e.target.value;
    setValue({
      ...value,
      message: val,
    });

    if (val.length === 0) {
      setError({ ...error, message: "message cannot be blank" });
    } else {
      setError({ ...error, message: "" });
    }
  };

  const handleNameChange = (e) => {
    const val = e.target.value;
    setValue({
      ...value,
      [e.target.name]: val,
    });
    /* 
    if (!/[^a-zA-Z]/.test(val)) {
      setValue({
        ...value,
        [e.target.name]: val,
      });
    } else {
      setValue({
        ...value,
        [e.target.name]: "",
      });
    }
 */
    if (val.length < 2) {
      setError({
        ...error,
        [e.target.name]: "name cannot be less than two characters",
      });
    } else {
      setError({
        ...error,
        [e.target.name]: "",
      });
    }
  };

  const handleEmailChange = (e) => {
    const reg = /\S+@\S+\.\S+/;
    const val = e.target.value;
    setValue({ ...value, emailAddress: val });

    if (reg.test(String(val).toLowerCase())) {
      setError({ ...error, emailAddress: "" });
    } else {
      setError({
        ...error,
        emailAddress: "please enter a valid email address",
      });
    }
  };

  const handlePhoneNumberChange = (e) => {
    const val = e.target.value;
    const reg = /^[0-9]+$/;

    if (reg.test(val)) {
      setValue({ ...value, phoneNumber: val });
    }

    if (val.length < 11 || val.length > 11) {
      setError({
        ...error,
        phoneNumber:
          "your phone number should not be more or less than 11 digits",
      });
    } else {
      setError({
        ...error,
        phoneNumber: "",
      });
    }
  };

  return (
    <Overlay>
      <Wrapper>
        {productURL ? (
          <>
            <BannerContentWrapper>
              <Title> {title}</Title>
              {descriptions.map((description, key) => (
                <Text
                  key={key}
                  dangerouslySetInnerHTML={{ __html: `${description}` }}
                ></Text>
              ))}
              {linkText && linkPath && (
                <LinkWrapper>
                  <a href={linkPath}>
                    {linkText} <CaretRightFilled />
                  </a>
                </LinkWrapper>
              )}
              {children}
            </BannerContentWrapper>

            <Video>
              <iframe
                width="100%"
                height="315"
                src={productURL}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Video>
          </>
        ) : (
          <Content backgroundImage={backgroundImage}>
            <BannerContentWrapper>
              <Title> {title}</Title>
              {descriptions.map((description, key) => (
                <Text
                  key={key}
                  dangerouslySetInnerHTML={{ __html: `${description}` }}
                ></Text>
              ))}

              {linkText && linkPath && (
                <LinkWrapper>
                  <a href={linkPath} onClick={showModal}>
                    {linkText} <CaretRightFilled />
                  </a>
                </LinkWrapper>
              )}

              {children}
            </BannerContentWrapper>
          </Content>
        )}
        <Modal
          visible={modalVisible}
          onCancel={handleCancel}
          footer={null}
          style={{ padding: "0px" }}
        >
          <Col>
            <h1 style={{ color: "rgb(255,0,0)", paddingBottom: "10px" }}>
              Subscribe To Our Exclusive Newsletter
            </h1>
          </Col>
          <Row gutter={[32, 16]} style={{ marginTop: "30px" }}>
            <Col span={24} md={12}>
              <FormInput
                placeholder="Full name"
                name="firstName"
                type="text"
                onChange={handleNameChange}
                value={value.firstName}
              />
              <small>{error.firstName}</small>
            </Col>
            <Col span={24} md={12}>
              <FormInput
                placeholder="Email address"
                name="emailAddress"
                type="text"
                onChange={handleEmailChange}
                value={value.emailAddress}
              />
              <small>{error.emailAddress}</small>
            </Col>
            <Col span={24} md={12}>
              <FormInput
                placeholder="Phone number"
                name="phone"
                type="text"
                onChange={handlePhoneNumberChange}
                value={value.phoneNumber}
              />
              <small>{error.phoneNumber}</small>
            </Col>
            <Col span={24} md={12}>
              <FormInput
                placeholder="Newsletter Subscription"
                name="message"
                type="text"
                value="Newsletter Subscription"
                hidden={true}
              />
            </Col>
            <Col span={24}>
              <Checkbox
                checked={isChecked}
                onChange={() => setChecked(!isChecked)}
              >
                I agree to the company's{" "}
                <Link to="/terms" target="blank">
                  Terms and Conditions
                </Link>
              </Checkbox>
            </Col>
          </Row>
          <Row gutter={[32, 16]} style={{ marginTop: "30px" }}>
            <Col span={24} md={12}>
              <Button
                style={{
                  borderRadius: "10px",
                }}
                onClick={handleSubmit}
                disabled={isDisabled}
              >
                Submit
              </Button>
              {status.info.error && (
                <div className="error">Error: {status.info.msg}</div>
              )}
            </Col>
          </Row>
        </Modal>
      </Wrapper>
      {show ? (
        <ContentWrapper style={{ width: "100%" }}>
          <Col>
            <h1 style={{ color: "rgb(255,0,0)", paddingBottom: "10px" }}>
              Exclusive! Here are 5 Things Your Mechanic Will Not Tell You
            </h1>
          </Col>
          <Text style={{ fontSize: "12pt", padding: "0px 4px" }}>
            When it comes to our beloved cars, we trust mechanics to be our
            knights in shining armor, the experts who can diagnose and fix any
            issues that arise even just by the description of the problem.
            However, like any profession, the automobile industry has its fair
            share of secrets and untold truths. In this article, we will delve
            into what your mechanics might not reveal about your car. Buckle up,
            as we expose the hidden tricks that could impact your driving
            experience.
          </Text>
          <ol>
            <li style={{ paddingBottom: "10px" }}>
              <Row gutter={[16, 0]} style={{ padding: "16px 2px 2px" }}>
                <Col lg={16} style={{ marginBottom: "4px" }}>
                  <b>
                    Extra weight increases fuel consumption; plain and simple:
                  </b>{" "}
                </Col>
                <Col lg={16} style={{ marginBottom: "4px" }}>
                  <img src={img1} style={{ width: "350px", height: "200px" }} />
                </Col>
                <Col lg={16} style={{ marginBottom: "4px" }}>
                  Just like the people who carpool with you want to save money,
                  so do you (I'm guessing). According to the U.S Department of
                  Energy (Energy Efficiency & Renewable Energy), an extra 45 kg
                  can reduce your Miles Per Gallon (MPG) by up to 2 percent.
                  Imagine. This trick affects smaller vehicles more than larger
                  ones and we both know your car is in the former category. So,
                  the next time you offer your 'school bus' services remember to
                  charge or request a contribution at least to your fuel money.
                  Don't forget, even Danfo drivers charge fees per seat.
                </Col>
              </Row>
            </li>
            <li style={{ paddingBottom: "10px" }}>
              <Row gutter={[16, 0]} style={{ padding: "16px 2px 2px" }}>
                <Col lg={16} style={{ marginBottom: "4px" }}>
                  <b>Hidden Warranty: </b>
                </Col>
                <Col lg={16} style={{ marginBottom: "4px" }}>
                  <img src={img2} style={{ width: "350px", height: "200px" }} />
                </Col>
                <Col lg={16} style={{ marginBottom: "4px" }}>
                  When you buy a brand-new car, it typically comes with a
                  warranty that provides reassurance. However, your mechanic may
                  fail to mention that getting repairs or modifications done
                  outside of authorized dealerships can potentially invalidate
                  your warranty. Shine your eyes! Read the fine print and
                  consult with your manufacturer or authorized service centre
                  before making significant changes to your vehicle to avoid
                  this. Note that only accredited dealerships of a manufacturer
                  in Nigeria will perform warranty services like a Toyota.
                </Col>
              </Row>
            </li>
            <li style={{ paddingBottom: "10px" }}>
              <Row gutter={[16, 0]} style={{ padding: "16px 2px 2px" }}>
                <Col lg={16} style={{ marginBottom: "4px" }}>
                  <b>The Magic of Regular Vehicle Inspection: </b>
                </Col>
                <Col lg={16} style={{ marginBottom: "4px" }}>
                  <img src={img3} style={{ width: "350px", height: "200px" }} />
                </Col>
                <Col lg={16} style={{ marginBottom: "4px" }}>
                  You may think you've got this covered but regular car
                  inspections are vital for maintaining your vehicle's health.
                  Again, emphasis on 'regular'. These inspections can identify
                  small problems before they become expensive repairs and
                  ultimately extend the lifespan of your car. This is an easy
                  money and great safety tip.{" "}
                </Col>
              </Row>
            </li>
            <li style={{ paddingBottom: "10px" }}>
              <Row gutter={[16, 0]} style={{ padding: "16px 2px 2px" }}>
                <Col lg={16} style={{ marginBottom: "4px" }}>
                  <b>The Overlooked Maintenance Schedules: </b>
                </Col>
                <Col lg={16} style={{ marginBottom: "4px" }}>
                  <img src={img4} style={{ width: "350px", height: "200px" }} />
                </Col>
                <Col lg={16} style={{ marginBottom: "4px" }}>
                  Even if your mechanic forgets this, you should not! It is your
                  manufacturer's maintenance schedule. Some of these activities
                  that happen during maintenance are changing cabin air filters,
                  fuel filters, and performing brake fluid flushes on time. You
                  see how important they are now. Failure to do these tasks can
                  gradually harm your car's performance and compromise your
                  safety.{" "}
                </Col>
              </Row>
            </li>
            <li style={{ paddingBottom: "10px" }}>
              <Row gutter={[16, 0]} style={{ padding: "16px 2px 2px" }}>
                <Col lg={16} style={{ marginBottom: "4px" }}>
                  <b>Genuine Parts vs “Tokunbo” Parts: </b>
                </Col>
                <Col lg={16} style={{ marginBottom: "4px" }}>
                  <img src={img5} style={{ width: "350px", height: "200px" }} />
                </Col>
                <Col lg={16} style={{ marginBottom: "4px" }}>
                  Mechanics prefer genuine parts because of their superior
                  quality, as they come directly from the manufacturer. However,
                  genuine parts can be expensive. I am sure we all know. On the
                  other hand, “Tokunbo” parts are more affordable and can
                  maintain superior quality. Your mechanic may not mention the
                  choice, so it is important to inquire about your options and
                  make a well-informed decision. Again, Tokunbo can maintain
                  decent quality but have options.{" "}
                </Col>
                <Col lg={16} style={{ marginBottom: "4px" }}>
                  Understanding these hidden truths allows you to make informed
                  decisions about your car's maintenance and avoid unnecessary
                  expenses. Remember to ask questions and stay proactive in
                  caring for your car. Share this link with your friends and let
                  them know to subscribe to premium content like this.
                </Col>
              </Row>
            </li>
          </ol>
        </ContentWrapper>
      ) : null}
    </Overlay>
  );
};

export default Banner;
