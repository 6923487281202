import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Modal, Form, Row } from "antd";
import {
  List,
  PlanChatTitle,
  PlanChatWrapper,
  Title,
  Wrapper,
  Text,
} from "../PlanPicker/styles";
import modestyle from "./style.css";
import ShopInsurance from "../../assets/images/shop-insurance.png";
// import { HeirsDashboardUrl } from "../../utils/constants";
// import { Mixpanel } from "../../Mixpanel";

const Index = () => {
  const handleChatWithUs = (e) => {
    e.preventDefault();
    window.fcWidget.open({});
  };

  const [modalVisible, setModalVisible] = useState(false);
  const showModal = () => {
    setModalVisible(true);
  };
  const handleCancel = () => {
    setModalVisible(false);
  };
  /* 
  const checkScrollTop = () => {
    const tableTop = document.getElementById("d-fixed");
    if (tableTop) {
      if (window.pageYOffset > 590) {
        tableTop.classList.add("p-fixed");
        document.querySelector("#d-fixed .ant-table-tbody").style.display =
          "none";
        document.querySelector(
          "#product-table .ant-table-thead"
        ).style.display = "none";
      } else {
        tableTop.classList.remove("p-fixed");
      }
    }
  };

  useEffect(() => {
    let p = document.getElementById("table");
    let p_prime = p.cloneNode(true);
    document.getElementById("d-fixed").appendChild(p_prime);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
  });
 */
  return (
    <Wrapper id="travel">
      <div className="ant-table" id="ant-table">
        <div id="d-fixed" />
        <div id="product-table">
          <table id="table">
            <tbody className="ant-table-tbody">
              <tr>
                <th colspan="7" className="ant-table-cell">
                  <Title>
                    <h3
                      style={{
                        color: "#ff0000",
                        padding: "16px",
                        textAlign: "center",
                      }}
                    >
                      Geographical Scope of Coverage
                    </h3>{" "}
                  </Title>
                </th>
              </tr>
              <tr>
                <td className="ant-table-cell" style={{ width: "25%" }}>
                  <Text bold s12>
                    Europe
                  </Text>
                </td>
                <td colspan="6" className="ant-table-cell">
                  <Text s12>
                    All European countries including the Schengen Area
                  </Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" style={{ width: "25%" }}>
                  <Text bold s12>
                    Schengen
                  </Text>
                </td>
                <td colspan="6" className="ant-table-cell">
                  <Text s12>
                    An area that includes 28 European continental member states.
                    See more on https://ec.europa.eu/
                  </Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" style={{ width: "25%" }}>
                  <Text bold s12>
                    Worldwide
                  </Text>
                </td>
                <td colspan="6" className="ant-table-cell">
                  <Text s12>
                    Provides worldwide cover excluding the country of residence
                  </Text>
                </td>
              </tr>
            </tbody>
          </table>

          <table style={{ marginTop: "20px" }}>
            <tbody className="ant-table-tbody">
              <tr>
                <td className="ant-table-cell" style={{ width: "25%" }}></td>
                <td className="ant-table-cell">
                  <Text highlight s11>
                    Traveller
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text highlight s11>
                    Pearl
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text highlight s11>
                    Family
                  </Text>
                </td>
                <td colspan="2" className="ant-table-cell">
                  <Text highlight s11>
                    Europe (Limits in EUR)
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text highlight s11>
                    Economy
                  </Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" style={{ width: "25%" }}>
                  <Text s11>Cost</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>From NGN 12,697.03</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>From NGN 5,812.26</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>From NGN 11,263.73</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>From NGN 3,271.87</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>From NGN 2,243.85</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>From NGN 2,906.13</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" style={{ width: "25%" }}>
                  <Text s11>Destination</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Worldwide</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Worldwide</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Worldwide</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Europe & Schengen</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Schengen only</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Worldwide</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" style={{ width: "25%" }}>
                  <Text s11>Pricing</Text>
                </td>
                <td className="ant-table-cell" colspan="6">
                  <Text s11>
                    <a
                      href="/travel-insurance-pricelist"
                      style={{ width: "130px", padding: "4px 16px" }}
                    >
                      See Price List
                    </a>
                  </Text>
                </td>
              </tr>
              <tr>
                <td
                  className="ant-table-cell"
                  style={{ textAlign: "center" }}
                  colspan="7"
                >
                  <Text highlight>Coverage for You</Text>
                </td>
              </tr>
              <tr>
                <td
                  className="ant-table-cell"
                  style={{ width: "25%" }}
                  colspan="7"
                >
                  <Text bold s11>
                    Medical & Emergency Assistance
                  </Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" style={{ width: "25%" }}>
                  <Text s11>
                    Medical expenses and hospitalisation (including COVID-19)
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $150,000 (excess $100)</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $50,000 (excess $100)</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $50,000 (excess $100)</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to EUR 30,000 (excess EUR 50)</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to EUR 30,000 (excess EUR 50)</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $15,000 (excess $50)</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" style={{ width: "25%" }}>
                  <Text s11>Emergency medical Evacuation</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $150,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $50,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $50,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to EUR 30,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to EUR 15,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $15,000</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" colspan="7">
                  <Text bold s11>
                    Baggage
                  </Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" style={{ width: "25%" }}>
                  <Text s11>
                    Loss of passport, driving license, or national identity card
                    abroad
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $500</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $300</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $300</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to EUR 500</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Not covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Not covered</Text>
                </td>
              </tr>
              <tr>
                <td
                  className="ant-table-cell"
                  style={{ width: "25%", verticalAlign: "bottom" }}
                >
                  <Text s11>
                    Compensation for in-flight loss of checked-in baggage
                  </Text>
                  <List style={{ paddingLeft: "4px" }}>
                    <li style={{ paddingTop: "20px" }}>
                      <Text s11>Per bag</Text>
                    </li>
                    <li style={{ paddingTop: "20px" }}>
                      <Text s11>Per item</Text>
                    </li>
                  </List>
                </td>
                <td
                  className="ant-table-cell"
                  style={{ verticalAlign: "bottom" }}
                >
                  <Text s11>Up to $2,500</Text>
                  <List>
                    <li style={{ paddingTop: "10px" }}>
                      <Text s11>Up to $1,250</Text>
                    </li>
                    <li style={{ paddingTop: "10px" }}>
                      <Text s11>Up to $125</Text>
                    </li>
                  </List>
                </td>
                <td
                  className="ant-table-cell"
                  style={{ verticalAlign: "bottom" }}
                >
                  <Text s11>Up to $1,000</Text>
                  <List>
                    <li style={{ paddingTop: "10px" }}>
                      <Text s11>Up to $500</Text>
                    </li>
                    <li style={{ paddingTop: "10px" }}>
                      <Text s11>Up to $50</Text>
                    </li>
                  </List>
                </td>
                <td
                  className="ant-table-cell"
                  style={{ verticalAlign: "bottom" }}
                >
                  <Text s11>Up to $1,000</Text>
                  <List>
                    <li style={{ paddingTop: "10px" }}>
                      <Text s11>Up to $500</Text>
                    </li>
                    <li style={{ paddingTop: "10px" }}>
                      <Text s11>Up to $50</Text>
                    </li>
                  </List>
                </td>
                <td
                  className="ant-table-cell"
                  style={{ verticalAlign: "bottom" }}
                >
                  <Text s11>Up to EUR 1,000</Text>
                  <List>
                    <li style={{ paddingTop: "10px" }}>
                      <Text s11>Up to EUR 500</Text>
                    </li>
                    <li style={{ paddingTop: "10px" }}>
                      <Text s11>Up to EUR 50</Text>
                    </li>
                  </List>
                </td>
                <td
                  className="ant-table-cell"
                  style={{ verticalAlign: "bottom" }}
                >
                  <Text s11>Up to EUR 250</Text>
                  <List>
                    <li style={{ paddingTop: "10px" }}>
                      <Text s11>Up to EUR 125</Text>
                    </li>
                    <li style={{ paddingTop: "10px" }}>
                      <Text s11>Up to EUR 10</Text>
                    </li>
                  </List>
                </td>
                <td
                  className="ant-table-cell"
                  style={{ verticalAlign: "bottom" }}
                >
                  <Text s11>Up to $250</Text>
                  <List>
                    <li style={{ paddingTop: "10px" }}>
                      <Text s11>Up to $250</Text>
                    </li>
                    <li style={{ paddingTop: "10px" }}>
                      <Text s11>Up to $25</Text>
                    </li>
                  </List>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" style={{ width: "25%" }}>
                  <Text s11>
                    Compensation for delay in the arrival of luggage
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $1,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $500</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $500</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to EUR 500</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Not covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Not covered</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" colspan="7">
                  <Text bold s11>
                    Personal Accident in Means of Public Transport
                  </Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" style={{ width: "25%" }}>
                  <Text s11>
                    Accidental death occurred in means of public transport
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $50,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $25,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $25,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $15,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to EUR 10,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to EUR 15,000</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" colspan="7">
                  <Text bold s11>
                    Cancellation or Curtailment
                  </Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" style={{ width: "25%" }}>
                  <Text s11>Trip cancellation expenses</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $2,000 (excess $100)</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $1,000 (excess $100)</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $1,500 (excess $100)</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Not covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Not covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $1,000 (excess $70)</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" colspan="7">
                  <Text bold s11>
                    Losses and Delays
                  </Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" style={{ width: "25%" }}>
                  <Text s11>Delayed departure</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $1,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $500</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $250</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Not covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Not covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Not covered</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" colspan="7">
                  <Text s11>
                    Other benefits (limits vary according to the selected
                    variant):
                  </Text>
                  <List s11 style={{ paddingLeft: "24px" }}>
                    <li>
                      Compulsory quarantine in case of COVID-19 infection.
                    </li>
                    <li>Repatriation after treatment.</li>
                    <li>Emergency dental care.</li>
                    <li>Repatriation of mortal remains.</li>
                    <li>
                      Repatriation of family member traveling with the insured.
                    </li>
                    <li>
                      Emergency return home following the death of a close
                      relative.
                    </li>
                    <li>Travel of one immediate family member.</li>
                    <li>Hijacking in means of public transport.</li>
                    <li>Location & forwarding of personal effects.</li>
                    <li>Permanent (total) disability.</li>
                    <li>Advance of a bail bond.</li>
                    <li>Personal civil liability.</li>
                    <li>Legal defence abroad.</li>
                  </List>
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }} colspan="7">
                  <Text s12>
                    <a
                      href="https://forms.heirsinsurance.com/travel-insurance/"
                      target="blank"
                      style={{
                        background: "#ff0000",
                        color: "#fff",
                        display: "inline-block",
                        padding: "8px",
                        borderRadius: "5px",
                        width: "200px",
                        height: "auto",
                      }}
                    >
                      Get Started
                    </a>
                  </Text>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Wrapper>
  );
};

export default Index;
