import React from "react";
import { Wrapper, Title, SubTitle } from "./style";

const Section = ({ title, subTitle, subTitlePrefix, children, filled }) => {
  return (
    <Wrapper filled={filled}>
      {title && <Title>{title} </Title>}
      {subTitle && (
        <SubTitle>
          {subTitlePrefix && <span>{subTitlePrefix}&nbsp;</span>}
          {subTitle}
        </SubTitle>
      )}

      {children}
    </Wrapper>
  );
};

export default Section;
