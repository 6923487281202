import React from "react";
import backgroundImage from "../../assets/images/Hoeprotect.png";
import Banner from "../../components/ProductBanner";
import CargoPlanPicker from "../../components/CargoPlanPicker";

const Index = () => {
  const bannerPayload = {
    title: "Marine Cargo Insurance",
    descriptions: [
      "The Marine Cargo insurance can be taken by manufacturing and construction companies, importers, corporate organizations, or any business that imports goods by sea or air.",
      "This type of insurance covers businesses that have goods exposed in transit against maritime perils such as rough weather, sinking, and theft.",
      "As a cargo owner, if your goods are lost or damaged to the listed perils while in transit, Heirs General Insurance will pay the cost to replace them. Thus, saving your business from a huge loss or potential bankruptcy.",
      "<b>Who needs the Heirs Marine Cargo Insurance?</b>",
      "Cargo owners who transport goods via sea or air including:",
      `<ul>
            <li>Manufacturing companies</li>
            <li>Construction companies</li>
            <li>Importers & Exporters</li>
            <li>Logistics companies</li>
            <li>Freight forwarders</li>
            <li>Commodities traders</li>
            <li>Public institutions</li>
            <li>Corporate organisations</li>
            <li>Banks</li>
            <li>SMEs</li>
            <li.Etc.</li>
        </ul>`,
    ],
    backgroundImage,
    // linkPath: "https://www.youtube.com/channel/UCDrC_N0SMI_ZYtgxhQ24HwQ",
    linkPath: "#cargo",
    // linkText: "Watch how it works",
    linkText: "Learn More",
  };
  return (
    <div>
      <Banner {...bannerPayload} />

      <CargoPlanPicker />
    </div>
  );
};

export default Index;
