import React from "react";
import { Row } from "antd";
import Plan from "./Plan";
import { BigText, PlansContainer } from "../style";
import { ReactComponent as CarIcon } from "../../../assets/images/car.svg";
import { ReactComponent as HearseIcon } from "../../../assets/images/hearse.svg";
import { ReactComponent as ComprehensiveIcon } from "../../../assets/images/Comprehensive.svg";
import { ReactComponent as HerMotorIcon } from "../../../assets/images/HerMotor.svg";
import { ReactComponent as BikerIcon } from "../../../assets/images/motorbike.svg";
import { ReactComponent as LandlordIcon } from "../../../assets/images/landlord.svg";
import { ReactComponent as TenantIcon } from "../../../assets/images/tenant.svg";
// import { ReactComponent as PhoneIcon } from "../../../assets/images/smartphone.svg";
import { ReactComponent as AccidentIcon } from "../../../assets/images/accident.svg";
// import { ReactComponent as StoreIcon } from "../../../assets/images/store.svg";
import { ReactComponent as ShipmentIcon } from "../../../assets/images/shipment.svg";
// import { ReactComponent as ShipIcon } from "../../../assets/images/ship.svg";
// import { ReactComponent as FileIcon } from "../../../assets/images/file.svg";
// import { ReactComponent as FireIcon } from "../../../assets/images/fire-insurance.svg";
// import { ReactComponent as SaveMoneyIcon } from "../../../assets/images/save-money.svg";

const PlanItems = () => {
  const general = [
    {
      title: "Personal Accident Bronze",
      icon: AccidentIcon,
      textmsg:
        "If an accident stops you from working and earning a living, we pay you a lump sum to help replace your lost income during the period in which you are unwell. \n\r• Personal Accident Bronze costs N1,000 and pays you/your family N500,000 in the event of permanent disability/death.",
      url: "/personal-accident",
      mixpanel: "Personal Accident Bronze Bancassurance",
      quote:
        "https://bancassurance.heirsinsurance.com/accident-type?policy-type=HEIRS%20Personal%20Accident%20(Bronze)&referralId=3b5192e1-d4f0-42df-8db3-81ab3d83a4e4",
      inx: "",
    },
    {
      title: "Personal Accident Silver",
      icon: AccidentIcon,
      textmsg:
        "If an accident stops you from working and earning a living, we pay you a lump sum to help replace your lost income during the period in which you are unwell. \n\r• Personal Accident Silver costs N2,500 and pays you/your family N1,000,000 in the event of permanent disability/death.",
      url: "/personal-accident",
      mixpanel: "Personal Accident Silver Bancassurance",
      quote:
        "https://bancassurance.heirsinsurance.com/accident-type?policy-type=HEIRS%20Personal%20Accident%20(Silver)&referralId=3b5192e1-d4f0-42df-8db3-81ab3d83a4e4",
      inx: "",
    },
    {
      title: "Personal Accident Gold",
      icon: AccidentIcon,
      textmsg:
        "If an accident stops you from working an earning a living, we pay you a lump sum to help replace your lost income during the period in which you are unwell. \n\r• Personal Accident Gold costs N5,000 and pays you/your family N2,000,000 in the event of permanent disability/death.",
      url: "/personal-accident",
      mixpanel: "Personal Accident Gold Bancassurance",
      quote:
        "https://bancassurance.heirsinsurance.com/accident-type?policy-type=HEIRS%20Personal%20Accident%20(Gold)&referralId=3b5192e1-d4f0-42df-8db3-81ab3d83a4e4",
      inx: "",
    },
    {
      title: "Personal Accident Platinum",
      icon: AccidentIcon,
      textmsg:
        "If an accident stops you from working an earning a living, we pay you a lump sum to help replace your lost income during the period in which you are unwell. \n\r• Personal Accident Platinum costs N10,000 and pays you/your family N3,500,000 in the event of permanent disability/death.",
      url: "/personal-accident",
      mixpanel: "Personal Accident Platinum Bancassurance",
      quote:
        "https://bancassurance.heirsinsurance.com/accident-type?policy-type=HEIRS%20Personal%20Accident%20(Platinum)&referralId=3b5192e1-d4f0-42df-8db3-81ab3d83a4e4",
      inx: "",
    },
    {
      title: "HomeProtect Plan",
      icon: LandlordIcon,
      textmsg:
        "The HomeProtect plan is ideal for landlords and home owners. This home insurance plan saves you from paying the exorbitant costs associated with fire damage and other types of incidents to your home, as well as replaces your valuables, if stolen. \n\r• HomeProtect is ideal for houses not more than 25 million Naira.",
      url: "/home-protect",
      mixpanel: "HomeProtect Plan Bancassurance",
      quote:
        "https://bancassurance.heirsinsurance.com/homeowner-type?policy-type=Home%20Protect&referralId=3b5192e1-d4f0-42df-8db3-81ab3d83a4e4",
      inx: "",
    },
    {
      title: "HomeProtect Plus Plan",
      icon: LandlordIcon,
      textmsg:
        "The HomeProtect plan is ideal for landlords and home owners. This home insurance plan saves you from paying the exorbitant costs associated with fire damage and other types of incidents to your home, as well as replaces your valuables, if stolen. \n\r• HomeProtect Plus is ideal for houses more than 25 million Naira.",
      url: "/home-protect",
      mixpanel: "HomeProtect Plus Plan Bancassurance",
      quote:
        "https://bancassurance.heirsinsurance.com/homeowner-type?policy-type=Home%20Protect%20Plus&referralId=3b5192e1-d4f0-42df-8db3-81ab3d83a4e4",
      inx: "",
    },
    {
      title: "TenantProtect Bronze Plan",
      icon: TenantIcon,
      textmsg:
        "The TenantProtect plan is ideal for tenants who want to shield their valuables from loss. This insurance plan saves you from paying the exorbitant costs associated with fire damage, theft and other types of incidents to your valuables. \n\r• TenantProtect Bronze costs N5,000/yr and covers your household items for up to N1,000,000.",
      url: "/tenants",
      mixpanel: "TenantProtect Bronze Bancassurance",
      quote:
        "https://bancassurance.heirsinsurance.com/tenant-type?policy-type=Tenant%20Protect%20(Bronze)&referralId=3b5192e1-d4f0-42df-8db3-81ab3d83a4e4",
      inx: "",
    },
    {
      title: "TenantProtect Gold Plan",
      icon: TenantIcon,
      textmsg:
        "The TenantProtect plan is ideal for tenants who want to shield their valuables from loss. This insurance plan saves you from paying the exorbitant costs associated with fire damage, theft and other types of incidents to your valuables. \n\r• TenantProtect Gold costs N12,500/yr and covers your household items for up to N2,000,000.",
      url: "/tenants",
      mixpanel: "TenantProtect Gold Bancassurance",
      quote:
        "https://bancassurance.heirsinsurance.com/tenant-type?policy-type=Tenant%20Protect%20(Gold)&referralId=3b5192e1-d4f0-42df-8db3-81ab3d83a4e4",
      inx: "",
    },
    {
      title: "TenantProtect Platinum Plan",
      icon: TenantIcon,
      textmsg:
        "The TenantProtect plan is ideal for tenants who want to shield their valuables from loss. This insurance plan saves you from paying the exorbitant costs associated with fire damage, theft and other types of incidents to your valuables. \n\r• TenantProtect Platinum costs N25,000/yr and covers your household items for up to N5,000,000.",
      url: "/tenants",
      mixpanel: "TenantProtect Platinum Bancassurance",
      quote:
        "https://bancassurance.heirsinsurance.com/tenant-type?policy-type=Tenant%20Protect%20(Platinum)&referralId=3b5192e1-d4f0-42df-8db3-81ab3d83a4e4",
      inx: "",
    },
    {
      title: "TenantProtect Plus",
      icon: TenantIcon,
      textmsg:
        "The TenantProtect plan is ideal for tenants who want to shield their valuables from loss. This insurance plan saves you from paying the exorbitant costs associated with fire damage, theft and other types of incidents to your valuables. \n\r• TenantProtect Plus cost depends on the value of items in your rented apartment.",
      url: "/tenants",
      mixpanel: "TenantProtect Plus Bancassurance",
      quote:
        "https://bancassurance.heirsinsurance.com/tenant-type?policy-type=Tenant%20Protect%20Plus&referralId=3b5192e1-d4f0-42df-8db3-81ab3d83a4e4",
      inx: "",
    },
  ];
  const plans = [
    {
      title: "Heirs Third Party",
      icon: CarIcon,
      textmsg:
        "This basic motor protection shields you from the cost of damage caused by you to another person's vehicle with just N15,000 yearly.",
      url: "/third-party",
      mixpanel: "Heirs Third Party Bancassurance",
      quote:
        "https://bancassurance.heirsinsurance.com/motor-policy-type?policy-type=Third%20Party&referralId=3b5192e1-d4f0-42df-8db3-81ab3d83a4e4",
      inx: "",
    },
    /* {
      title: "Heirs Third Party Plus",
      icon: HerMotorIcon,
      textmsg:
        "This third-party insurance comes with an extra protection for your car up to the tune of N250,000, in addition to paying the cost of the damage caused by you to another person's vehicle. \n\r• This plan costs N10,000 yearly.",
      url: "/third-party",
      mixpanel: "Heirs Third Party Plus Bancassurance",
      quote:
        "https://bancassurance.heirsinsurance.com/motor-policy-type?policy-type=Third%20Party%20,%20Fire%20and%20Theft&referralId=3b5192e1-d4f0-42df-8db3-81ab3d83a4e4",
      inx: "",
    }, */
    /* {
      title: "Heirs Flexi-15 Motor Insurance ",
      icon: HearseIcon,
      textmsg:
        "Our Flexi motor plans are perfect for people who want more cover and less headaches. Our plans shield you from exorbitant costs of repairing your car, replacing it, or taking care of medical bills, in the unforeseen event of an accident, theft or fire. \n\r• Heirs Flexi 15 costs N15,000/yr and covers your car for up to N350,000.",
      url: "/flexi-motor",
      mixpanel: "Flexi-15 Motor Insurance Bancassurance",
      quote:
        "https://bancassurance.heirsinsurance.com/motor-policy-type?policy-type=HEIRS%20Motor%20Flexi%2015K&referralId=3b5192e1-d4f0-42df-8db3-81ab3d83a4e4",
      inx: "",
    },
    {
      title: "Heirs Flexi-23 Motor Insurance ",
      icon: HearseIcon,
      textmsg:
        "Our Flexi motor plans are perfect for people who want more cover and less headaches. Our plans shield you from exorbitant costs of repairing your car, replacing it, or taking care of medical bills, in the unforeseen event of an accident, theft or fire. \n\r•	Heirs Flexi 23 costs N23,000/yr and covers your car for up to N500,000.",
      url: "/flexi-motor",
      mixpanel: "Flexi-23 Motor Insurance Bancassurance",
      quote:
        "https://bancassurance.heirsinsurance.com/motor-policy-type?policy-type=HEIRS%20Motor%20Flexi%2023K&referralId=3b5192e1-d4f0-42df-8db3-81ab3d83a4e4",
      inx: "",
    },
    {
      title: "Heirs Flexi-40 Motor Insurance ",
      icon: HearseIcon,
      textmsg:
        "Our Flexi motor plans are perfect for people who want more cover and less headaches. Our plans shield you from exorbitant costs of repairing your car, replacing it, or taking care of medical bills, in the unforeseen event of an accident, theft or fire. \n\r•	Heirs Flexi 40 costs N40,000/yr and covers your car for up to N1,000,000",
      url: "/flexi-motor",
      mixpanel: "Flexi-40 Motor Insurance Bancassurance",
      quote:
        "https://bancassurance.heirsinsurance.com/motor-policy-type?policy-type=HEIRS%20Motor%20Flexi%2040K&referralId=3b5192e1-d4f0-42df-8db3-81ab3d83a4e4",
      inx: "",
    }, */
    {
      title: "Heirs Comprehensive Motor Insurance",
      icon: ComprehensiveIcon,
      textmsg:
        "The Heirs Comprehensive insurance offers total coverage for your vehicle. Coverage for you: Theft, accidental damage, fire, towing services (Limit: inter - 30,000 & Intra - 20,000.00), medical bills, flood (N100,000.00 Limit), strike, riot, civil commotion. Coverage for the other party: Accidental damage, medical bills, life insurance benefit.",
      url: "/comprehensive-motor",
      mixpanel: "Comprehensive Motor Bancassurance",
      quote:
        "https://bancassurance.heirsinsurance.com/motor-policy-type?policy-type=Comprehensive&referralId=3b5192e1-d4f0-42df-8db3-81ab3d83a4e4",
      inx: "1",
    },
    /* {
      title: "Her Motor Insurance",
      icon: HerMotorIcon,
      textmsg:
        "Her Motor Insurance Cover is a comprehensive motor insurance that shields women from the exorbitant costs of repairing or replacing their vehicle in the event of an accident, theft or fire incident. If your car is damaged or destroyed, Heirs General Insurance pays to fix or replace the car. \n\r• A key benefit of this plan is the 24 hours rescue and assistance you get if your car breaks down on the road. This plan costs 5% of the cost of your vehicle.",
      url: "/her-motor",
      mixpanel: "Her Motor Insurance Bancassurance",
      quote:
        "https://bancassurance.heirsinsurance.com/motor-policy-type?policy-type=HER%20Motor&referralId=3b5192e1-d4f0-42df-8db3-81ab3d83a4e4",
      inx: "1",
    }, */
    /* {
      title: "Bikers Insurance Third Party Plus",
      icon: BikerIcon,
      textmsg:
        "The Heirs Bikers Plan provides coverage for you, your bike and the vehicle of the other party in an unforeseen accident. \n\r• Third Party Plus costs N10,000 per year and shields you from the cost of damage caused by you to another person's property.",
      url: "/bikers",
      mixpanel: "Bikers Insurance Bancassurance",
      quote:
        "https://bancassurance.heirsinsurance.com/biker-type?policy-type=HEIRS%20Bikers%20Third%20Party%20Plus&referralId=3b5192e1-d4f0-42df-8db3-81ab3d83a4e4",
      inx: "",
    },
    {
      title: "Bikers Insurance Flexi Cover",
      icon: BikerIcon,
      textmsg:
        "The Heirs Bikers Plan provides coverage for you, your bike and the vehicle of the other party in an unforeseen accident. \n\r• Flexi Cover costs 5% of the cost of your bike and offers total coverage for your bike against accident, fire, theft, flood, riot or civil commotion, and other related risks. If any of the stated risks happen, we pay to repair or replace your bike.",
      url: "/bikers",
      mixpanel: "Bikers Insurance Flexi Bancassurance",
      quote:
        "https://bancassurance.heirsinsurance.com/biker-type?policy-type=HEIRS%20Bikers%20Flexi%20MotorCycle&referralId=3b5192e1-d4f0-42df-8db3-81ab3d83a4e4",
      inx: "",
    }, */
  ];
  const marine = [
    {
      title: "Marine Cargo (Single Transit) A",
      icon: ShipmentIcon,
      textmsg:
        "The Marine Cargo insurance can be taken by manufacturing and construction companies, importers, corporate organizations, or any business that imports goods by sea or air. As a cargo owner, if your goods are lost or damaged to incidents such as rough weather, sinking, theft, and other related maritime perils, Heirs General Insurance will pay the cost to replace them.",
      url: "/cargo",
      mixpanel: "Marine Cargo A Bancassurance",
      quote:
        "https://bancassurance.heirsinsurance.com/marine-goods?policyType=null&referralId=3b5192e1-d4f0-42df-8db3-81ab3d83a4e4",
      inx: "",
    },
    {
      title: "Marine Cargo (Single Transit) B",
      icon: ShipmentIcon,
      textmsg:
        "The Marine Cargo insurance can be taken by manufacturing and construction companies, importers, corporate organizations, or any business that imports goods by sea or air. As a cargo owner, if your goods are lost or damaged to incidents such as rough weather, sinking, theft, and other related maritime perils, Heirs General Insurance will pay the cost to replace them.",
      url: "/cargo",
      mixpanel: "Marine Cargo B Bancassurance",
      quote:
        "https://bancassurance.heirsinsurance.com/marine-goods?policyType=null&referralId=3b5192e1-d4f0-42df-8db3-81ab3d83a4e4",
      inx: "",
    },
    {
      title: "Marine Cargo (Single Transit) C",
      icon: ShipmentIcon,
      textmsg:
        "The Marine Cargo insurance can be taken by manufacturing and construction companies, importers, corporate organizations, or any business that imports goods by sea or air. This plan offers protection to goods (cargo) imported by sea or air. As a cargo owner, if your goods are lost or damaged to incidents such as rough weather, sinking, theft, and other related maritime perils, Heirs General Insurance will pay the cost to replace them.",
      url: "/cargo",
      mixpanel: "Marine Cargo C Bancassurance",
      quote:
        "https://bancassurance.heirsinsurance.com/marine-goods?policyType=null&referralId=3b5192e1-d4f0-42df-8db3-81ab3d83a4e4",
      inx: "",
    },
  ];

  return (
    <PlansContainer>
      <BigText style={{ padding: "4px 4px 4px" }}>
        MOTORS INSURANCE PRODUCTS
      </BigText>
      <Row gutter={[16, 0]}>
        {plans.map(
          ({ title, textmsg, icon, url, mixpanel, quote, inx }, key) => (
            <Plan
              title={title}
              mixpanel={mixpanel}
              textmsg={textmsg}
              icon={icon}
              url={url}
              key={key}
              quote={quote}
              inx={inx}
            />
          )
        )}
      </Row>
      <BigText style={{ padding: "48px 4px 4px" }}>
        GENERAL ACCIDENT INSURANCE PRODUCTS
      </BigText>
      <Row gutter={[16, 0]}>
        {general.map(
          ({ title, textmsg, icon, url, mixpanel, quote, inx }, key) => (
            <Plan
              title={title}
              mixpanel={mixpanel}
              textmsg={textmsg}
              icon={icon}
              url={url}
              key={key}
              quote={quote}
              inx={inx}
            />
          )
        )}
      </Row>
      <BigText style={{ padding: "48px 4px 4px" }}>
        MARINE INSURANCE PRODUCTS
      </BigText>
      <Row gutter={[16, 0]}>
        {marine.map(
          ({ title, textmsg, icon, url, mixpanel, quote, inx }, key) => (
            <Plan
              title={title}
              mixpanel={mixpanel}
              textmsg={textmsg}
              icon={icon}
              url={url}
              key={key}
              quote={quote}
              inx={inx}
            />
          )
        )}
      </Row>
    </PlansContainer>
  );
};

export default PlanItems;
