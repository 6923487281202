import styled from "styled-components";
import * as Colors from "../../utils/colors";

export const Wrapper = styled("div")`
  padding: 40px;
  background: ${Colors.PRIMARY};
`;

export const Content = styled("div")`
  width: 70%;
  margin: 0 auto;
`;

export const Text = styled("p")`
  text-align: center;
  margin: 0;
  color: ${Colors.WHITE};
`;

export const Title = styled("h1")`
  font-size: 18px;
  color: ${Colors.WHITE};
  margin: 0;
  text-align: center;
`;

export const ContentItem = styled("div")`
  text-align: center;
  margin-bottom: 15px;

  img {
    width: 100%;
  }
`;
