import React, { useEffect, useState } from "react";
import {
  PlanChatTitle,
  PlanChatWrapper,
  Text,
  Title,
  Wrapper,
  List,
} from "../PlanPicker/styles";

import tenant from "../../assets/images/icon_tenant.png";
import { Link } from "react-router-dom";
import { HeirsDashboardUrl } from "../../utils/constants";
import { Mixpanel } from "./../../Mixpanel";
import { Modal, Col, Row } from "antd";

const Index = () => {
  const [modalVisible, setModalVisible] = useState(false);

  const showModal = () => {
    setModalVisible(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const handleChatWithUs = (e) => {
    e.preventDefault();
    // window.fcWidget.open({});
    window.Freshbots.showWidget({});
  };

  const checkClickEvent = (e) => {
    if (e.target.id === "purchaseStatus") {
      setPurchaseStatus();
    }
  };

  const setPurchaseStatus = () => {
    localStorage.setItem("attempted_purchase", "true");
  };

  const checkScrollTop = () => {
    const tableTop = document.getElementById("d-fixed");

    if (tableTop) {
      if (window.pageYOffset > 690) {
        tableTop.classList.add("p-fixed");
        document.querySelector("#d-fixed .ant-table-tbody").style.display =
          "none";
        document.querySelector(
          "#product-table .ant-table-thead"
        ).style.display = "none";
      } else {
        tableTop.classList.remove("p-fixed");
      }
    }
  };

  useEffect(() => {
    let p = document.getElementById("table");
    let p_prime = p.cloneNode(true);
    document.getElementById("d-fixed").appendChild(p_prime);
  }, []);

  useEffect(() => {
    localStorage.setItem("last_accessed_product", window.location.pathname);
    window.addEventListener("scroll", checkScrollTop);
    window.addEventListener("click", checkClickEvent);
  });
  return (
    <Wrapper id="tenant">
      <div className="ant-table" id="ant-table">
        <div id="d-fixed" />
        <div id="product-table">
          <table id="table">
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell" style={{ width: "20%" }}>
                  <img src={tenant} alt="tenant insurance" />
                </th>
                <th className="ant-table-cell" style={{ width: "20%" }}>
                  <Title>Tenant Protect Bronze</Title>
                  <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Tenant Insurance - Buy Now")}
                    href={`${HeirsDashboardUrl}/tenant-type?policy-type=Tenant Protect (Bronze)`}
                  >
                    Buy Now
                  </a>
                  <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Tenant Insurance - Get Quote")}
                    href={`${HeirsDashboardUrl}/tenant-type?policy-type=Tenant Protect (Bronze)`}
                    style={{ marginTop: "5px" }}
                  >
                    Get A Quote
                  </a>
                </th>
                <th className="ant-table-cell" style={{ width: "20%" }}>
                  <Title>Tenant Protect Gold</Title>
                  <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Tenant Insurance - Buy Now")}
                    href={`${HeirsDashboardUrl}/tenant-type?policy-type=Tenant Protect (Gold)`}
                  >
                    Buy Now
                  </a>
                  <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Tenant Insurance - Get Quote")}
                    href={`${HeirsDashboardUrl}/tenant-type?policy-type=Tenant Protect (Gold)`}
                    style={{ marginTop: "5px" }}
                  >
                    Get A Quote
                  </a>
                </th>
                <th className="ant-table-cell" style={{ width: "20%" }}>
                  <Title>Tenant Protect Platinum</Title>
                  <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Tenant Insurance - Buy Now")}
                    href={`${HeirsDashboardUrl}/tenant-type?policy-type=Tenant Protect (Platinum)`}
                  >
                    Buy Now
                  </a>
                  <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Tenant Insurance - Get Quote")}
                    href={`${HeirsDashboardUrl}/tenant-type?policy-type=Tenant Protect (Platinum)`}
                    style={{ marginTop: "5px" }}
                  >
                    Get A Quote
                  </a>
                </th>
                <th className="ant-table-cell" style={{ width: "20%" }}>
                  <Title>Tenant Protect Plus</Title>
                  <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Tenant Insurance - Buy Now")}
                    href={`${HeirsDashboardUrl}/tenant-type?policy-type=Tenant Protect Plus`}
                  >
                    Buy Now
                  </a>
                  <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Tenant Insurance - Get Quote")}
                    href={`${HeirsDashboardUrl}/tenant-type?policy-type=Tenant Protect Plus`}
                    style={{ marginTop: "5px" }}
                  >
                    Get A Quote
                  </a>
                </th>
              </tr>
            </thead>

            <tbody className="ant-table-tbody">
              <tr>
                <td className="ant-table-cell">
                  <Text>Cost</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>N5,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>N12,500</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>N25,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>
                    Based on your annual rent and the cost of your household
                    items.
                    {/* <span style={{ color: "#fe0000" }}>
                      (1 month free insurance).
                    </span> */}
                  </Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Premium Frequency</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Yearly</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Yearly</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Yearly</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Half-yearly/ Yearly</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" colSpan="5">
                  <Text highlight>Coverage for you</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Replaces household items/personal items</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Up to N1M</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Up to 2M</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Up to N5M</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Up to value of items insured</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>
                    Replaces laptops, cameras, and other related items
                    (excluding phones and jewellery)
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Up to N500,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Up to N500,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Up to value of items insured</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Alternative accommodation in the case of damage</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>N250,000 limit</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>N500,000 limit</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>N500,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not more than cost of annual rent</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Medical expenses</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>N20,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>N30,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>N50,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>N/A</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>
                    Financial support to beneficiary if there is loss of life
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text>N50,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>N50,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>N100,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>N200,000</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" colSpan="5">
                  <Text highlight>
                    Coverage for other parties in the case of a fire accident
                  </Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Medical expenses</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>N20,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>N50,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>N100,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>N200,000</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Document Requirements</Text>
                </td>
                <td className="ant-table-cell" colspan="4">
                  <Text>
                    <List>
                      <li>Duly Filled Proposal/KYC Form.</li>
                      <li>Photographs of Subject Matter.</li>
                      <li>Evidence of Payment.</li>
                      <li>
                        Valid Means of I.D- Driver license, Voter's card, NIMC,
                        National ID, International Passport.
                      </li>
                      <li>Certificate of incorporation (for company).</li>
                      <li>
                        Proof of residence - Utility Bill, Front page of the
                        customer's address (same address as indicated on the
                        form or portal), Local government levy.
                      </li>
                      <li>Valuation Report (Building).</li>
                      <li>Inventory of contents with values.</li>
                      <li>Purchase receipt.</li>
                    </List>
                  </Text>
                </td>
              </tr>
              <tr>
                <td
                  className="ant-table-cell"
                  colspan="5"
                  style={{ textAlign: "center" }}
                >
                  <Text>
                    <a
                      onClick={showModal}
                      target="blank"
                      style={{
                        background: "#ff0000",
                        color: "#fff",
                        display: "inline-block",
                        padding: "8px",
                        borderRadius: "5px",
                        width: "100px",
                        height: "auto",
                        margin: "0px 10px",
                      }}
                    >
                      FAQs
                    </a>
                  </Text>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <PlanChatWrapper>
        <PlanChatTitle>
          Not Sure of the Right <br />
          Plan for you?
        </PlanChatTitle>
        <Link to="/" onClick={handleChatWithUs}>
          Chat with us
        </Link>
      </PlanChatWrapper>
      <Modal
        visible={modalVisible}
        onCancel={handleCancel}
        footer={null}
        style={{ padding: "0px" }}
      >
        <Row>
          <h1 style={{ color: "rgb(255,0,0)", paddingBottom: "10px" }}>
            Tenant Protect - FAQs
          </h1>
        </Row>
        <div style={{ height: "450px", overflowY: "auto" }}>
          <Row style={{ padding: "10px 2px" }}>
            <Col>
              <h3 style={{ fontWeight: "bold" }}>What is TenantProtect?</h3>
            </Col>
            <Col>
              Our home insurance plan for tenants who want to protect their
              rented apartments (and belongings in them) from damage.
            </Col>
          </Row>
          <Row style={{ padding: "10px 2px" }}>
            <Col>
              <h3 style={{ fontWeight: "bold" }}>How much is it?</h3>
            </Col>
            <Col> Our TenantProtect plan starts from N5,000 yearly.</Col>
          </Row>
          <Row style={{ padding: "10px 2px" }}>
            <Col>
              <h3 style={{ fontWeight: "bold" }}>What are the benefits?</h3>
            </Col>
            <Col>
              <ol>
                <li>
                  Replaces household/personal items damaged or lost to listed
                  risks
                </li>
                <li>
                  Provides alternative accommodation in case of damage (up to
                  rent)
                </li>
                <li>
                  Medical bills for injuries sustained by you and anybody else
                  (from listed incidents occurring in your home)
                </li>
                <li>
                  Financial support to your beneficiary if the incident is fatal
                </li>
              </ol>
            </Col>
          </Row>
          <Row style={{ padding: "10px 2px" }}>
            <Col>
              <h3 style={{ fontWeight: "bold" }}>
                Do I need to provide a detailed inventory of my personal
                belongings?
              </h3>
            </Col>
            <Col>
              Yes, you must provide an inventory of contents with their values
              as well as the purchase receipts.
            </Col>
          </Row>
          <Row style={{ padding: "10px 2px" }}>
            <Col>
              <h3 style={{ fontWeight: "bold" }}>Are phones covered?</h3>
            </Col>
            <Col>
              No, phones and jewellery are not covered under this plan.
              Computers, home office equipment and cameras, however, are covered
              up to the limit of your selected plan.
            </Col>
          </Row>
          <Row style={{ padding: "10px 2px" }}>
            <Col>
              <h3 style={{ fontWeight: "bold" }}>
                Can I transfer my plan if I move to a new apartment?
              </h3>
            </Col>
            <Col> Yes, you can. You must however inform us beforehand.</Col>
          </Row>
          <Row style={{ padding: "10px 2px" }}>
            <Col>
              <h3 style={{ fontWeight: "bold" }}>
                If my house is demolished, will I get compensated?
              </h3>
            </Col>
            <Col> No, the plan does not provide cover for demolition.</Col>
          </Row>
          <Row style={{ padding: "10px 2px" }}>
            <Col>
              <h3 style={{ fontWeight: "bold" }}>
                Does the plan cover eviction?
              </h3>
            </Col>
            <Col> No, it does not.</Col>
          </Row>
          <Row style={{ padding: "10px 2px" }}>
            <Col>
              <h3 style={{ fontWeight: "bold" }}>
                Does the plan cover electrical faults?
              </h3>
            </Col>
            <Col> No, it does not.</Col>
          </Row>
        </div>
      </Modal>
    </Wrapper>
  );
};

export default Index;
