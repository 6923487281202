import styled from "styled-components";
import * as Colors from "../../utils/colors";
import { ButtonWrapper } from "../../components/Button/style";
import { containerWrapper } from "../../utils/stylescheet";

export const Title = styled("h1")`
  font-size: 55px;
  line-height: 60px;
  color: ${Colors.TEXT_DEFAULT};
  margin-bottom: 0;

  @media screen and (max-width: 991px) {
    font-size: 35px;
    line-height: 35px;
  }

  @media screen and (max-width: 767px) {
    font-size: 30px;
    line-height: 30px;
  }

  @media screen and (max-width: 575px) {
    font-size: 20px;
    line-height: 15px;
  }
`;

export const BannerContentWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-height: 400px;

  @media screen and (max-width: 991px) {
    min-height: 300px;
    background-size: 300px auto;
  }

  @media screen and (max-width: 767px) {
    min-height: 200px;
    background-size: 200px auto;
  }

  @media screen and (max-width: 575px) {
    min-height: 150px;
    background-size: 150px auto;

    ${ButtonWrapper} {
      margin-top: 10px;
    }
  }
`;

export const Overlay = styled("div")`
  margin-top: 4.4%;
  background-color: rgb(243 84 91 / 7%);
`;

export const Wrapper = styled("div")`
  ${containerWrapper}
`;

export const Content = styled("div")`
  min-height: 400px;
  background-image: ${({ backgroundImage }) =>
    backgroundImage ? "url(" + backgroundImage + ")" : ""};
  background-repeat: no-repeat;
  background-size: 400px auto;
  background-position: right center;

  @media screen and (max-width: 991px) {
    min-height: 300px;
    background-size: 300px auto;
  }

  @media screen and (max-width: 767px) {
    min-height: 200px;
    background-size: 200px auto;
  }

  @media screen and (max-width: 575px) {
    min-height: 150px;
    background-size: 150px auto;
  }
`;
