import React, { useEffect } from "react";
import {
  PlanChatTitle,
  PlanChatWrapper,
  Text,
  Title,
  Wrapper,
} from "../PlanPicker/styles";
import {
  TextContentWrapper,
  TitleText,
  TextParagraph,
  ImageWrapper,
} from "./styles";

import bancassurance_icon from "../../assets/images/bancassurance-2.png";
import { Link } from "react-router-dom";
import { HeirsDashboardUrl } from "../../utils/constants";
import { Mixpanel } from "../../Mixpanel";

const Index = () => {
  return (
    <Wrapper id="tenant">
      <ImageWrapper>
        <img src={bancassurance_icon} style={{ width: "100%" }} />
      </ImageWrapper>
      <TextContentWrapper>
        <TitleText>What is Bancassurance?</TitleText>
        <div>
          <TextParagraph>
            Bancassurance is a collaboration between a bank and an insurance
            company that allows the bank to refer insurance products to its
            customers.
          </TextParagraph>
          <TextParagraph>
            With the bancassurance partnership between the United Bank for
            Africa (UBA) Nigeria and Heirs General Insurance, customers of UBA
            can now buy insurance products through UBA's digital channels,
            across all UBA branches, as well as other authorized channels.
          </TextParagraph>
          <TextParagraph>
            <a
              href="https://www.heirsinsurance.com/blog/faqs-on-bancassurance-partnership-603"
              style={{ fontSize: "12pt" }}
            >
              Frequently Asked Questions on Bancassurance
            </a>
          </TextParagraph>
        </div>
      </TextContentWrapper>
    </Wrapper>
  );
};

export default Index;
