import React from "react";
import Banner from "./Banner";
import backgroundImage from "../../assets/images/contact-bg.png";
import { Wrapper } from "./styles";
import Contents from "./MotorFaq";

const Index = () => {
  const bannerPayload = {
    title: "Motor Insurance FAQs",
    backgroundImage,
    linkPath: "#read",
    linkText: "Read",
  };
  return (
    <Wrapper>
      <Banner {...bannerPayload} />

      <Contents />

      {/* <Form /> */}
    </Wrapper>
  );
};

export default Index;
