import React, { useEffect } from "react";
import {
  PlanChatTitle,
  PlanChatWrapper,
  Text,
  Title,
  Wrapper,
} from "../../components/PlanPicker/styles";
import {
  TextContentWrapper,
  TitleText,
  TextParagraph,
  ImageWrapper,
} from "./style";

import bancassurance_icon from "../../assets/images/product-image.png";
import { Link } from "react-router-dom";
import { HeirsDashboardUrl } from "../../utils/constants";
import { Mixpanel } from "../../Mixpanel";

const Index = () => {
  return (
    <Wrapper id="excess">
      <ImageWrapper style={{ padding: "32px 32px 2px" }}>
        <img src={bancassurance_icon} style={{ width: "100%" }} />
      </ImageWrapper>
      <TextContentWrapper style={{ padding: "2px 8px" }}>
        {/* <TitleText>
          <span>FAQ</span>
        </TitleText> */}
        <div>
          <TextParagraph style={{ "font-weight": "bold" }}>
            1.) What is Excess Buy Back
          </TextParagraph>
          <TextParagraph style={{ "margin-bottom": "3em" }}>
            First, let's explain what an Excess is. <b>Excess</b> is the fixed
            amount or a percentage of every claim that you, the insured, are
            expected to bear when you have a claim. It is usually a small
            amount, compared to what Heirs General Insurance will pay. It is
            your way of accepting some responsibility to keep your property
            safe. When you have a claim, the fixed amount and the percentage
            option will be assessed. You will be required to pay the higher of
            the two. However, to avoid Excess charges on your claim, you have
            the option to buy the excess back at the start of the policy. <br />
            <b>Excess Buy Back</b>, therefore, is the money you pay to cover up
            for the excess charge at the inception of a policy which is between
            0.5% -1% of the value of the car. Always check your policy document
            for the terms of your cover. <br />
            <b>Note:</b> the option to buy excess back is only for vehicles from
            N2.1m and above.
          </TextParagraph>
          <TextParagraph style={{ "font-weight": "bold" }}>
            2.) What does Betterment in my Insurance Policy mean?
          </TextParagraph>
          <TextParagraph style={{ "margin-bottom": "3em" }}>
            After an accident, if the repairs lead to an improvement in the
            performance or condition of the vehicle before the accident, the
            insured will be made to contribute to the cost of the repair or
            replacement. To explain further, if you suffer damage to the
            right-side passenger door, we are required to repair the damaged
            door and paint the right-side door only. However, if you request
            that the entire car be painted, you will contribute to the cost of
            painting. Heirs General Insurance is only obligated to repair what
            was damaged in the accident. Any other additional repairs or
            replacements will come at an additional cost to you.
          </TextParagraph>
          <TextParagraph style={{ "font-weight": "bold" }}>
            3.) Does my Insurance plan cover mechanical faults?
          </TextParagraph>
          <TextParagraph style={{ "margin-bottom": "3em" }}>
            No, it does not. Your plan only covers repairs/replacement caused by
            accidents and theft as stated in your policy document.
          </TextParagraph>
          <TextParagraph style={{ "font-weight": "bold" }}>
            4.) Can I pay in installments?
          </TextParagraph>
          <TextParagraph style={{ "margin-bottom": "3em" }}>
            Our Motor Insurance policies (except for the Flexi Comprehensive
            plans and Third-Party plans, which have annual payment frequencies)
            have quarterly, bi-annually, and annual premium payment plans.
          </TextParagraph>
          <TextParagraph style={{ "font-weight": "bold" }}>
            5.) Who does the car valuation?
          </TextParagraph>
          <TextParagraph style={{ "margin-bottom": "3em" }}>
            The Insured would indicate the value of the vehicle at the start of
            the policy. However, we advise that you use the current market value
            of the vehicle. Our survey unit will also carry out a pre-loss
            inspection of your vehicle.
          </TextParagraph>
        </div>
      </TextContentWrapper>
    </Wrapper>
  );
};

export default Index;
