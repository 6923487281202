import React from "react";
import ContentPage from "../../components/ContentPage";
import { Heading, Paragraph, Text } from "../../components/ContentPage/styles";
const Privacy = () => {
  return (
    <ContentPage title="Privacy Policy">
      <Paragraph>
        <Text>
          Heirs General Insurance does not claim the Site will operate free of
          errors or that the Site and its servers are free of possibly harmful
          elements.
        </Text>
      </Paragraph>

      <Paragraph>
        <Heading>THIRD PARTY LINKS</Heading>
        <Text>
          The Site may contain links to third party Web Sites. These links are
          provided solely as a convenience to you. Heirs General Insurance/
          Heirs Life Assurance is not responsible for the content of linked
          third party sites and does not make any representations regarding the
          content or accuracy of materials on such third party Web Sites. If you
          decide to access linked third-party Web Sites, you do so at your own
          risk and in accordance with the prevailing terms and conditions and
          privacy policies of third party sites.
        </Text>
      </Paragraph>

      <Paragraph>
        <Heading>GENERAL</Heading>
        <Text>
          Any disputes, claims or proceedings arising out of or in any way
          relating to the materials or the Site shall be governed by the laws of
          the Federal Republic of Nigeria. The Nigerian Courts shall have
          exclusive jurisdiction for the purpose of any proceedings arising out
          of or in any way relating to the materials or the Site. If any
          provision of this Agreement is found to be invalid by any court having
          competent jurisdiction, the invalidity of such provision shall not
          affect the validity of the remaining provisions of this Agreement,
          which shall remain in full force and effect. No waiver of any term of
          this Agreement shall be deemed a further or continuing waiver of such
          term or any other term. Heirs General Insurance may at any time and
          without liability modify, suspend or discontinue the Site or any
          materials (or any part or specification thereof), with or without
          notice, for any valid technical, operational or commercial reasons.
          These Terms and Conditions constitute the entire agreement between you
          and Heirs General Insurance with respect to the use of the Site.
        </Text>
      </Paragraph>
    </ContentPage>
  );
};

export default Privacy;
