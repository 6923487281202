import React from "react";
import FirePlanPicker from "../../components/FirePlanPicker";
import backgroundImage from "../../assets/images/heirs-fire-damages.png";
import Banner from "../../components/ProductBanner";

const Index = () => {
  const bannerPayload = {
    title: "Fire & Special Perils",
    descriptions: [
      "Buildings in the form of houses, offices, shops, factories and more, are huge investments to their owners, likewise the contents in them.",
      "Our Fire & Special Perils cover is for smart property owners who want to protect their cherished assets and shield themselves from the despair of losing huge investments.",
      "If your building is damaged because of fire, lightning, explosion, thunderstrike, flood, earthquake, tornado, impact and other similar risks, Heirs General Insurance will pay to repair the damage or replace the building.",
    ],
    backgroundImage,
    // linkPath: "https://www.youtube.com/channel/UCDrC_N0SMI_ZYtgxhQ24HwQ",
    linkPath: "#fire",
    // linkText: "Watch how it works",
    linkText: "Learn More",
  };
  return (
    <div>
      <Banner {...bannerPayload} />

      <FirePlanPicker />
    </div>
  );
};

export default Index;
