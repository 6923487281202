import React from "react";
import Banner from "./Banner";
import { PageContentWrapper } from "./styles";

const Index = ({ title, children }) => {
  const bannerPayload = {
    title,
    descriptions: [
      "We are a team of professionals who believe that there is a simpler and more interesting way to do insurance.",
    ],
  };
  return (
    <div>
      <Banner {...bannerPayload} />

      <PageContentWrapper>{children}</PageContentWrapper>
    </div>
  );
};

export default Index;
