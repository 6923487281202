import { Rate, Row, Col, Spin, Checkbox } from "antd";
import axios from "axios";
import { CheckCircleOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { HeirsFetchPostsUrl } from "../../utils/constants";
import {
  ClaimFormWrapper,
  ClaimRatingContainer,
  ClaimRatingFormWrapper,
  ClaimRatingCheckboxContainer,
  ClaimRatingInfoContainer,
  ClaimRatingInfoWrapper,
  Title,
} from "../Contact/styles";
import { SubTitle } from "../Contact/bannerStyle";
import SubmitButton from "../../components/Button";

const ClaimsSuccessForm = ({ claimType, insuredName }) => {
  const currentLink = useLocation();
  const history = useHistory();

  const [claimRate, setClaimRate] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [value, setValue] = useState({
    valueFive: false,
    valueFour: false,
    valueThree: false,
    valueTwo: false,
    valueOne: false,
  });

  const handleValueOneRateChange = (e) => {
    const val = e.target.checked;
    setValue({
      valueFive: false,
      valueFour: false,
      valueThree: false,
      valueTwo: false,
      valueOne: true,
    });
    if (val) {
      setClaimRate(1);
    }
  };

  const handleValueTwoRateChange = (e) => {
    const val = e.target.checked;
    setValue({
      valueFive: false,
      valueFour: false,
      valueThree: false,
      valueTwo: true,
      valueOne: false,
    });
    if (val) {
      setClaimRate(2);
    }
  };

  const handleValueThreeRateChange = (e) => {
    const val = e.target.checked;
    setValue({
      valueFive: false,
      valueFour: false,
      valueThree: true,
      valueTwo: false,
      valueOne: false,
    });
    if (val) {
      setClaimRate(3);
    }
  };

  const handleValueFourRateChange = (e) => {
    const val = e.target.checked;
    setValue({
      valueFive: false,
      valueFour: true,
      valueThree: false,
      valueTwo: false,
      valueOne: false,
    });
    if (val) {
      setClaimRate(4);
    }
  };
  const handleValueFiveRateChange = (e) => {
    const val = e.target.checked;
    setValue({
      valueFive: true,
      valueFour: false,
      valueThree: false,
      valueTwo: false,
      valueOne: false,
    });
    if (val) {
      setClaimRate(5);
    }
  };

  const handleRateFormSubmit = async (e) => {
    setIsLoading(true);
    const data = {
      customerName: insuredName,
      rating: claimRate,
    };
    console.log(data);

    const response = await axios({
      method: "POST",
      url: `${HeirsFetchPostsUrl}/rating/insurance`,
      data,
    });
    if (response && response.data.status === "success") {
      setIsLoading(false);
      setIsSuccessful(true);
      history.replace(currentLink.pathname);
    }
  };

  return (
    <ClaimFormWrapper>
      {claimType !== "surrender" ? (
        <>
          <Row gutter={[16, 16]}>
            <Col span={24} md={24} lg={12} xl={12}>
              <ClaimRatingContainer>
                <ClaimRatingFormWrapper>
                  <Title>
                    Thank you for using our fast claims portal. Please rate your
                    experience.
                  </Title>
                  <ClaimRatingCheckboxContainer>
                    <Checkbox
                      name="valueFive"
                      checked={value.valueFive}
                      onChange={handleValueFiveRateChange}
                    >
                      <Rate disabled defaultValue={5} />
                    </Checkbox>
                    <Checkbox
                      name="valueFour"
                      checked={value.valueFour}
                      onChange={handleValueFourRateChange}
                    >
                      <Rate disabled defaultValue={4} />
                    </Checkbox>
                    <Checkbox
                      name="valueThree"
                      checked={value.valueThree}
                      onChange={handleValueThreeRateChange}
                    >
                      <Rate disabled defaultValue={3} />
                    </Checkbox>
                    <Checkbox
                      name="valueTwo"
                      checked={value.valueTwo}
                      onChange={handleValueTwoRateChange}
                    >
                      <Rate disabled defaultValue={2} />
                    </Checkbox>
                    <Checkbox
                      name="valueOne"
                      checked={value.valueOne}
                      onChange={handleValueOneRateChange}
                    >
                      <Rate disabled defaultValue={1} />
                    </Checkbox>
                  </ClaimRatingCheckboxContainer>
                  {isLoading && !isSuccessful ? (
                    <SubmitButton disabled style={{ width: "30%" }}>
                      <Spin size="small" />
                    </SubmitButton>
                  ) : null}
                  {!isLoading && !isSuccessful ? (
                    <SubmitButton
                      style={{ width: "30%" }}
                      htmlType="button"
                      onClick={handleRateFormSubmit}
                    >
                      Submit
                    </SubmitButton>
                  ) : null}
                  {!isLoading && isSuccessful ? (
                    <div>
                      <SubmitButton style={{ width: "30%" }}>
                        Thank you! <CheckCircleOutlined />
                      </SubmitButton>
                      <div>
                        <p>Your Claim has been received successfully</p>
                        <p>A care team member will contact you shortly.</p>
                        <p>Thank you.</p>
                      </div>
                    </div>
                  ) : null}
                </ClaimRatingFormWrapper>
              </ClaimRatingContainer>
            </Col>
            <Col span={24} md={24} lg={12} xl={12}>
              <ClaimRatingInfoContainer>
                <ClaimRatingInfoWrapper>
                  <Rate disabled defaultValue={5} />
                  <SubTitle>Extremely Easy (5)</SubTitle>
                </ClaimRatingInfoWrapper>
                <ClaimRatingInfoWrapper>
                  <Rate disabled defaultValue={4} />
                  <SubTitle>Easy (4)</SubTitle>
                </ClaimRatingInfoWrapper>
                <ClaimRatingInfoWrapper>
                  <Rate disabled defaultValue={3} />
                  <SubTitle>Indifferent (3)</SubTitle>
                </ClaimRatingInfoWrapper>
                <ClaimRatingInfoWrapper>
                  <Rate disabled defaultValue={2} />
                  <SubTitle>Not Easy (2)</SubTitle>
                </ClaimRatingInfoWrapper>
                <ClaimRatingInfoWrapper>
                  <Rate disabled defaultValue={1} />
                  <SubTitle>Difficult (1)</SubTitle>
                </ClaimRatingInfoWrapper>
              </ClaimRatingInfoContainer>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Title>
            Your surrender request has been redirected to the Heirs Care Team.
          </Title>
          <Title>
            You will be contacted by a member of our team shortly. Thank you.
          </Title>
        </>
      )}
    </ClaimFormWrapper>
  );
};

export default ClaimsSuccessForm;
