import React from "react";
import { Content, Wrapper } from "../../components/PlansList/style";
import Section from "../../components/Section";
import ClaimsForm from "./Form";

const ClaimsDetails = () => {
  return (
    <Wrapper id="fast-claims">
      <Content>
        <Section title="">
          <ClaimsForm />
        </Section>
      </Content>
    </Wrapper>
  );
};

export default ClaimsDetails;
