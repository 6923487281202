import React from "react";
import Section from "../Section";
import { Row, Col } from "antd";
import Icon from "@ant-design/icons";
import {
  Wrapper,
  Title,
  Content,
  Text,
  ContentWrapper,
  IconWrapper,
} from "./style";
import { ReactComponent as AlertFill } from "../../assets/images/alert-fill.svg";
import { Video } from "../ProductBanner/styles";
import ReactCountryFlag from "react-country-flag";

const imgcol = {
  height: "200px",
  width: "100%",
  overflow: "hidden",
};
const cols = {
  background: "#fff",
  border: "1px solid #cdcdcd",
};
const pstyle = {
  lineHeight: "18pt",
};

const StoryTeller = () => {
  return (
    <Wrapper>
      <ContentWrapper>
        <Title> </Title>
        <Row gutter={[24, 0]}>
          <Col lg={5} style={cols}>
            <Text>
              <table>
                <tr>
                  <td>
                    <div style={imgcol}>
                      <img
                        src="https://heirsdigitalform.azurewebsites.net/wp-content/uploads/2023/11/lombes.jpeg"
                        width="100%"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingBottom: "15px", lineHeight: "18pt" }}>
                    <span
                      style={{
                        paddingLeft: "5px",
                        fontWeight: "bold",
                        paddingBottom: "18px",
                        display: "inline-block",
                      }}
                    >
                      Margaret Lombe and Judith Lombe
                    </span>
                    <span style={{ display: "block" }}>
                      <ReactCountryFlag countryCode="ZM" svg /> Zambia
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingBottom: "15px" }}>
                    <p style={pstyle}>
                      <b>Gender:</b>{" "}
                    </p>
                    <p style={pstyle}>Females</p>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingBottom: "15px" }}>
                    <p style={pstyle}>
                      <b>Project Type:</b>{" "}
                    </p>
                    <p style={pstyle}>Documentary</p>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingBottom: "15px" }}>
                    <p style={pstyle}>
                      <b>Project Title:</b>{" "}
                    </p>
                    <p style={pstyle}>A Thousand Words By...</p>
                  </td>
                </tr>
              </table>
            </Text>
          </Col>
          <Col offset={0} lg={10}>
            {/* <Text>
              <b>About: </b> <br />
            </Text> */}
            <Text>
              <b>Description of Project: </b>
              <br /> A Thousand Words By... is a documentary showcasing the
              visual arts industry in Zambia with a focus on artists based in
              Lusaka and Livingstone.
            </Text>
            <Text>
              <b>Creative Thought Behind Project: </b>
              <br /> The visual arts industry is largely under-represented and
              not recognised in Zambia. It is treated mostly as a hobby and not
              a real source of employment or income earning, thus discouraging
              people from entering into it as a professional. The idea of this
              documentary is to present visual arts as an exciting, vibrant
              industry with several different players from different backgrounds
              who are becoming successful in this industry as well as changing
              the outlook of this industry. It is expected that the stories
              shared by the participants will inspire others, specifically the
              youth, to consider self-employment in the Zambian visual arts
              industry.
            </Text>
            <Text>
              <b>Impact: </b>
              <br />{" "}
              <ol>
                <li>
                  Through this project ten people plus the employees of the art
                  galleries and paint factory visited during principle
                  production.
                </li>
                <li>
                  It is expected that through this project there will be a
                  change in the perception of the visual arts industry in Zambia
                  and it will be perceived as an avenue of self-employment/
                  formal employment for many in Zambia.
                </li>
              </ol>
            </Text>
          </Col>
          <Col offset={0} lg={9}>
            <Text>
              <b>Project</b>
              <br />
              <a href="https://www.youtube.com/channel/UCSt8hFBp0-F2KhkB5lZgRqA">
                See Youtube Channel
              </a>
              <br />
              <Video>
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/93jyJXvcwjE?si=Reo92yoYB4KxTSfl"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </Video>
            </Text>
            <Text>
              <b>Lesson Learnt While Working on Project: </b>
              <ol>
                <li>
                  Film Management (pre-production; principle production; post
                  production).
                </li>
                <li>Time management and budgeting in film.</li>
                <li>People Management in film.</li>
                <li>
                  The potential the visual arts industry in Zambia has to become
                  a huge player in alleviating unemployment rates in the
                  country, not only through people becoming visual artists but
                  also those who supply paints, canvases, etc to artists.
                </li>
                <li>
                  Entrepreneurship and business management from observing the
                  documentary participants and how they balance their work-life.
                </li>
              </ol>
            </Text>
          </Col>
        </Row>
      </ContentWrapper>
    </Wrapper>
  );
};

export default StoryTeller;
