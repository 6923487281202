import React, { useState } from "react";
import { Row, Col, Modal } from "antd";
import { Wrapper, Content, IconWrapper, Text, Card } from "./style";
import Section from "../../Section";

import Lottie from "lottie-react-web";
import quoteAnimation from "../../../assets/animations/heirs-life-vector1.json";
import affordableAnimation from "../../../assets/animations/heirs-life-vector3.json";
import rewardAnimation from "../../../assets/animations/heirs-life-vector2.json";
import bancs1 from "../../../assets/images/bancs-1.png";
import bancs2 from "../../../assets/images/bancs-2.png";
import bancs3 from "../../../assets/images/bancs-3.png";
import { SecurityScanTwoTone } from "@ant-design/icons";

const defaultOptions = {
  loop: true,
};

const Index = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [textMssg, setTextMssg] = useState(false);

  const showModal = (e) => {
    const text =
      e == "m1"
        ? "You can now enjoy insurance and banking services in one financial hub."
        : e == "m2"
        ? "Save money, secure your finances, and protect your assets, the convenient way."
        : "Buy insurance services from UBA internet banking, UBA mobile app or walk into any UBA branch close to you.";
    setTextMssg(text);
    setModalVisible(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  return (
    <Wrapper>
      <Content>
        <Section title="How Does Bancassurance Benefit You?" filled>
          <Row gutter={[32, 0]}>
            <Col md={8} xs={24}>
              <Card onClick={() => showModal("m1")}>
                <IconWrapper>
                  {/* <Lottie
                    width="100%"
                    options={{
                      ...defaultOptions,
                      animationData: affordableAnimation,
                    }}
                  /> */}
                  <img src={bancs1} />
                </IconWrapper>
                <Text>
                  A One-stop Financial Hub <br />
                  for you
                </Text>
              </Card>
            </Col>
            <Col md={8} xs={24}>
              <Card onClick={() => showModal("m2")}>
                <IconWrapper>
                  {/* <Lottie
                    width="100%"
                    options={{
                      ...defaultOptions,
                      animationData: quoteAnimation,
                    }}
                  /> */}
                  <img src={bancs2} />
                </IconWrapper>
                <Text>Triple Assurance of Convenience</Text>
              </Card>
            </Col>
            <Col md={8} xs={24}>
              <Card onClick={() => showModal("m3")}>
                <IconWrapper>
                  {/* <Lottie
                    width="100%"
                    options={{
                      ...defaultOptions,
                      animationData: rewardAnimation,
                    }}
                  />{" "} */}
                  <img src={bancs3} />
                </IconWrapper>
                <Text>Insurance Services at your Fingertips</Text>
              </Card>
            </Col>
          </Row>
        </Section>
      </Content>
      <Modal visible={modalVisible} onCancel={handleCancel} footer={null}>
        <div
          style={{
            height: "200px",
            width: "100%",
            fontSize: "18pt",
            textAlign: "center",
            padding: "32px 16px",
          }}
        >
          {textMssg}
        </div>
      </Modal>
    </Wrapper>
  );
};

export default Index;
