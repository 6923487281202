import React from "react";
import Banner from "./Banner";
import CorporateSocial from "../../components/CorporateSocial";
import CSR from "../../components/CorporateSocialTEF/wonai.js";
import SubscribeForm from "../../components/SubscribeForm";
import backgroundImage from "../../assets/images/Storeyteller.png";

const Index = () => {
  const bannerPayload = {
    title: "Tony Elumelu Storytellers Fund Grantee",
    descriptions: [" "],
    backgroundImage,
    linkPath: "/corporate-social",
    linkText: "Home",
  };

  return (
    <div>
      <Banner {...bannerPayload} />

      <CSR />
    </div>
  );
};

export default Index;
