import React, { useEffect } from "react";
import { Row, Col } from "antd";
import {
  PlanChatTitle,
  PlanChatWrapper,
  Text,
  Title,
  Wrapper,
} from "../../../components/PlanPicker/styles";
import {
  TextContentWrapper,
  TitleText,
  TextParagraph,
  ImageWrapper,
} from "../style";

import bancassurance_icon from "../../../assets/images/product-image.png";
import { Link } from "react-router-dom";
import { HeirsDashboardUrl } from "../../../utils/constants";
import { Mixpanel } from "../../../Mixpanel";

const Index = () => {
  const travel = [
    {
      th1: "STUDENTS ECONOMY (Worldwide 1)",
      th2: "TOTAL PREMIUM",
      tr1: "6 Months - Max. 180 Consecutive days",
      tr2: "NGN 61,028.76",
      tr3: "9 Months - Max. 276 Consecutive days",
      tr4: "NGN 68,205.12",
      tr5: "1 Year - Max. 365 Consecutive days",
      tr6: "NGN 76,651.68",
    },
    {
      th1: "STUDENTS ECONOMY (Worldwide)",
      th2: "TOTAL PREMIUM",
      tr1: "6 Months - Max. 180 Consecutive days",
      tr2: "NGN 75,559.41",
      tr3: "9 Months - Max. 276 Consecutive days",
      tr4: "NGN 85,004.34",
      tr5: "1 Year - Max. 365 Consecutive days",
      tr6: "NGN 95,175.80",
    },
    {
      th1: "STUDENTS CLASSIC (Worldwide 1)",
      th2: "TOTAL PREMIUM",
      tr1: "6 Months - Max. 180 Consecutive days",
      tr2: "NGN 72,653.28",
      tr3: "9 Months - Max. 276 Consecutive days",
      tr4: "NGN 82,098.21",
      tr5: "1 Year - Max. 365 Consecutive days",
      tr6: "NGN 91,543.13",
    },
    {
      th1: "STUDENTS CLASSIC (Worldwide)",
      th2: "TOTAL PREMIUM",
      tr1: "6 Months - Max. 180 Consecutive days",
      tr2: "NGN 84,643.54",
      tr3: "9 Months - Max. 276 Consecutive days",
      tr4: "NGN 95,541.53",
      tr5: "1 Year - Max. 365 Consecutive days",
      tr6: "NGN 106,800.32",
    },
    {
      th1: "STUDENTS PREMIUM (Worldwide 1)",
      th2: "TOTAL PREMIUM",
      tr1: "6 Months - Max. 180 Consecutive days",
      tr2: "NGN 77,012.48",
      tr3: "9 Months - Max. 276 Consecutive days",
      tr4: "NGN 88,276.21",
      tr5: "1 Year - Max. 365 Consecutive days",
      tr6: "NGN 98,808.46",
    },
    {
      th1: "STUDENTS PREMIUM (Worldwide)",
      th2: "TOTAL PREMIUM",
      tr1: "6 Months - Max. 180 Consecutive days",
      tr2: "NGN 89,729.27",
      tr3: "9 Months - Max. 276 Consecutive days",
      tr4: "NGN 102,806.86",
      tr5: "1 Year - Max. 365 Consecutive days",
      tr6: "NGN 116,245.25",
    },
  ];

  return (
    <Wrapper id="read">
      <TextContentWrapper style={{ padding: "2px 8px", width: "100%" }}>
        <div>
          <TextParagraph
            className="large"
            style={{ "font-weight": "bold", "text-align": "center" }}
          >
            Travel Insurance Premium Justification Table
          </TextParagraph>
          <Row gutter={[24, 0]}>
            {travel.map(({ th1, th2, tr1, tr2, tr3, tr4, tr5, tr6 }, key) => (
              <Col lg={8}>
                <TextParagraph style={{ "margin-bottom": "3em" }}>
                  <table className="table">
                    <tr>
                      <th style={{ background: "#ff0000", color: "#fff" }}>
                        {th1}
                      </th>
                      <th style={{ background: "#ff0000", color: "#fff" }}>
                        {th2}
                      </th>
                    </tr>
                    <tr>
                      <td>{tr1}</td>
                      <td className="_right">{tr2}</td>
                    </tr>
                    <tr>
                      <td>{tr3}</td>
                      <td className="_right">{tr4}</td>
                    </tr>
                    <tr>
                      <td>{tr5}</td>
                      <td className="_right">{tr6}</td>
                    </tr>
                  </table>
                </TextParagraph>
              </Col>
            ))}
          </Row>
        </div>
      </TextContentWrapper>
      {/* <ImageWrapper style={{ padding: "32px 32px 2px" }}>
        <img src={bancassurance_icon} style={{ width: "100%" }} />
      </ImageWrapper> */}
    </Wrapper>
  );
};

export default Index;
