import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  List,
  PlanChatTitle,
  PlanChatWrapper,
  Title,
  Wrapper,
  Text,
} from "../PlanPicker/styles";
import ShopInsurance from "../../assets/images/shop-insurance.png";
// import { HeirsDashboardUrl } from "../../utils/constants";
// import { Mixpanel } from "../../Mixpanel";

const Index = () => {
  const handleChatWithUs = (e) => {
    e.preventDefault();
    window.fcWidget.open({});
  };

  return (
    <Wrapper id="travel">
      <div className="ant-table" id="ant-table">
        <div id="d-fixed" />
        <div id="product-table">
          <table id="table">
            <tbody className="ant-table-tbody">
              <tr>
                <th colspan="4" className="ant-table-cell">
                  <Title>
                    <h3
                      style={{
                        color: "#ff0000",
                        padding: "16px",
                        textAlign: "center",
                      }}
                    >
                      Geographical Scope of Coverage
                    </h3>{" "}
                  </Title>
                </th>
              </tr>
              <tr>
                <td className="ant-table-cell" style={{ width: "40%" }}>
                  <Text bold s12>
                    Worldwide
                  </Text>
                </td>
                <td colspan="3" className="ant-table-cell">
                  <Text s12>
                    Provides worldwide cover except in the country of residence.
                  </Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" style={{ width: "40%" }}>
                  <Text bold s12>
                    Worldwide I
                  </Text>
                </td>
                <td colspan="3" className="ant-table-cell">
                  <Text s12>
                    Provides worldwide cover except in the country of residence,
                    USA, Canada, and Australia
                  </Text>
                </td>
              </tr>
            </tbody>
          </table>

          <table style={{ marginTop: "20px" }}>
            <tbody className="ant-table-tbody">
              <tr>
                <td className="ant-table-cell" style={{ width: "40%" }}></td>
                <td className="ant-table-cell">
                  <Text highlight s11>
                    Students Economy
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text highlight s11>
                    Students Classic
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text highlight s11>
                    Students Premium
                  </Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" style={{ width: "25%" }}>
                  <Text s11>Cost</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>From NGN N61,028.76</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>From NGN 72,653.28</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>From NGN 77,012.48</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" style={{ width: "25%" }}>
                  <Text s11>Destination</Text>
                </td>
                <td className="ant-table-cell" colspan="3">
                  <Text s11>Worldwide or Worldwide I</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" style={{ width: "25%" }}>
                  <Text s11>Pricing</Text>
                </td>
                <td className="ant-table-cell" colspan="3">
                  <Text s11>
                    <a
                      href="/travel-student-pricelist"
                      style={{ width: "130px", padding: "4px 16px" }}
                    >
                      See Price List
                    </a>
                  </Text>
                </td>
              </tr>
              <tr>
                <td
                  className="ant-table-cell"
                  style={{ textAlign: "center" }}
                  colspan="4"
                >
                  <Text highlight>Coverage for You</Text>
                </td>
              </tr>
              <tr>
                <td
                  className="ant-table-cell"
                  style={{ width: "40%" }}
                  colspan="4"
                >
                  <Text bold s11>
                    Medical & Emergency Assistance
                  </Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" style={{ width: "40%" }}>
                  <Text s11>
                    Medical expenses and hospitalisation abroad including
                    COVID-19
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $30,000 (excess $100)</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $60,000 (excess $100)</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $100,000 (excess $100)</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" style={{ width: "40%" }}>
                  <Text s11>
                    Compulsory quarantine if infected with COVID-19
                  </Text>
                </td>
                <td className="ant-table-cell" colspan="3">
                  <Text s11>$85/day (maximum 14 days)</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" style={{ width: "40%" }}>
                  <Text s11>Emergency medical evacuation</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $30,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $30,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $50,000</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" style={{ width: "40%" }}>
                  <Text s11>Repatriation after treatment</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $30,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $30,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $50,000</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" style={{ width: "40%" }}>
                  <Text s11>Emergency dental care</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $400 (excess $70)</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $400 (excess $70)</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $400 (excess $70)</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" style={{ width: "40%" }}>
                  <Text s11>Repatriation of mortal remains</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $15,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $15,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $25,000</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" style={{ width: "40%" }}>
                  <Text s11>
                    Repatriation of family member travelling with the insured
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $2,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $2,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $2,000</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" style={{ width: "40%" }}>
                  <Text s11>
                    Emergency return home following death of a close relative
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $2,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $2,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $2,000</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" style={{ width: "40%" }}>
                  <Text s11>Travel of one immediate family member</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>$100/day (max. $1,000)</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>$100/day (max. $1,000)</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>$100/day (max. $1,000)</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" colspan="4">
                  <Text bold s11>
                    Personal Assistance Services
                  </Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" style={{ width: "40%" }}>
                  <Text s11>24-hour assistance services</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Covered</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" style={{ width: "40%" }}>
                  <Text s11>Delivery of medicines</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $1,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $1,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $1,000</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" style={{ width: "40%" }}>
                  <Text s11>Hijacking in means of public transport</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>$200/hour (max. $6,000) (excess 12 hours)</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>$200/hour (max. $6,000) (excess 12 hours)</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>$200/hour (max. $6,000) (excess 12 hours)</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" colspan="4">
                  <Text bold s11>
                    Baggage
                  </Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" style={{ width: "40%" }}>
                  <Text s11>
                    Loss of passport, driving license, or national identity card
                    abroad
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $500</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $300</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $300</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" style={{ width: "40%" }}>
                  <Text s11>
                    Compensation for in-flight loss of checked-in baggage
                  </Text>
                  <List s11>
                    <li>Per bag</li>
                    <li>Per item</li>
                  </List>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $1,000</Text>
                  <List s11>
                    <li>Up to $500</li>
                    <li>Up to $100</li>
                  </List>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $1,000</Text>
                  <List s11>
                    <li>Up to $500</li>
                    <li>Up to $100</li>
                  </List>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $1,000</Text>
                  <List>
                    <li>Up to $500</li>
                    <li>Up to $100</li>
                  </List>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" style={{ width: "40%" }}>
                  <Text s11>
                    Compensation for delay in the arrival of luggage
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $300</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $300</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $300</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" style={{ width: "40%" }}>
                  <Text s11>Location and forwarding of personal effects</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Covered</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" colspan="4">
                  <Text bold s11>
                    Personal Accident in Means of Public Transport
                  </Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" style={{ width: "40%" }}>
                  <Text s11>
                    Accidental death occurred in means of public transport
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $10,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $10,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $10,000</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" style={{ width: "40%" }}>
                  <Text s11>Personal disability</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>% of principal sum as per scale</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>% of principal sum as per scale</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>% of principal sum as per scale</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" style={{ width: "40%" }}>
                  <Text s11>Permanent total disability</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $10,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $10,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $10,00</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" colspan="4">
                  <Text bold s11>
                    Personal Liability
                  </Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" style={{ width: "40%" }}>
                  <Text s11>Personal civil liability</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $30,000 (excess $150)</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $50,000 (excess $150)</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $50,000 (excess $150)</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" style={{ width: "40%" }}>
                  <Text s11>Legal defence</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $2</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $2</Text>
                </td>
                <td className="ant-table-cell">
                  <Text s11>Up to $2</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" colspan="4">
                  <Text bold s11>
                    Optional Covers - Subject to premium surcharge
                  </Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text bold s11>
                    Winter Sports (product upgrade)
                  </Text>
                </td>
                <td className="ant-table-cell" colspan="3">
                  <Text bold s11>
                    Available as an option
                  </Text>
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }} colspan="4">
                  <Text s12>
                    <a
                      href="https://forms.heirsinsurance.com/travel-insurance/"
                      target="blank"
                      style={{
                        background: "#ff0000",
                        color: "#fff",
                        display: "inline-block",
                        padding: "8px",
                        borderRadius: "5px",
                        width: "200px",
                        height: "auto",
                      }}
                    >
                      Get Started
                    </a>
                  </Text>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Wrapper>
  );
};

export default Index;
