import React from "react";
import Section from "../Section";
import { Row, Col } from "antd";
import Icon from "@ant-design/icons";
import {
  Wrapper,
  Title,
  Content,
  Text,
  ContentWrapper,
  IconWrapper,
} from "./style";
import { ReactComponent as AlertFill } from "../../assets/images/alert-fill.svg";
import { Video } from "../ProductBanner/styles";
import ReactCountryFlag from "react-country-flag";

const imgcol = {
  height: "200px",
  width: "100%",
  overflow: "hidden",
};
const cols = {
  background: "#fff",
  border: "1px solid #cdcdcd",
};
const pstyle = {
  lineHeight: "18pt",
};

const StoryTeller = () => {
  return (
    <Wrapper>
      <ContentWrapper>
        <Title> </Title>
        <Row gutter={[24, 0]}>
          <Col lg={5} style={cols}>
            <Text>
              <table>
                <tr>
                  <td>
                    <div style={imgcol}>
                      <img
                        src="https://heirsdigitalform.azurewebsites.net/wp-content/uploads/2023/08/Dudu.png"
                        width="100%"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingBottom: "15px" }}>
                    <span style={{ paddingLeft: "5px", fontWeight: "bold" }}>
                      Duduzile Luthuli
                    </span>
                    <span style={{ display: "block" }}>
                      <ReactCountryFlag countryCode="ZA" svg /> South Africa
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingBottom: "15px" }}>
                    <p style={pstyle}>
                      <b>Gender:</b>{" "}
                    </p>
                    <p style={pstyle}>Female</p>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingBottom: "15px" }}>
                    <p style={pstyle}>
                      <b>Project Type:</b>{" "}
                    </p>
                    <p style={pstyle}>Digital Zine</p>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingBottom: "15px" }}>
                    <p style={pstyle}>
                      <b>Project Title:</b>{" "}
                    </p>
                    <p style={pstyle}>
                      The Everyday Entrepreneur: Stories From South Africa's
                      Informal Sector
                    </p>
                  </td>
                </tr>
              </table>
            </Text>
          </Col>
          <Col offset={0} lg={10}>
            <Text>
              <b>About: </b> <br />
              Duduzile Luthuli is a writer and marketer who believes in the
              power of words to transform realities and has carved out a career
              across multiple industries including education, finance, telecoms,
              agriculture, and real estate. Her happy place is working with
              business owners, in any sector, who want to offer something
              meaningful, something excellent, and something true to this world.
            </Text>
            <Text>
              <b>Description of Project: </b>
              <br /> The Everyday Entrepreneur: Stories from South Africa's
              informal sector which is a series of articles that will uncover
              the stories of a number of entrepreneurs who have operated in the
              informal economy. The goal is to shed light on a sector that is
              barely covered in a nuanced, layered and dynamic light. This
              project also seeks to contribute to addressing stereotypical
              narratives about Africa by saying: hey! It's also important for us
              to ensure that we do not leave out a large part of the
              entrepreneurial landscape that includes bold self-starters who are
              operating in informal markets. We need to build holistic,
              inclusive and diverse narratives about entrepreneurship that go
              beyond fintech startups that grow to be unicorns, and this is the
              goal of my project.
            </Text>
            <Text>
              <b>Creative Thought Behind Project: </b>
              <br /> What began as a series of articles has morphed into a
              dynamic digital magazine that features interviews with
              entrepreneurs, my own musings, as well as poignant quotes. The
              motivation for this to become a zine instead of a series of
              articles I publish on my blog is to pair my words with
              aesthetically pleasing layouts and imagery, to add another layer
              of flavour to the topic. For the project launch, I will be sharing
              specific quotes and elements of the zine visually, to encourage
              audiences to engage with the work in different ways.
            </Text>
          </Col>
          <Col offset={0} lg={9}>
            <Text>
              <a href="https://theeverydayentrepreneur.co.za/">
                <b>Project: </b>
                <br />
                <img
                  src="https://heirsdigitalform.azurewebsites.net/wp-content/uploads/2023/09/the-everyday-entrepreneur-cover.png"
                  width="100%"
                />
              </a>
            </Text>
            <Text>
              <b>Impact: </b>
              <br /> Over 500 impressions and 100 views so far, the launch all
              within 24 hours.
            </Text>
            <Text>
              <b>Lesson Learnt While Working on Project: </b>
              <br />
              It is better for non-fiction to showcase what is true than to
              force a positive narrative; I should let the stories tell
              themselves (this translated to me using a Q&A format as opposed to
              a narrative piece when it comes to the entrepreneurs' stories);
              and it is important to analyse and ask questions about the
              narratives we form, even when they are formed by us.
            </Text>
          </Col>
        </Row>
      </ContentWrapper>
    </Wrapper>
  );
};

export default StoryTeller;
