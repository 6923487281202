import React from "react";
import Section from "../Section";
import { Row, Col } from "antd";
import Icon from "@ant-design/icons";
import {
  Wrapper,
  Title,
  Content,
  Text,
  ContentWrapper,
  IconWrapper,
} from "./style";
import { ReactComponent as AlertFill } from "../../assets/images/alert-fill.svg";
import { Video } from "../ProductBanner/styles";
import ReactCountryFlag from "react-country-flag";

const imgcol = {
  height: "200px",
  width: "100%",
  overflow: "hidden",
};
const cols = {
  background: "#fff",
  border: "1px solid #cdcdcd",
};
const pstyle = {
  lineHeight: "18pt",
};

const StoryTeller = () => {
  return (
    <Wrapper>
      <ContentWrapper>
        <Title> </Title>
        <Row gutter={[24, 0]}>
          <Col lg={5} style={cols}>
            <Text>
              <table>
                <tr>
                  <td>
                    <div style={imgcol}>
                      <img
                        src="https://heirsdigitalform.azurewebsites.net/wp-content/uploads/2023/08/Dacosta.png"
                        width="100%"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingBottom: "15px" }}>
                    <span style={{ paddingLeft: "5px", fontWeight: "bold" }}>
                      Boakye D. Alpha
                    </span>
                    <span style={{ display: "block" }}>
                      <ReactCountryFlag countryCode="GH" svg /> Ghana
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingBottom: "15px" }}>
                    <p style={pstyle}>
                      <b>Gender:</b>{" "}
                    </p>
                    <p style={pstyle}>Male</p>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingBottom: "15px" }}>
                    <p style={pstyle}>
                      <b>Project Type:</b>{" "}
                    </p>
                    <p style={pstyle}>Creative Non-Fiction</p>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingBottom: "15px" }}>
                    <p style={pstyle}>
                      <b>Project Title:</b>{" "}
                    </p>
                    <p style={pstyle}>
                      Bridging the gap between creativity and entrepreneurship
                      in Ghana
                    </p>
                  </td>
                </tr>
              </table>
            </Text>
          </Col>
          <Col offset={0} lg={10}>
            <Text>
              <b>About: </b> <br />
              Boakye D. Alpha is a multi-talented creative, renowned for his
              diverse contributions in writing, film, and entrepreneurship.
              Boakye has firmly established himself as a versatile artist who
              passionately addresses crucial societal issues and amplifies
              underrepresented voices through his storytelling. His works have
              been published on multiple platforms across the globe and have
              received multiple recognition.
            </Text>
            <Text>
              <b>Description of Project: </b>
              <br /> His project, "Bridging The Gap Between Creativity and
              Entrepreneurship", offers insights and recommendations on how
              creatives can become more than just creators but also individuals
              with entrepreneurial mindset. Through research, interviews, and
              analysis, he sought to identify the challenges faced by creatives
              in Ghana and propose potential solutions for bridging the gap
              between the creative industry and entrepreneurship. Speaking to
              industry experts, the project highlights various sectors that can
              be amplified for the collective growth of the creative ecosystems
              and economy of Ghana and Africa as a whole.
            </Text>
            <Text>
              <b>Creative Thought Behind Project: </b>
              <br /> This project aims to explore ways in which creatives can
              tap into entrepreneurship and create businesses for themselves
              with their crafts and even employ others.
            </Text>
            <Text>
              <b>Impact: </b>
              <br /> As a testament to the magnitude of the project and its
              impact, he had the opportunity to speak at a webinar dubbed
              "Becoming a Profiting Creative Entrepreneur" where over 200 young
              African creatives were in attendance. Feedback from the webinar
              was impressive and there was a need for more from Boakye. Hence, a
              second edition was organised.
            </Text>
          </Col>
          <Col offset={0} lg={9}>
            <Text>
              <a href="#">
                <b>Project: </b>
                <br />
                <img
                  src="https://heirsdigitalform.azurewebsites.net/wp-content/uploads/2023/09/boakye-project.png"
                  width="100%"
                />
              </a>
            </Text>
            <Text>
              <b>Lesson Learnt While Working on Project: </b>
              <ol>
                <li>
                  <b>Recognizing the Gap:</b> One of the most significant
                  lessons I've learned is the stark gap that exists between the
                  creative industry and entrepreneurship in Ghana. While there
                  is no shortage of creative talent, the lack of entrepreneurial
                  skills and support systems is a major hindrance to creatives'
                  success. Understanding this gap was crucial in shaping the
                  direction of my project.
                </li>
                <li>
                  <b>Importance of Research:</b> Research played a pivotal role
                  in this project. I've learned that conducting thorough
                  research, including interviews with industry experts and
                  creatives themselves, is essential for gaining a deeper
                  understanding of the challenges and potential solutions. It
                  allowed me to gather valuable data and insights that formed
                  the basis of my recommendations.
                </li>
                <li>
                  <b>Entrepreneurial Mindset:</b> I've come to appreciate the
                  importance of instilling an entrepreneurial mindset among
                  creatives. Many talented individuals are passionate about
                  their craft but lack the business acumen to turn their
                  creativity into a sustainable livelihood. Encouraging
                  creatives to think beyond their creative lenses and consider
                  the business side of their work is vital.
                </li>
                <li>
                  <b>Collaboration and Networking:</b> Building a network and
                  collaborating with industry experts and fellow creatives is
                  crucial. I've learned that the creative industry thrives on
                  partnerships and collaborations. Working together can open up
                  new opportunities and avenues for growth.
                </li>
              </ol>
            </Text>
          </Col>
        </Row>
      </ContentWrapper>
    </Wrapper>
  );
};

export default StoryTeller;
