import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { routes } from "./routes";

const AppRouter = ({ Router }) => (
  <Router>
    <Switch>
      {routes.map((route, index) => (
        <Route exact key={index} {...route} />
      ))}

      <Redirect to="/" />
    </Switch>
  </Router>
);

export default AppRouter;
