import React, { useEffect, useState } from "react";
import { Row, Col, Checkbox, Modal } from "antd";
import { FormContentWrapper, FormWrapper, Title } from "./styles";
import FormInput from "../../components/Input";
import Button from "../../components/Button";
import { Link } from "react-router-dom";
import axios from "axios";
import { Mixpanel } from "./../../Mixpanel";
import { HeirsEmailUrl } from "../../utils/constants";

const Form = () => {
  const [value, setValue] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    destination: "wecare@heirsgeneralinsurance.com",
    message: "",
  });
  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const [isChecked, setChecked] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);

  const [status, setStatus] = useState({
    submitted: false,
    submitting: false,
    info: { error: false, msg: null },
  });

  useEffect(() => {
    if (
      isChecked &&
      value.firstName &&
      value.lastName &&
      value.email &&
      value.phoneNumber &&
      value.message &&
      !error.firstName &&
      !error.lastName &&
      !error.email &&
      !error.phoneNumber &&
      !error.message
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [value, error, isChecked]);

  const handleServerResponse = (ok, msg) => {
    if (ok) {
      Modal.success({
        content: msg,
      });
      setStatus({
        submitted: true,
        submitting: false,
        info: { error: false, msg: "" },
      });
      Array.from(document.querySelectorAll("input")).forEach(
        (input) => (input.value = "")
      );
      Array.from(document.querySelectorAll("textarea")).forEach(
        (textarea) => (textarea.value = "")
      );
      setIsDisabled(false);
    } else {
      setStatus({
        info: { error: true, msg: msg },
      });
    }
  };

  const handleSubmit = (e) => {
    Mixpanel.track("Send Message");
    const data = { ...value };
    e.preventDefault();
    setStatus((prevStatus) => ({ ...prevStatus, submitting: true }));
    axios({
      method: "POST",
      url: `${HeirsEmailUrl}/email`,
      data: data,
    })
      .then((response) => {
        if (response.status === 200) {
          handleServerResponse(true, response.data.message);
        } else {
          handleServerResponse(false, response.data.message);
        }
      })
      .catch((error) => {
        handleServerResponse(false, "An internal error occured");
      });
  };

  const handleChange = (e) => {
    const val = e.target.value;
    setValue({
      ...value,
      message: val,
    });

    if (val.length === 0) {
      setError({ ...error, message: "message cannot be blank" });
    } else {
      setError({ ...error, message: "" });
    }
  };

  const handleNameChange = (e) => {
    const val = e.target.value;
    if (!/[^a-zA-Z]/.test(val)) {
      setValue({
        ...value,
        [e.target.name]: val,
      });
    } else {
      setValue({
        ...value,
        [e.target.name]: "",
      });
    }

    if (val.length < 3) {
      setError({
        ...error,
        [e.target.name]: "name cannot be less than three characters",
      });
    } else {
      setError({
        ...error,
        [e.target.name]: "",
      });
    }
  };

  const handleEmailChange = (e) => {
    const reg = /\S+@\S+\.\S+/;
    const val = e.target.value;
    setValue({ ...value, email: val });

    if (reg.test(String(val).toLowerCase())) {
      setError({ ...error, email: "" });
    } else {
      setError({
        ...error,
        email: "please enter a valid email address",
      });
    }
  };

  const handlePhoneNumberChange = (e) => {
    const val = e.target.value;
    const reg = /^[0-9]+$/;

    if (reg.test(val)) {
      setValue({ ...value, phoneNumber: val });
    }

    if (val.length < 11 || val.length > 11) {
      setError({
        ...error,
        phoneNumber:
          "your phone number should not be more or less than 11 digits",
      });
    } else {
      setError({
        ...error,
        phoneNumber: "",
      });
    }
  };

  return (
    <FormWrapper>
      <FormContentWrapper>
        <Title>Send us a message</Title>
        <Row gutter={[32, 16]}>
          <Col span={24} md={12}>
            <FormInput
              placeholder="First name"
              name="firstName"
              type="text"
              onChange={handleNameChange}
              value={value.firstName}
            />
            <small>{error.firstName}</small>
          </Col>
          <Col span={24} md={12}>
            <FormInput
              placeholder="Last name"
              name="lastName"
              type="text"
              onChange={handleNameChange}
              value={value.lastName}
            />
            <small>{error.lastName}</small>
          </Col>
          <Col span={24} md={12}>
            <FormInput
              placeholder="Email Address"
              name="email"
              type="email"
              onChange={handleEmailChange}
              value={value.email}
            />
            <small>{error.email}</small>
          </Col>
          <Col span={24} md={12}>
            <FormInput
              placeholder="Phone number"
              name="phoneNumber"
              type="text"
              onChange={handlePhoneNumberChange}
              value={value.phoneNumber}
            />
            <small>{error.phoneNumber}</small>
          </Col>
          <Col span={24}>
            <textarea
              rows={4}
              placeholder="Leave a message"
              name="message"
              onChange={handleChange}
              value={value.message}
            />
            <small>{error.message}</small>
          </Col>
          <Col span={24}>
            <Checkbox
              checked={isChecked}
              onChange={() => setChecked(!isChecked)}
            >
              I agree to the company's{" "}
              <Link to="/terms">Terms and Conditions</Link>
            </Checkbox>
          </Col>
        </Row>
      </FormContentWrapper>
      <Button onClick={handleSubmit} disabled={isDisabled}>
        Send Message
      </Button>
      {status.info.error && (
        <div className="error">Error: {status.info.msg}</div>
      )}
      {!status.info.error && status.info.msg && <p>{status.info.msg}</p>}
      <div style={{ margin: "20px auto" }}>
        <table
          style={{
            padding: "20px",
            border: "1px solid #ff0000",
            margin: "20px auto",
          }}
        >
          <tr>
            <th
              colspan="3"
              style={{
                padding: "5px 10px",
                background: "#ff0000",
                color: "#fff",
              }}
            >
              OUR RETAIL OFFICE
            </th>
          </tr>
          <tr>
            <th style={{ padding: "5px 10px" }}>SN</th>
            <th style={{ padding: "5px 10px", textAlign: "left" }}>LOCATION</th>
            <th style={{ padding: "5px 10px", textAlign: "left" }}>ADDRESS</th>
          </tr>
          <tr>
            <td style={{ padding: "5px 10px", verticalAlign: "top" }}>1.</td>
            <td
              style={{
                padding: "5px 10px",
                textAlign: "left",
                verticalAlign: "top",
              }}
            >
              Abuja
            </td>
            <td
              style={{
                padding: "5px 10px",
                textAlign: "left",
                verticalAlign: "top",
              }}
            >
              18 Ademola Adetokunbo Crescent, Wuse 2, Abuja.{" "}
            </td>
          </tr>
        </table>
      </div>
    </FormWrapper>
  );
};

export default Form;
