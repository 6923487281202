import styled from "styled-components";
// import { BLACK, WHITE } from "../../utils/colors";
import * as Colors from "../../utils/colors";

export const TitleText = styled("h1")`
  font-weight: 600;
  font-size: 2.5em;
  line-height: 55px;
  text-align: left;
  color: #ff0000;

  span {
    display: block;
    text-align: center;
  }

  @media screen and (min-width: 768px) {
    & {
      font-size: 2.5em;
      line-height: 60px;
    }
  }

  @media screen and (max-width: 480px) {
    font-size: 1.7em;
    line-height: 50px;
  }
`;

export const TextContentWrapper = styled("div")`
  @media screen and (min-width: 768px) {
    width: 60%;
    display: inline-block;
    vertical-align: top;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    font-size: 1.2em;
  }

  .table {
    width: 100%;
    border: 1px solid #000;
    // margin: 0 auto 50px;
    // position: relative;

    // table {
    //   @media (min-width: 1200px) {
    //     table-layout: fixed;
    //   }
    // }

    a {
      background: ${Colors.PRIMARY};
      color: ${Colors.WHITE};
      display: flex;
      width: 95px;
      align-items: center;
      justify-content: center;
      height: 40px;
      padding: 0;

      &:hover {
        color: ${Colors.WHITE};
        background: ${Colors.BLACK};
      }
    }

    .p-fixed {
      position: fixed;
      width: 60%;
      top: 70px;
      z-index: 4;

      @media (max-width: 767px) {
        position: initial;
        top: 53px;
        width: 100%;
        overflow-x: scroll;
      }
    }

    #d-fixed {
      background: #fff;
      scroll-behavior: smooth;
    }

    // @media (max-width: 1024px) {
    //   #d-fixed {
    //     display: none !important;
    //   }
    //   #product-table {
    //     .ant-table-thead {
    //       display: revert !important;
    //     }
    //   }
    // }

    // @media screen and (min-width: 992px) {
    //   width: 60%;
    // }

    tr {
      th {
        border-bottom: none;
        background: none;
        vertical-align: bottom;
        position: sticky;
        top: 0;
        border: solid 1px ${Colors.GRAY_15};
        padding: 4px;

        img {
          width: 120px;
        }
      }
      td {
        border: solid 1px ${Colors.GRAY_15};
        padding: 4px;
      }
    }

    ._right {
      text-align: right;
    }
  }

  .large {
    font-size: 24pt;
    color: #ff0000;
    padding: 16pt 4pt;
  }
`;

export const ImageWrapper = styled("div")`
  @media screen and (min-width: 768px) {
    width: 40%;
    display: inline-block;
    vertical-align: top;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

export const TextParagraph = styled("p")`
  margin-top: 10px;
  font-size: 1.1em;

  @media screen and (max-width: 480px) {
    width: 100%;
    font-size: 0.9em;
  }
`;

export const List = styled("ul")`
  li {
    list-style-type: circle;
    font-size: 16px;
    margin-left: 10px;
    padding: 0px 6px;
    ${({ s10 }) => s10 && `font-size: 10pt;`}
    ${({ s11 }) => s11 && `font-size: 11pt;`}
    ${({ s12 }) => s12 && `font-size: 12pt;`}
  }
`;

/*
export const Wrapper = styled("div")`
  text-align: center;
  position: relative;

  background: ${WHITE};
  overflow: hidden;

  ${ButtonWrapper} {
    padding: 20px 45px !important;
    font-size: 25px;
    box-shadow: rgb(241 38 47 / 70%) 1px 10px 17px 1px;

    :hover {
      background: ${BLACK};
    }
  }

  @media screen and (max-width: 768px) {
    ${ButtonWrapper} {
      padding: 10px 25px !important;
    }
  }

  @media screen and (max-width: 480px) {
    ${ButtonWrapper} {
      padding: 10px 25px !important;
    }
  }
`;
*/
