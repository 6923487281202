import React from "react";
import Banner from "../Banner";
import backgroundImage from "../../../assets/images/contact-bg.png";
import { Wrapper } from "../styles";
import Contents from "./studentprice";

const Index = () => {
  const bannerPayload = {
    title: "Travel Insurance Pricing",
    backgroundImage,
    linkPath: "#read",
    linkText: "Student List",
  };
  return (
    <Wrapper>
      <Banner {...bannerPayload} />

      <Contents />

      {/* <Form /> */}
    </Wrapper>
  );
};

export default Index;
