import React from "react";
import {} from "antd";
import { Wrapper, Content } from "./styles";
import Section from "../Section";
import DigitalChannels from "./DigitalChannels";

const Index = () => {
  return (
    <Wrapper id="digitalchannels">
      <Content>
        <Section title="Choose how you want to be served">
          <DigitalChannels />
          {/* <Row gutter={[32, 0]}>
            <Col
              md={8}
              xs={24}
              style={{ cursor: "pointer" }}
              onClick={() => {
                window
                  .open(
                    encodeURI("https://simple.heirsinsurance.com/"),
                    "_blank"
                  )
                  .focus();
              }}
            >
              <Card>
                <IconWrapper>
                  <Icon component={WebIcon} />
                </IconWrapper>
                <Text>Web</Text>
                <SubText>Visit simple.heirsinsurance.com</SubText>
              </Card>
            </Col>
            <Col
              md={8}
              xs={24}
              style={{ cursor: "pointer" }}
              onClick={() => {
                window
                  .open("https://wa.me/2349122222200?text=Hi", "_blank")
                  .focus();
              }}
            >
              <Card>
                <IconWrapper>
                  <Icon component={WhatsAppIcon} />
                </IconWrapper>
                <Text>WhatsApp</Text>
                <SubText>Chat with us on 09122222200</SubText>
              </Card>
            </Col>
            <Col
              md={8}
              xs={24}
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.open(encodeURI("tel:*1100#"), "_blank").focus();
              }}
            >
              <Card>
                <IconWrapper>
                  <Icon component={USSDIcon} />
                </IconWrapper>
                <Text>USSD</Text>
                <SubText>Dial *1100# to get started</SubText>
              </Card>
            </Col>
          </Row>
          <Row gutter={[32, 0]}>
            <Col offset={4} md={8} xs={24}>
              <Card>
                <IconWrapper>
                  <Icon component={MobileIcon} />
                </IconWrapper>
                <Text>Mobile App</Text>
                <SubText>Coming Soon</SubText>
              </Card>
            </Col>
            <Col md={8} xs={24}>
              <Card>
                <IconWrapper>
                  <Icon component={Web2Icon} />
                </IconWrapper>
                <Text>Partners Portal</Text>
                <SubText>Coming Soon</SubText>
              </Card>
            </Col>
          </Row> */}
        </Section>
      </Content>
    </Wrapper>
  );
};

export default Index;
