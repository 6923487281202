import React, { useEffect } from "react";
import { Row, Col } from "antd";
import {
  PlanChatTitle,
  PlanChatWrapper,
  Text,
  Title,
  Wrapper,
} from "../../../components/PlanPicker/styles";
import {
  TextContentWrapper,
  TitleText,
  TextParagraph,
  ImageWrapper,
} from "../style";

import bancassurance_icon from "../../../assets/images/product-image.png";
import { Link } from "react-router-dom";
import { HeirsDashboardUrl } from "../../../utils/constants";
import { Mixpanel } from "../../../Mixpanel";

const Index = () => {
  const travel = [
    {
      th1: "PILGRIMAGE PROTECTION BASIC",
      th2: "TOTAL PREMIUM",
      tr1: "Up to 15 days",
      tr2: "NGN 2,545.34",
      tr3: "Up to 30 days",
      tr4: "NGN 5,451.47",
      tr5: "Up to 45 days",
      tr6: "NGN 8,357.60",
    },
    {
      th1: "PILGRIMAGE PROTECTION PLUS",
      th2: "TOTAL PREMIUM",
      tr1: "Up to 15 days",
      tr2: "NGN 3,271.87",
      tr3: "Up to 30 days",
      tr4: "NGN 7,265.33",
      tr5: "Up to 45 days",
      tr6: "NGN 9,444.93",
    },
    {
      th1: "PILGRIMAGE PROTECTION EXTRA",
      th2: "TOTAL PREMIUM",
      tr1: "Up to 15 days",
      tr2: "NGN 3,632.66",
      tr3: "Up to 30 days",
      tr4: "NGN 8,357.60",
      tr5: "Up to 45 days",
      tr6: "NGN 11,263.73",
    },
  ];

  return (
    <Wrapper id="read">
      <TextContentWrapper style={{ padding: "2px 8px", width: "100%" }}>
        <div>
          <TextParagraph
            className="large"
            style={{ "font-weight": "bold", "text-align": "center" }}
          >
            Travel Insurance Premium Justification Table
          </TextParagraph>
          <Row gutter={[24, 0]}>
            {travel.map(({ th1, th2, tr1, tr2, tr3, tr4, tr5, tr6 }, key) => (
              <Col lg={8}>
                <TextParagraph style={{ "margin-bottom": "3em" }}>
                  <table className="table">
                    <tr>
                      <th style={{ background: "#ff0000", color: "#fff" }}>
                        {th1}
                      </th>
                      <th style={{ background: "#ff0000", color: "#fff" }}>
                        {th2}
                      </th>
                    </tr>
                    <tr>
                      <td>{tr1}</td>
                      <td className="_right">{tr2}</td>
                    </tr>
                    <tr>
                      <td>{tr3}</td>
                      <td className="_right">{tr4}</td>
                    </tr>
                    <tr>
                      <td>{tr5}</td>
                      <td className="_right">{tr6}</td>
                    </tr>
                  </table>
                </TextParagraph>
              </Col>
            ))}
          </Row>
        </div>
      </TextContentWrapper>
      {/* <ImageWrapper style={{ padding: "32px 32px 2px" }}>
        <img src={bancassurance_icon} style={{ width: "100%" }} />
      </ImageWrapper> */}
    </Wrapper>
  );
};

export default Index;
