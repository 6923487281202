/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Col, Modal, Form } from "antd";
import Icon, { WindowsFilled } from "@ant-design/icons";
import {
  PlanWrapper,
  Title,
  Text,
  LinksWrapper,
  LinksWrapperGrey,
  FormWrapper,
} from "../style";
import { Mixpanel } from "./../../../Mixpanel";
import FormInput from "../../../components/Input";
import axios from "axios";
import { HeirsEmailUrl } from "../../../utils/constants";
import { DatePickerWrapper } from "../../../components/Input/style";

const Plan = ({ title, icon, textmsg, url, mixpanel, quote, inx }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [date, setDate] = useState("");

  const [status, setStatus] = useState({
    submitted: false,
    submitting: false,
    info: { error: false, msg: null },
  });

  const handleClick = () => {
    Mixpanel.track(mixpanel);
    window.location.pathname = quote;
  };

  const handleDetails = () => {
    Mixpanel.track(mixpanel);
    window.open(quote);
    /*
    const data = {
      firstName: "",
      lastName: "",
      email: email,
      phoneNumber: "",
      destination: [
        "wecare@heirsgeneralinsurance.com",
        "nimiweremi.selekere@heirslifeassurance.com",
      ],
      message: date,
      plan: title,
    };
    axios({
      method: "POST",
      url: `${HeirsEmailUrl}/email/others`,
      data: data,
    })
      .then((response) => {
        if (response.status === 200) {
          handleServerResponse(true, response.data.message);
          // setTimeout(() => {
          //   history.push("/flexi-motor");
          // }, 5000);
        } else {
          handleServerResponse(false, response.data.message);
        }
      })
      .catch((error) => {
        handleServerResponse(false, "An internal error occured");
      }); */
  };

  const handleDateChange = (e) => {
    setDate(`Remind me on ${e._d}`);
  };

  const handleServerResponse = (ok, msg) => {
    if (ok) {
      Modal.success({
        content: msg,
      });
      setStatus({
        submitted: true,
        submitting: false,
        info: { error: false, msg: msg },
      });
      Array.from(document.querySelectorAll("input")).forEach(
        (input) => (input.value = "")
      );
      Array.from(document.querySelectorAll("textarea")).forEach(
        (textarea) => (textarea.value = "")
      );
      // setIsDisabled(false);
    } else {
      setStatus({
        info: { error: true, msg: msg },
      });
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const showModal = () => {
    // !inx ? setModalVisible(true) : window.open(quote);
    setModalVisible(true);
  };

  const showText = !inx ? "BUY LATER" : "GET QUOTE";
  const dtext = !inx ? "" : "";
  const mim = !inx ? "minimum" : "";

  return (
    <>
      <Col xs={12} sm={8} md={6} lg={6}>
        <PlanWrapper>
          <a href={quote} onClick={handleClick}>
            <Icon component={icon} />
          </a>
          <Title>{title}</Title>
          {/* <Text>
            {price} {mim}
          </Text> */}
          <LinksWrapperGrey style={{ display: dtext }}>
            <a onClick={showModal}>Details</a>
            {/* <a onClick={showModal}>{showText}</a> */}
          </LinksWrapperGrey>
          {/* <LinksWrapper onClick={handleClick}>
            <a href={quote}>Buy Now</a>
          </LinksWrapper> */}
        </PlanWrapper>
      </Col>
      <Modal
        visible={modalVisible}
        onCancel={handleCancel}
        okText="Buy Now"
        onOk={() => handleDetails(title)}
      >
        <Col>
          <h1>{title}</h1>
        </Col>
        <FormWrapper>
          <Col span={24} style={{ padding: 0 }}>
            {textmsg}
          </Col>
        </FormWrapper>
        {status.info.error && (
          <div className="error">Error: {status.info.msg}</div>
        )}
        {!status.info.error && status.info.msg && <p>{status.info.msg}</p>}
      </Modal>
    </>
  );
};

export default Plan;
