import React from "react";
import Banner from "../../components/ProductBanner";
import ThirdPartyMotorPlanPicker from "../../components/ThirdPartyMotorPlanPicker";
import backgroundImage from "../../assets/images/THird_party_motor.png";

const Index = () => {
  const bannerPayload = {
    title: "Third Party Motor",
    descriptions: [
      "Choose our basic motor protection to shield you from the cost of damage caused by you to another person’s vehicle.",
      // "Our Third-Party motor plan comes with extra protection for you and your vehicle too.",
    ],
    backgroundImage,
    // linkPath: "https://www.youtube.com/channel/UCDrC_N0SMI_ZYtgxhQ24HwQ",
    linkPath: "#third-party",
    // linkText: "Watch how it works",
    linkText: "Learn More",
  };
  return (
    <div>
      <Banner {...bannerPayload} />

      <ThirdPartyMotorPlanPicker />
    </div>
  );
};

export default Index;
