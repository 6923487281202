import React from "react";
import PersonalPlanPicker from "../../components/PersonalPlanPicker";
import backgroundImage from "../../assets/images/personal_accident.png";
import Banner from "../../components/ProductBanner";

const Index = () => {
  const bannerPayload = {
    title: "Personal Accident",
    descriptions: [
      "Nobody ever prays for accidents; unfortunately, they happen without notice and can drastically alter one's life.",
      "If you are involved in an accident, Heirs General Insurance will take care of your medical bills.",
      "If you are permanently disabled, as a result of the accident, we will pay you a lump sum.",
      "Our Personal Accident plan also comes with a life insurance benefit which pays a lump sum to your beneficiary if you don’t survive the incident.",
    ],
    backgroundImage,
    // linkPath: "https://www.youtube.com/channel/UCDrC_N0SMI_ZYtgxhQ24HwQ",
    linkPath: "#accidents",
    // linkText: "Watch how it works",
    linkText: "Learn More",
  };
  return (
    <div>
      <Banner {...bannerPayload} />

      <PersonalPlanPicker />
    </div>
  );
};

export default Index;
