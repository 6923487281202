import React from "react";
import { Menu, Grid } from "antd";
import { HeirsDashboardUrl } from "../../../../utils/constants";
// import { Link } from "react-router-dom";
import { Mixpanel } from "./../../../../Mixpanel";
// import SubMenu from "antd/lib/menu/SubMenu";
// import { HeirsLifeExternalUrl } from "../../../../utils/constants";

const { useBreakpoint } = Grid;

const RightMenu = ({ onLandingPage }) => {
  const { lg } = useBreakpoint();
  return (
    <Menu mode={lg ? "horizontal" : "inline"} style={{ width: "25vw" }}>
      {onLandingPage ? (
        <>
          <Menu.Item key="app">
            <a
              onClick={Mixpanel.track("Self-Service")}
              href={`${HeirsDashboardUrl}/signin`}
            >
              Self Service
            </a>
          </Menu.Item>
          <Menu.Item key="hil claim">
            <a onClick={Mixpanel.track("Claims")} href="/claims">
              Fast Claims
            </a>
          </Menu.Item>
          {/* <Menu.Item key="complaints">
            <a onClick={Mixpanel.track("Complaint")} href="/complaint">
              Complaint
            </a>
          </Menu.Item> */}
          {/*<Menu.Item key="pay">
            <a href={`${HeirsDashboardUrl}/signin`}>Pay Premium</a>
          </Menu.Item> */}
          {/* <SubMenu title="Fast Claims" id="right">
            <Menu.Item key="hil claim">
              <a onClick={Mixpanel.track("HGI claims")} href="/claims">
                Heirs Insurance
              </a>
            </Menu.Item>
            <Menu.Item key="hil claim">
              <a
                onClick={Mixpanel.track("HGI claims")}
                href={`${HeirsLifeExternalUrl}/claims`}
              >
                Heirs Life
              </a>
            </Menu.Item>
          </SubMenu> */}
        </>
      ) : (
        <>
          <Menu.Item key="app" className="">
            <a
              onClick={Mixpanel.track("Self-Service")}
              href={`${HeirsDashboardUrl}/signin`}
            >
              Self Service
            </a>
          </Menu.Item>
          <Menu.Item key="hil claim">
            <a onClick={Mixpanel.track("Claims")} href="/claims">
              Fast Claims
            </a>
          </Menu.Item>
          <Menu.Item key="verify">
            <a
              onClick={Mixpanel.track("NIID Portal")}
              href="https://askniid.org/verifypolicy.aspx"
              target="blank"
            >
              Verify Car Policy
            </a>
          </Menu.Item>
        </>
      )}
    </Menu>
  );
};

export default RightMenu;
