import React from "react";
import Banner from "../../components/ProductBanner";
import HerMotorPlanPicker from "../../components/HerMotorPlanPicker";
import backgroundImage from "../../assets/images/Flexi&comprehensive_motor.png";

const Index = () => {
  const bannerPayload = {
    title: " Her Motor Insurance Cover",
    descriptions: [
      "If you are a female car owner and you want to drive with peace of mind, then this plan is for you.",
      "Her Motor Insurance Cover is a comprehensive motor insurance that shields women from the exorbitant costs of repairing or replacing their vehicle in the event of an accident, theft or fire incident. If your car is damaged or destroyed, Heirs General Insurance pays to fix or replace the car.",
      "A key benefit of this plan is the 24 hours rescue and assistance you get if your car breaks down on the road.",
    ],
    backgroundImage,
    // linkPath: "https://www.youtube.com/channel/UCDrC_N0SMI_ZYtgxhQ24HwQ",
    productURL:
      "https://www.youtube.com/embed/6kDcSGIP_-c?list=PLKzX6UG7Pf2mUnoDdk3fK9v8nVcfHN6q4&rel=0&controls=0",
    linkPath: "#her-motor",
    // linkText: "Watch how it works",
    linkText: "Learn More",
  };
  return (
    <div>
      <Banner {...bannerPayload} />

      <HerMotorPlanPicker />
    </div>
  );
};

export default Index;
