import React from "react";
import { Wrapper, Title, BannerContentWrapper } from "./styles";
const Banner = ({ title }) => {
  return (
    <Wrapper>
      <BannerContentWrapper>
        <Title> {title}</Title>
      </BannerContentWrapper>
    </Wrapper>
  );
};

export default Banner;
