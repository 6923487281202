import React from "react";
import backgroundImage from "../../assets/images/Hoeprotect.png";
import Banner from "../../components/ProductBanner";
import HullPlanPicker from "../../components/HullPlanPicker";

const Index = () => {
  const bannerPayload = {
    title: "Marine Hull Insurance",
    descriptions: [
      "It costs a fortune to acquire boats, ship, tankers, etc. That is why owners and hirers of water-faring vessels should consider buying the Marine Hull cover.",
      "Our Marine Hull Insurance saves you the exorbitant cost of replacing your water-faring vessel, if damaged by fire, accident, or other related water perils. In the case that your vessel is damaged by the listed risks, Heirs General Insurance will pay the cost to repair or replace it.",
      "In addition, our Marine Hull Insurance covers you- the vessel owner or hirer, against legal issues arising from seepage caused by your damaged vessel.",
      "<b>Vessels that can be covered under this policy include:</b>",
      `<ul>
            <li>Oil Tanker Vessels</li>
            <li>Passenger Carrying Vessel</li>
            <li>Fishing Trawlers</li>
            <li>Ocean Going Vessels</li>
            <li>General Cargo Vessels</li>
            <li>Rigs</li>
            <li.Etc.</li>
        </ul>`,
    ],
    backgroundImage,
    // linkPath: "https://www.youtube.com/channel/UCDrC_N0SMI_ZYtgxhQ24HwQ",
    linkPath: "#hull",
    // linkText: "Watch how it works",
    linkText: "Learn More",
  };
  return (
    <div>
      <Banner {...bannerPayload} />

      <HullPlanPicker />
    </div>
  );
};

export default Index;
