import styled from "styled-components";
import { ButtonWrapper } from "../../components/Button/style";
import { InputWrapper } from "../../components/Input/style";
import * as Colors from "../../utils/colors";
import { containerWrapper } from "../../utils/stylescheet";

export const Content = styled("div")`
  margin-top: 25px;

  @media screen and (max-width: 575px) {
    margin-top: 10px;
  }
`;

export const AnswersWrapper = styled("div")`
  padding: 40px 0;
  ${containerWrapper}

  ${InputWrapper} {
    width: 100% !important;
  }

  .ant-row {
    margin-left: 0 !important;
    margin-right: 0 !important;

    @media (min-width: 1025px) {
      margin-left: -32px !important;
      margin-right: -32px !important;
    }
  }
`;

export const PillsWrapper = styled("div")`
  margin-bottom: 15px;
`;
export const PillItemWrapper = styled("div")`
  display: inline-block;
  padding: 13px 20px;
  margin-right: 8px;
  border-radius: 8px;
  color: ${Colors.PRIMARY};

  &:first-of-type {
    margin-left: 0;
  }

  ${({ active }) =>
    active && `color: ${Colors.WHITE}; background: ${Colors.PRIMARY}`}
`;

export const AnswerItemWrapper = styled("div")`
  margin: 25px 0;
  cursor: pointer;

  :hover {
    -ms-transform: translate(5px, -5px);
    transform: translate(5px, -5px);
  }

  img {
    width: 100%;
    margin-bottom: 15px;
  }
`;
export const Title = styled("h2")`
  // font-family: Mulish, sans-serif;
  line-height: 30px;
`;

export const Text = styled("p")`
  font-size: 14px;
`;

export const Wrapper = styled("div")`
  ${ButtonWrapper} {
    padding: 7px 18px !important;
    font-family: Mulish, sans-serif !important;
    text-transform: uppercase;
    display: inline-block;
  }

  ${InputWrapper} {
    display: inline-block;
    margin-right: 20px;
  }
`;

export const ImageContainer = styled("div")`
  background-image: ${({ backgroundImage }) =>
    backgroundImage ? "url(" + backgroundImage + ")" : ""};
  background-repeat: no-repeat;
  background-size: contain;
  height: 20rem;
  background-position: center;

  @media (max-width: 767px) {
    background-size: contain;
    height: 18rem;
  }
`;
