import styled from "styled-components";
import * as Colors from "../../utils/colors";
import { containerWrapper } from "../../utils/stylescheet";

export const Title = styled("h1")`
  font-size: 55px;
  line-height: 60px;
  color: ${Colors.TEXT_DEFAULT};
  margin-bottom: 0;

  @media screen and (max-width: 991px) {
    font-size: 35px;
    line-height: 35px;
  }

  @media screen and (max-width: 767px) {
    font-size: 25px;
    line-height: 25px;
  }

  @media screen and (max-width: 576px) {
    margin-top: 10%;
    font-size: 18px;
    line-height: 17px;
  }
`;

export const Text = styled("p")`
  color: ${Colors.TEXT_DEFAULT};
  font-size: 20px;
  margin-bottom: 20px;

  @media screen and (max-width: 991px) {
    width: 50%;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const BannerContentWrapper = styled("div")`
  @media screen and (max-width: 575px) {
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgb(255 255 255 / 50%);
  }

  ${containerWrapper}
`;

export const BannerContent = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media screen and (min-width: 576px) {
    width: 50%;
  }

  @media screen and (min-width: 768px) {
    width: 75%;
  }

  @media screen and (min-width: 992px) {
    width: 50%;
  }
`;

export const Overlay = styled("div")`
  background-color: rgb(243 84 91 / 7%);
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
`;

export const Wrapper = styled("div")`
  min-height: 200px;
  background-image: ${({ backgroundImage }) =>
    backgroundImage ? "url(" + backgroundImage + ")" : ""};
  background-repeat: no-repeat;
  background-position: 85% center;
  background-size: 400px;
  background-color: rgb(243 84 91 / 7%);
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 4.4%;
  @media screen and (min-width: 576px) {
    min-height: 250px;
  }

  @media screen and (min-width: 768px) {
    min-height: 300px;
  }

  @media screen and (min-width: 992px) {
    min-height: 350px;
  }

  @media screen and (min-width: 1200px) {
    min-height: 450px;
  }

  @media screen and (min-width: 1600px) {
    min-height: 550px;
  }

  @media screen and (max-width: 1024px) {
    background-image: none;
  }
`;
export const LinkWrapper = styled("div")`
  margin-top: 15px;
  display: inline-block;
  background: ${Colors.PRIMARY};

  @media screen and (max-width: 767px) {
    margin-top: 5px;
  }

  a {
    padding: 10px 20px;
    color: ${Colors.WHITE};
    display: flex;
    align-items: center;
    font-weight: bold;

    svg {
      width: 20px;
      height: 20px;
      margin-left: 10px;
    }

    &:hover {
      color: ${Colors.WHITE};
      background: ${Colors.BLACK};
    }
  }
`;
