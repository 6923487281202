import { css } from "styled-components";
import { GRAY_10, PRIMARY } from "./colors";

export const containerWrapper = css`
  padding-left: 15px;
  padding-right: 15px;
  margin: auto;
  width: 100%;

  @media (min-width: 768px) {
    width: 750px;
  }

  @media (min-width: 992px) {
    width: 970px;
  }

  @media (min-width: 1200px) {
    width: 1170px;
  }

  @media (min-width: 1600px) {
    width: 1450px;
  }
`;

export const gradientPrimaryBackground = css`
  background: linear-gradient(
    43deg,
    #fe0000 4%,
    rgba(243, 99, 102, 0.8) 98%,
    rgba(250, 173, 174, 1) 100%
  );
`;

export const gradientGreyBackground = css`
  background: linear-gradient(
    43deg,
    #666769 4%,
    rgb(199 199 199) 98%,
    rgb(246 246 246) 100%
  );
`;

export const lightGrayBackgroundGradient = css`
  background: linear-gradient(
    179deg,
    rgb(255 255 255) 44%,
    rgb(247 247 247 / 10%) 98%,
    rgb(247 247 247) 100%
  );
`;

export const boxShadow = css`
  box-shadow: 1px 6px 14px 0px #b3b3b330;
`;
export const boxShadowDark5 = css`
  box-shadow: 1px 6px 14px 0px #b3b3b396;
`;

export const primaryBoxShadow = css`
  box-shadow: 1px 10px 17px 1px #fe000085;
`;

export const primaryBoxShadowWt5 = css`
  box-shadow: 1px 10px 17px 1px #fe000038;
`;

export const otherPrimaryBoxShadowWt5 = css`
  box-shadow: 1px 10px 17px 1px #b3b0b0c2;
`;

export const FormInput = css`
  color: ${GRAY_10};
  height: auto !important;
  padding: 10px 25px !important;
  border: solid 1px ${PRIMARY} !important;
  border-radius: 5px;
  ${boxShadow};

  &:focus {
    box-shadow: none;
  }
`;
