import React from "react";
import Section from "../Section";
import { Row, Col } from "antd";
import {
  Wrapper,
  Title,
  Content,
  TextContent,
  ContentWrapper,
  DownloadWrapper,
} from "./style";
// import Button from "../Button";
import callBackImage from "../../assets/images/requestCallback.png";
import appleStore from "../../assets/images/appstore.png";
import googlePlay from "../../assets/images/playstore.png";

import { Tooltip } from "antd";
import Form from "./Form";
import { Mixpanel } from "./../../Mixpanel";

let isSet = false;

const handleClick = () => {
  isSet = !isSet;
};

const Testimonial = () => {
  return (
    <Wrapper>
      <Section>
        <ContentWrapper>
          <Content>
            <TextContent>
              <Title style={{ fontSize: "40px" }}>
                Download Our Mobile App
              </Title>
              <Row gutter={0}>
                <Col sm={8} xs={12}>
                  <img
                    src={appleStore}
                    alt={"apple"}
                    style={{ marginRight: "10px", marginBottom: "10px" }}
                  />
                  <img src={googlePlay} alt={"google"} />
                </Col>
                {/* <Col span={24} md={12}>
                  <a
                    // onClick={Mixpanel.track("Call Us Now")}
                    onClick={handleClick()}
                    href="#complaint"
                  >
                    Send a Message
                  </a>
                  {/* </Tooltip> */}
                {/* </Col> */}
              </Row>
            </TextContent>

            <div>
              <img src={callBackImage} alt="call back" />
            </div>
          </Content>
          {isSet ? <Form /> : null}
        </ContentWrapper>
      </Section>
    </Wrapper>
  );
};

export default Testimonial;
