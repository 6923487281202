import React, { useEffect, useState } from "react";
import { List, Spin } from "antd";
import axios from "axios";
import {
  AnswerItemWrapper,
  AnswersWrapper,
  ImageContainer,
  Text,
  Title,
} from "./styles";
import { useHistory } from "react-router-dom";
import { HeirsFetchPostsUrl, HeirsWordPressUrl } from "../../utils/constants";
import { dateFormat } from "../../utils/dateFormatter";
import { ContentLoader } from "../MediaPost/styles";
import SubscribeForm from "../../components/SubscribeForm";

const Media = () => {
  const history = useHistory();
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPosts, setTotalPosts] = useState(1);
  const [pageSize, setPageSize] = useState(1);

  const handlePostDisplay = (item) => {
    const title = item.post_title.toLowerCase();
    history.push(`/blog/${title.replace(/\s+/g, "-")}-${item.ID}`);
  };

  const fetchPosts = (pageNum) => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    if (pageNum) {
      setIsLoading(true);
    }
    axios({
      method: "GET",
      url: pageNum
        ? `${HeirsFetchPostsUrl}/posts?slug=heirs-insurance-blog&limit=10&page=${pageNum}`
        : `${HeirsFetchPostsUrl}/posts?slug=heirs-insurance-blog&limit=10`,
    }).then((response) => {
      const { data } = response.data;
      const validPosts = [];
      data[0].posts.map((post) => {
        if (post.categories.includes("heirs insurance blog")) {
          validPosts.push(post);
        }
        setPageSize(validPosts.length);
        return validPosts;
      });
      setTotalPosts(data[0].totalPages * validPosts.length);
      setCurrentPage(pageNum ? pageNum : 1);
      setPosts(validPosts);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  const handlePageChange = (page) => {
    fetchPosts(page);
  };

  return (
    <>
      <AnswersWrapper>
        {!isLoading ? (
          <List
            pagination={{
              position: "bottom",
              total: totalPosts,
              pageSize: pageSize,
              current: currentPage,
              onChange: (page) => {
                handlePageChange(page);
              },
            }}
            grid={{
              gutter: 64,
              xs: 1,
              sm: 1,
              md: 1,
              lg: 2,
              xl: 2,
              xxl: 2,
            }}
            dataSource={posts}
            renderItem={(item) => (
              <List.Item key={item.ID}>
                <AnswerItemWrapper onClick={() => handlePostDisplay(item)}>
                  <ImageContainer
                    backgroundImage={`${HeirsWordPressUrl}${item.image}`}
                  />
                  <Text>{dateFormat(item.post_date)}</Text>
                  <Title>{item.post_title}</Title>
                </AnswerItemWrapper>
              </List.Item>
            )}
          />
        ) : (
          <ContentLoader>
            <Spin size="large" />
          </ContentLoader>
        )}
      </AnswersWrapper>
      <SubscribeForm />
    </>
  );
};

export default Media;
