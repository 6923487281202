import React, { useEffect } from "react";
import {
  PlanChatTitle,
  PlanChatWrapper,
  Text,
  Title,
  Wrapper,
} from "../../components/PlanPicker/styles";
import {
  TextContentWrapper,
  TitleText,
  TextParagraph,
  ImageWrapper,
  List,
} from "./style";

import bancassurance_icon from "../../assets/images/product-image.png";
import { Link } from "react-router-dom";
import { HeirsDashboardUrl } from "../../utils/constants";
import { Mixpanel } from "../../Mixpanel";

const Index = () => {
  return (
    <Wrapper id="read">
      <ImageWrapper style={{ padding: "32px 32px 2px" }}>
        <img src={bancassurance_icon} style={{ width: "100%" }} />
      </ImageWrapper>
      <TextContentWrapper style={{ padding: "2px 8px" }}>
        <TitleText>
          <span>TERMS & CONDITIONS</span>
        </TitleText>
        <div>
          <TextParagraph style={{ "font-weight": "bold" }}>
            1.) Third-Party Insurance:
          </TextParagraph>
          <TextParagraph style={{ "margin-bottom": "3em" }}>
            <List>
              <li>
                Purchase a Third-party motor insurance plan from any Heirs
                Insurance Group channels.
              </li>
              <li>
                Your phone number used in purchasing the motor insurance plan
                will be credited with #1,000 Airtime within 72 working hours.
              </li>
              <li>
                You automatically qualify to enter the raffle draw to win one
                (1) of the twenty-five (25) available #100,000 fuel vouchers.
              </li>
            </List>
          </TextParagraph>
          <TextParagraph style={{ "font-weight": "bold" }}>
            2.) Comprehensive Motor Insurance:
          </TextParagraph>
          <TextParagraph style={{ "margin-bottom": "3em" }}>
            <List>
              <li>
                Purchase/renew your comprehensive plan from any Heirs Insurance
                Group Channels.
              </li>
              <li>
                You will automatically receive a #3,000 shopping voucher via the
                email used in purchasing the insurance policy within 72 working
                hours.
              </li>
              <li>
                This automatically qualifies you to enter the raffle draw to win
                one (1) of the twenty-five (25) available #100,000 fuel
                vouchers.
              </li>
            </List>
          </TextParagraph>
          <TextParagraph style={{ "font-weight": "bold" }}>
            3.) How the Raffle Draw for Fuel Voucher works:
          </TextParagraph>
          <TextParagraph style={{ "margin-bottom": "3em" }}>
            <List>
              <li>
                After purchasing a motor insurance plan, you are automatically
                entered into a monthly draw to win one of the available
                twenty-five (25) #100,000 Fuel vouchers.
              </li>
              <li>
                Two (2) draws will be held on Heirs General Insurance's social
                media Pages during the duration of the promo.
              </li>
              <li>Winners will be announced after each draw.</li>
              <li>
                If your name isn't picked in the first draw, it will be added to
                the next, giving you a second chance to win the fuel voucher.
              </li>
            </List>
          </TextParagraph>
        </div>
        <TitleText>
          <span>FAQ</span>
        </TitleText>
        <div>
          <TextParagraph style={{ "font-weight": "bold" }}>
            1.) What is the Subsidy Promo?
          </TextParagraph>
          <TextParagraph style={{ "margin-bottom": "3em" }}>
            The Subsidy Promo by Heirs General Insurance is a promotion offer
            that gives everyone who purchases a Third-Party Motor Insurance or
            Comprehensive Motor Insurance the opportunity to win recharge cards,
            shopping vouchers or fuel vouchers.
          </TextParagraph>
          <TextParagraph style={{ "font-weight": "bold" }}>
            2.) Who is eligible to win?
          </TextParagraph>
          <TextParagraph style={{ "margin-bottom": "3em" }}>
            This promo is open to individuals purchasing a private third-party
            or comprehensive motor insurance policy. It is not open to
            commercial vehicles.
          </TextParagraph>
          <TextParagraph style={{ "font-weight": "bold" }}>
            3.) How do I qualify to win?
          </TextParagraph>
          <TextParagraph style={{ "margin-bottom": "3em" }}>
            Purchase/renew your third-party or comprehensive motor insurance
            plan with Heirs General Insurance to win.
          </TextParagraph>
          <TextParagraph style={{ "font-weight": "bold" }}>
            4.) How does the raffle draw for the fuel voucher work?
          </TextParagraph>
          <TextParagraph style={{ "margin-bottom": "3em" }}>
            After purchasing a motor insurance plan, you are automatically
            entered into a draw to win one of the available Twenty-Five (25)
            N100,000 Fuel vouchers.
          </TextParagraph>
          <TextParagraph style={{ "font-weight": "bold" }}>
            5.) Where will the raffle draw for the fuel voucher take place?
          </TextParagraph>
          <TextParagraph style={{ "margin-bottom": "3em" }}>
            A draw will be held on Heirs General Insurance's social media Pages
            (Instagram and Facebook) twice for the duration of the promo. All
            information on the time of the draw will be posted on the social
            media pages as well before the draw takes place.
          </TextParagraph>
          <TextParagraph style={{ "font-weight": "bold" }}>
            6.) What happens if my name isn't picked up in the first draw after
            I purchase my plan?
          </TextParagraph>
          <TextParagraph style={{ "margin-bottom": "3em" }}>
            If your name isn't picked in the first draw, it will be added to the
            second draw, giving you more chances to win the fuel voucher.
          </TextParagraph>
          <TextParagraph style={{ "font-weight": "bold" }}>
            7.) What do I win when I purchase a Third-party Insurance plan?
          </TextParagraph>
          <TextParagraph style={{ "margin-bottom": "3em" }}>
            You win N1,000 airtime and the opportunity to be entered into the
            raffle draw to win one of the available N100,000 fuel vouchers.
          </TextParagraph>
          <TextParagraph style={{ "font-weight": "bold" }}>
            8.) What do I win when I purchase a Comprehensive Motor Insurance
            plan?
          </TextParagraph>
          <TextParagraph style={{ "margin-bottom": "3em" }}>
            You win a N3,000 shopping voucher and the opportunity to be entered
            into the raffle draw to win one of the available N100,000 fuel
            vouchers.
          </TextParagraph>
          <TextParagraph style={{ "font-weight": "bold" }}>
            9.) How long will it take to receive my Airtime or Shopping Voucher?
          </TextParagraph>
          <TextParagraph style={{ "margin-bottom": "3em" }}>
            This will be sent to your contact details within 48 working hours
            after you purchase your motor insurance.
          </TextParagraph>
          <TextParagraph style={{ "font-weight": "bold" }}>
            10.) How do I pick up my fuel voucher If I win?
          </TextParagraph>
          <TextParagraph style={{ "margin-bottom": "3em" }}>
            If you are one of the lucky winners of the fuel voucher, you can
            schedule a pickup time at any of our locations nationwide.
          </TextParagraph>
          <TextParagraph style={{ "font-weight": "bold" }}>
            11.) How will I be contacted If I win one of the fuel vouchers?
          </TextParagraph>
          <TextParagraph style={{ "margin-bottom": "3em" }}>
            If your name is drawn as a winner in the fuel voucher draw, you will
            be contacted using the contact details used when purchasing your
            motor insurance policy.
          </TextParagraph>
          <TextParagraph style={{ "font-weight": "bold" }}>
            12.) What tenure of Comprehensive Insurance qualifies me for the
            raffle draw?
          </TextParagraph>
          <TextParagraph style={{ "margin-bottom": "3em" }}>
            A comprehensive insurance plan of 6 months and above only qualifies
            you for the promo.
          </TextParagraph>
        </div>
      </TextContentWrapper>
    </Wrapper>
  );
};

export default Index;
