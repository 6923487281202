import React from "react";
import Section from "../Section";
import { Row, Col } from "antd";
import Icon from "@ant-design/icons";
import {
  Wrapper,
  Title,
  Content,
  Text,
  ContentWrapper,
  IconWrapper,
} from "./style";
import { ReactComponent as AlertFill } from "../../assets/images/alert-fill.svg";
import { Video } from "../ProductBanner/styles";
import ReactCountryFlag from "react-country-flag";

const imgcol = {
  height: "200px",
  width: "100%",
  overflow: "hidden",
};
const cols = {
  background: "#fff",
  border: "1px solid #cdcdcd",
};
const pstyle = {
  lineHeight: "18pt",
};

const StoryTeller = () => {
  return (
    <Wrapper>
      <ContentWrapper>
        <Title> </Title>
        <Row gutter={[24, 0]}>
          <Col lg={5} style={cols}>
            <Text>
              <table>
                <tr>
                  <td>
                    <div style={imgcol}>
                      <img
                        src="https://heirsdigitalform.azurewebsites.net/wp-content/uploads/2023/08/Siziphiwe.png"
                        width="100%"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingBottom: "15px" }}>
                    <span style={{ paddingLeft: "5px", fontWeight: "bold" }}>
                      Siziphiwe Keke
                    </span>
                    <span style={{ display: "block" }}>
                      <ReactCountryFlag countryCode="ZA" svg /> South Africa
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingBottom: "15px" }}>
                    <p style={pstyle}>
                      <b>Gender:</b>{" "}
                    </p>
                    <p style={pstyle}>Female</p>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingBottom: "15px" }}>
                    <p style={pstyle}>
                      <b>Project Type:</b>{" "}
                    </p>
                    <p style={pstyle}>Short Documentary Series</p>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingBottom: "15px" }}>
                    <p style={pstyle}>
                      <b>Project Title:</b>{" "}
                    </p>
                    <p style={pstyle}>Untrapped</p>
                  </td>
                </tr>
              </table>
            </Text>
          </Col>
          <Col offset={0} lg={10}>
            {/* <Text>
              <b>About: </b> <br />
              Felix Byaruhanga, the visionary founder and team lead of Skyline
              Media & Management Limited, spearheads a cutting-edge creative
              agency situated in Kampala, Uganda. Notably recognized, he earned
              a coveted spot in Forbes' 30 under 30 list for 2021. CNN's "Inside
              Africa" spotlighted him in 2022, showcasing his role as a
              pioneering creative entrepreneur. Leveraging digital platforms and
              innovation, he's reshaping the landscape, fostering growth, and
              opening doors for creators in the dynamic creative industry.
            </Text> */}
            <Text>
              <b>Description of Project: </b>
              <br /> The Untrapped Series is a short three-part documentary
              series which follows Khutso Moleko, an entrepreneur who is
              differently abled and living life to the fullest and making
              strides in her career despite society's limitations.
            </Text>
            <Text>
              <b>Creative Thought Behind Project: </b>
              <br /> I work in Film and Television and the lack of inclusion and
              representation of people with disabilities is what drives most of
              my projects. I wanted to tell a different story while highlighting
              that life is what you make it. This series intends to shine a
              light on and highlights the inspirational journey of each
              individual.
            </Text>
            <Text>
              <b>Impact: </b>
              <br /> The Untrapped Series is a project geared to shine a light
              on differently abled creatives and entrepreneurs who are breaking
              boundaries in their lives and communities at large. Dlala Nathi
              Productions has a primal mandate to be inclusive and
              representative of all people by providing a platform for stories
              from every facet of our community to be seen and celebrated.
              Khutso Moleko, a Marketing professional, mother and creative is
              the first feature on the series and unpacks her struggles and
              triumps in a 3-part video series which has made over 10 000
              impressions across all our social media platforms
              (@dlalanathiproductions) and is being screened for different
              audiences during National Disabilities Awareness Month to create a
              more personalised engagement.
            </Text>
          </Col>
          <Col offset={0} lg={9}>
            <Text>
              <b>Project: </b>
              <a href="https://www.youtube.com/@dlalanathiproductions/videos">
                Untrapped
              </a>
            </Text>
            <Text>
              <Video>
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/a0-FuNiliX8?si=QhoUAEnvM8ZGfRQJ"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </Video>
            </Text>
            <Text>
              <b>Lesson Learnt While Working on Project: </b>
              <br /> To let the project lead you, I tried to cover more than one
              subject in the series and realised that the stories are strong and
              it would make sense to focus on one person at a time. I will under
              my own capacity fund the continuation of the series as it has
              shown potential to keep growing.
            </Text>
          </Col>
        </Row>
      </ContentWrapper>
    </Wrapper>
  );
};

export default StoryTeller;
