import React from "react";
import Banner from "./Banner";
import backgroundImage from "../../assets/images/get-answers.png";
import Button from "../../components/Button";
import { Content, Wrapper } from "./styles";
import Input from "../../components/Input";
import Answers from "./Answers";

const Index = () => {
  const bannerPayload = {
    title: "Get Answers",
    backgroundImage,
  };
  return (
    <Wrapper>
      <Banner {...bannerPayload}>
        <Content>
          <Input placeholder="email@example.com" />
          <Button>Subscribe</Button>
        </Content>
      </Banner>

      <Answers />
    </Wrapper>
  );
};

export default Index;
