import React from "react";
import Section from "../Section";
import { Row, Col } from "antd";
import Icon from "@ant-design/icons";
import {
  Wrapper,
  Title,
  Content,
  Text,
  ContentWrapper,
  IconWrapper,
} from "./style";
import { ReactComponent as AlertFill } from "../../assets/images/alert-fill.svg";
import { Video } from "../ProductBanner/styles";
import ReactCountryFlag from "react-country-flag";

const imgcol = {
  height: "200px",
  width: "100%",
  overflow: "hidden",
};
const cols = {
  background: "#fff",
  border: "1px solid #cdcdcd",
};
const pstyle = {
  lineHeight: "18pt",
};

const StoryTeller = () => {
  return (
    <Wrapper>
      <ContentWrapper>
        <Title> </Title>
        <Row gutter={[24, 0]}>
          <Col lg={5} style={cols}>
            <Text>
              <table>
                <tr>
                  <td>
                    <div style={imgcol}>
                      <img
                        src="https://heirsdigitalform.azurewebsites.net/wp-content/uploads/2023/08/Wonai.png"
                        width="100%"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingBottom: "15px" }}>
                    <span style={{ paddingLeft: "5px", fontWeight: "bold" }}>
                      Wonai Haruperi
                    </span>
                    <span style={{ display: "block" }}>
                      <ReactCountryFlag countryCode="ZW" svg /> Zimbabwe
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingBottom: "15px" }}>
                    <p style={pstyle}>
                      <b>Gender:</b>{" "}
                    </p>
                    <p style={pstyle}>Female</p>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingBottom: "15px" }}>
                    <p style={pstyle}>
                      <b>Project Type:</b>{" "}
                    </p>
                    <p style={pstyle}>Photography Zine</p>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingBottom: "15px" }}>
                    <p style={pstyle}>
                      <b>Project Title:</b>{" "}
                    </p>
                    <p style={pstyle}>The Hands That Feed</p>
                  </td>
                </tr>
              </table>
            </Text>
          </Col>
          <Col offset={0} lg={10}>
            <Text>
              <b>About: </b> <br />
              Wonai Haruperi is an independent document photographer from
              Harare, Zimbabwe whose work focuses on capturing the beauty in
              simply being while using visual storytelling to communicate what
              individual narratives highlight about society - both its victories
              and challenges.
            </Text>
            <Text>
              <b>Description of Project: </b>
              <br /> The Hands That Feed is a photography zine presenting a
              collection of stories aimed at celebrating female creative
              entrepreneurship in Zimbabwe. The stories of 3 women demonstrate a
              desire to solve a specific problem in their communities through
              innovation, passion, perseverance and above all connecting with
              those they serve, all while putting to rest the age old stereotype
              of the starving artist. The photographers, entrepreneurs in their
              own right, had the unique opportunity to share their work and its
              intrinsic value -- the impact of visual storytelling beyond a
              pretty picture.
            </Text>
            <Text>
              <b>Creative Thought Behind Project: </b>
              <br /> Wonai wanted to showcase the storytelling opportunities and
              the impact documentary photography can have while encouraging a
              return to print publication. In addition, she wanted the project
              to showcase the diversity of the creative industry in Zimbabwe and
              how creative entrepreneurs are contributing positively to the
              workforce.
            </Text>
          </Col>
          <Col offset={0} lg={9}>
            <Text>
              <a href="https://thehandsthatfeed.com">
                <b>Project: </b>
                <br />
                <img
                  src="https://heirsdigitalform.azurewebsites.net/wp-content/uploads/2023/09/the-hand-that-feeds.png"
                  width="100%"
                />
              </a>
            </Text>
            <Text>
              <b>Impact: </b>
              <br /> Reached over 20k people over 16 countries worldwide.
            </Text>
            <Text>
              <b>Lesson Learnt While Working on Project: </b>
              <ol>
                <li>
                  There is power in diversity, using three different
                  photographers to tell three different stories of
                  entrepreneurship meant the entrepreneurs and perspectives
                  brought forward were vastly different yet each important to
                  share.
                  <li>
                    To thoroughly research all aspects of what it will take to
                    execute the project to finish. Understand the team needed if
                    own expertise does not cover all aspects of the project and
                    establish the cost of each of the services that need
                    outsourcing.
                  </li>
                </li>
              </ol>
            </Text>
          </Col>
        </Row>
      </ContentWrapper>
    </Wrapper>
  );
};

export default StoryTeller;
