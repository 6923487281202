import React from "react";
import {
  Wrapper,
  Title,
  BannerContentWrapper,
  BannerContent,
  Overlay,
} from "./styles";
import { Text } from "../../components/ProductBanner/styles";
const Banner = ({ title, backgroundImage, descriptions }) => {
  return (
    <Wrapper backgroundImage={backgroundImage}>
      <Overlay>
        <BannerContentWrapper>
          <BannerContent>
            <Title> {title}</Title>
            {descriptions.map((description, key) => (
              <Text key={key}>{description}</Text>
            ))}
          </BannerContent>
        </BannerContentWrapper>
      </Overlay>
    </Wrapper>
  );
};

export default Banner;
