import React from "react";
import ContentPage from "../../components/ContentPage";
import { Heading, Paragraph, Text } from "../../components/ContentPage/styles";
const Terms = () => {
  return (
    <ContentPage title="Terms & Conditions">
      <Paragraph>
        <Text>
          We at Heirs General Insurance treat the privacy of our visitors with
          the highest importance. This policy details the measures we take to
          preserving and safely guarding your privacy when you visit our site or
          communicate with our personnel. The Privacy Policy here has been
          approved and provided by our legal advisors.
        </Text>

        <Text>
          A detailed explanation of how we may store or otherwise use personal
          information about you is explained in this Privacy Policy.
        </Text>
      </Paragraph>

      <Paragraph>
        <Heading>A. Privacy Policies</Heading>
        <ul style={{ listStyle: "lower-roman" }}>
          <li>Data Collection</li>
          <li>Your Personal Data and how it is Used</li>
          <li>Change of Purpose</li>
          <li>Your Personal Data Rights</li>
          <li>Persons who have access to your Personal Data</li>
          <li>Security & Confidentiality</li>
          <li>Transfer of Personal Data outside Nigeria</li>
          <li>Retention of Personal Data</li>
          <li>Third Party Links</li>
        </ul>
      </Paragraph>

      <Paragraph>
        <Heading>B. Cookie Policy</Heading>
      </Paragraph>

      <Paragraph>
        <Heading>C. Subject Access Request Response Procedure</Heading>
        <Text>
          We undertake regular updates to our Privacy Policy which we will
          notify you of on our website or by email.
        </Text>

        <Heading>GLOSSARY</Heading>
        <ul>
          <li>
            <strong>Affiliated Third Parties </strong> includes companies with
            which we have common ownership or management or other contractual
            strategic support or partnership relationships with, our advisers,
            consultants, bankers, vendors or sub-contractors.
          </li>

          <li>
            <strong>Data is information</strong>, which is stored
            electronically, on a computer, or in certain paper-based filing
            systems.
          </li>
          <li>
            <strong>Data Controller</strong> is a person responsible for
            determining the manner in which Personal Data would be processed.
          </li>
          <li>
            <strong>NDPR</strong> means the Nigerian Data Protection Regulations
          </li>
          <li>
            <strong>NITDA</strong> means the National Information Technology
            Development Agency
          </li>
          <li>
            <strong>Personal Data</strong> is the information relating to an
            identified or identifiable natural person. These include a name,
            gender, a photo, an email address, bank details, medical
            information, computer internet protocol address and any other
            information specific to the physical, physiological, genetic,
            mental, economic, cultural or social identity of that natural
            person.
          </li>
          <li>
            <strong>Processing</strong> is any activity that involves use of
            Personal Data. It includes obtaining, recording or holding the data,
            or carrying out any operation or set of operations on the data
            including organising, amending, recording, retrieving, using,
            disclosing, erasing or destroying it. Processing also includes
            transferring personal data to third parties.
          </li>
          <li>
            <strong>Sensitive Personal Data</strong> includes information about
            a person’s racial origin, political opinions, religious or similar
            beliefs, trade union membership, physical or mental health or
            condition or sexual life.
          </li>
        </ul>
      </Paragraph>

      <Paragraph>
        <Heading>C. Subject Access Request Response Procedure</Heading>
        <Text>
          We undertake regular updates to our Privacy Policy which we will
          notify you of on our website or by email.
        </Text>
      </Paragraph>

      <Paragraph>
        <Heading>Privacy Policy</Heading>
        <Heading>1. Data Collection</Heading>
        <ul style={{ listStyle: "decimal" }}>
          <li>
            We may collect, use, store and transfer different kinds of Personal
            Data about you which we have grouped together as follows:
          </li>
          <li>
            <strong>Identity Data</strong> includes first name, last name,
            username or similar identifier, title, date of birth and gender.
          </li>
          <li>
            <strong>Contact Data </strong>includes residential address, email
            address and telephone numbers.
          </li>
          <li>
            <strong>Human Resource Data </strong>includes information on your
            employment history, professional and educational information
            submitted upon applying for employment with us.
          </li>
          <li>
            <strong>Technical Data </strong> includes internet protocol (IP)
            address, domain name, your login data, browser type and version,
            time zone setting and location, browser plug-in types and versions,
            operating system and platform, and other technology on the devices
            you use to access this website.
          </li>
          <li>
            <strong>Profile Data </strong> includes your username and password,
            purchases or orders made by you, your interests, preferences,
            feedback and survey responses.
          </li>
          <li>
            <strong>Usage Data </strong> includes information about how you use
            our website and services.
          </li>
          <li>
            <strong>Marketing and Communications Data </strong> includes your
            preferences in receiving marketing communications from us and our
            Affiliated Third Parties and your communication preferences.
          </li>
          <li>
            You provide this information through direct interaction when you
            visit our website, sign up to our newsletters or publications,
            request marketing materials to be sent to you, , respond to surveys,
            complete our feedback or comment form, provide your business card to
            any of our staff, sign our visitor management form, complete other
            forms, apply for employment through our careers page, or contact us
            to request for any information or other correspondence by post,
            email, our website or otherwise.
          </li>
          <li>
            As you interact with our website, we will automatically collect
            technical data about your equipment, browsing actions and patterns.
            We collect this data by using cookies, and other similar
            technologies. Please see our cookie policy for further details.
          </li>
          <li>
            We do not intentionally or knowingly collect any Sensitive Personal
            Data. We ask that you do not send to us nor disclose such Sensitive
            Personal Data save where required for a specific purpose.
          </li>
        </ul>
      </Paragraph>

      <Paragraph>
        <Heading>2. Your Personal Data and how it is Used</Heading>
        <Text>
          Primarily, we collect, process and store your Personal Data to help us
          better connect with you. The following are the purposes for which we
          collect your Personal Data:
        </Text>
        <ul style={{ listStyle: "lower-alpha" }}>
          <li>
            To monitor, review, evaluate and improve your experience when you
            visit our website
          </li>
          <li>
            To analyse the traffic on our website, including determining the
            number of visitors to the website and analyse how they navigate the
            website.
          </li>
          <li>
            To invite you to complete a survey or provide feedback to us on
            specific matters.
          </li>
          <li>
            At any time, you request information from us via a form or other
            electronic transmission we may use your Personal Data to fulfil that
            request and keep a record of such request and how it was handled,
            for quality assurance and service improvement purposes.
          </li>
          <li>
            To keep you updated on our activities, programmes and events where
            your explicit consent has been given.
          </li>
          <li>
            To notify you of changes to our websites or relevant processes.
          </li>
          <li>
            We may also use your information or allow Affiliated Third Parties
            such as our affiliate companies or partners use of this Personal
            Data, to offer you information about unrelated products or services
            you may be interested in. We or such Affiliated Third Parties can
            only communicate with you if you have expressly consented to such
            communication and data use.
          </li>
          <li>
            We may share your personal data with Affiliated Third Parties such
            as service providers who we have engaged to assist with providing
            certain services on our behalf, for which they require your personal
            data.
          </li>
          <li>
            Where we have any contracts with you which create a commitment, we
            may require contact or use of your information to perform the
            contract.
          </li>
          <li>To process or manage your appointments with any of our staff.</li>
          <li>
            To fulfil legal/ regulatory obligations or to report any criminal or
            unethical activity.
          </li>
          <li>
            To store either on our central computer system or a third-party
            Computer’s central computer system for archiving and back up
            purposes.
          </li>
        </ul>

        <Text>
          Be aware that we do not reveal identifiable information about you to
          our advertisers, though we may at times share statistical visitor
          information with our advertisers.
        </Text>
      </Paragraph>

      <Paragraph>
        <Heading>3. Change of Purpose</Heading>
        <Text>
          We will only use your Personal Data for the aforementioned purposes,
          unless we reasonably consider that we need to use it for another
          reason and that reason is compatible with the original purpose. If you
          wish to get an explanation as to how the processing for the new
          purpose is compatible with the original purpose, please contact us.
        </Text>

        <Text>
          If we need to use your Personal Data for an unrelated purpose, we will
          notify you and request for your express consent.
        </Text>
      </Paragraph>

      <Paragraph>
        <Heading>4. Your Personal Data Rights</Heading>
        <Text>
          Data Protection Laws provides you with certain rights in relation to
          the information that we collect about you.
        </Text>

        <ul style={{ listStyle: "lower-alpha" }}>
          <li>
            The right to withdraw consent previously given to us or our
            Affiliated Third Parties. In order to make use of your personal
            data, we would have obtained your consent. For consent to be valid,
            it must be given voluntarily. In line with regulatory requirements,
            consent cannot be implied, and we ensure that you have the
            opportunity to read our data protection privacy policy before you
            provide your consent. Consent in respect of Sensitive Personal Data
            must be explicit and will be given by you in writing to us. The
            consent of minors (under the age of 18) will always be protected and
            obtained from the minor’s representatives in accordance with
            applicable regulatory requirements.
            <br /> <br />
            You can ask us or Affiliated Third Parties to stop sending you
            marketing messages at any time by logging onto the website and
            checking or un-checking relevant boxes to adjust your marketing
            preferences or by following the opt-out links on any marketing
            message sent to you.
          </li>
          <li>
            The right to request that we delete your Personal Data that is in
            our possession, subject however to retention required for legal
            purposes and the time required technically to delete such
            information.
          </li>
          <li>
            The right to request for access to your Personal Data or object to
            us processing the same. Where personal data is held electronically
            in a structured form, such you have a right to receive that data in
            a common electronic format.
          </li>
          <li>
            The right to receive your Personal Data and have it transferred to
            another Data Controller, as applicable.{" "}
          </li>
          <li>The right to lodge a complaint.</li>
        </ul>

        <Text>
          You may exercise any of the above stated rights following our{" "}
          <strong>Data Subject Access Request Procedure</strong>.
        </Text>
      </Paragraph>

      <Paragraph>
        <Heading>5. Persons who have access to your Personal Data</Heading>
        <Text>
          In addition to our staff who have a business need to know, the
          following trusted third parties have access to your information:
        </Text>

        <ul style={{ listStyle: "lower-alpha" }}>
          <li>
            We use a customer relationship management tool to help manage our
            contact database and send out email communications to you.
          </li>
          <li>
            Our Affiliate Third Parties who require your information for the
            same purposes described in this Policy and who have adopted similar
            privacy policy standards further to contractual obligations to us
            under a third party data processing agreement we have entered with
            them.
          </li>
          <li>
            Professional service providers such as IT service providers and
            website hosts.
          </li>
        </ul>

        <Text>
          We will transfer your Personal Data to only those Affiliated Third
          Parties who we are sure can offer the required level of protection to
          your privacy and information and who are also contractually obligated
          to us to do so. We do not and will not at any point in time sell your
          Personal Data. We require all Affiliated Third Parties to respect the
          security of your personal data and to treat it in accordance with the
          law. We do not allow our professional service providers to use your
          Personal Data for their own purposes and only permit them to process
          your Personal Data for specified purposes and in accordance with our
          instructions.
        </Text>
      </Paragraph>

      <Paragraph>
        <Heading>6. Security & Confidentiality</Heading>
        <Text>
          Information submitted by you is stored on secure servers we have which
          are encrypted and access is restricted to only authorised persons in
          charge of maintaining the servers. We have put in place physical,
          electronic and procedural processes that safeguard and protect your
          information against unauthorised access, modification or erasure.
          However, we cannot guarantee 100% security as no security programme is
          completely fool proof. In the unlikely event that we experience any
          breach to your personal data, such breach shall be handled in
          accordance with our Personal Data Breach Management Procedures. All
          such breaches shall be notified to the NITDA within 72 hours of
          occurrence and where deemed necessary, based on the severity and
          potential risks, we shall notify you of such occurrence, steps taken
          and remedies employed to prevent a reoccurrence.
        </Text>

        <Text>
          Our staff also have an obligation to maintain the confidentiality of
          any Personal Data held by us.
        </Text>

        <Text>
          As you know, transmission of data on the internet is never guaranteed
          regarding safety. It is impossible to completely guarantee your safety
          with electronic data and transmission. You are therefore at your own
          risk if you elect to transmit any data electronically.
        </Text>
      </Paragraph>

      <Paragraph>
        <Heading>7. Transfer of Personal Data outside Nigeria</Heading>
        <Text>
          The Personal Data we collect may be transferred to and processed in
          another country other than your country of residence for the purposes
          stated above. The data protection laws in those countries may be
          different from, and less stringent than the laws applicable in your
          country of residence.
        </Text>
        <Text>
          By accepting this Policy or by providing your Personal Data to us, you
          expressly consent to such transfer and Processing. We will however
          take all reasonable steps to ensure that your data is treated securely
          and transfer of your Personal Data will only be done in accordance
          with the requirements of applicable laws and to parties who have put
          in place adequate controls to secure and protect your Personal Data.
        </Text>
      </Paragraph>

      <Paragraph>
        <Heading>8. Retention of Personal Data</Heading>
        <Text>
          We retain your Personal Data for no longer than reasonably necessary
          for the purposes set out in this Policy and in accordance with legal,
          regulatory, tax, accounting or reporting requirements.
        </Text>
        <Text>
          We may retain your Personal Data for a longer period in the event of a
          complaint or if we reasonably believe there is a prospect of
          litigation in respect to our relationship with you. To determine the
          appropriate retention period for personal data, we consider the
          amount, nature and sensitivity of the Personal Data, the potential
          risk of harm from unauthorised use or disclosure of your Personal
          Data, the purposes for which we process your Personal Data and whether
          we can achieve those purposes through other means, and the applicable
          legal, regulatory, tax, accounting or other requirements.
        </Text>
        <Text>
          Where your Personal Data is contained within a document, the retention
          period applicable to such type of document in our document retention
          policy shall apply.
        </Text>
      </Paragraph>

      <Paragraph>
        <Heading>9. Third Party Links</Heading>
        <Text>
          This website or our email communication may include links to third
          party websites, plug-ins and applications. Clicking on those links or
          enabling those connections may allow third parties to collect or share
          data about you. We do not control these third-party websites and are
          not responsible for their privacy statements. When you leave our
          website, we encourage you to read the privacy policy of every website
          you visit.
        </Text>
      </Paragraph>

      <Paragraph>
        <Heading>Cookie Policy</Heading>
        <ul style={{ listStyle: "decimal" }}>
          <li>
            Our advertisers and organisation may have the occasion to collect
            information in regard to your computer for our services. The
            information is gained in a statistical manner for our use or
            advertisers on our site.
          </li>
          <li>
            Data gathered will not identify you personally. It is strictly
            aggregate statistical data about our visitors and how they used our
            resources on the site. No identifying Personal Data will be shared
            at any time via cookies.
          </li>
          <li>
            Close to the above, data gathering can be about general online use
            through a cookie file. When used, cookies are automatically placed
            in your hard drive where information transferred to your computer
            can be found. These cookies are designed to help us correct and
            improve our site’s services for you.
          </li>
          <li>
            You may elect to decline all cookies via your computer or set up
            alerts to prompt you when websites set or access cookies. Every
            computer has the ability to decline file downloads like cookies.
            Your browser has an option to enable the declining of cookies. If
            you do decline cookie downloads you may be limited to certain areas
            of our site, as there are parts of our site that require cookies.
          </li>
          <li>
            Any of our advertisers may also have a use for cookies. We are not
            responsible, nor do we have control of the cookies downloaded from
            advertisements. They are downloaded only if you click on the
            advertisement.
          </li>
        </ul>
      </Paragraph>

      <Paragraph>
        <Heading>Subject Access Request Response Procedure</Heading>
        <ul style={{ listStyle: "decimal" }}>
          <li>
            Where you wish to exercise any of your data privacy rights you shall
            make a formal request by completing the Subject Access Request Form
            (SAR Form) and sending the completed form via email to us at
            privacy@heirsholdings.com.
          </li>
          <li>
            We shall contact you within 5 working days of the receipt of the SAR
            Form to confirm receipt of the subject access request and may
            request additional information to verify and confirm the identity of
            the individual making the request.
          </li>
          <li>
            On receiving any request from you, we shall record the request and
            carry out verification of the identity of the individual making the
            request using the details provided in the SAR Form and a valid means
            of identification such as international passport, driver’s license,
            national identification card or any other acceptable means of
            identification.
          </li>
          <li>
            Where the request is from a third party (such as relative or your
            representative), we will verify their authority to act for you and
            may contact you to confirm their identity and request your consent
            to disclose the information.
          </li>
          <li>
            When your identity is verified, we shall coordinate the gathering of
            all information collected with respect to you in a concise,
            transparent, intelligible and easily accessible form, using clear
            and plain language with a view to responding to the specific
            request. The information may be provided in writing, or by other
            means, including, where appropriate, by electronic means or orally
            provided that your identity is proven by other means. We may also
            contact you to ask you for further information in relation to your
            request to speed up our response.
          </li>
          <li>
            Where the information requested relates directly or indirectly to
            another person, we will seek the consent of that person before
            processing the request. However, where disclosure would adversely
            affect the rights and freedoms of others and we are unable to
            disclose the information, we will inform you promptly, with reasons
            for that decision.
          </li>
        </ul>
      </Paragraph>

      <Paragraph>
        <Heading>7. Fees and Timeframe</Heading>
        <Text>
          7.1. We shall ensure that we provide the information required by you
          within a period of one month from the receipt of the request.
          Occasionally it could take us longer than a month if your request is
          particularly complex or you have made a number of requests. In this
          case, we will notify you and keep you updated. However, where we are
          unable to act on your request, we shall inform you promptly at least
          within one month of receipt of the request of the reasons for not
          taking action and give you the option of lodging a complaint with the
          NITDA, in line with the NDPR.
        </Text>
        <Text>
          7.2. Where the request relates to any perceived violation of your
          rights, we shall take appropriate steps to remedy such violations,
          once confirmed. Remedies shall include but not limited to the
          investigation and reporting to appropriate authorities, recovering the
          personal data, correcting it and/ or enhancing controls around it. You
          shall be appropriately informed of the remedies employed.
        </Text>
        <Text>
          7.3 Any information provided to you by us shall be provided free of
          charge. However, where requests are manifestly unfounded or excessive
          in particular because of their repetitive or cumbersome nature, we
          may:
        </Text>
        <Text>
          a. charge a reasonable fee taking into account the administrative
          costs of providing the information or communication, taking the action
          required or making a decision to refuse to act on the request; or
        </Text>
        <Text>
          b. write a letter to you stating refusal to act on the request and
          copying the National Information Technology Development Agency
          (NITDA).
        </Text>
      </Paragraph>

      <Paragraph>
        <Heading>Exceptions to Data Subjects Access Rights</Heading>
        <Text>
          To the extent permitted by applicable laws, we may refuse to act on
          your request, if at least one of the following applies:
        </Text>

        <Text>
          a) in compliance with a legal obligation to which we are subject;
        </Text>

        <Text>
          b) protecting your vital interests or of another natural person; and
        </Text>

        <Text>
          c) for public interest or in exercise of official public mandate
          vested in us.
        </Text>
      </Paragraph>

      <Paragraph>
        <Heading>Contacting Us</Heading>
        <Text>
          We welcome any queries, requests you may have regarding our Data
          Protection Privacy Policies, or our privacy practices.
        </Text>
      </Paragraph>
    </ContentPage>
  );
};

export default Terms;
