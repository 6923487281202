import styled from "styled-components";
import * as Colors from "../../utils/colors";

export const Wrapper = styled("div")`
  padding: 50px 0;
  background: ${Colors.GRAY_15};

  @media screen and (max-width: 575px) {
    padding: 20px 0;
  }
`;

export const Title = styled("h1")`
  font-size: 55px;
  line-height: 60px;
  margin-top: 30px;
  margin-bottom: 22px;

  @media screen and (max-width: 767px) {
    font-size: 35px;
    line-height: 30px;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 575px) {
    font-size: 25px;
    line-height: 25px;
    margin-top: 10px;
    margin-bottom: 5px;
  }
`;

export const Text = styled("p")`
  line-height: 40px;
  margin-bottom: 25px;
  ${({ padXl }) =>
    padXl && `margin: 0px 24px 30px 40px; text-align: center; font-size: 24px;`}

  @media screen and (max-width: 575px) {
    ${({ padXl }) =>
      padXl &&
      `margin: 0px 0px 20px 12px; text-align: center; font-size: 18px; line-height: 30px;`}
  }
`;

export const ContentWrapper = styled("div")`
  margin: auto;
  width: 80%;
`;

export const IconWrapper = styled("span")`
  svg {
    //width: 15px;
    //height: auto;
    fill: ${Colors.PRIMARY};
  }

  @media screen and (max-width: 991px) {
    display: none;
  }
`;

export const Link = styled("a")`
  padding: 4px 2px;
  background: #ff0000;
  color: #fff;
  width: 100%;
  display: block;
  text-align: center;
  font-size: 10pt;
  font-weight: bold;

  &:hover {
    color: ${Colors.GRAY_50};
  }
`;
