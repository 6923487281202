import React from "react";
import Section from "../Section";
import { Row, Col } from "antd";
import Icon from "@ant-design/icons";
import {
  Wrapper,
  Title,
  Content,
  Text,
  ContentWrapper,
  IconWrapper,
} from "./style";
// import Button from "../Button";
import { ReactComponent as AlertFill } from "../../assets/images/alert-fill.svg";

import { Video } from "../ProductBanner/styles";
import ReactCountryFlag from "react-country-flag";

const imgcol = {
  height: "200px",
  width: "100%",
  overflow: "hidden",
};
const pstyle = {
  lineHeight: "12pt",
  fontStyle: "italic",
  fontSize: "10pt",
  paddingTop: "8px",
};
const cstyle = {
  lineHeight: "12pt",
  fontSize: "10pt",
};
const fstyle = { verticalAlign: "top", paddingRight: "5px" };
const std = { padding: "2px 16px" };
const cols = {
  width: "245px",
  padding: "16px 2px",
  background: "#fff",
  border: "1px solid #cdcdcd",
};

const WList = () => {
  return (
    <Wrapper>
      <ContentWrapper>
        <Title> </Title>
        <Row gutter={[25, 0]}>
          <Col offset={0} lg={4.5} style={cols}>
            <div style={imgcol}>
              <a href="#">
                <img
                  src="https://heirsdigitalform.azurewebsites.net/wp-content/uploads/2023/08/Abdou.png"
                  width="100%"
                />
              </a>
            </div>
            <table style={{ fontSize: "12pt" }}>
              <tr>
                <td style={std}>
                  <b>Abdou Zoure</b>
                  <p style={cstyle}>
                    <ReactCountryFlag countryCode="BF" svg /> Burkina Faso
                  </p>{" "}
                  <p style={pstyle}>Journalist</p>{" "}
                </td>
              </tr>
            </table>
          </Col>
          <Col offset={0} lg={4.5} style={cols}>
            <div style={imgcol}>
              <a href="#">
                <img
                  src="https://heirsdigitalform.azurewebsites.net/wp-content/uploads/2023/08/Veronica.png"
                  width="100%"
                />
              </a>
            </div>
            <table style={{ fontSize: "12pt" }}>
              <tr>
                <td style={std}>
                  <b>Veronica Adeti </b>
                  <p style={cstyle}>
                    <ReactCountryFlag countryCode="KE" svg /> Kenya
                  </p>
                  <p style={pstyle}>
                    Cultural Practitioner & Communication Specialist
                  </p>{" "}
                </td>
              </tr>
            </table>
          </Col>
          <Col lg={4.8} style={cols}>
            <div style={imgcol}>
              <a href="TOE-storytellers-fund-grantee-bolarinwa-idowu-emmanuel">
                <img
                  src="https://heirsdigitalform.azurewebsites.net/wp-content/uploads/2023/08/Bolarinwa.png"
                  width="100%"
                />
              </a>
            </div>
            <table style={{ fontSize: "12pt" }}>
              <tr>
                <td style={std}>
                  <b>Bolarinwa Idowu Emmanuel</b>
                  <p style={cstyle}>
                    <ReactCountryFlag countryCode="NG" svg /> Nigeria
                  </p>
                  <p style={pstyle}>Documentary Producer</p>{" "}
                </td>
              </tr>
            </table>
          </Col>
          <Col offset={0} lg={4.5} style={cols}>
            <div style={imgcol}>
              <a href="TOE-storytellers-fund-grantee-boakye-dacosta-alpha">
                <img
                  src="https://heirsdigitalform.azurewebsites.net/wp-content/uploads/2023/08/Dacosta.png"
                  width="100%"
                />
              </a>
            </div>
            <table style={{ fontSize: "12pt" }}>
              <tr>
                <td style={std}>
                  <b>Dacosta Boakye</b>
                  <p style={cstyle}>
                    <ReactCountryFlag countryCode="GH" svg /> Ghana
                  </p>
                  <p style={pstyle}>Author, Poet & Filmmaker</p>{" "}
                </td>
              </tr>
            </table>
          </Col>
          <Col offset={0} lg={4.5} style={cols}>
            <div style={imgcol}>
              <a href="#">
                <img
                  src="https://heirsdigitalform.azurewebsites.net/wp-content/uploads/2023/08/Andriarimanjaka.png"
                  width="100%"
                />
              </a>
            </div>
            <table style={{ fontSize: "12pt" }}>
              <tr>
                <td style={std}>
                  <b>Andriarimanjaka Dina Nomena</b>
                  <p style={cstyle}>
                    <ReactCountryFlag countryCode="MG" svg /> Madagascar
                  </p>
                  <p style={pstyle}>Journalist</p>{" "}
                </td>
              </tr>
            </table>
          </Col>
          <Col offset={0} lg={4.5} style={cols}>
            <div style={imgcol}>
              <a href="/TOE-storytellers-fund-grantee-duduzile-luthuli">
                <img
                  src="https://heirsdigitalform.azurewebsites.net/wp-content/uploads/2023/08/Dudu.png"
                  width="100%"
                />
              </a>
            </div>
            <table style={{ fontSize: "12pt" }}>
              <tr>
                <td style={std}>
                  <b>Dudu Luthuli</b>
                  <p style={cstyle}>
                    <ReactCountryFlag countryCode="ZA" svg /> South Africa
                  </p>
                  <p style={pstyle}>Writer & Content Specialist</p>{" "}
                </td>
              </tr>
            </table>
          </Col>
          <Col offset={0} lg={4.5} style={cols}>
            <div style={imgcol}>
              <a href="/TOE-storytellers-fund-grantee-ezra-million">
                <img
                  src="https://heirsdigitalform.azurewebsites.net/wp-content/uploads/2023/08/Ezra.png"
                  width="100%"
                />
              </a>
            </div>
            <table style={{ fontSize: "12pt" }}>
              <tr>
                <td style={std}>
                  <b>Ezra Million</b>
                  <p style={cstyle}>
                    <ReactCountryFlag countryCode="ET" svg /> Ethopia
                  </p>
                  <p style={pstyle}>Multimedia Journalist</p>{" "}
                </td>
              </tr>
            </table>
          </Col>
          <Col offset={0} lg={4.5} style={cols}>
            <div style={imgcol}>
              <a href="TOE-storytellers-fund-grantee-felix-byaruhanga">
                <img
                  src="https://heirsdigitalform.azurewebsites.net/wp-content/uploads/2023/08/Felix.png"
                  width="100%"
                />
              </a>
            </div>
            <table style={{ fontSize: "12pt" }}>
              <tr>
                <td style={std}>
                  <b>Felix Byaruhanga</b>
                  <p style={cstyle}>
                    <ReactCountryFlag countryCode="UG" svg /> Uganda
                  </p>
                  <p style={pstyle}>Podcaster</p>{" "}
                </td>
              </tr>
            </table>
          </Col>
          <Col offset={0} lg={4.5} style={cols}>
            <div style={imgcol}>
              <a href="#">
                <img
                  src="https://heirsdigitalform.azurewebsites.net/wp-content/uploads/2023/08/Habibou.png"
                  width="100%"
                />
              </a>
            </div>
            <table style={{ fontSize: "12pt" }}>
              <tr>
                <td style={std}>
                  <b>Habibou Diaou</b>
                  <p style={cstyle}>
                    <ReactCountryFlag countryCode="ML" svg /> Mali
                  </p>
                  <p style={pstyle}>Documentary Producer</p>{" "}
                </td>
              </tr>
            </table>
          </Col>
          <Col offset={0} lg={4.5} style={cols}>
            <div style={imgcol}>
              <a href="#">
                <img
                  src="https://heirsdigitalform.azurewebsites.net/wp-content/uploads/2023/08/Kgomotso.png"
                  width="100%"
                />
              </a>
            </div>
            <table style={{ fontSize: "12pt" }}>
              <tr>
                <td style={std}>
                  <b>Kgomotso Ramushu</b>
                  <p style={cstyle}>
                    <ReactCountryFlag countryCode="ZA" svg /> South Africa
                  </p>
                  <p style={pstyle}>Writer & Researcher</p>{" "}
                </td>
              </tr>
            </table>
          </Col>
          <Col offset={0} lg={4.5} style={cols}>
            <div style={imgcol}>
              <a href="/TOE-storytellers-fund-grantee-marcelline-akinyi-okech">
                <img
                  src="https://heirsdigitalform.azurewebsites.net/wp-content/uploads/2023/08/Marcelline.png"
                  width="100%"
                />
              </a>
            </div>
            <table style={{ fontSize: "12pt" }}>
              <tr>
                <td style={std}>
                  <b>Marcelline Okech</b>
                  <p style={cstyle}>
                    <ReactCountryFlag countryCode="KE" svg /> Kenya
                  </p>
                  <p style={pstyle}>Video Journalist</p>{" "}
                </td>
              </tr>
            </table>
          </Col>
          <Col offset={0} lg={4.5} style={cols}>
            <div style={imgcol}>
              <a href="/TOE-storytellers-fund-grantee-margaret-lombe">
                <img
                  src="https://heirsdigitalform.azurewebsites.net/wp-content/uploads/2023/08/Margaret.png"
                  width="100%"
                />
              </a>
            </div>
            <table style={{ fontSize: "12pt" }}>
              <tr>
                <td style={std}>
                  <b>Margaret Lombe</b>
                  <p style={cstyle}>
                    <ReactCountryFlag countryCode="ZM" svg /> Zambia
                  </p>
                  <p style={pstyle}>Writer & Filmmaker</p>{" "}
                </td>
              </tr>
            </table>
          </Col>
          <Col offset={0} lg={4.5} style={cols}>
            <div style={imgcol}>
              <a href="/TOE-storytellers-fund-grantee-nasir-ahmed-achile">
                <img
                  src="https://heirsdigitalform.azurewebsites.net/wp-content/uploads/2024/01/nasir-ahmed.jpeg"
                  width="100%"
                />
              </a>
            </div>
            <table style={{ fontSize: "12pt" }}>
              <tr>
                <td style={std}>
                  <b>Nasir Ahmed</b>
                  <p style={cstyle}>
                    <ReactCountryFlag countryCode="NG" svg /> Nigeria
                  </p>
                  <p style={pstyle}>Journalist & Creative</p>{" "}
                </td>
              </tr>
            </table>
          </Col>
          <Col offset={0} lg={4.5} style={cols}>
            <div style={imgcol}>
              <a href="#">
                <img
                  src="https://heirsdigitalform.azurewebsites.net/wp-content/uploads/2023/08/Ngozi.png"
                  width="100%"
                />
              </a>
            </div>
            <table style={{ fontSize: "12pt" }}>
              <tr>
                <td style={std}>
                  <b>Ngozi John</b>
                  <p style={cstyle}>
                    <ReactCountryFlag countryCode="NG" svg /> Nigeria
                  </p>
                  <p style={pstyle}>Author</p>{" "}
                </td>
              </tr>
            </table>
          </Col>
          <Col offset={0} lg={4.5} style={cols}>
            <div style={imgcol}>
              <a href="#">
                <img
                  src="https://heirsdigitalform.azurewebsites.net/wp-content/uploads/2023/08/Olivier.png"
                  width="100%"
                />
              </a>
            </div>
            <table style={{ fontSize: "12pt" }}>
              <tr>
                <td style={std}>
                  <b>Olivier Sentema</b>
                  <p style={cstyle}>
                    <ReactCountryFlag countryCode="BI" svg /> Burundi
                  </p>
                  <p style={pstyle}>Documentary Producer</p>{" "}
                </td>
              </tr>
            </table>
          </Col>
          <Col offset={0} lg={4.5} style={cols}>
            <div style={imgcol}>
              <a href="/TOE-storytellers-fund-grantee-siziphiwe-keke">
                <img
                  src="https://heirsdigitalform.azurewebsites.net/wp-content/uploads/2023/08/Siziphiwe.png"
                  width="100%"
                />
              </a>
            </div>
            <table style={{ fontSize: "12pt" }}>
              <tr>
                <td style={std}>
                  <b>Siziphiwe Keke</b>
                  <p style={cstyle}>
                    <ReactCountryFlag countryCode="ZA" svg /> South Africa
                  </p>
                  <p style={pstyle}>Documentary Producer</p>{" "}
                </td>
              </tr>
            </table>
          </Col>
          <Col offset={0} lg={4.5} style={cols}>
            <div style={imgcol}>
              <a href="TOE-storytellers-fund-grantee-wonai-haruperi">
                <img
                  src="https://heirsdigitalform.azurewebsites.net/wp-content/uploads/2023/08/Wonai.png"
                  width="100%"
                />
              </a>
            </div>
            <table style={{ fontSize: "12pt" }}>
              <tr>
                <td style={std}>
                  <b>Wonai Haruperi</b>
                  <p style={cstyle}>
                    <ReactCountryFlag countryCode="ZW" svg /> Zimbabwe
                  </p>
                  <p style={pstyle}>Documentary & Portrait Photographer</p>{" "}
                </td>
              </tr>
            </table>
          </Col>
        </Row>
      </ContentWrapper>
    </Wrapper>
  );
};

export default WList;
