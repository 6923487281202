import styled from "styled-components";
import {
  DatePickerWrapper,
  InputWrapper,
  SelectWrapper,
  TimePickerWrapper,
} from "../../components/Input/style";
import { TextInputWrapper } from "../../components/Textarea/style";
import * as Colors from "../../utils/colors";
import { containerWrapper } from "../../utils/stylescheet";

export const Content = styled("div")`
  margin-top: 25px;

  @media screen and (max-width: 575px) {
    margin-top: 10px;
  }
`;

export const FormWrapper = styled("form")`
  padding: 40px 0;
  text-align: center;
  ${containerWrapper}

  ${InputWrapper} {
    width: 100% !important;
    border: solid 1px ${Colors.DARK} !important;
  }

  ${SelectWrapper} {
    width: 100% !important;
    border: solid 1px ${Colors.DARK} !important;
  }

  ${TextInputWrapper} {
    width: 100% !important;
    border: solid 1px ${Colors.DARK} !important;
    min-height: 150px;
  }

  textarea {
    width: 100%;
    padding: 10px 20px;
    outline: none;
    border: solid 1px ${Colors.DARK} !important;
    border-radius: 5px;

    ::placeholder {
      color: #bfbfbf;
      opacity: 1;
    }

    :-ms-input-placeholder {
      color: #bfbfbf;
    }

    ::-ms-input-placeholder {
      color: #bfbfbf;
    }
  }
`;

export const ClaimFormWrapper = styled("div")`
  padding: 0 0 40px 0;
  text-align: center;
  ${containerWrapper}

  ${InputWrapper} {
    width: 100% !important;
    border: solid 1px ${Colors.DARK} !important;
  }

  ${SelectWrapper} {
    width: 100% !important;
    border: solid 1px ${Colors.DARK} !important;
    padding: 10px 0 !important;

    .ant-select-selector {
      padding-left: 25px;
      box-shadow: initial !important;
    }
  }

  ${DatePickerWrapper} {
    width: 100% !important;
    border: solid 1px ${Colors.DARK} !important;
  }

  ${TimePickerWrapper} {
    width: 100% !important;
    border: solid 1px ${Colors.DARK} !important;
  }

  ${TextInputWrapper} {
    width: 100% !important;
    border: solid 1px ${Colors.DARK} !important;
    min-height: 150px;
  }

  textarea {
    width: 100%;
    padding: 10px 20px;
    outline: none;
    border: solid 1px ${Colors.DARK} !important;
    border-radius: 5px;

    ::placeholder {
      color: #bfbfbf;
      opacity: 1;
    }

    :-ms-input-placeholder {
      color: #bfbfbf;
    }

    ::-ms-input-placeholder {
      color: #bfbfbf;
    }
  }

  #approvedEstimate .ant-form-item-control-input-content,
  #repairUpload .ant-form-item-control-input-content,
  #repairUpload .ant-form-item-extra {
    text-align: left;
  }

  .ant-radio-button-wrapper {
    height: fit-content;
    padding: 10px;
  }
`;

export const ClaimIcon = styled("div")`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  .anticon {
    width: 100%;
    color: ${Colors.PRIMARY};
    height: 90%;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`;

export const ClaimText = styled("p")`
  font-size: 12px;
`;

export const ClaimContainer = styled("div")`
  width: 100%;
  height: 130px;
  border: 1px solid ${Colors.GRAY_10};
  padding: 7px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  :hover {
    box-shadow: 3px 3px 10px 1px #eaeaea;
    border: 1px solid ${Colors.PRIMARY};
  }
`;

export const FormContentWrapper = styled("div")`
  width: 100%;
  margin: 0 auto 20px;

  small {
    color: ${Colors.PRIMARY};
  }

  .ant-form-item .ant-form-item {
    margin-bottom: 0;
  }

  @media screen and (min-width: 992px) {
    width: 60%;
  }
`;

export const Title = styled("h2")`
  text-align: center;
  margin: 15px 0 25px;
`;

export const Text = styled("p")`
  font-size: 14px;
`;

export const Wrapper = styled("div")`
  background-image: ${({ backgroundImage }) =>
    backgroundImage ? "url(" + backgroundImage + ")" : ""};
`;

export const DetailsWrapper = styled("div")`
  padding-top: 60px;
  background: ${Colors.GRAY_35};
  position: relative;

  ${Title} {
    @media screen and (max-width: 767px) {
      font-size: 18px;
      line-height: 18px;
    }
  }

  ${Text} {
    word-break: break-word;

    @media screen and (max-width: 767px) {
      font-size: 12px;
      line-height: 14px;
    }
  }
`;

export const PhoneImageWrapper = styled("div")`
  padding: 0 10px;

  img {
    width: 100%;
    margin-right: 0;
  }
`;
export const LeftDetailsContentWrapper = styled("div")`
  padding-right: 25px;
  padding-bottom: 15px;
  border-right: solid 1px ${Colors.BLACK};
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
`;
export const RightDetailsContentWrapper = styled("div")`
  margin-left: 25px;

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;

  img {
    width: 60px;
    height: auto;
    margin-right: 10px;
    margin-bottom: 10px;

    @media screen and (min-width: 768px) {
      width: 80px;
    }

    @media screen and (min-width: 992px) {
      width: 120px;
    }
  }
`;
export const DetailsContentWrapper = styled("div")`
  ${containerWrapper}

  ${Title} {
    text-align: right;
    margin-bottom: 10px;
  }

  ${Text} {
    text-align: right;
  }
`;

export const DetailsRowWrapper = styled("div")``;
