import styled from "styled-components";
import * as Colors from "../../utils/colors";
import { ButtonWrapper } from "../../components/Button/style";
import { containerWrapper } from "../../utils/stylescheet";
import wrapperBg from "../../assets/images/tef.jpeg";

export const Text = styled("p")`
  color: ${Colors.TEXT_DEFAULT};
  font-size: 20px;
  margin-bottom: 5px;

  ul {
    list-style: disc;
  }

  @media screen and (max-width: 991px) {
    width: 50%;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const LinkWrapper = styled("div")`
  margin-top: 15px;
  display: flex;

  a {
    margin-left: 10px;
    padding: 10px 20px;
    color: ${Colors.WHITE};
    display: flex;
    align-items: center;
    font-weight: bold;
    background: ${Colors.PRIMARY};

    svg {
      width: 20px;
      height: 20px;
      margin-left: 10px;
    }

    &:hover {
      color: ${Colors.WHITE};
      background: ${Colors.BLACK};
    }
  }
`;

export const Title = styled("h1")`
  font-size: 55px;
  line-height: 60px;
  color: ${Colors.TEXT_DEFAULT};
  margin-bottom: 0;

  @media screen and (max-width: 991px) {
    font-size: 35px;
    line-height: 35px;
  }

  @media screen and (max-width: 767px) {
    font-size: 30px;
    line-height: 30px;
  }

  @media screen and (max-width: 575px) {
    font-size: 20px;
    line-height: 15px;
  }
`;

export const SubTitle = styled("h2")`
  color: #626166;
  font-size: 20px;
  line-height: 15px;

  @media screen and (max-width: 991px) {
    font-size: 15px;
    line-height: 12px;
  }

  @media screen and (max-width: 767px) {
    font-size: 12px;
    line-height: 10px;
  }
`;

export const BannerContentWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-height: 400px;

  @media screen and (max-width: 991px) {
    min-height: 300px;
  }

  @media screen and (max-width: 767px) {
    min-height: 200px;
  }

  @media screen and (max-width: 575px) {
    min-height: 180px;

    ${ButtonWrapper} {
      margin-top: 10px;
    }
  }
`;

export const Overlay = styled("div")`
  background-image: ${({ backgroundImage }) =>
    backgroundImage ? "url(" + backgroundImage + ")" : ""};
  background-repeat: no-repeat;
  background-size: contain;
`;

export const Wrapper = styled("div")`
  ${containerWrapper};
  background-image: url(${wrapperBg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right center;
`;

export const Content = styled("div")`
  min-height: 500px;
  background-image: ${({ backgroundImage }) =>
    backgroundImage ? "url(" + backgroundImage + ")" : ""};
  background-repeat: repeat-x;
  background-size: contain;
  background-position: left bottom;
  background-color: rgb(243 84 91 / 7%);
  padding-top: 20px;

  @media screen and (max-width: 991px) {
    min-height: 200px;
  }

  @media screen and (max-width: 575px) {
    min-height: 100px;
    padding-top: 0;
  }
`;
