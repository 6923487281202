import styled from "styled-components";
import * as Colors from "../../utils/colors";
import { containerWrapper } from "../../utils/stylescheet";

export const Wrappers = styled("div")`
  background: ${Colors.WHITE};
`;
export const Content = styled("div")`
  ${containerWrapper}
`;

export const Title = styled("p")`
  text-align: center;
  font-weight: 600;
  font-size: 12px;
`;

export const Text = styled("p")`
  text-align: center;
  font-weight: bold;
  font-size: 14px;
`;

export const LinksWrapper = styled("div")`
  display: inline-block;
  padding: 4px 8px;
  margin: 5px 0;
  line-height: 13px;
  border: solid 2px ${Colors.BLACK};

  a {
    font-size: 12px;
    color: ${Colors.TEXT_DEFAULT};
    text-transform: uppercase;
    font-weight: bold;
  }
`;

export const LinksWrapperGrey = styled("div")`
  display: inline-block;
  padding: 4px 8px;
  background-color: ${Colors.GRAY};
  margin: 5px 0;
  line-height: 13px;
  border: solid 2px ${Colors.BLACK};

  a {
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
  }
`;

export const PlansContainer = styled("div")`
  margin-bottom: 15px;
`;

export const PlanWrapper = styled("div")`
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;

  svg {
    width: 60px;
    height: 60px;
    display: inline-block;
    margin: 15px auto;
    fill: ${Colors.PRIMARY};

    @media screen and (min-width: 992px) {
      width: 70px;
      height: 70px;
    }
  }

  &:hover {
    background: ${Colors.PRIMARY};

    svg {
      fill: ${Colors.WHITE};
    }

    p,
    a {
      color: ${Colors.WHITE};
    }

    ${LinksWrapper} {
      border: solid 2px ${Colors.WHITE};
    }
  }
`;

export const PlanChatTitles = styled("h1")`
  color: ${Colors.TEXT_DEFAULT};
  margin: 0;
  text-align: right;

  @media screen and (max-width: 575px) {
    font-size: 18px;
    line-height: 18px;
  }

  @media screen and (max-width: 767px) {
    font-size: 20px;
    line-height: 20px;
  }
`;

export const PlanChatWrappers = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin: auto;

  a {
    margin-left: 40px;
    display: inline-block;
    background: ${Colors.PRIMARY};
    padding: 10px 20px;
    color: ${Colors.WHITE};

    &:hover {
      color: ${Colors.WHITE};
      background: ${Colors.BLACK};
    }

    @media screen and (max-width: 992px) {
      margin-left: 20px;
    }
  }

  @media screen and (max-width: 992px) {
    width: 100%;
  }
`;

export const FormWrapper = styled("form")`
  width: 100%;
  text-align: center;
  padding: 12px;
  margin-top: 20px;
  text-align: left;

  input {
    margin-top: 5px;
  }
`;

export const BigText = styled(Text)`
  font-size: 30px;
  font-weight: 400;
`;
