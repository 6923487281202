import React from "react";
import Section from "../Section";
import { Row, Col } from "antd";
import Icon from "@ant-design/icons";
import {
  Wrapper,
  Title,
  Content,
  Text,
  ContentWrapper,
  IconWrapper,
} from "./style";
import { ReactComponent as AlertFill } from "../../assets/images/alert-fill.svg";
import { Video } from "../ProductBanner/styles";
import ReactCountryFlag from "react-country-flag";

const imgcol = {
  height: "200px",
  width: "100%",
  overflow: "hidden",
};
const cols = {
  background: "#fff",
  border: "1px solid #cdcdcd",
};
const pstyle = {
  lineHeight: "18pt",
};

const StoryTeller = () => {
  return (
    <Wrapper>
      <ContentWrapper>
        <Title> </Title>
        <Row gutter={[24, 0]}>
          <Col lg={5} style={cols}>
            <Text>
              <table>
                <tr>
                  <td>
                    <div style={imgcol}>
                      <img
                        src="https://heirsdigitalform.azurewebsites.net/wp-content/uploads/2023/08/Felix.png"
                        width="100%"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingBottom: "15px" }}>
                    <span style={{ paddingLeft: "5px", fontWeight: "bold" }}>
                      Felix Byaruhanga
                    </span>
                    <span style={{ display: "block" }}>
                      <ReactCountryFlag countryCode="UG" svg /> Uganda
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingBottom: "15px" }}>
                    <p style={pstyle}>
                      <b>Gender:</b>{" "}
                    </p>
                    <p style={pstyle}>Male</p>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingBottom: "15px" }}>
                    <p style={pstyle}>
                      <b>Project Type:</b>{" "}
                    </p>
                    <p style={pstyle}>Video & Podcast</p>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingBottom: "15px" }}>
                    <p style={pstyle}>
                      <b>Project Title:</b>{" "}
                    </p>
                    <p style={pstyle}>My Hustle</p>
                  </td>
                </tr>
              </table>
            </Text>
          </Col>
          <Col offset={0} lg={10}>
            <Text>
              <b>About: </b> <br />
              Felix Byaruhanga, the visionary founder and team lead of Skyline
              Media & Management Limited, spearheads a cutting-edge creative
              agency situated in Kampala, Uganda. Notably recognized, he earned
              a coveted spot in Forbes' 30 under 30 list for 2021. CNN's "Inside
              Africa" spotlighted him in 2022, showcasing his role as a
              pioneering creative entrepreneur. Leveraging digital platforms and
              innovation, he's reshaping the landscape, fostering growth, and
              opening doors for creators in the dynamic creative industry.
            </Text>
            <Text>
              <b>Description of Project: </b>
              <br /> Felix's project "My Hustle," is a comprehensive visual and
              audio podcast series designed for the Ugandan and African creative
              economy. It is addressing the gap of insufficient guidance for
              sustainable creative business development and growth. Created by
              Skyline Media, the series aims to empower creative business owners
              by offering direct insights and strategies for building and
              maintaining sustainable enterprises. The podcast will showcase
              real-life examples from successful creative entrepreneurs,
              spanning from the birth of a business idea to effectively managing
              a thriving enterprise.
            </Text>
            <Text>
              <b>Impact</b>
              <br /> The project's reach of 2,000 individuals has set the stage
              for engagement, fostering community and collaboration among
              creative business owners.
            </Text>
          </Col>
          <Col offset={0} lg={9}>
            {/* <Text>
              <a href="https://www.instagram.com/ar/248575918020204/">
                Project
              </a>
            </Text> */}
            <Text>
              <b>Project</b>
              <br />
              <Video>
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/videoseries?si=DPqYerpaqcJWl5_L&amp;list=PLopQ52ybnd7ONKZ5-yyIdO0XT7UypRoUE"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </Video>
            </Text>
            <Text>
              <b>Lesson Learnt While Working on Project: </b>
              <ol>
                <li>
                  Engagement Drives Impact: Fostering a community through active
                  engagement has created strong connections and collaborative
                  learning.{" "}
                  <li>
                    Diverse Guests Enrich Content: Featuring a varied guest
                    lineup has boosted credibility, broadening the initiative's
                    relevance.
                  </li>
                  <li>
                    Adaptability is Key: Incorporating feedback and adapting
                    content to evolving needs enhance project effectiveness.
                  </li>
                  <li>
                    Visibility Requires Effort: Consistent marketing efforts and
                    collaborations are essential for maintaining and expanding
                    viewership.
                  </li>
                </li>
              </ol>
            </Text>
          </Col>
        </Row>
      </ContentWrapper>
    </Wrapper>
  );
};

export default StoryTeller;
