import React from "react";
import Banner from "../../components/ProductBanner";
import ComprehensivePlanPicker from "../../components/ComprehensivePlanPicker";
import backgroundImage from "../../assets/images/Flexi&comprehensive_motor.png";

const Index = () => {
  const bannerPayload = {
    title: " Comprehensive Cover",
    descriptions: [
      "This is the ‘full-course meal’ of motor insurance for those who want more cover and less headaches.",
      "Our Heirs Comprehensive insurance offers total coverage for your vehicle against accident, fire, theft, flood, riot or civil commotion, and other related risks. If any of the stated risks happen, we pay to repair or replace your car.",
      "This way, you can drive with peace of mind, knowing that your precious four-wheel is fully covered by Heirs General Insurance.",
    ],
    backgroundImage,
    // linkPath: "https://www.youtube.com/watch?v=6v_fReAkzO8",
    linkPath: "#comprehensive",
    // linkText: "Watch how it works",
    linkText: "Learn More",
  };
  return (
    <div>
      <Banner {...bannerPayload} />

      <ComprehensivePlanPicker />
    </div>
  );
};

export default Index;
