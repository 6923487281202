import React from "react";
import { Row, Col } from "antd";
import {
  Wrapper,
  Title,
  BannerContentWrapper,
  BannerContent,
  LinkWrapper,
  Overlay,
  CarAnimationWrapper,
} from "./styles";
import { CaretRightFilled } from "@ant-design/icons";
import buildingsAnimationData from "../../assets/animations/buildings.json";
import carAnimationData from "../../assets/animations/car.json";
import Lottie from "lottie-react-web";
const buildingsAnimationOptions = {
  loop: false,
  speed: 0.5,
  animationData: buildingsAnimationData,
};

const carAnimationOptions = {
  loop: true,
  animationData: carAnimationData,
};

const Banner = ({ title, linkPath, linkText }) => {
  return (
    <Wrapper>
      <Lottie
        height="100%"
        width="100%"
        speed={0.1}
        options={buildingsAnimationOptions}
      />
      <Overlay>
        <BannerContentWrapper>
          <Row>
            <Col xl={12} lg={14} md={12} sm={14} xs={14}>
              <BannerContent>
                <Title> {title}</Title>
                <LinkWrapper>
                  <a href={linkPath}>
                    {linkText} <CaretRightFilled />
                  </a>
                </LinkWrapper>
              </BannerContent>
            </Col>
            <Col xl={12} lg={10} md={12} sm={10} xs={10}>
              <CarAnimationWrapper>
                <Lottie width="90%" options={carAnimationOptions} />
              </CarAnimationWrapper>
            </Col>
          </Row>
        </BannerContentWrapper>
      </Overlay>
    </Wrapper>
  );
};

export default Banner;
