import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./assets/styles/base.less";
import "./assets/styles/normalize.css";
import reportWebVitals from "./reportWebVitals";
import AppRouter from "./components/AppRouter";

ReactDOM.render(
  <>
    <AppRouter Router={BrowserRouter} />
  </>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
