import React from "react";
import { Wrapper, Title, BannerContentWrapper, Content } from "./bannerStyle";

const Banner = ({ title, backgroundImage, children }) => {
  return (
    <Content backgroundImage={backgroundImage}>
      <Wrapper>
        <BannerContentWrapper>
          <Title> {title}</Title>
          {children}
        </BannerContentWrapper>
      </Wrapper>
    </Content>
  );
};

export default Banner;
