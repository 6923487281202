import React from "react";
import {
  Wrapper,
  Title,
  Text,
  BannerContentWrapper,
  LinkWrapper,
  Overlay,
  Video,
  Content,
} from "./styles";
import { CaretRightFilled } from "@ant-design/icons";

const Banner = ({
  title,
  descriptions = [],
  linkPath,
  productURL,
  linkText,
  children,
  backgroundImage,
  link_Path,
  link_Text,
}) => {
  return (
    <Overlay>
      <Wrapper>
        {productURL ? (
          <>
            <BannerContentWrapper>
              <Title> {title}</Title>
              {descriptions.map((description, key) => (
                <Text
                  key={key}
                  dangerouslySetInnerHTML={{ __html: `${description}` }}
                ></Text>
              ))}
              {linkText && linkPath && (
                <LinkWrapper>
                  <a href={linkPath}>
                    {linkText} <CaretRightFilled />
                  </a>
                </LinkWrapper>
              )}
              {children}
            </BannerContentWrapper>

            <Video>
              <iframe
                width="100%"
                height="315"
                src={productURL}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Video>
          </>
        ) : (
          <Content backgroundImage={backgroundImage}>
            <BannerContentWrapper>
              <Title> {title}</Title>
              {descriptions.map((description, key) => (
                <Text
                  key={key}
                  dangerouslySetInnerHTML={{ __html: `${description}` }}
                ></Text>
              ))}

              {linkText && linkPath && (
                <LinkWrapper>
                  <a href={linkPath}>
                    {linkText} <CaretRightFilled />
                  </a>
                  <a href={link_Path}>
                    {link_Text} <CaretRightFilled />
                  </a>
                </LinkWrapper>
              )}

              {children}
            </BannerContentWrapper>
          </Content>
        )}
      </Wrapper>
    </Overlay>
  );
};

export default Banner;
