import React, { useState } from "react";
import { Row, Col, Modal } from "antd";
import { Wrapper, Content, IconWrapper, Text, Card } from "./style";
import Section from "../../Section";

import Lottie from "lottie-react-web";
import quoteAnimation from "../../../assets/animations/heirs-life-vector1.json";
import affordableAnimation from "../../../assets/animations/heirs-life-vector3.json";
import rewardAnimation from "../../../assets/animations/heirs-life-vector2.json";
import bancs1 from "../../../assets/images/bancs-1.png";
import bancs2 from "../../../assets/images/bancs-2.png";
import bancs3 from "../../../assets/images/bancs-3.png";
import { SecurityScanTwoTone } from "@ant-design/icons";
import { List } from "../../PlansList/style";

const defaultOptions = {
  loop: true,
};

const Index = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [textMssg, setTextMssg] = useState(false);

  const showModal = (e) => {
    const text =
      e == "m1"
        ? "With Connect, you earn a reward for every insurance plan you sell to your customers."
        : e == "m2"
        ? "You offer your customers the additional value of securing their finances and property through your business. One access, many offerings."
        : "Buy insurance services from UBA internet banking, UBA mobile app or walk into any UBA branch close to you.";
    setTextMssg(text);
    setModalVisible(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  return (
    <Wrapper>
      <Content>
        <Section title="Ways To Get Connected" filled>
          <p
            style={{
              color: "#fff",
              textAlign: "center",
              margin: "-50px auto 20px",
              padding: "32px",
              fontSize: "14pt",
            }}
          >
            Our Support team is available to guide you through the process of
            getting connected.
          </p>
          <Row
            gutter={[32, 0]}
            style={{
              width: "90%",
              margin: "0px auto",
            }}
          >
            <Col md={12} xs={24} style={{ fontSize: "14pt" }}>
              {/* <Card> */}
              <Text
                style={{
                  fontSize: "18pt",
                  textAlign: "left",
                  padding: "4px 32px",
                }}
              >
                Open API
              </Text>
              <p
                style={{
                  textAlign: "left",
                  margin: "0px 0px 10px",
                  color: "#fff",
                  fontSize: "14pt",
                  padding: "4px 32px",
                }}
              >
                You can seamlessly integrate your platform via our APIs and
                start selling instantly.
              </p>
              <List
                style={{
                  textAlign: "left",
                  color: "#fff",
                  padding: "4px 32px",
                }}
              >
                <li style={{ fontSize: "14pt" }}>
                  Sandbox: Test out your integrations and see what's possible.
                </li>
                <li style={{ fontSize: "14pt" }}>
                  Full API Documentation: Our APIs are fully documented for easy
                  consumptions and it's do-it-your-self ready.
                </li>
              </List>
              {/* </Card> */}
            </Col>
            <Col md={12} xs={24} style={{ fontSize: "14pt" }}>
              {/* <Card> */}
              <Text
                style={{
                  fontSize: "18pt",
                  textAlign: "left",
                  padding: "4px 32px",
                }}
              >
                Portal
              </Text>
              <p
                style={{
                  textAlign: "left",
                  margin: "0px 0px 10px",
                  color: "#fff",
                  fontSize: "14pt",
                  padding: "4px 32px",
                }}
              >
                <a href="https://partners.heirsinsurance.com/signin">Log In</a>{" "}
                to sell directly from our interactive dashboard.
              </p>
              {/* </Card> */}
            </Col>
          </Row>
        </Section>
      </Content>
      <Modal visible={modalVisible} onCancel={handleCancel} footer={null}>
        <div
          style={{
            height: "200px",
            width: "100%",
            fontSize: "18pt",
            textAlign: "center",
            padding: "32px 16px",
          }}
        >
          {textMssg}
        </div>
      </Modal>
    </Wrapper>
  );
};

export default Index;
