import React, { useEffect, useState } from "react";
import { List, Spin, Avatar, Card, Skeleton, Switch, Button } from "antd";
import {
  AnswerItemWrapper,
  AnswersWrapper,
  ImageContainer,
  Text,
  Title,
} from "./styles";
import { dateFormat } from "../../utils/dateFormatter";
import { ContentLoader } from "../MediaPost/styles";
import CallBack from "../../components/CallBack";

const FinancialStatements = () => {
  const [posts, setPosts] = useState([]);
  const [posts2, setPosts2] = useState([]);
  const [posts3, setPosts3] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchPosts = (pageNum) => {
    setPosts([
      {
        ID: 1,
        title: "Anti Money Laundering - CFT Policy",
        description:
          "Anti Money Laundering - CFT Policy. Click the button to read the full details of the report",
        image: "https://via.placeholder.com/350x150",
        url: "https://heirsinsurancelimited.sharepoint.com/:b:/s/PublicFolder/ESP6iAZ-4zZEmb9bRCI-KM4BFASf7rjO2StfPfTcu72mKg?e=AXgeAJ",
      },
    ]);
    setIsLoading(false);
  };

  const fetchPosts2 = (pageNum) => {
    setPosts2([
      {
        ID: 1,
        title: "2022 Audited Financial Statement",
        description:
          "2022 Audited Financial Statement. Click the button to read the full details of the report",
        image: "https://via.placeholder.com/350x150",
        url: "https://heirsinsurancelimited.sharepoint.com/:b:/s/PublicFolder/EfSAgUS_FatOrBidmj32EwcBzQIWdj8Ef8rXlaJYyYvZfg?e=KRxGFj",
      },
      {
        ID: 2,
        title: "13 Months Financial Statement Reports Ending December 2021",
        description:
          "13 months financial statement reports ending december, 2021. Click the button to read the full details of the report",
        image: "https://via.placeholder.com/350x150",
        url: "https://heirsinsurancelimited.sharepoint.com/:b:/s/PublicFolder/EY1MT08R1DtFn0jCuf-JDygBPqPDRax7bq1eCHMs6ONu4A?e=bITdM9",
      },
    ]);
    setIsLoading(false);
  };

  const fetchPosts3 = (pageNum) => {
    setPosts3([
      {
        ID: 1,
        title: "Stakeholders Management and Communications Policy",
        description:
          "Stakeholders Management and Communications Policy. Click the button to read the full details of the report",
        image: "https://via.placeholder.com/350x150",
        url: "https://heirsinsurancelimited.sharepoint.com/:b:/s/PublicFolder/ESeaatpScSlNjyJlWXafE_8B0SsSRI3m7Sv-xMMhP4yvUA?e=YWe4Ul",
      },
      {
        ID: 2,
        title: "Sustainability Policy",
        description:
          "Sustainability Policy. Click the button to read the full details of the report",
        image: "https://via.placeholder.com/350x150",
        url: "https://heirsinsurancelimited.sharepoint.com/:b:/s/PublicFolder/ESWxRQUmsvZBp-DRhh-gtvUBsT9mcoyVC_nqjMEU04P7aQ?e=fZLFTy",
      },
      {
        ID: 3,
        title: "Code of Conduct Policy",
        description:
          "Code of Conduct Policy. Click the button to read the full details of the report",
        image: "https://via.placeholder.com/350x150",
        url: "https://heirsinsurancelimited.sharepoint.com/:b:/s/PublicFolder/EQRnmXco4kdEiiJXf2de1gUB7PL48-RHTqsqjRIvvDDvmg?e=td18wb",
      },
      {
        ID: 4,
        title: "Corporate Communications Policy",
        description:
          "Corporate Communications Policy. Click the button to read the full details of the report",
        image: "https://via.placeholder.com/350x150",
        url: "https://heirsinsurancelimited.sharepoint.com/:b:/s/PublicFolder/EU3w6cNQ_mROpryX-1__5PsByBHfB6NdNy4lzu3Q-IIi4w?e=mYRbJ0",
      },
      {
        ID: 5,
        title: "Whistle Blowing Policy",
        description:
          "Whistle Blowing Policy. Click the button to read the full details of the report",
        image: "https://via.placeholder.com/350x150",
        url: "https://heirsinsurancelimited.sharepoint.com/:b:/s/PublicFolder/EYmgqCUgzypBsqYrT9hNUykB3oejs_fqDeez1wn_aJ5agw?e=wu23cw",
      },
    ]);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  useEffect(() => {
    fetchPosts2();
  }, []);

  useEffect(() => {
    fetchPosts3();
  }, []);

  return (
    <>
      <AnswersWrapper>
        <div
          style={{ fontWeight: "bold", fontSize: "18pt", paddingLeft: "20px" }}
        >
          BOARD ARCHIVE
        </div>
        <hr></hr>
        {!isLoading ? (
          <>
            <List
              // pagination={{
              //   position: "bottom",
              //   total: totalPosts,
              //   pageSize: pageSize,
              //   current: currentPage,
              //   onChange: (page) => {
              //     handlePageChange(page);
              //   },
              // }}
              grid={{
                gutter: 64,
                column: 2,
              }}
              dataSource={posts3}
              renderItem={(item) => (
                <List.Item key={item.ID}>
                  <Card
                    style={{ width: 500, marginTop: 16 }}
                    actions={[
                      <Button
                        onClick={() => {
                          window.open(item.url);
                        }}
                      >
                        View Report
                      </Button>,
                    ]}
                  >
                    <Card.Meta
                      title={item.title}
                      description={item.description}
                    />
                  </Card>
                </List.Item>
              )}
            />
          </>
        ) : (
          <ContentLoader>
            <Spin size="large" />
          </ContentLoader>
        )}
      </AnswersWrapper>
      {/* <CallBack /> */}

      <AnswersWrapper>
        <div
          style={{ fontWeight: "bold", fontSize: "18pt", paddingLeft: "20px" }}
        >
          FINANCE ARCHIVE
        </div>
        <hr></hr>
        {!isLoading ? (
          <>
            <List
              // pagination={{
              //   position: "bottom",
              //   total: totalPosts,
              //   pageSize: pageSize,
              //   current: currentPage,
              //   onChange: (page) => {
              //     handlePageChange(page);
              //   },
              // }}
              grid={{
                gutter: 64,
                column: 2,
              }}
              dataSource={posts2}
              renderItem={(item) => (
                <List.Item key={item.ID}>
                  <Card
                    style={{ width: 500, marginTop: 16 }}
                    actions={[
                      <Button
                        onClick={() => {
                          window.open(item.url);
                        }}
                      >
                        View Report
                      </Button>,
                    ]}
                  >
                    <Card.Meta
                      title={item.title}
                      description={item.description}
                    />
                  </Card>
                </List.Item>
              )}
            />
          </>
        ) : (
          <ContentLoader>
            <Spin size="large" />
          </ContentLoader>
        )}
      </AnswersWrapper>

      <AnswersWrapper>
        <div
          style={{ fontWeight: "bold", fontSize: "18pt", paddingLeft: "20px" }}
        >
          REGULATORY ARCHIVE
        </div>
        <hr></hr>
        {!isLoading ? (
          <>
            <List
              // pagination={{
              //   position: "bottom",
              //   total: totalPosts,
              //   pageSize: pageSize,
              //   current: currentPage,
              //   onChange: (page) => {
              //     handlePageChange(page);
              //   },
              // }}
              grid={{
                gutter: 64,
                column: 2,
              }}
              dataSource={posts}
              renderItem={(item) => (
                <List.Item key={item.ID}>
                  <Card
                    style={{ width: 500, marginTop: 16 }}
                    actions={[
                      <Button
                        onClick={() => {
                          window.open(item.url);
                        }}
                      >
                        View Report
                      </Button>,
                    ]}
                  >
                    <Card.Meta
                      title={item.title}
                      description={item.description}
                    />
                  </Card>
                </List.Item>
              )}
            />
          </>
        ) : (
          <ContentLoader>
            <Spin size="large" />
          </ContentLoader>
        )}
      </AnswersWrapper>
    </>
  );
};

export default FinancialStatements;
