import styled from "styled-components";
import * as Colors from "../../utils/colors";
import { containerWrapper } from "../../utils/stylescheet";

export const Title = styled("h1")`
  font-size: 55px;
  line-height: 60px;
  color: ${Colors.TEXT_DEFAULT};

  @media screen and (max-width: 575px) {
    font-size: 14px;
    line-height: 14px;
  }

  @media screen and (min-width: 576px) {
    font-size: 18px;
    line-height: 18px;
  }

  @media screen and (min-width: 768px) {
    font-size: 25px;
    line-height: 25px;
  }

  @media screen and (min-width: 992px) {
    font-size: 30px;
    line-height: 30px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 35px;
    line-height: 35px;
  }

  @media screen and (min-width: 1600px) {
    font-size: 50px;
    line-height: 50px;
  }
`;

export const Text = styled("p")`
  color: ${Colors.TEXT_DEFAULT};
  font-size: 20px;
  margin-bottom: 20px;

  @media screen and (max-width: 991px) {
    width: 50%;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const BannerContentWrapper = styled("div")`
  ${containerWrapper};
  position: relative;
`;

export const CarAnimationWrapper = styled("div")`
  margin-top: 15%;
  bottom: 10px;

  @media screen and (min-width: 576px) {
    margin-top: 13%;
  }

  @media screen and (min-width: 768px) {
    margin-top: 9%;

    svg {
      width: 90% !important;
    }
  }

  @media screen and (min-width: 992px) {
    margin-top: 12%;

    svg {
      width: 100% !important;
    }
  }

  @media screen and (min-width: 1200px) {
    margin-top: 10%;
    svg {
      width: 100% !important;
    }
  }

  @media screen and (min-width: 1600px) {
    margin-top: 8%;
    svg {
      width: 100% !important;
    }
  }
`;

export const BannerContent = styled("div")`
  ${Title} {
    @media screen and (max-width: 575px) {
      margin-top: 10%;
    }

    @media screen and (min-width: 576px) {
      margin-top: 8%;
    }

    @media screen and (min-width: 768px) {
      margin-top: 10%;
    }

    @media screen and (min-width: 992px) {
      margin-top: 10%;
    }

    @media screen and (min-width: 1200px) {
      margin-top: 10%;
    }
  }
`;

export const LinkWrapper = styled("div")`
  margin-top: 15px;
  display: inline-block;
  background: ${Colors.PRIMARY};

  a {
    padding: 10px 20px;
    color: ${Colors.WHITE};
    display: flex;
    align-items: center;
    font-weight: bold;

    svg {
      width: 20px;
      height: 20px;
      margin-left: 10px;
    }

    &:hover {
      color: ${Colors.WHITE};
      background: ${Colors.BLACK};
    }
  }

  @media screen and (max-width: 575px) {
    margin-top: 5px;

    a {
      padding: 5px 10px;
      font-size: 13px;

      svg {
        width: 15px;
        height: 15px;
        margin-left: 5px;
      }
    }
  }
`;

export const Overlay = styled("div")`
  background-color: rgb(243 84 91 / 7%);
  position: absolute;
  right: 0;
  left: 0;
  bottom: 2px;
  top: 0;
`;

export const Wrapper = styled("div")`
  margin-top: 4.4%;
  margin-top: 4.4%;
  position: relative;
  margin-bottom: 20px;
  height: 120px;

  @media screen and (min-width: 576px) {
    height: 150px;
  }

  @media screen and (min-width: 768px) {
    height: 200px;
  }

  @media screen and (min-width: 992px) {
    height: 230px;
  }

  @media screen and (min-width: 1200px) {
    height: 320px;
  }

  @media screen and (min-width: 1600px) {
    height: 400px;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 0;
  }
`;

// Discount Modal

export const ModalText = styled("p")`
  font-size: 12px;
  :first-of-type {
    margin-bottom: 1rem;
    text-align: center;
  }

  :not(:first-of-type) {
    margin-top: 1rem;
  }
`;

export const ModalHeader = styled("h2")`
  margin-bottom: 1.5rem;
`;

export const ModalContentWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  justify-content: center;
  align-items: center;

  .ant-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .ant-form-item {
      width: 60%;
    }

    .ant-form-item:not(:first-child) {
      margin-bottom: 0;
    }

    .ant-form-item .ant-form-item {
      width: 100%;
      margin-bottom: 0;
    }
  }
`;

export const ModalWrapper = styled("div")``;
