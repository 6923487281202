import styled, { css } from "styled-components";

export const FlipCardInner = styled("div")`
  @media (min-width: 992px) {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }
`;

export const FlipContent = styled("div")`
  @media (min-width: 992px) {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
`;

export const BackFlipContent = styled(FlipContent)`
  display: none;
  @media (min-width: 992px) {
    transform: rotateY(180deg);
    display: block;
  }
`;

const flippedCard = css`
  ${FlipCardInner} {
    transform: rotateY(180deg);
  }
`;

export const FlipCard = styled("div")`
  @media (min-width: 992px) {
    background-color: transparent;
    height: 120px;
    perspective: 1000px;

    ${({ flip }) => flip && flippedCard};

    &:hover {
      ${FlipCardInner} {
        transform: rotateY(180deg);
      }
    }
  }
`;
