import React, { useState } from "react";
import { Row, Col } from "antd";
import {
  Wrapper,
  ItemWrapper,
  Title,
  ListWrapper,
  ListItemWrapper,
  Text,
} from "./styles";
import OurValues from "../../assets/images/our_values_HIL.png";
import { CaretRightOutlined } from "@ant-design/icons";

const Index = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <Wrapper>
      <Row gutter={[16, 0]}>
        <Col lg={16}>
          <ListWrapper>
            <ListItemWrapper
              active={activeTab === 0}
              onClick={() => setActiveTab(0)}
            >
              OUR CULTURE
            </ListItemWrapper>
            <ListItemWrapper
              active={activeTab === 1}
              onClick={() => setActiveTab(1)}
            >
              OUR VALUES
            </ListItemWrapper>
            {/* <ListItemWrapper
              active={activeTab === 2}
              onClick={() => setActiveTab(2)}
            >
              Our Approach
            </ListItemWrapper> */}
          </ListWrapper>

          <ItemWrapper active={activeTab === 0}>
            {/* <Title>OUR CULTURE</Title> */}

            <Text>
              At Heirs General Insurance Limited, we live a culture that
              prioritizes collaboration and innovation. <br /> <br />
              By encouraging people to think way beyond the box and bring their
              unique ideas to the table, we transform our industry one idea at a
              time while contributing to the economic development of our
              country. <br /> <br />
              Collectively, as a team, we are responsible for living our
              corporate values and ethics, the bedrock of our organisation.
            </Text>
          </ItemWrapper>

          <ItemWrapper active={activeTab === 1}>
            {/* <Title>OUR VALUES</Title> */}

            <Text>
              We derive our value from our parent company, Heirs Holdings. Our
              values stem from our Founder, Tony Elumelu’s business beliefs,
              practices, and culture; philosophies that have been tested and
              proven over time as well as continue to transform the African
              continent. <br /> <br />
              <p>
                <Title>
                  <CaretRightOutlined />
                  <b>ENTERPRISE</b>
                </Title>
              </p>
              Having the willingness and determination to do whatever it takes
              to get the job done. <br /> <br />
              <p>
                <Title>
                  <CaretRightOutlined />
                  <b>EXCELLENCE</b>
                </Title>
              </p>
              A burning desire to always see things through to completion in a
              timely, efficient, and effective manner. <br /> <br />
              <p>
                <Title>
                  <CaretRightOutlined />
                  <b>EXECUTION</b>
                </Title>
              </p>
              Delivering in an outstanding manner, consistently. <br /> <br />
            </Text>
          </ItemWrapper>
        </Col>
        <Col lg={8}>
          <img src={OurValues} alt={"Our Values"} />
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Index;
