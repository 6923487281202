import React, { useEffect, useState } from "react";
import {
  PlanChatTitle,
  PlanChatWrapper,
  Text,
  Title,
  Wrapper,
  List,
} from "../PlanPicker/styles";

import personal from "../../assets/images/icon_personal_accident.png";
import { Link } from "react-router-dom";
import { HeirsDashboardUrl } from "../../utils/constants";
import { Mixpanel } from "./../../Mixpanel";
import { Modal, Col, Row } from "antd";

const Index = () => {
  const [modalVisible, setModalVisible] = useState(false);

  const showModal = () => {
    setModalVisible(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const handleChatWithUs = (e) => {
    e.preventDefault();
    // window.fcWidget.open({});
    window.Freshbots.showWidget({});
  };

  const checkClickEvent = (e) => {
    if (e.target.id === "purchaseStatus") {
      setPurchaseStatus();
    }
  };

  const setPurchaseStatus = () => {
    localStorage.setItem("attempted_purchase", "true");
  };

  const checkScrollTop = () => {
    const tableTop = document.getElementById("d-fixed");
    if (tableTop) {
      if (window.pageYOffset > 690) {
        tableTop.classList.add("p-fixed");
        document.querySelector("#d-fixed .ant-table-tbody").style.display =
          "none";
        document.querySelector(
          "#product-table .ant-table-thead"
        ).style.display = "none";
      } else {
        tableTop.classList.remove("p-fixed");
      }
    }
  };

  useEffect(() => {
    let p = document.getElementById("table");
    let p_prime = p.cloneNode(true);
    document.getElementById("d-fixed").appendChild(p_prime);
  }, []);

  useEffect(() => {
    localStorage.setItem("last_accessed_product", window.location.pathname);
    window.addEventListener("scroll", checkScrollTop);
    window.addEventListener("click", checkClickEvent);
  });

  return (
    <Wrapper id="accidents">
      <div className="ant-table" id="ant-table">
        <div id="d-fixed" />
        <div id="product-table">
          <table id="table">
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell" style={{ width: "20%" }}>
                  <img src={personal} alt="personal accident" />
                </th>
                <th className="ant-table-cell" style={{ width: "20%" }}>
                  <Title>Personal Accident Bronze</Title>
                  <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Personal Accident - Buy Now")}
                    href={`${HeirsDashboardUrl}/accident-type?policy-type=HEIRS Personal Accident (Bronze)`}
                  >
                    Buy Now
                  </a>
                  <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Personal Accident - Get Quote")}
                    href={`${HeirsDashboardUrl}/accident-type?policy-type=HEIRS Personal Accident (Bronze)`}
                    style={{ marginTop: "5px" }}
                  >
                    Get A Quote
                  </a>
                </th>
                <th className="ant-table-cell" style={{ width: "20%" }}>
                  <Title>Personal Accident Silver</Title>
                  <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Personal Accident - Buy Now")}
                    href={`${HeirsDashboardUrl}/accident-type?policy-type=HEIRS Personal Accident (Silver)`}
                  >
                    Buy Now
                  </a>
                  <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Personal Accident - Get Quote")}
                    href={`${HeirsDashboardUrl}/accident-type?policy-type=HEIRS Personal Accident (Silver)`}
                    style={{ marginTop: "5px" }}
                  >
                    Get A Quote
                  </a>
                </th>
                <th className="ant-table-cell" style={{ width: "20%" }}>
                  <Title>Personal Accident Gold</Title>
                  <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Personal Accident - Buy Now")}
                    href={`${HeirsDashboardUrl}/accident-type?policy-type=HEIRS Personal Accident (Gold)`}
                  >
                    Buy Now
                  </a>
                  <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Personal Accident - Get Quote")}
                    href={`${HeirsDashboardUrl}/accident-type?policy-type=HEIRS Personal Accident (Gold)`}
                    style={{ marginTop: "5px" }}
                  >
                    Get A Quote
                  </a>
                </th>
                <th className="ant-table-cell" style={{ width: "20%" }}>
                  <Title>Personal Accident Platinum</Title>
                  <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Personal Accident - Buy Now")}
                    href={`${HeirsDashboardUrl}/accident-type?policy-type=HEIRS Personal Accident (Platinum)`}
                  >
                    Buy Now
                  </a>
                  <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Personal Accident - Get Quote")}
                    href={`${HeirsDashboardUrl}/accident-type?policy-type=HEIRS Personal Accident (Platinum)`}
                    style={{ marginTop: "5px" }}
                  >
                    Get A Quote
                  </a>
                </th>
              </tr>
            </thead>

            <tbody className="ant-table-tbody">
              <tr>
                <td className="ant-table-cell">
                  <Text>Cost</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>N1,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>N2,500</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>N5,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>N10,000</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Perfect for?</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Age 18 - 60</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Age 18 - 60</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Age 18 - 60</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Age 18 - 60</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Payment for medical expenses</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>N25,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>N50,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>N100,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>N200,000</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Compensation for disability</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>N500,0000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>N1,000,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>N2,000,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>N3,500,000</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>
                    Financial support to beneficiary if there is loss of life
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text>N500,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>N1,000,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>N2,000,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>N3,500,000</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Funeral Expense</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>N25,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>N50,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>N100,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>N200,000</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" colSpan="5">
                  <Text highlight>Exclusions (Benefits not covered)</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Self-Inflicted Injury</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Injury from influence of Drugs/Alcohol</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Suicide & attempted suicide</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>
                    Ailment(s) existing before the inception of policy
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Terminal illnesses</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Document Requirements</Text>
                </td>
                <td className="ant-table-cell" colspan="4">
                  <Text>
                    <List>
                      <li>Duly Filled Proposal/KYC Form.</li>
                      <li>Evidence of Payment.</li>
                      <li>
                        Valid means of ID - Driver license, Voter's Card, NIMC,
                        National ID, International Passport.
                      </li>
                      <li>
                        Proof of residence - Utility Bill, Front page of the
                        customer's address (same address as indicated on the
                        form or portal), Local government levy.
                      </li>
                    </List>
                  </Text>
                </td>
              </tr>
              <tr>
                <td
                  className="ant-table-cell"
                  colspan="5"
                  style={{ textAlign: "center" }}
                >
                  <Text>
                    <a
                      onClick={showModal}
                      target="blank"
                      style={{
                        background: "#ff0000",
                        color: "#fff",
                        display: "inline-block",
                        padding: "8px",
                        borderRadius: "5px",
                        width: "100px",
                        height: "auto",
                        margin: "0px 10px",
                      }}
                    >
                      FAQs
                    </a>
                  </Text>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <PlanChatWrapper>
        <PlanChatTitle>
          Not Sure of the Right <br />
          Plan for you?
        </PlanChatTitle>
        <Link to="/" onClick={handleChatWithUs}>
          Chat with us
        </Link>
      </PlanChatWrapper>
      <Modal
        visible={modalVisible}
        onCancel={handleCancel}
        footer={null}
        style={{ padding: "0px" }}
      >
        <Row>
          <h1 style={{ color: "rgb(255,0,0)", paddingBottom: "10px" }}>
            Personal Accident - FAQs
          </h1>
        </Row>
        <div style={{ height: "450px", overflowY: "auto" }}>
          <Row style={{ padding: "10px 2px" }}>
            <Col>
              <h3 style={{ fontWeight: "bold" }}>Who is eligible?</h3>
            </Col>
            <Col> Anyone aged 18 - 60</Col>
          </Row>
          <Row style={{ padding: "10px 2px" }}>
            <Col>
              <h3 style={{ fontWeight: "bold" }}>
                How much does the plan cost?
              </h3>
            </Col>
            <Col> Our Personal Accident plan starts from N1,000 yearly.</Col>
          </Row>
          <Row style={{ padding: "10px 2px" }}>
            <Col>
              <h3 style={{ fontWeight: "bold" }}>
                What types of accidents does the insurance plan cover?
              </h3>
            </Col>
            <Col> All types of accidents (home, road, workplace etc.)</Col>
          </Row>
          <Row style={{ padding: "10px 2px" }}>
            <Col>
              <h3 style={{ fontWeight: "bold" }}>
                How severe does the accident have to be for me to get
                compensated?
              </h3>
            </Col>
            <Col>
              {" "}
              Any injury is regarded as an accident if it restrains you from
              carrying out your day-to-day activities within a specified period.
            </Col>
          </Row>
          <Row style={{ padding: "10px 2px" }}>
            <Col>
              <h3 style={{ fontWeight: "bold" }}>What are the benefits?</h3>
            </Col>
            <Col>
              {" "}
              <ol>
                <li> Payment for medical expenses</li>
                <li>Compensation for disability</li>
                <li>
                  Funeral expenses and financial support to your beneficiary if
                  the accident is fatal.
                </li>
              </ol>
            </Col>
          </Row>
          <Row style={{ padding: "10px 2px" }}>
            <Col>
              <h3 style={{ fontWeight: "bold" }}>Does it cover illness?</h3>
            </Col>
            <Col>
              {" "}
              No, except for illness arising from an accident. The following
              incidents are also not covered:
              <ol>
                <li> Self-inflicted injury.</li>
                <li> Injury from drug/alcohol use.</li>
                <li> Suicide or attempted suicide.</li>
                <li> Terminal illness.</li>
              </ol>
            </Col>
          </Row>
          <Row style={{ padding: "10px 2px" }}>
            <Col>
              <h3 style={{ fontWeight: "bold" }}>
                Does coverage extend outside Nigeria?{" "}
              </h3>
            </Col>
            <Col>
              {" "}
              Yes, if your policy was active before travelling outside the
              country.
            </Col>
          </Row>
        </div>
      </Modal>
    </Wrapper>
  );
};

export default Index;
