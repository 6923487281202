import React from "react";
import backgroundImage from "../../assets/images/fast-claims.png";
// import backgroundImage from "../../assets/images/contact-bg.png";
import Banner from "./Banner";
import { Wrapper } from "../Contact/styles";
import ClaimsDetails from "./ClaimsDetails";

const Index = () => {
  const bannerPayload = {
    // title: "",
    descriptions: ["Your Fast Claims Journey Starts Now"],
    // descriptions: [],
    linkPath: "#fast-claims",
    // linkText: "Watch how it works",
    // linkText: "",
    linkText: "Start a Claim",
    otherLinkPath: "/",
    otherLinkText: "What to Expect",
    backgroundImage,
  };
  return (
    <Wrapper>
      <Banner {...bannerPayload} />
      <ClaimsDetails />
    </Wrapper>
  );
};

export default Index;
