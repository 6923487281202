import React from "react";
import Banner from "./Banner";
import OurPhilosophy from "../../components/OurPhilosophy";
import OurLeadership from "../../components/OurLeadership";
import backgroundImage from "../../assets/images/about-us-banner.png";

const Index = () => {
  const bannerPayload = {
    title: "Who We Are",
    descriptions: [
      "We are a team of professionals who believe that there is a simpler and better way to do insurance.",
    ],
    backgroundImage,
  };
  return (
    <div>
      <Banner {...bannerPayload} />
      <OurPhilosophy />
      <OurLeadership />
    </div>
  );
};

export default Index;
