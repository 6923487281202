import styled from "styled-components";
import { Button as AntButton } from "antd";
import * as Colors from "../../utils/colors";

export const ButtonWrapper = styled(AntButton)`
  color: ${Colors.WHITE};
  height: auto !important;
  padding: 10px 25px !important;
  border: none !important;
  background: ${Colors.PRIMARY};
  transition: none !important;
  border-radius: 0 !important;

  &:hover {
    height: auto !important;
    padding: 10px 25px !important;
    border: none !important;
    background: ${Colors.PRIMARY};
  }

  @media screen and (max-width: 768px) {
    & {
      padding: 6px 21px !important;
      font-size: 12px !important;
    }
  }
`;
