import React from "react";
import Banner from "./Banner.js";
import CorporateSocial from "../../components/CorporateSocial/index.js";
import CSR from "../../components/CorporateSocialTEF/siziphiwe.js";
import SubscribeForm from "../../components/SubscribeForm/index.jsx";
import backgroundImage from "../../assets/images/Storeyteller.png";

const Index = () => {
  const bannerPayload = {
    title: "Tony Elumelu Storytellers Fund Grantee",
    descriptions: [" "],
    backgroundImage,
    linkPath: "/corporate-social",
    linkText: "Home",
  };

  return (
    <div>
      <Banner {...bannerPayload} />

      <CSR />
    </div>
  );
};

export default Index;
