import styled from "styled-components";
import * as Colors from "../../utils/colors";
import { containerWrapper } from "../../utils/stylescheet";

export const Title = styled("h1")`
  font-size: 55px;
  line-height: 60px;
  color: ${Colors.TEXT_DEFAULT};
  margin-bottom: 0;

  @media screen and (max-width: 991px) {
    font-size: 35px;
    line-height: 35px;
  }

  @media screen and (max-width: 767px) {
    font-size: 25px;
    line-height: 25px;
  }

  @media screen and (max-width: 576px) {
    margin-top: 10%;
    font-size: 18px;
    line-height: 17px;
  }
`;

export const Text = styled("p")`
  color: ${Colors.TEXT_DEFAULT};
  font-size: 20px;
  margin-bottom: 20px;

  @media screen and (max-width: 991px) {
    width: 50%;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const BannerContentWrapper = styled("div")`
  ${containerWrapper};
`;

export const BannerContent = styled("div")`
  @media screen and (max-width: 575px) {
    background: rgb(255 255 255 / 50%);
    position: absolute;
    height: 100%;
    width: 100%;
    padding: 0 10px;
  }

  @media screen and (min-width: 576px) {
    width: 60%;
    margin-top: 5%;
  }

  @media screen and (min-width: 768px) {
    width: 80%;
    margin-top: 15%;
  }

  @media screen and (min-width: 991px) {
    width: 45%;
    margin-top: 20%;
  }

  @media screen and (min-width: 1200px) {
    width: 45%;
    margin-top: 15%;
  }

  @media screen and (min-width: 1600px) {
    width: 45%;
    margin-top: 10%;
  }
`;

export const Overlay = styled("div")`
  background-color: rgb(243 84 91 / 7%);
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
`;

export const Wrapper = styled("div")`
  margin-top: 4.4%;
  min-height: 565px;
  background-image: ${({ backgroundImage }) =>
    backgroundImage ? "url(" + backgroundImage + ")" : ""};
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 700px auto;
  position: relative;

  @media screen and (max-width: 991px) {
    min-height: 400px;
    background-size: 500px auto;
  }

  @media screen and (max-width: 767px) {
    min-height: 205px;
    background-size: 250px auto;
  }

  @media screen and (max-width: 575px) {
    min-height: 250px;
    background-size: contain;
  }

  @media screen and (max-width: 1024px) {
    background-image: none;
  }
`;
