import React, { useEffect } from "react";
import {
  PlanChatTitle,
  PlanChatWrapper,
  Text,
  Title,
  Wrapper,
} from "../PlanPicker/styles";
import { Wrappers, Content, PlanChatWrappers, PlanChatTitles } from "./style";
import {
  TextContentWrapper,
  TitleText,
  TextParagraph,
  ImageWrapper,
} from "./styles";

import bancassurance_icon from "../../assets/images/bancassurance-2.png";
import { Link } from "react-router-dom";
import { HeirsDashboardUrl } from "../../utils/constants";
import { Mixpanel } from "../../Mixpanel";
import Section from "../Section";
import PlanItems from "./Items";

const Index = () => {
  const onLoadPg = () => {
    Mixpanel.track("Bancassurance Products");
  };

  useEffect(() => {
    onLoadPg();
  }, []);

  return (
    <Wrappers id="tenant">
      <Content>
        <Section title="Heirs General Insurance Products"></Section>
        <PlanItems />
      </Content>
    </Wrappers>
  );
};

export default Index;
