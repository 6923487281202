import styled from "styled-components";
import { Input } from "antd";
import * as Colors from "../../utils/colors";
import { boxShadow } from "../../utils/stylescheet";
const { TextArea } = Input;

export const TextInputWrapper = styled(TextArea)`
  color: ${Colors.GRAY_10}
  height: auto !important;
  padding: 10px 25px !important;
  border: solid 1px ${Colors.PRIMARY} !important;
  border-radius: 5px;
  ${boxShadow}
  
  &:focus {
    box-shadow: none;
  }
`;
