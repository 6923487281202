import styled from "styled-components";
import * as Colors from "../../utils/colors";
import { containerWrapper } from "../../utils/stylescheet";

export const Wrapper = styled("div")`
  padding: 90px 0;
  ${containerWrapper};

  img {
    width: 100%;
  }
`;

export const Title = styled("h4")`
  margin-bottom: 10px;
  font-weight: 900;
  font-family: "Mulish Bold", sans-serif;
`;

export const Text = styled("p")`
  line-height: 30px;
  margin-bottom: 25px;
`;

export const ItemWrapper = styled("div")`
  width: 100%;
  opacity: 0;
  transform: translateX(-50px);
  transition: transform 800ms ease-in-out;
  height: 0;
  ${({ active }) =>
    active && `height: auto; transform: translateX(0); opacity: 1;`};

  @media screen and (min-width: 992px) {
    width: 80%;
  }
`;

export const ListItemWrapper = styled("li")`
  display: inline-block;
  border: solid 1px ${Colors.GRAY_10};
  margin: 0 15px;
  text-align: center;
  padding: 5px 25px;
  cursor: pointer;

  ${({ active }) =>
    active &&
    `border: none; border-bottom: solid 2px ${Colors.PRIMARY};
       @media screen and (max-width: 991px) {
          border: solid 2px ${Colors.PRIMARY};
       }
    `};

  @media screen and (max-width: 575px) {
    display: block;
    margin: 5px 0;
  }

  @media screen and (min-width: 768px) {
    margin: 0 5px;
  }
`;
export const ListWrapper = styled("ul")`
  margin: 0 0 30px;
  padding: 0;
  list-style: none;

  ${ListItemWrapper} {
    &:first-of-type {
      margin-left: 0;
    }
  }
`;
