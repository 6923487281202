export const dateFormat = (date) => {
  const d = new Date(date);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const dMonth = d.getMonth();

  const createdDate = `${months[dMonth]} ${d.getDate()}, ${d.getFullYear()}`;
  return createdDate;
};
