import React, { useEffect, useState } from "react";
import { List, Spin, Avatar, Card, Skeleton, Switch, Button } from "antd";
import {
  AnswerItemWrapper,
  AnswersWrapper,
  ImageContainer,
  Text,
  Title,
} from "./styles";
import { dateFormat } from "../../utils/dateFormatter";
import { ContentLoader } from "../MediaPost/styles";
import CallBack from "../../components/CallBack";

const FinancialStatements = () => {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchPosts = (pageNum) => {
    setPosts([
      {
        ID: 1,
        title:
          "HLA - 2021 Corporate Governance Evaluation Statement for Annual Report",
        description:
          "HLA - 2021 Corporate Governance Evaluation Statement for Annual Report. Click the button to read the full details of the report",
        image: "https://via.placeholder.com/350x150",
        url: "https://heirsinsurancelimited.sharepoint.com/:b:/s/Repository/ESWuOCXblU5KtNJ2Atr-RzIBdsYshvF8TUO_HZ63-15GZg?e=gKpgAc",
      },
      {
        ID: 2,
        title: "HLA - 2021 Board Evaluation Statement for Annual Report",
        description:
          "HLA  - 2021 Board Evaluation Statement for Annual Report. Click the button to read the full details of the report",
        image: "https://via.placeholder.com/350x150",
        url: "https://heirsinsurancelimited.sharepoint.com/:b:/s/Repository/EbnEboMVditAq-h1fv0h5lEBpy67OzE4Sq_b4pXkQM-ZMA?e=HWqIxX",
      },
      {
        ID: 3,
        title: "HLA - Stakeholders Management and Communications Policy",
        description:
          "HLA - Stakeholders Management and Communications Policy. Click the button to read the full details of the report",
        image: "https://via.placeholder.com/350x150",
        url: "https://heirsinsurancelimited.sharepoint.com/:b:/s/Repository/ESWuOCXblU5KtNJ2Atr-RzIBdsYshvF8TUO_HZ63-15GZg?e=gKpgAc",
      },
    ]);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  return (
    <>
      <AnswersWrapper>
        {!isLoading ? (
          <>
            <List
              // pagination={{
              //   position: "bottom",
              //   total: totalPosts,
              //   pageSize: pageSize,
              //   current: currentPage,
              //   onChange: (page) => {
              //     handlePageChange(page);
              //   },
              // }}
              grid={{
                gutter: 64,
                column: 3,
              }}
              dataSource={posts}
              renderItem={(item) => (
                <List.Item key={item.ID}>
                  <Card
                    style={{ width: 300, marginTop: 16 }}
                    actions={[
                      <Button
                        onClick={() => {
                          window.open(item.url);
                        }}
                      >
                        View Report
                      </Button>,
                    ]}
                  >
                    <Card.Meta
                      title={item.title}
                      description={item.description}
                    />
                  </Card>
                </List.Item>
              )}
            />
          </>
        ) : (
          <ContentLoader>
            <Spin size="large" />
          </ContentLoader>
        )}
      </AnswersWrapper>
      <CallBack />
    </>
  );
};

export default FinancialStatements;
