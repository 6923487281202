import styled from "styled-components";
import { DatePicker, Input, Select, TimePicker } from "antd";
import { FormInput } from "../../utils/stylescheet";
import { DARK } from "../../utils/colors";

export const InputWrapper = styled(Input)`
  ${FormInput};
`;

export const SelectWrapper = styled(Select)`
  ${FormInput};

  .ant-select-selector {
    border: none !important;
  }

  .ant-select-selection-placeholder,
  .ant-select-selection-item {
    text-align: left;
  }
`;

export const SelectText = styled("p")`
  color: ${DARK};
`;

export const DatePickerWrapper = styled(DatePicker)`
  ${FormInput};
`;
export const { Option } = styled(Select)`
  ${FormInput};
`;

export const TimePickerWrapper = styled(TimePicker)`
  ${FormInput};
`;
