import React, { useEffect } from "react";
import { Row, Col } from "antd";
import {
  PlanChatTitle,
  PlanChatWrapper,
  Text,
  Title,
  Wrapper,
} from "../../../components/PlanPicker/styles";
import {
  TextContentWrapper,
  TitleText,
  TextParagraph,
  ImageWrapper,
} from "../style";

import bancassurance_icon from "../../../assets/images/product-image.png";
import { Link } from "react-router-dom";
import { HeirsDashboardUrl } from "../../../utils/constants";
import { Mixpanel } from "../../../Mixpanel";

const Index = () => {
  const travel = [
    {
      th1: "EUROPE SCHENGEN",
      th2: "TOTAL PREMIUM",
      tr1: "Up to 7 days",
      tr2: "NGN 3,271.87",
      tr3: "Up to 10 days",
      tr4: "NGN 3,998.40",
      tr5: "Up to 15 days",
      tr6: "NGN 4,724.93",
      tr7: "Up to 21 days",
      tr8: "NGN 6,538.80",
      tr9: "Up to 30 days",
      tr10: "NGN 7,265.33",
      tr11: "Up to 45 days",
      tr12: "NGN 9,444.93",
      tr13: "Up to 60 days",
      tr14: "NGN 11,263.73",
      tr15: "Up to 92 days",
      tr16: "NGN 16,349.46",
      tr17: "Up to 180 days (Multi-trip)",
      tr18: "NGN 19,255.59",
      tr19: "Up to 365 days (Annual Multi-trip)",
      tr20: "NGN 28,334.78",
      tr21: "Up to 730 days (Bi-Annual Multi-trip)",
      tr22: "NGN 55,216.49",
    },
    {
      th1: "SCHENGEN ONLY",
      th2: "TOTAL PREMIUM",
      tr1: "Up to 7 days",
      tr2: "NGN 2,243.85",
      tr3: "Up to 10 days",
      tr4: "NGN 2,747.97",
      tr5: "Up to 15 days",
      tr6: "NGN 3,242.21",
      tr7: "Up to 21 days",
      tr8: "NGN 4,492.64",
      tr9: "Up to 30 days",
      tr10: "NGN 4,991.82",
      tr11: "Up to 45 days",
      tr12: "NGN 6,489.37",
      tr13: "Up to 60 days",
      tr14: "NGN 7,739.80",
      tr15: "Up to 92 days",
      tr16: "NGN 11,234.08",
      tr17: "Up to 180 days (Multi-trip)",
      tr18: "NGN 13,235.75",
      tr19: "Up to 365 days (Annual Multi-trip)",
      tr20: "NGN 19,473.06",
      tr21: "Up to 730 days (Bi-Annual Multi-trip)",
      tr22: "NGN 37,947.75",
    },
    {
      th1: "TRAVELLER (WORLD WIDE)",
      th2: "TOTAL PREMIUM",
      tr1: "Up to 7 days",
      tr2: "NGN 12,697.03",
      tr3: "Up to 10 days",
      tr4: "NGN 13,443.33",
      tr5: "Up to 15 days",
      tr6: "NGN 17,802.52",
      tr7: "Up to 21 days",
      tr8: "NGN 21,795.98",
      tr9: "Up to 30 days",
      tr10: "NGN 31,967.44",
      tr11: "Up to 45 days",
      tr12: "NGN 35,965.84",
      tr13: "Up to 60 days",
      tr14: "NGN 39,598.51",
      tr15: "Up to 92 days",
      tr16: "NGN 44,684.24",
      tr17: "Up to 180 days (Multi-trip)",
      tr18: "NGN 55,582.23",
      tr19: "Up to 365 days (Annual Multi-trip)",
      tr20: "NGN 60,302.22",
      tr21: "Up to 730 days (Bi-Annual Multi-trip)",
      tr22: "NGN 116,610.99",
    },
    {
      th1: "PEARL (WORLD WIDE)",
      th2: "TOTAL PREMIUM",
      tr1: "Up to 7 days",
      tr2: "NGN 5,812.26",
      tr3: "Up to 10 days",
      tr4: "NGN 6,538.80",
      tr5: "Up to 15 days",
      tr6: "NGN 9,084.13",
      tr7: "Up to 21 days",
      tr8: "NGN 10,171.46",
      tr9: "Up to 30 days",
      tr10: "NGN 13,077.59",
      tr11: "Up to 45 days",
      tr12: "NGN 14,530.66",
      tr13: "Up to 60 days",
      tr14: "NGN 16,349.46",
      tr15: "Up to 92 days",
      tr16: "NGN 24,341.32",
      tr17: "Up to 180 days (Multi-trip)",
      tr18: "NGN 27,608.25",
      tr19: "Up to 365 days (Annual Multi-trip)",
      tr20: "NGN 32,693.98",
      tr21: "Up to 730 days (Bi-Annual Multi-trip)",
      tr22: "NGN 61,394.49",
    },
    {
      th1: "FAMILY (WORLD WIDE)",
      th2: "TOTAL PREMIUM",
      tr1: "Up to 7 days",
      tr2: "NGN 11,263.73",
      tr3: "Up to 10 days",
      tr4: "NGN 13,804.12",
      tr5: "Up to 15 days",
      tr6: "NGN 16,349.46",
      tr7: "Up to 21 days",
      tr8: "NGN 23,249.05",
      tr9: "Up to 30 days",
      tr10: "NGN 24,702.12",
      tr11: "Up to 45 days",
      tr12: "NGN 26,520.92",
      tr13: "Up to 60 days",
      tr14: "NGN 28,700.52",
      tr15: "Up to 92 days",
      tr16: "NGN 41,051.57",
      tr17: "Up to 180 days (Multi-trip)",
      tr18: "NGN 65,027.16",
      tr19: "Up to 365 days (Annual Multi-trip)",
      tr20: "NGN 71,200.21",
      tr21: "Up to 730 days (Bi-Annual Multi-trip)",
      tr22: "NGN 138,767.76",
    },
    {
      th1: "ECONOMY (WORLD WIDE)",
      th2: "TOTAL PREMIUM",
      tr1: "Up to 7 days",
      tr2: "NGN 2,906.13",
      tr3: "Up to 10 days",
      tr4: "NGN 3,632.66",
      tr5: "Up to 15 days",
      tr6: "NGN 4,359.20",
      tr7: "Up to 21 days",
      tr8: "NGN 6,178.00",
      tr9: "Up to 30 days",
      tr10: "NGN 6,904.53",
      tr11: "Up to 45 days",
      tr12: "NGN 8,718.39",
      tr13: "Up to 60 days",
      tr14: "NGN 11,263.73",
      tr15: "Up to 92 days",
      tr16: "NGN 15,622.93",
      tr17: "Up to 180 days (Multi-trip)",
      tr18: "NGN 18,889.85",
      tr19: "Up to 365 days (Annual Multi-trip)",
      tr20: "NGN 23,614.79",
      tr21: "Up to 730 days (Bi-Annual Multi-trip)",
      tr22: "NGN 45,771.57",
    },
  ];

  return (
    <Wrapper id="read">
      <TextContentWrapper style={{ padding: "2px 8px", width: "100%" }}>
        <div>
          <TextParagraph
            className="large"
            style={{ "font-weight": "bold", "text-align": "center" }}
          >
            Travel Insurance Premium Justification Table
          </TextParagraph>
          <Row gutter={[24, 0]}>
            {travel.map(
              (
                {
                  th1,
                  th2,
                  tr1,
                  tr2,
                  tr3,
                  tr4,
                  tr5,
                  tr6,
                  tr7,
                  tr8,
                  tr9,
                  tr10,
                  tr11,
                  tr12,
                  tr13,
                  tr14,
                  tr15,
                  tr16,
                  tr17,
                  tr18,
                  tr19,
                  tr20,
                  tr21,
                  tr22,
                },
                key
              ) => (
                <Col lg={8}>
                  <TextParagraph style={{ "margin-bottom": "3em" }}>
                    <table className="table">
                      <tr>
                        <th style={{ background: "#ff0000", color: "#fff" }}>
                          {th1}
                        </th>
                        <th style={{ background: "#ff0000", color: "#fff" }}>
                          {th2}
                        </th>
                      </tr>
                      <tr>
                        <td>{tr1}</td>
                        <td className="_right">{tr2}</td>
                      </tr>
                      <tr>
                        <td>{tr3}</td>
                        <td className="_right">{tr4}</td>
                      </tr>
                      <tr>
                        <td>{tr5}</td>
                        <td className="_right">{tr6}</td>
                      </tr>
                      <tr>
                        <td>{tr7}</td>
                        <td className="_right">{tr8}</td>
                      </tr>
                      <tr>
                        <td>{tr9}</td>
                        <td className="_right">{tr10}</td>
                      </tr>
                      <tr>
                        <td>{tr11}</td>
                        <td className="_right">{tr12}</td>
                      </tr>
                      <tr>
                        <td>{tr13}</td>
                        <td className="_right">{tr14}</td>
                      </tr>
                      <tr>
                        <td>{tr15}</td>
                        <td className="_right">{tr16}</td>
                      </tr>
                      <tr>
                        <td>{tr17}</td>
                        <td className="_right">{tr18}</td>
                      </tr>
                      <tr>
                        <td>{tr19}</td>
                        <td className="_right">{tr20}</td>
                      </tr>
                      <tr>
                        <td>{tr21}</td>
                        <td className="_right">{tr22}</td>
                      </tr>
                    </table>
                  </TextParagraph>
                </Col>
              )
            )}
          </Row>
        </div>
      </TextContentWrapper>
      {/* <ImageWrapper style={{ padding: "32px 32px 2px" }}>
        <img src={bancassurance_icon} style={{ width: "100%" }} />
      </ImageWrapper> */}
    </Wrapper>
  );
};

export default Index;
