import React from "react";
import Banner from "../../components/ProductBanner";
import TenantsPlanPicker from "../../components/TenantsPlanPicker";
import backgroundImage from "../../assets/images/tenant.png";

const Index = () => {
  const bannerPayload = {
    title: "Tenant Insurance",
    descriptions: [
      "The TenantProtect plan is ideal for tenants who want to shield their valuables from loss. This insurance plan saves you from paying the exorbitant costs associated with fire damage, theft and other types of incidents to your valuables.",
    ],
    backgroundImage,
    // linkPath: "https://www.youtube.com/channel/UCDrC_N0SMI_ZYtgxhQ24HwQ",
    linkPath: "#tenant",
    // linkText: "Watch how it works",
    linkText: "Learn More",
  };
  return (
    <div>
      <Banner {...bannerPayload} />

      <TenantsPlanPicker />
    </div>
  );
};

export default Index;
