import React from "react";
import Banner from "./Banner";
import JionOurTeam from "../../components/JoinOurTeam";
import FraudAlert from "../../components/FraudAlert";
import SubscribeForm from "../../components/SubscribeForm";
import backgroundImage from "../../assets/images/careers_HIL.png";

const Index = () => {
  const bannerPayload = {
    title: "Join the Simple Insurance Team",
    descriptions: [
      "We are constantly on the lookout for passionate and driven individuals who believe that there is a better way to provide insurance in the 21st century.",
    ],
    backgroundImage,
    linkPath: "http://recruitment.heirsinsurance.com/",
    linkText: "FIND AVAILABLE VACANCIES",
  };
  const subscribeFormPayload = {
    title: "SIGN UP FOR EMAIL UPDATES",
    description:
      "Stay in touch to get exclusive news and about recent openings in our company.",
  };
  return (
    <div>
      <Banner {...bannerPayload} />

      <JionOurTeam />

      <FraudAlert />

      <SubscribeForm {...subscribeFormPayload} />
    </div>
  );
};

export default Index;
