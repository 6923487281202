import React from "react";
import { Link } from "react-router-dom";
import Section from "../Section";
import {
  Wrapper,
  Content,
  PlanChatWrapper,
  PlanChatTitle,
  Text,
} from "./style";
import PlanItems from "./Items";
import { Mixpanel } from "./../../Mixpanel";

const Plans = () => {
  const handleChatWithUs = (e) => {
    Mixpanel.track("chat with us");
    e.preventDefault();
    // window.fcWidget.open({});
    window.Freshbots.showWidget({});
  };

  return (
    <Wrapper id="planchoice">
      <Content>
        {/*         <Section title="https://www.heirsinsurancegroup.com/">
          <Text
            style={{
              textAlign: "center",
              marginTop: "-25px",
              fontSize: "18pt",
              color: "#ff0000",
            }}
          >
            Coming Soon
          </Text>
        </Section> */}
        <Section title="Choose Your Plan">
          <PlanItems />

          <PlanChatWrapper>
            <PlanChatTitle>
              Not Sure of the Right <br />
              Plan for you?
            </PlanChatTitle>
            <Link to="/" onClick={handleChatWithUs}>
              Chat with us
            </Link>
          </PlanChatWrapper>
        </Section>
      </Content>
    </Wrapper>
  );
};

export default Plans;
