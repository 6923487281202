import React from "react";
import Banner from "../../components/ProductBanner";
import BurglaryPlanPicker from "../../components/BurglaryPlanPicker";
import backgroundImage from "../../assets/images/heirs-burglary.png";

const Index = () => {
  const bannerPayload = {
    title: "Burglary Insurance",
    descriptions: [
      "The Heirs Burglary insurance is for homeowners, shop owners, and corporate entities who want to protect the content in their building the simple way.",
      "This plan protects the valuables in your building against theft/burglary incidents, attempted break-ins and other related risks.",
      "If any of the stated risks happen, Heirs General Insurance will pay the cost to replace your stolen/damaged property. This way you are shielded from the painful cost of starting from ground zero during the period of loss.",
    ],
    backgroundImage,
    // linkPath: "https://www.youtube.com/channel/UCDrC_N0SMI_ZYtgxhQ24HwQ",
    linkPath: "#burglary",
    // linkText: "Watch how it works",
    linkText: "Learn More",
  };
  return (
    <div>
      <Banner {...bannerPayload} />

      <BurglaryPlanPicker />
    </div>
  );
};

export default Index;
