import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import BlogContentPage from "../../components/BlogContentPage";
import { Paragraph } from "../../components/ContentPage/styles";
import { HeirsFetchPostsUrl, HeirsWordPressUrl } from "../../utils/constants";
import {
  BlogHeading,
  ContentLoader,
  ContentWrapper,
  PostImage,
  PostText,
} from "../MediaPost/styles";
import CallBack from "../../components/CallBack";
import { Spin } from "antd";

const MediaPost = () => {
  let { id } = useParams();
  id = id.split("-");
  const post_id = id[id.length - 1];
  const [post, setPost] = useState([]);

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    axios({
      method: "GET",
      url: `${HeirsFetchPostsUrl}/posts/${post_id}`,
    }).then((response) => {
      if (
        response.data.data[0].post &&
        response.data.data[0].post.post_content
      ) {
        let el = document.createElement("div");
        el.innerHTML = response.data.data[0].post.post_content;
        el.querySelectorAll("img").forEach((img) => {
          const partUrl = img.src.split("wp-content/");
          if (partUrl.length > 1) {
            img.src = `${HeirsWordPressUrl}wp-content/${partUrl[1]}`;
            // img.style.height = "300px";
            img.style.width = "100%";
          }
        });
        response.data.data[0].post.post_content = el.innerHTML;
        setPost(response.data.data[0].post);
      }
    });
  }, [post_id]);

  return (
    <>
      {post && post.post_title ? (
        <BlogContentPage title={post.post_title}>
          <ContentWrapper>
            <Paragraph>
              <BlogHeading>{post.post_excerpt}</BlogHeading>
              <PostImage
                backgroundImage={`${HeirsWordPressUrl}${post.image}`}
              />
            </Paragraph>
            <Paragraph>
              <PostText
                dangerouslySetInnerHTML={{ __html: `${post.post_content}` }}
              ></PostText>
            </Paragraph>
          </ContentWrapper>
        </BlogContentPage>
      ) : (
        <ContentLoader>
          <Spin size="large" />
        </ContentLoader>
      )}
      <CallBack />
    </>
  );
};

export default MediaPost;
