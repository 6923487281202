import React from "react";
import TravelItem from "../../components/TravelStudent";
import backgroundImage from "../../assets/images/student.png";
import Banner from "../../components/ProductBanner";

const Index = () => {
  const bannerPayload = {
    title: "Student Travel Plans",
    descriptions: [
      "Our student insurance plans ensure your safety and well-being throughout your time away, letting you study free of worry.",
    ],
    backgroundImage,
    // linkPath: "https://www.youtube.com/channel/UCDrC_N0SMI_ZYtgxhQ24HwQ",
    linkPath: "#travel",
    // linkText: "Watch how it works",
    linkText: "See Details",
  };
  return (
    <div>
      <Banner {...bannerPayload} />

      <TravelItem />
    </div>
  );
};

export default Index;
