import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  List,
  PlanChatTitle,
  PlanChatWrapper,
  Title,
  Wrapper,
  Text,
} from "../PlanPicker/styles";
import Standard from "../../assets/images/standard.png";
import Student from "../../assets/images/student.png";
import Pilgrimage from "../../assets/images/pilgrimage.png";
// import { HeirsDashboardUrl } from "../../utils/constants";
// import { Mixpanel } from "../../Mixpanel";
import { Modal, Col, Row } from "antd";

const Index = () => {
  const handleChatWithUs = (e) => {
    e.preventDefault();
    window.fcWidget.open({});
  };
  /* 
  const checkScrollTop = () => {
    const tableTop = document.getElementById("d-fixed");
    if (tableTop) {
      if (window.pageYOffset > 590) {
        tableTop.classList.add("p-fixed");
        document.querySelector("#d-fixed .ant-table-tbody").style.display =
          "none";
        document.querySelector(
          "#product-table .ant-table-thead"
        ).style.display = "none";
      } else {
        tableTop.classList.remove("p-fixed");
      }
    }
  };
 
  useEffect(() => {
    let p = document.getElementById("table");
    let p_prime = p.cloneNode(true);
    document.getElementById("d-fixed").appendChild(p_prime);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
  });
*/
  return (
    <Wrapper id="travel" padXl>
      <Row gutter={[16, 0]} style={{ marginTop: "20px" }}>
        <Col lg={14}>
          <Text style={{ padding: "32px 0px" }}>
            <b>Standard (Business/Tourism) Travel Insurance:</b> This plan is
            suitable for individuals and families travelling for business or
            leisure and wanting to enjoy their trip without worries. The cover
            period is for up to 92 days.{" "}
            <a
              href="/travel-business"
              style={{
                width: "250px",
                borderRadius: "10px",
                marginTop: "5px",
                display: "block",
              }}
            >
              Get Standard Protection
            </a>
          </Text>
        </Col>
        <Col lg={2}>
          <img src={Standard} style={{ width: "100%", paddingTop: "30px" }} />
        </Col>
      </Row>
      <Row gutter={[16, 0]} style={{ marginTop: "20px" }}>
        <Col lg={14}>
          <Text style={{ padding: "32px 0px" }}>
            <b>Student Travel Insurance:</b> Suitable for individuals travelling
            for study purposes. This plan covers medical emergencies, personal
            accidents and more so you can study without worry. The cover period
            is for up to a year (365 consecutive days).{" "}
            <a
              href="/travel-school"
              style={{
                width: "250px",
                borderRadius: "10px",
                marginTop: "5px",
                display: "block",
              }}
            >
              Get Student Protection
            </a>
          </Text>
        </Col>
        <Col lg={2}>
          <img src={Student} style={{ width: "100%", paddingTop: "30px" }} />
        </Col>
      </Row>
      <Row gutter={[16, 0]} style={{ marginTop: "20px" }}>
        <Col lg={14}>
          <Text style={{ padding: "32px 0px" }}>
            <b>Pilgrimage Travel Insurance:</b> Focus on the spiritual things as
            you travel to the Holy Land while we take care of the physical. This
            plan was created for individuals and families travelling for
            pilgrimage. The cover period is for up to 45 days.{" "}
            <a
              href="/travel-pilgrimage"
              style={{
                width: "250px",
                borderRadius: "10px",
                marginTop: "5px",
                display: "block",
              }}
            >
              Get Pilgrimage Protection
            </a>
          </Text>
        </Col>
        <Col lg={2}>
          <img src={Pilgrimage} style={{ width: "100%", paddingTop: "30px" }} />
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Index;
