import React, { useState } from "react";
import { Row, Col, Modal } from "antd";
import { Wrapper, Content, IconWrapper, Text, Card } from "./style";
import Section from "../../Section";

import Lottie from "lottie-react-web";
import quoteAnimation from "../../../assets/animations/heirs-life-vector1.json";
import affordableAnimation from "../../../assets/animations/heirs-life-vector3.json";
import rewardAnimation from "../../../assets/animations/heirs-life-vector2.json";
import bancs1 from "../../../assets/images/bancs-1.png";
import bancs2 from "../../../assets/images/bancs-2.png";
import bancs3 from "../../../assets/images/bancs-3.png";
import { SecurityScanTwoTone } from "@ant-design/icons";

const defaultOptions = {
  loop: true,
};

const Index = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [textMssg, setTextMssg] = useState(false);

  const showModal = (e) => {
    const text =
      e == "m1"
        ? "With Connect, you earn a reward for every insurance plan you sell to your customers."
        : e == "m2"
        ? "You offer your customers the additional value of securing their finances and property through your business. One access, many offerings."
        : "Buy insurance services from UBA internet banking, UBA mobile app or walk into any UBA branch close to you.";
    setTextMssg(text);
    setModalVisible(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  return (
    <Wrapper>
      <Content>
        <Section title="Why Heirs Travel Insurance?" filled>
          <Row gutter={[32, 0]}>
            <Col md={6} xs={24}>
              {/* <Card onClick={() => showModal("m1")}> */}
              <Card>
                <IconWrapper>
                  {/* <Lottie
                    width="100%"
                    options={{
                      ...defaultOptions,
                      animationData: affordableAnimation,
                    }}
                  /> */}
                  <img src={bancs1} />
                </IconWrapper>
                <Text>
                  <b>Do it yourself</b>
                </Text>
                <Text>
                  Be in control of your dashboard, purchase policies and send
                  out certificate to your clients instantly.
                </Text>
              </Card>
            </Col>
            <Col md={6} xs={24}>
              {/* <Card onClick={() => showModal("m2")}> */}
              <Card>
                <IconWrapper>
                  {/* <Lottie
                    width="100%"
                    options={{
                      ...defaultOptions,
                      animationData: quoteAnimation,
                    }}
                  /> */}
                  <img src={bancs2} />
                </IconWrapper>
                <Text>
                  <b>Wider Coverage</b>
                </Text>
                <Text>
                  Get a wider range of coverage locations for your clients.
                </Text>
              </Card>
            </Col>
            <Col md={6} xs={24}>
              {/* <Card onClick={() => showModal("m2")}> */}
              <Card>
                <IconWrapper>
                  <img src={bancs3} />
                </IconWrapper>
                <Text>
                  <b>Instant Commissions</b>
                </Text>
                <Text>
                  Calculate your commissions and remittances instantly on our
                  portal.
                </Text>
              </Card>
            </Col>
            <Col md={6} xs={24}>
              {/* <Card onClick={() => showModal("m2")}> */}
              <Card>
                <IconWrapper>
                  <img src={bancs2} />
                </IconWrapper>
                <Text>
                  <b>Connect with APIs</b>
                </Text>
                <Text>
                  We are digital! Connect via our APIs to your own websites.
                </Text>
              </Card>
            </Col>
          </Row>
        </Section>
      </Content>
      <Modal visible={modalVisible} onCancel={handleCancel} footer={null}>
        <div
          style={{
            height: "200px",
            width: "100%",
            fontSize: "18pt",
            textAlign: "center",
            padding: "32px 16px",
          }}
        >
          {textMssg}
        </div>
      </Modal>
    </Wrapper>
  );
};

export default Index;
