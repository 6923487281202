import styled from "styled-components";
import * as Colors from "../../utils/colors";

export const Text = styled("p")`
  font-size: 15px;
`;

export const IconWrapper = styled("div")`
  height: 60%;

  .anticon {
    height: 100%;
    width: 100%;
  }
`;

export const PlanItemContent = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 20px 5px 15px;
  background: ${Colors.WHITE};
  box-shadow: 3px 3px 10px 1px #eaeaea;
  height: 100%;

  :focus,
  :hover {
    border: 0.8px solid ${Colors.PRIMARY};
  }
`;

export const PlanItem = styled("div")`
  position: relative;
  cursor: pointer;
  transition: all 0.8s ease-in-out;
  height: 140px;
  padding: 4px;

  ${Text} {
    font-size: 0.95em;
    line-height: 15px;
    text-align: center;
    margin: 7px 0 !important;
    text-transform: capitalize;
    font-weight: bold;
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .anticon {
    svg {
      height: 60px;
      width: auto;
      fill: ${Colors.PRIMARY};
    }
  }
`;
