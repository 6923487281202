import React from "react";
import Banner from "../../components/ProductBanner";
import GoodsInTransitPlanPicker from "../../components/GoodsInTransitPlanPicker";
import backgroundImage from "../../assets/images/Hoeprotect.png";

const Index = () => {
  const bannerPayload = {
    title: "Goods-In-Transit",
    descriptions: [
      "If your business involves transporting goods in large quantities by road, this plan is for you.",
      "Often, such businesses come with high risks as goods worth a lot of money are exposed to damage by fire, accident, or loss from theft.",
      "Therefore, distribution companies, corporate organizations and any business owner involved in the movement of goods in large quantities should consider the Goods-in-transit cover.",
      "If any of the stated risks occurs, Heirs General Insurance will pay the cost to replace your goods, thus saving you and your business a lot of money.",
      "This plan can also be taken by individuals for relocation purposes. If you're moving houses, you may consider protecting your property from theft or accident to the conveying vehicle.",
    ],
    backgroundImage,
    // linkPath: "https://www.youtube.com/channel/UCDrC_N0SMI_ZYtgxhQ24HwQ",
    linkPath: "#goods",
    linkText: "Learn More",
    // linkText: "Learn More",
  };
  return (
    <div>
      <Banner {...bannerPayload} />

      <GoodsInTransitPlanPicker />
    </div>
  );
};

export default Index;
