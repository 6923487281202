import React from "react";
import { List, Row, Col } from "antd";
import {
  AnswerItemWrapper,
  AnswersWrapper,
  PillItemWrapper,
  PillsWrapper,
  Text,
  Title,
} from "./styles";
import blogImage from "../../assets/images/driveinlagos.jpg";
import Input from "../../components/Input";

const Answers = () => {
  const data = new Array(6).fill({});
  const filters = [
    {
      name: "All",
      count: false,
    },
    {
      name: "Life",
      count: 4,
    },
    {
      name: "Family",
      count: 4,
    },
    {
      name: "Kids",
      count: 4,
    },
    {
      name: "Couples",
      count: 4,
    },
    {
      name: "Savings",
      count: 4,
    },
    {
      name: "Insurance 101",
      count: 4,
    },
  ];
  return (
    <AnswersWrapper>
      <Row gutter={16}>
        <Col xs={24} md={24} sm={24} lg={18}>
          <PillsWrapper>
            {filters.map((filter, key) => (
              <PillItemWrapper key={key} active={key === 0}>
                {filter.name} {filter.count && <sup>{filter.count}</sup>}
              </PillItemWrapper>
            ))}
          </PillsWrapper>
        </Col>
        <Col xs={24} md={24} sm={24} lg={6}>
          <Input placeholder="Search" />
        </Col>
      </Row>
      <List
        pagination={{
          position: "bottom",
          total: 100,
          pageSize: 10,
          current: 2,
        }}
        grid={{
          gutter: 32,
          xs: 1,
          sm: 2,
          md: 2,
          lg: 3,
          xl: 3,
          xxl: 3,
        }}
        dataSource={data}
        renderItem={(item) => (
          <List.Item>
            <AnswerItemWrapper>
              <img src={blogImage} alt="lagos" />
              <Text>May 15, 2021</Text>
              <Title>How to drive in Lagos without a scratch</Title>
            </AnswerItemWrapper>
          </List.Item>
        )}
      />
    </AnswersWrapper>
  );
};

export default Answers;
