import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  PlanChatTitle,
  PlanChatWrapper,
  Text,
  Title,
  Wrapper,
  List,
} from "../PlanPicker/styles";
import FireDamages from "../../assets/images/Icon_Homeprotect.png";
import { HeirsDashboardUrl } from "../../utils/constants";
import { Mixpanel } from "../../Mixpanel";

const Index = () => {
  const handleChatWithUs = (e) => {
    e.preventDefault();
    // window.fcWidget.open({});
    window.Freshbots.showWidget({});
  };

  const checkClickEvent = (e) => {
    if (e.target.id === "purchaseStatus") {
      setPurchaseStatus();
    }
  };

  const setPurchaseStatus = () => {
    localStorage.setItem("attempted_purchase", "true");
  };

  const checkScrollTop = () => {
    const tableTop = document.getElementById("d-fixed");
    if (tableTop) {
      if (window.pageYOffset > 890) {
        tableTop.classList.add("p-fixed");
        document.querySelector("#d-fixed .ant-table-tbody").style.display =
          "none";
        document.querySelector(
          "#product-table .ant-table-thead"
        ).style.display = "none";
      } else {
        tableTop.classList.remove("p-fixed");
      }
    }
  };

  useEffect(() => {
    let p = document.getElementById("table");
    let p_prime = p.cloneNode(true);
    document.getElementById("d-fixed").appendChild(p_prime);
  }, []);

  useEffect(() => {
    localStorage.setItem("last_accessed_product", window.location.pathname);
    window.addEventListener("scroll", checkScrollTop);
    window.addEventListener("click", checkClickEvent);
  });

  return (
    <Wrapper id="cargo">
      <div className="ant-table" id="ant-table">
        <div id="d-fixed" />
        <div id="product-table">
          <table id="table">
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell" style={{ width: "33%" }}>
                  <img src={FireDamages} alt="home protect" />
                </th>
                <th className="ant-table-cell" style={{ width: "33%" }}>
                  <Title>Single Transit Cover</Title>
                  <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Marine Cargo Insurance - Buy Now")}
                    href={`${HeirsDashboardUrl}/marine-goods`}
                  >
                    Buy Now
                  </a>
                  <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track(
                      "Marine Cargo Insurance - Get Quote"
                    )}
                    href={`${HeirsDashboardUrl}/marine-goods`}
                    style={{ marginTop: "5px" }}
                  >
                    Get A Quote
                  </a>
                </th>
                <th className="ant-table-cell" style={{ width: "33%" }}>
                  <Title>Open Cover</Title>
                  {/* <a id="disabled" href="/">
                    Buy Now
                  </a> */}
                </th>
              </tr>
            </thead>

            <tbody className="ant-table-tbody">
              <tr>
                <td className="ant-table-cell">
                  <Text>For whom?</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>For small businesses dealing in one-off shipments</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>For businesses dealing in regular shipments</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Cost</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>
                    Based on the cost of your goods in transit. (ICC A & ICC"
                    C")
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text>
                    Based on the estimated annual cost of goods and the number
                    of shipments per year. (ICC A & ICC" C")
                  </Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Premium frequency</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Per shipment</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Annually or Advance deposit</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" colSpan="3">
                  <Text highlight>Coverage for you</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>
                    Loss or damage to goods in the event of sinking, collision,
                    or theft and other related maritime perils
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Covered</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Loss or damage to goods transported by air.</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Covered</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Document Requirements</Text>
                </td>
                <td className="ant-table-cell" colspan="2">
                  <Text>
                    <List>
                      <li>Duly filled proposal Form.</li>
                      <li>Evidence of Payment.</li>
                      <li>Certificate of Incorporation (for company).</li>
                      <li>Proforma Invoice.</li>
                    </List>
                  </Text>
                </td>
              </tr>
              {/* <tr>
                <td colspan="3" style={{ textAlign: "center" }}>
                  <Text>
                    <a
                      href="/contact"
                      style={{
                        background: "#fff",
                        color: "#ff0000",
                        display: "inline-block",
                      }}
                    >
                      Contact Us
                    </a>{" "}
                    via our various channels.
                  </Text>
                </td>
              </tr> */}
            </tbody>
          </table>
        </div>
      </div>

      <PlanChatWrapper>
        <PlanChatTitle>
          Not Sure of the Right <br />
          Plan for you?
        </PlanChatTitle>
        <Link to="/" onClick={handleChatWithUs}>
          Chat with us
        </Link>
      </PlanChatWrapper>
    </Wrapper>
  );
};

export default Index;
