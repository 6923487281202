import React from "react";
import { Row } from "antd";
import Plan from "./Plan";
import { PlansContainer } from "../style";
import { ReactComponent as CarIcon } from "../../../assets/images/car.svg";
import { ReactComponent as HearseIcon } from "../../../assets/images/hearse.svg";
import { ReactComponent as ComprehensiveIcon } from "../../../assets/images/Comprehensive.svg";
import { ReactComponent as HerMotorIcon } from "../../../assets/images/HerMotor.svg";
import { ReactComponent as BikerIcon } from "../../../assets/images/motorbike.svg";
import { ReactComponent as LandlordIcon } from "../../../assets/images/landlord.svg";
import { ReactComponent as TenantIcon } from "../../../assets/images/tenant.svg";
// import { ReactComponent as PhoneIcon } from "../../../assets/images/smartphone.svg";
import { ReactComponent as AccidentIcon } from "../../../assets/images/accident.svg";
// import { ReactComponent as StoreIcon } from "../../../assets/images/store.svg";
import { ReactComponent as ShipmentIcon } from "../../../assets/images/shipment.svg";
// import { ReactComponent as ShipIcon } from "../../../assets/images/ship.svg";
// import { ReactComponent as FileIcon } from "../../../assets/images/file.svg";
// import { ReactComponent as FireIcon } from "../../../assets/images/fire-insurance.svg";
// import { ReactComponent as SaveMoneyIcon } from "../../../assets/images/save-money.svg";

const PlanItems = () => {
  const plans = [
    {
      title: "Third Party Motor",
      icon: CarIcon,
      price: "N15,000",
      url: "/third-party",
      mixpanel: "Third Party Motor",
      quote:
        "https://simple.heirsinsurance.com/motor-policy-type?policy-type=Third%20Party",
      inx: "",
      req: "7",
    },
    /* {
      title: "Flexi Motor Insurance",
      icon: HearseIcon,
      price: "N15,000",
      url: "/flexi-motor",
      mixpanel: "Flexi Motor Insurance",
      quote:
        "https://simple.heirsinsurance.com/motor-policy-type?policy-type=HEIRS%20Motor%20Flexi%2015K",
      inx: "",
      req: "1",
    }, */
    {
      title: "Comprehensive Motor",
      icon: ComprehensiveIcon,
      price:
        "5% of vehicle cost (for private vehicles only. GET QUOTE for other vehicles)",
      url: "/comprehensive-motor",
      mixpanel: "Comprehensive Motor",
      quote:
        "https://simple.heirsinsurance.com/motor-policy-type?policy-type=Comprehensive",
      inx: "1",
      req: "8",
    },
    {
      title: "Her Motor Insurance",
      icon: HerMotorIcon,
      price: "5% of vehicle cost",
      url: "/her-motor",
      mixpanel: "Home Her Motor Insurance Button",
      quote:
        "https://simple.heirsinsurance.com/motor-policy-type?policy-type=HER%20Motor",
      inx: "1",
      req: "1",
    },
    /* {
      title: "Bikers Insurance",
      icon: BikerIcon,
      price: "N10,000",
      url: "/bikers",
      quote:
        "https://simple.heirsinsurance.com/biker-type?policy-type=HEIRS%20Bikers%20Third%20Party%20Plus",
      inx: "",
      req: "1",
    }, */
    {
      title: "Homeowner Insurance",
      icon: LandlordIcon,
      price: "N25,000",
      url: "/home-protect",
      mixpanel: "Homeowner Insurance",
      quote:
        "https://simple.heirsinsurance.com/homeowner-type?policy-type=Home%20Protect",
      inx: "1",
      req: "2",
    },
    {
      title: "Tenant Insurance",
      icon: TenantIcon,
      price: "N5,000",
      url: "/tenants",
      mixpanel: "Tenant Insurance",
      quote:
        "https://simple.heirsinsurance.com/tenant-type?policy-type=Tenant%20Protect%20(Bronze)",
      inx: "1",
      req: "2",
    },
    // {
    //   title: "Mobile Items",
    //   icon: PhoneIcon,
    //   price: "",
    //   url: "",
    // mixpanel: 'Home Home Protect Button'
    // },
    {
      title: "Personal Accident",
      icon: AccidentIcon,
      price: "N1,000",
      url: "/personal-accident",
      mixpanel: "Personal Accident",
      quote:
        "https://simple.heirsinsurance.com/accident-type?policy-type=HEIRS%20Personal%20Accident%20(Bronze)",
      inx: "1",
      req: "4",
    },
    // {
    //   title: "SMEs & Shops",
    //   icon: StoreIcon,
    //   price: "",
    //   url: "",
    // mixpanel: 'Home Personal Accident Button'
    // },
    {
      title: "Goods-in-Transit",
      icon: ShipmentIcon,
      price: "",
      url: "/goods-in-transit",
      quote: "",
      inx: "1",
      req: "6",
    },
    {
      title: "Business Protect",
      icon: LandlordIcon,
      price: "N7,350",
      url: "/shop-insurance",
      mixpanel: "Business Protect",
      quote:
        "https://simple.heirsinsurance.com/businessowner-type?policy-type=Business%20Protect",
      inx: "1",
      req: "2",
    },
    // {
    //   title: "Marine",
    //   icon: ShipIcon,
    //   price: "",
    //   url: "",
    // },
    // {
    //   title: "Others",
    //   icon: FileIcon,
    //   price: "",
    //   url: "",
    // },
    // {
    //   title: "Savings & Life Insurance",
    //   icon: SaveMoneyIcon,
    //   price: "",
    //   url: "",
    // },
    // {
    //   title: "Fire & Special Perils",
    //   icon: FireIcon,
    //   price: "",
    //   url: "/fire",
    //   mixpanel: "Fire and Special Perils Button",
    // },
  ];

  return (
    <PlansContainer>
      <Row gutter={[16, 0]}>
        {plans.map(
          ({ title, price, icon, url, mixpanel, quote, inx, req }, key) => (
            <Plan
              title={title}
              mixpanel={mixpanel}
              price={price}
              icon={icon}
              url={url}
              key={key}
              quote={quote}
              inx={inx}
              req={req}
            />
          )
        )}
      </Row>
    </PlansContainer>
  );
};

export default PlanItems;
