import React from "react";
import Banner from "../../components/ProductBanner";
import FlexiPlanPicker from "../../components/FlexiPlanPicker";
import backgroundImage from "../../assets/images/Flexi&comprehensive_motor.png";

const Index = () => {
  const bannerPayload = {
    title: " Flexi Motor Cover",
    descriptions: [
      "Need more cover for less?",
      "Our Flexi motor plans are perfect for people who want more cover and less headaches.",
      "Our plans shield you from exorbitant costs of repairing your car, replacing it, or taking care of medical bills, in the unforeseen event of an accident.",
    ],
    backgroundImage,
    // linkPath: "https://www.youtube.com/channel/UCDrC_N0SMI_ZYtgxhQ24HwQ",
    productURL:
      "https://www.youtube.com/embed/6v_fReAkzO8?list=PLKzX6UG7Pf2mUnoDdk3fK9v8nVcfHN6q4&rel=0&controls=0",
    linkPath: "#flexi",
    // linkText: "Watch how it works",
    linkText: "Learn More",
  };
  return (
    <div>
      <Banner {...bannerPayload} />

      <FlexiPlanPicker />
    </div>
  );
};

export default Index;
