import React, { useEffect } from "react";
import {
  PlanChatTitle,
  PlanChatWrapper,
  SubTitle,
  Text,
  Title,
  Wrapper,
  List,
} from "../PlanPicker/styles";

import thirdParty from "../../assets/images/icon_THird_party_motor.png";
import { Link } from "react-router-dom";
import { HeirsDashboardUrl } from "../../utils/constants";
import { Mixpanel } from "./../../Mixpanel";

const Index = () => {
  const handleChatWithUs = (e) => {
    e.preventDefault();
    // window.fcWidget.open({});
    window.Freshbots.showWidget({});
  };

  const checkClickEvent = (e) => {
    if (e.target.id === "purchaseStatus") {
      setPurchaseStatus();
    }
  };

  const setPurchaseStatus = () => {
    localStorage.setItem("attempted_purchase", "true");
  };

  const checkScrollTop = () => {
    const tableTop = document.getElementById("d-fixed");

    if (tableTop) {
      if (window.pageYOffset > 690) {
        tableTop.classList.add("p-fixed");
        document.querySelector("#d-fixed .ant-table-tbody").style.display =
          "none";
        document.querySelector(
          "#product-table .ant-table-thead"
        ).style.display = "none";
      } else {
        tableTop.classList.remove("p-fixed");
      }
    }
  };

  useEffect(() => {
    let p = document.getElementById("table");
    let p_prime = p.cloneNode(true);
    document.getElementById("d-fixed").appendChild(p_prime);
  }, []);

  useEffect(() => {
    localStorage.setItem("last_accessed_product", window.location.pathname);
    window.addEventListener("scroll", checkScrollTop);
    window.addEventListener("click", checkClickEvent);
  });
  return (
    <Wrapper id="third-party">
      <div className="ant-table" id="ant-table">
        <div id="d-fixed" />
        <div id="product-table">
          <table id="table">
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell" style={{ width: "33%" }}>
                  <img src={thirdParty} alt="third party motor" />
                </th>
                <th className="ant-table-cell" style={{ width: "33%" }}>
                  <Title>Third Party</Title>
                  <SubTitle>From N15,000/year</SubTitle>
                  <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Third Party Motor - Buy Now")}
                    href={`${HeirsDashboardUrl}/motor-policy-type?policy-type=Third Party`}
                  >
                    Buy Now
                  </a>
                  {/* <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Third party 5000 quote")}
                    href={`${HeirsDashboardUrl}/motor-policy-type?policy-type=Third Party`}
                    style={{ marginTop: "5px" }}
                  >
                    Get A Quote
                  </a> */}
                </th>
                {/* <th className="ant-table-cell" style={{ width: "33%" }}>
                  <Title>Third Party Plus</Title>
                  <SubTitle>N10,000/year</SubTitle>
                  <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Third party plus 10000 buy")}
                    href={`${HeirsDashboardUrl}/motor-policy-type?policy-type=HEIRS Third Party Plus`}
                  >
                    Buy Now
                  </a>
                </th> */}
              </tr>
            </thead>

            <tbody className="ant-table-tbody">
              <tr>
                <td className="ant-table-cell">
                  <Text>Perfect for?</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Anyone</Text>
                </td>
                {/* <td className="ant-table-cell">
                  <Text>Anyone</Text>
                </td> */}
              </tr>
              <tr>
                <td className="ant-table-cell" colSpan="2">
                  <Text highlight>Coverage for You</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Covers damage to your car</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
                {/* <td className="ant-table-cell">
                  <Text>Up to N250,000</Text>
                </td> */}
              </tr>
              <tr>
                <td className="ant-table-cell" colSpan="2">
                  <Text highlight>Coverage for the Other Party</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Covers damage to the other vehicle</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Up to N3million</Text>
                </td>
                {/* <td className="ant-table-cell">
                  <Text>Up to N1million</Text>
                </td> */}
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Pays medical bills</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Unlimited Cost</Text>
                </td>
                {/* <td className="ant-table-cell">
                  <Text>Unlimited Cost</Text>
                </td> */}
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>
                    Financial support to beneficiary if there is loss of life
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Unlimited Cost</Text>
                </td>
                {/* <td className="ant-table-cell">
                  <Text>Unlimited Cost</Text>
                </td> */}
              </tr>
              <tr>
                <td className="ant-table-cell" colSpan="2">
                  <Text highlight>Exclusions (Benefits not covered)</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Free vehicle tracker</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
                {/* <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td> */}
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Towing services</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
                {/* <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td> */}
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Rescue and assistance during road emergencies</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
                {/* <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td> */}
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Damage caused by flood, riot, civil commotion.</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
                {/* <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td> */}
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Excess amount on policy</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
                {/* <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td> */}
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Theft of vehicle and vehicle parts</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
                {/* <td className="ant-table-cell">
                  <Text></Text>
                </td> */}
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Damage to your own vehicle</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
                {/* <td className="ant-table-cell">
                  <Text></Text>
                </td> */}
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Document Requirements</Text>
                </td>
                <td className="ant-table-cell" colspan="1">
                  <Text>
                    <List>
                      <li>
                        Valid means of ID - Driver License, Voter's card, NIMC,
                        National ID, International Passport.
                      </li>
                      <li>
                        Proof of Residence - Utility Bill, Front page of the
                        customer's address (same address as indicated on the
                        form or portal), Local Government Levy.
                      </li>
                    </List>
                  </Text>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <PlanChatWrapper>
        <PlanChatTitle>
          Not Sure of the Right <br />
          Plan for you?
        </PlanChatTitle>
        <Link to="/" onClick={handleChatWithUs}>
          Chat with us
        </Link>
      </PlanChatWrapper>
    </Wrapper>
  );
};

export default Index;
