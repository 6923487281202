import React, { useEffect } from "react";
import {
  PlanChatTitle,
  PlanChatWrapper,
  Text,
  Title,
  Wrapper,
} from "../PlanPicker/styles";

import flexi from "../../assets/images/Icon_Flexi&comprehensive_motor.png";
import { Link } from "react-router-dom";
import { HeirsDashboardUrl } from "../../utils/constants";
import { Mixpanel } from "../../Mixpanel";

const Index = () => {
  /* const handleChatWithUs = (e) => {
    e.preventDefault();
    window.fcWidget.open({});
  };

  const checkClickEvent = (e) => {
    if (e.target.id === "purchaseStatus") {
      setPurchaseStatus();
    }
  };

  const setPurchaseStatus = () => {
    localStorage.setItem("attempted_purchase", "true");
  }; */

  /* const checkScrollTop = () => {
    const tableTop = document.getElementById("d-fixed");
    if (tableTop) {
      if (window.pageYOffset > 690) {
        tableTop.classList.add("p-fixed");
        document.querySelector("#d-fixed .ant-table-tbody").style.display =
          "none";
        document.querySelector(
          "#product-table .ant-table-thead"
        ).style.display = "none";
      } else {
        tableTop.classList.remove("p-fixed");
      }
    }
  }; */

  /*  useEffect(() => {
    let p = document.getElementById("table");
    let p_prime = p.cloneNode(true);
    document.getElementById("d-fixed").appendChild(p_prime);
  }, []);
   */
  /* 
  useEffect(() => {
    localStorage.setItem("last_accessed_product", window.location.pathname);
    window.addEventListener("scroll", checkScrollTop);
    window.addEventListener("click", checkClickEvent);
  });
 */
  return (
    <Wrapper id="comprehensive">
      <div className="ant-table" id="ant-table">
        <div id="d-fixed" />
        <div id="product-table">
          <p style={{ marginTop: "5px" }}>
            <b>Introduction:</b> Heirs General Insurance Limited otherwise known
            as "HGI" is committed to establishing a culture of integrity,
            transparency, openness and compliance in accordance with the values
            and code of ethics adopted by the Company. The whistle blowing
            policy provides for the protected disclosure of any attempted,
            suspected or actual and/or unlawful irregular or unethical behaviour
            that the whistleblower comes across in the Company in line with the
            guidelines for whistleblowing for Companies and Other Financial
            Institutions in Nigeria (May 2014) by providing a framework for
            employees and other stakeholders to report their concerns internally
            at the Company and externally.
          </p>
          <p style={{ marginTop: "20px" }}>
            <b>Scope:</b> Whistleblowing is intended for employees, Management,
            Directors of HGI and other Stakeholders. This policy also applies to
            whistleblowing disclosures made by non-employees or external parties
            to the company.
          </p>
          <p style={{ marginTop: "20px" }}>
            <b>Protection:</b> HGI will protect a whistleblower who makes a
            whistleblowing report from being victimized or suffering prejudice
            for making the report, provided that;
            <p>The whistleblower makes the report in good faith.</p>
            <p>It is reasonable for the whistle blower to make the report.</p>
            <p>The report is made on the reasonable belief that it is true.</p>
            <p>
              The report is not made for personal gain except if any reward is
              payable in terms of any law.
            </p>
            <p>The law is made in accordance with the policy.</p>
          </p>
          <p style={{ marginTop: "20px" }}>
            <b>Anonymity:</b> Heirs General Insurance allows for anonymous
            whistle blowing reporting of wrongdoings in the company. However,
            the following factors would be considered before investigating
            anonymous reports:
            <p>The seriousness of the issues.</p>
            <p>The significance and credibility of the concern and;</p>
            <p>The possibility of confirming the allegation.</p>
            <p style={{ marginTop: "20px" }}>
              Note that Heirs General Insurance cannot protect the identity of a
              whistle blower who remains anonymous. HGI is not obliged to
              protect a whistle blower against prejudice in the workplace, who
              in bad faith maliciously make a false report. Or who unfairly or
              unjustly dishonors another. Appropriate disciplinary action will
              be taken in these cases.
            </p>
          </p>
          <p style={{ marginTop: "20px" }}>
            <b>Investigation:</b> Heirs General Insurance ensures that all
            whistleblowing reports made in terms of this policy are reviewed and
            investigated where appropriate.
          </p>
          <p style={{ marginTop: "20px" }}>
            <b>Reporting Channels:</b> Whistle blowing reports can be made
            internally to the Company, through any of the following whistle
            blowing channels; 234 8113715555
          </p>
          <p style={{ marginTop: "20px", color: "rgb(250,0,0)" }}>
            whistleblowing@heirsgeneralinsurance.com
          </p>
        </div>
      </div>

      <PlanChatWrapper>
        <Link
          onClick={() => {
            window.open(
              "https://heirsinsurancelimited.sharepoint.com/:b:/s/PublicFolder/EYmgqCUgzypBsqYrT9hNUykB3oejs_fqDeez1wn_aJ5agw?e=wu23cw"
            );
          }}
        >
          Read More on the Policy Document
        </Link>
      </PlanChatWrapper>

      {/* <PlanChatWrapper>
        <PlanChatTitle>
          Not Sure of the Right <br />
          Plan for you?
        </PlanChatTitle>
        <Link to="/" onClick={handleChatWithUs}>
          Chat with us
        </Link>
      </PlanChatWrapper> */}
    </Wrapper>
  );
};

export default Index;
