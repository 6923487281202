export const WHITE = "#FFF";
export const PRIMARY = "#fe0000";
export const TEXT_DEFAULT = "#000";
export const WARNING = "#f5ae51";
export const DANGER = "#e46969";
export const SUCCESS = "#13c5a8";
export const RED_1 = "#fff9f9";
export const RED_5 = "#f77a7f";
export const GRAY_5 = "#f7f7f7";
export const GRAY_65 = "#626166";
export const GRAY_8 = "#f6f6f6";
export const GRAY_50 = "#313131";
export const GRAY = "#79879b";
export const GRAY_10 = "#7a889b";
export const GRAY_15 = "#ebebeb";
export const GRAY_25 = "#f3f8f9";
export const GRAY_35 = "#f4f2f3";
export const DARK = "#464646";
export const BLACK = "#000";
