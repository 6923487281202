import React from "react";
import Marquee from "react-fast-marquee";
import TestimonialCard from "./TestimonialCard";
import { TestimonialCardWrapper } from "../style";
import IfyImage from "../../../assets/images/ify.jpeg";
import PeterImage from "../../../assets/images/Peter.png";
import JideImage from "../../../assets/images/Jide.jpeg";
import ChinenyeImage from "../../../assets/images/Chinenye.jpeg";
import NgoziImage from "../../../assets/images/Ngozi.png";

const TestimonialCards = () => {
  const messages = [
    {
      firstName: "Solomon",
      lastName: "Nkekwe",
      message:
        "The online renewal process via your Self-Service Portal is great.",
      // avatarUrl: "https://avatars.githubusercontent.com/u/7956298?s=60&v=4",
    },
    {
      firstName: "Oluwarotimi",
      lastName: "Alo",
      message:
        "I had a claim last year and was promptly attended to. I now boast about my insurance company and have even made a couple of referrals.",
      // avatarUrl: "https://avatars.githubusercontent.com/u/7956298?s=60&v=4",
    },
    {
      firstName: "Victor",
      lastName: "Omolayo",
      message:
        "This has been an eye-opening experience that an honest and upholding business exists in Nigeria, with their main agenda themed, 'how can we best serve our customers?'",
      // avatarUrl: "https://avatars.githubusercontent.com/u/7956298?s=60&v=4",
    },
    {
      firstName: "Njideka Gloria",
      lastName: "Samuel-Anyanwu",
      message:
        "The timely response to complaints and emails is highly commendable. The customer care representatives are doing extremely well. The alert notifications are also very encouraging.",
      // avatarUrl: "https://avatars.githubusercontent.com/u/7956298?s=60&v=4",
    },
    {
      firstName: "Isaac Olusola",
      lastName: "Onifade",
      message:
        "The prompt response to emails, seamless online premium payment and speedy resolution of issues are my reasons.",
      // avatarUrl: "https://avatars.githubusercontent.com/u/7956298?s=60&v=4",
    },
    {
      firstName: "Gloria Ebotha",
      lastName: "Balogun",
      message:
        "The follow-up has been tremendous, and this is what I don't enjoy elsewhere. Please keep it up.",
      // avatarUrl: "https://avatars.githubusercontent.com/u/7956298?s=60&v=4",
    },
    {
      firstName: "Alasi",
      lastName: "Arinola",
      message:
        "I experienced top-notch service from the Heirs team. It was an easy pick-up as the customer service rep was very pleasant and patient as I was confused about the plan to take up. She put me through the details of each plan until I made my decision. Also, filling out the online form was seamless and easy. Thank you.",
      // avatarUrl: "https://avatars.githubusercontent.com/u/7956298?s=60&v=4",
    },
    /* {
      firstName: "Ify",
      lastName: "",
      message:
        "My claims journey was a short one and I was paid in less than 48 hrs. No better way to demonstrate ‘simple’ and ‘reliable’.  ",
      avatarUrl: IfyImage,
    },
    {
      firstName: "Chinenye",
      lastName: "Umeh",
      message:
        "The claim process was fast and stress-free.The damage was repaired in less than 2 weeks and my car returned to me. Overall, great experience!",
      avatarUrl: ChinenyeImage,
    },
    {
      firstName: "Ngozi",
      lastName: "Okoli",
      message:
        "Finally, an insurance company that speaks the layman's language. Love this brand, keep it up guys!",
      avatarUrl: NgoziImage,
    },
    {
      firstName: "Jide",
      lastName: "Sipe",
      message:
        "Got convinced by a friend to switch to Heirs Insurance. Buying insurance online was super easy, got my quote in a flash. ",
      avatarUrl: JideImage,
    },
    {
      firstName: "Peter",
      lastName: "Amedome",
      message:
        "Great products, fantastic customer service. I'll definitely recommend Heirs Insurance. ",
      avatarUrl: PeterImage,
    }, */
    // {
    //   firstName: "Jide",
    //   lastName: "Sipe",
    //   message:
    //     "Got convinced by a friend to switch to Heirs Insurance. Buying insurance online was super easy, got my quote in a flash. ",
    //   avatarUrl: JideImage,
    // },
  ];
  return (
    <TestimonialCardWrapper>
      <Marquee speed={40} pauseOnHover={true}>
        {messages.map(
          ({ firstName, lastName, username, message, avatarUrl }, key) => (
            <TestimonialCard
              firstName={firstName}
              lastName={lastName}
              message={message}
              avatarUrl={avatarUrl}
              key={key}
            />
          )
        )}
      </Marquee>
    </TestimonialCardWrapper>
  );
};

export default TestimonialCards;
