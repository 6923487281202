import React from "react";
import Section from "../Section";
import { Wrapper } from "./style";
import TestimonialCards from "./TestimonialCards";

const Testimonial = ({ showTestimonial = true }) => {
  return (
    <Wrapper>
      <Section title="What They Say About Us">
        {showTestimonial && <TestimonialCards />}
      </Section>
    </Wrapper>
  );
};

export default Testimonial;
