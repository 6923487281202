import React from "react";
import {
  Wrapper,
  Title,
  BannerContentWrapper,
  Overlay,
  Content,
} from "./bannerStyle";
const Banner = ({ title, backgroundImage, children }) => {
  return (
    <Overlay>
      <Wrapper>
        <Content backgroundImage={backgroundImage}>
          <BannerContentWrapper>
            <Title> {title}</Title>
            {children}
          </BannerContentWrapper>
        </Content>
      </Wrapper>
    </Overlay>
  );
};

export default Banner;
