import { withWrapper, withoutFooter } from "../HOC/AppWrapper";
import * as Screen from "../../screens";

const routes = [
  {
    path: "/",
    // component: withoutFooter(Screen.Start),
    component: withWrapper(Screen.Home),
  },
  {
    path: "/home",
    component: withWrapper(Screen.Home),
  },
  /* {
    path: "/bikers",
    component: withWrapper(Screen.Product),
  }, */
  {
    path: "/third-party",
    component: withWrapper(Screen.ThirdParty),
  },
  {
    path: "/flexi-x",
    component: withWrapper(Screen.Flexi),
  },
  {
    path: "/comprehensive-motor",
    component: withWrapper(Screen.Comprehensive),
  },
  {
    path: "/her-motor",
    component: withWrapper(Screen.HerMotor),
  },
  {
    path: "/burglary",
    component: withWrapper(Screen.Burglary),
  },
  {
    path: "/personal-accident",
    component: withWrapper(Screen.PersonalAccident),
  },
  {
    path: "/tenants",
    component: withWrapper(Screen.Tenants),
  },
  {
    path: "/fire",
    component: withWrapper(Screen.Fire),
  },
  {
    path: "/home-protect",
    component: withWrapper(Screen.HomeProtect),
  },
  {
    path: "/cargo",
    component: withWrapper(Screen.Cargo),
  },
  {
    path: "/hull",
    component: withWrapper(Screen.Hull),
  },
  {
    path: "/goods-in-transit",
    component: withWrapper(Screen.GoodsInTransit),
  },
  {
    path: "/claims",
    component: withWrapper(Screen.FastClaims),
    // component: withWrapper(Screen.Claims),
  },
  {
    path: "/fast-claims",
    component: withWrapper(Screen.FastClaims),
  },
  {
    path: "/who-we-are",
    component: withWrapper(Screen.About),
  },
  {
    path: "/career",
    component: withWrapper(Screen.Career),
  },
  {
    path: "/digital-channels",
    component: withWrapper(Screen.DigitalChannels),
  },
  {
    path: "/answers",
    component: withWrapper(Screen.GetAnswer),
  },
  {
    path: "/wp-media",
    component: withWrapper(Screen.Media),
  },
  {
    path: "/wp-media/:id",
    component: withWrapper(Screen.MediaPost),
  },
  {
    path: "/blog",
    component: withWrapper(Screen.WPMedia),
  },
  {
    path: "/news",
    component: withWrapper(Screen.News),
  },
  {
    path: "/blog/:id",
    component: withWrapper(Screen.WPMediaPost),
  },
  {
    path: "/news/:id",
    component: withWrapper(Screen.NewsPost),
  },
  {
    path: "/financial-statements",
    component: withWrapper(Screen.FinancialStatements),
  },
  {
    path: "/corporate-governance",
    component: withWrapper(Screen.CorporateGovernance),
  },
  {
    path: "/product-flyers",
    component: withWrapper(Screen.ProductFlyers),
  },
  {
    path: "/privacy",
    component: withWrapper(Screen.Privacy),
  },
  {
    path: "/terms",
    component: withWrapper(Screen.Terms),
  },
  {
    path: "/contact",
    component: withWrapper(Screen.Contact),
  },
  {
    path: "/complaint",
    component: withWrapper(Screen.Complaint),
  },
  {
    path: "/flexi-campaign",
    component: withWrapper(Screen.FlexiCampaign),
  },
  {
    path: "/her-motor-campaign",
    component: withWrapper(Screen.HerMotorCampaign),
  },
  {
    path: "/tenant-protect-campaign",
    component: withWrapper(Screen.TenantProtectCampaign),
  },
  {
    path: "/partnership-portal",
    component: withWrapper(Screen.PartnershipPortal),
  },
  {
    path: "/TEF",
    component: withWrapper(Screen.TEFCampaign),
  },
  {
    path: "/bancassurance",
    component: withWrapper(Screen.Bancassurance),
  },
  {
    path: "/whistleblowing",
    component: withWrapper(Screen.WhistleBlowing),
  },
  {
    path: "/bancassurance-products",
    component: withWrapper(Screen.BancassuranceProducts),
  },
  {
    path: "/faq",
    component: withWrapper(Screen.Faq),
  },
  {
    path: "/motor-faq",
    component: withWrapper(Screen.MotorFaq),
  },
  {
    path: "/travel-insurance-pricelist",
    component: withWrapper(Screen.TravelPricing),
  },
  {
    path: "/travel-student-pricelist",
    component: withWrapper(Screen.StudentPrice),
  },
  {
    path: "/travel-pilgrimage-pricelist",
    component: withWrapper(Screen.PilgrimagePrice),
  },
  {
    path: "/inconnect",
    component: withWrapper(Screen.Connect),
  },
  {
    path: "/travels",
    component: withWrapper(Screen.Travels),
  },
  {
    path: "/shop-insurance",
    component: withWrapper(Screen.ShopInsurance),
  },
  {
    path: "/mechanics",
    component: withWrapper(Screen.Mechanics),
  },
  {
    path: "/travel-insurance",
    component: withWrapper(Screen.TravelInsurance),
  },
  {
    path: "/travel-business",
    component: withWrapper(Screen.TravelBusiness),
  },
  {
    path: "/travel-school",
    component: withWrapper(Screen.TravelSchool),
  },
  {
    path: "/travel-pilgrimage",
    component: withWrapper(Screen.TravelPilgrimage),
  } /* 
  {
    path: "/promo",
    component: withWrapper(Screen.MotorPromo),
  }, */,
  {
    path: "/corporate-social",
    component: withWrapper(Screen.CorporateSocial),
  },
  {
    path: "/TOE-storytellers-fund-grantee-marcelline-akinyi-okech",
    component: withWrapper(Screen.CorporateSocial1),
  },
  {
    path: "/TOE-storytellers-fund-grantee-felix-byaruhanga",
    component: withWrapper(Screen.CorporateSocial2),
  },
  {
    path: "/TOE-storytellers-fund-grantee-wonai-haruperi",
    component: withWrapper(Screen.CorporateSocial3),
  },
  {
    path: "/TOE-storytellers-fund-grantee-bolarinwa-idowu-emmanuel",
    component: withWrapper(Screen.CorporateSocial4),
  },
  {
    path: "/TOE-storytellers-fund-grantee-boakye-dacosta-alpha",
    component: withWrapper(Screen.CorporateSocial5),
  },
  {
    path: "/TOE-storytellers-fund-grantee-duduzile-luthuli",
    component: withWrapper(Screen.CorporateSocial6),
  },
  {
    path: "/TOE-storytellers-fund-grantee-margaret-lombe",
    component: withWrapper(Screen.CorporateSocial7),
  },
  {
    path: "/TOE-storytellers-fund-grantee-ezra-million",
    component: withWrapper(Screen.CorporateSocial8),
  },
  {
    path: "/TOE-storytellers-fund-grantee-siziphiwe-keke",
    component: withWrapper(Screen.CorporateSocial9),
  },
  {
    path: "/TOE-storytellers-fund-grantee-nasir-ahmed-achile",
    component: withWrapper(Screen.CorporateSocial10),
  },
];

export { routes };
