import React from "react";
import backgroundImage from "../../assets/images/Hoeprotect.png";
import Banner from "../../components/ProductBanner";
import HomeProtectPlanPicker from "../../components/HomeProtectPlanPicker";

const Index = () => {
  const bannerPayload = {
    title: "HomeProtect",
    descriptions: [
      "Building a home is expensive. What happens if something happens to your home?",
      "The HomeProtect plan is ideal for landlords and home owners. This home insurance plan saves you from paying the exorbitant costs associated with fire damage and other types of incidents to your home, as well as replaces your valuables, if stolen.",
    ],
    backgroundImage,
    // linkPath: "https://www.youtube.com/channel/UCDrC_N0SMI_ZYtgxhQ24HwQ",
    linkPath: "#protect",
    productURL:
      "https://www.youtube.com/embed/HXfj_sEA2tU?list=PLKzX6UG7Pf2mUnoDdk3fK9v8nVcfHN6q4&rel=0&controls=0",
    // linkText: "Watch how it works",
    linkText: "Learn More",
  };
  return (
    <div>
      <Banner {...bannerPayload} />

      <HomeProtectPlanPicker />
    </div>
  );
};

export default Index;
