import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { HeirsFetchPostsUrl } from "../utils/constants";

const useClaims = () => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [value, setValue] = useState({
    insuredFirstName: "",
    insuredLastName: "",
    policyNumber: "",
    phoneNumber: "",
    email: "",
    homeAddress: "",
    claimType: "",
    otherClaimType: "",
    incidentLocation: "",
    incidentDate: "",
    incidentTime: "",
    description: "",
    engineNumber: "",
    regNumber: "",
    repairFile: [],
    licenseFile: [],
    damageFile: [],
    policeReport: [],
    approvedEstimate: false,
    trueClaims: false,
    motorClaims: [],
    homeClaims: [],
    tenantClaims: [],
    perilClaims: [],
    perilsClaim: "",
    itemOne: "",
    itemTwo: "",
    itemThree: "",
    itemFour: "",
    itemFive: "",
    itemSix: "",
    itemSeven: "",
    itemEight: "",
    itemNine: "",
    itemTen: "",
  });
  const [error, setError] = useState({
    insuredName: "",
    policyNumber: "",
    phoneNumber: "",
    email: "",
    homeAddress: "",
    claimType: "",
    otherClaimType: "",
    incidentLocation: "",
    incidentDate: "",
    incidentTime: "",
    description: "",
    engineNumber: "",
    regNumber: "",
    repairFile: "",
    licenseFile: "",
    approvedEstimate: "",
    trueClaims: "",
    damageFile: "",
    policeReport: "",
    motorClaims: "",
    homeClaims: "",
    tenantClaims: "",
    perilClaims: "",
    perilsClaim: "",
    stolenItems: "",
    submitError: "",
  });

  let stolenGoodsArr = [
    value.itemOne,
    value.itemTwo,
    value.itemThree,
    value.itemFour,
    value.itemFive,
    value.itemSix,
    value.itemSeven,
    value.itemEight,
    value.itemNine,
    value.itemTen,
  ];

  useEffect(() => {
    if (
      value.insuredFirstName !== "" &&
      value.insuredLastName !== "" &&
      value.policyNumber !== "" &&
      value.email !== "" &&
      value.phoneNumber !== "" &&
      value.incidentLocation !== "" &&
      (value.claimType !== "" || value.otherClaimType !== "") &&
      value.homeAddress !== "" &&
      value.incidentDate !== "" &&
      value.incidentTime !== "" &&
      value.trueClaims &&
      error.insuredName === "" &&
      error.policyNumber === "" &&
      error.phoneNumber === "" &&
      error.email === "" &&
      error.homeAddress === "" &&
      error.claimType === "" &&
      error.otherClaimType === "" &&
      error.incidentLocation === "" &&
      error.incidentTime === "" &&
      error.incidentDate === "" &&
      error.description === "" &&
      error.motorClaims === "" &&
      error.regNumber === "" &&
      error.licenseFile === "" &&
      error.approvedEstimate === "" &&
      error.damageFile === "" &&
      error.tenantClaims === "" &&
      error.policeReport === "" &&
      error.repairFile === "" &&
      error.perilClaims === "" &&
      error.perilsClaim === "" &&
      error.trueClaims === ""
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [value, error]);

  useEffect(() => {
    const claimContainer = document.getElementsByClassName("claims-container");
    const claimArray = Array.from(claimContainer);
    claimArray.map((claim) => {
      const claimId = document.getElementById(claim.id);
      if (value.claimType && claim.id === value.claimType) {
        claimId.style.border = "3px solid #fe0000";
      } else {
        claimId.style.border = "1px solid #7a889b";
      }
      return claim;
    });
  }, [value.claimType]);

  const handleLocationChange = (e) => {
    const val = e.target.value;
    setValue({
      ...value,
      [e.target.props ? e.target.props.name : e.target.name]: val,
    });
  };

  const handleInsuredNameChange = (e) => {
    const val = e.target.value;
    setValue({
      ...value,
      [e.target.name]: val,
    });

    if (val.length < 3) {
      setError({
        ...error,
        insuredName: "name cannot be less than three characters",
      });
    } else {
      setError({
        ...error,
        insuredName: "",
      });
    }
    // if (val && !/^[a-zA-Z]+$/.test(val)) {
    //   setError({ ...error, insuredName: "name should only be letters" });
    // } else {
    //   setError({
    //     ...error,
    //     insuredName: "",
    //   });
    // }
  };

  const handleEmailChange = (e) => {
    const reg = /\S+@\S+\.\S+/;
    const val = e.target.value;
    setValue({ ...value, email: val });

    if (reg.test(String(val).toLowerCase())) {
      setError({ ...error, email: "" });
    } else {
      setError({
        ...error,
        email: "please enter a valid email address",
      });
    }
  };

  const handleSelectChange = (claim) => {
    if (claim === value.claimType) {
      setValue({ ...value, claimType: "" });
    } else {
      setValue({ ...value, claimType: claim });
    }
  };

  const handleClaimChange = (e) => {
    const val = e.target.value;
    setValue({ ...value, otherClaimType: val });
  };

  const handleNumberChange = (e, callback) => {
    const val = e.target.value;
    const reg = /^[0-9]+$/;
    callback(e, reg, val);
  };

  const handlePolicyNumberChange = (e) => {
    const val = e.target.value;
    const reg = /^[0-9]+$/;

    if (val === "" || reg.test(val)) {
      setValue({ ...value, policyNumber: val });
      setError({ ...error, policyNumber: "" });
    } else {
      setError({ ...error, policyNumber: "please enter only numbers" });
    }
  };

  const handlePhoneNumberChange = (e, reg, val) => {
    if (reg.test(val)) {
      setValue({ ...value, phoneNumber: val });
    }

    if ((val.length > 0 && val.length < 11) || val.length > 11) {
      setError({
        ...error,
        phoneNumber:
          "your phone number should not be more or less than 11 digits",
      });
    } else {
      setError({
        ...error,
        phoneNumber: "",
      });
    }
  };

  function handleDateChange(date, dateString) {
    setValue({ ...value, incidentDate: dateString });
  }

  function handleTimeChange(time, timeString) {
    setValue({ ...value, incidentTime: timeString });
  }

  const handleOtherNumbersChange = (e, reg, val) => {
    if (reg.test(val)) {
      setValue({ ...value, [e.target.name]: val });
    } else {
      setError({ ...error, [e.target.name]: "values can only be numbers" });
    }
  };

  const handleDescriptionChange = (e) => {
    const val = e.target.value;
    setValue({ ...value, description: val });
  };

  const handleStolenItemsChange = (e) => {
    const val = e.target.value;
    setValue({ ...value, [e.target.name]: val });
  };

  const handleMotorClaimChange = (e) => {
    setValue({ ...value, motorClaims: e });
    if (e.length > 2) {
      setError({
        ...error,
        motorClaims: "Only two options can be selected at a time",
      });
    }
  };

  const handleHomeClaimChange = (e) => {
    setValue({ ...value, homeClaims: e });
    if (e.length > 2) {
      setError({
        ...error,
        homeClaims: "Only two options can be selected at a time",
      });
    }
  };

  const handleTenantClaimChange = (e) => {
    setValue({ ...value, tenantClaims: e });
    if (e.length > 2) {
      setError({
        ...error,
        tenantClaims: "Only two options can be selected at a time",
      });
    }
  };

  const handlePerilClaimChange = (e) => {
    setValue({ ...value, perilClaims: e });
    if (e.length > 2) {
      setError({
        ...error,
        perilClaims: "Only two options can be selected at a time",
      });
    }
  };

  const handlePerilsChange = (e) => {
    const val = e.target.value;
    setValue({ ...value, perilsClaim: val });
  };

  const onDamageUpload = (e) => {
    if (e.fileList.length > 0 && e.file.size > 4000000) {
      setError({ ...error, damageFile: "file size is more than 4MB" });
    } else {
      setError({ ...error, damageFile: "" });
    }
    return e && e.fileList;
  };

  const onPoliceReportUpload = (e) => {
    if (e.fileList.length > 0 && e.file.size > 1000000) {
      setError({ ...error, policeReport: "file size is more than 1MB" });
    } else {
      setError({ ...error, policeReport: "" });
    }
    return e && e.fileList;
  };

  const onLicenseUpload = (e) => {
    if (e.fileList.length > 0 && e.file.size > 4000000) {
      setError({ ...error, licenseFile: "file size is more than 4MB" });
    } else {
      setError({ ...error, licenseFile: "" });
    }
    return e && e.fileList;
  };

  const onRepairUpload = (e) => {
    if (e.fileList.length > 0 && e.file.size > 4000000) {
      setError({ ...error, repairFile: "file size is more than 4MB" });
    } else {
      setError({ ...error, repairFile: "" });
    }
    return e && e.fileList;
  };

  const handleUploadChange = (e) => {
    console.log(e);
  };

  const handleCheckedChange = (e) => {
    const val = e.target.checked;
    setValue({ ...value, [e.target.id]: val });
  };

  const handleDamageFileUpload = (data) => {
    const formData = new FormData();
    formData.append("file", data.file);
    if (data.file.size <= 4000000) {
      axios({
        method: "POST",
        url: `${HeirsFetchPostsUrl}/file-upload/insurance/single`,
        data: formData,
      })
        .then((response) => {
          console.log("kk...", response);
          setValue({
            ...value,
            damageFile: [
              ...value.damageFile,
              response.data.data.link.substring(
                0,
                response.data.data.link.indexOf("?")
              ),
            ],
          });
          data.onSuccess("ok");
        })
        .catch((err) => {
          data.onError("File upload failed. Please try again later");
        });
    } else {
      setError({
        ...error,
        damageFile: "File size is larger than 4MB",
      });
      data.onSuccess("ok");
    }
  };

  const handlePoliceFileUpload = (data) => {
    const formData = new FormData();
    formData.append("file", data.file);
    if (data.file.size <= 1000000) {
      axios({
        method: "POST",
        url: `${HeirsFetchPostsUrl}/file-upload/life/single`,
        data: formData,
      })
        .then((response) => {
          setValue({
            ...value,
            policeReport: [
              ...value.policeReport,
              response.data.data.link.substring(
                0,
                response.data.data.link.indexOf("?")
              ),
            ],
          });
          data.onSuccess("ok");
        })
        .catch((err) => {
          data.onError("File upload failed. Please try again later");
        });
    } else {
      setError({
        ...error,
        policeReport: "File size is larger than 1MB",
      });
      data.onSuccess("ok");
    }
  };

  const handleLicenseFileUpload = (data) => {
    const formData = new FormData();
    formData.append("file", data.file);
    if (data.file.size <= 4000000) {
      axios({
        method: "POST",
        url: `${HeirsFetchPostsUrl}/file-upload/insurance/single`,
        data: formData,
      })
        .then((response) => {
          setValue({
            ...value,
            licenseFile: [
              ...value.licenseFile,
              response.data.data.link.substring(
                0,
                response.data.data.link.indexOf("?")
              ),
            ],
          });
          data.onSuccess("ok");
        })
        .catch((err) => {
          data.onError("File upload failed. Please try again later");
        });
    } else {
      setError({
        ...error,
        licenseFile: "File size is larger than 4MB",
      });
      data.onSuccess("ok");
    }
  };

  const handleRepairFileUpload = (data) => {
    const formData = new FormData();
    formData.append("file", data.file);
    if (data.file.size <= 4000000) {
      axios({
        method: "POST",
        url: `${HeirsFetchPostsUrl}/file-upload/insurance/single`,
        data: formData,
      })
        .then((response) => {
          setValue({
            ...value,
            repairFile: [
              ...value.repairFile,
              response.data.data.link.substring(
                0,
                response.data.data.link.indexOf("?")
              ),
            ],
          });
          data.onSuccess("ok");
        })
        .catch((err) => {
          data.onError("File upload failed. Please try again later");
        });
    } else {
      setError({
        ...error,
        repairFile: "File size is larger than 4MB",
      });
      data.onSuccess("ok");
    }
  };

  const filterStolenItemsArray = () => {
    stolenGoodsArr = stolenGoodsArr.filter((item) => item);
    return stolenGoodsArr;
  };

  const toTimestamp = (date, time) => {
    let dateOfIncident = new Date(date);
    let day = dateOfIncident.getDate();
    let month = dateOfIncident.getMonth();
    let year = dateOfIncident.getFullYear();

    let hours = time.split(":")[0];
    let minutes = time.split(":")[1];
    let seconds = time.split(":")[2];
    const datum = new Date(Date.UTC(year, month, day, hours, minutes, seconds));
    return datum.toISOString();
  };

  function disabledDate(current) {
    return current && current > moment().endOf("day");
  }

  const handleClaimsFormSubmit = async (e) => {
    let data = {};
    if (
      value.insuredFirstName &&
      value.insuredLastName &&
      value.policyNumber &&
      value.phoneNumber &&
      value.email &&
      value.homeAddress &&
      value.claimType &&
      value.claimType === "home" &&
      value.homeClaims &&
      value.incidentLocation &&
      value.incidentTime &&
      value.incidentDate &&
      value.description &&
      value.damageFile &&
      value.trueClaims === true
    ) {
      data = {
        name: `${value.insuredFirstName} ${value.insuredLastName}`,
        phoneNumber: value.phoneNumber,
        policyNumber: value.policyNumber,
        emailAddress: value.email,
        address: value.homeAddress,
        type: value.claimType,
        subClaimTypes: value.homeClaims,
        incidentDate: toTimestamp(value.incidentDate, value.incidentTime),
        description: value.description,
        incidentLocation: value.incidentLocation,
        damageDocuments: value.damageFile,
        estimate: value.repairFile[0],
        useOfficialEstimate: value.approvedEstimate,
      };
      setIsLoading(true);
      setError({ ...error, submitError: "" });
    }

    if (
      value.insuredFirstName &&
      value.insuredLastName &&
      value.policyNumber &&
      value.phoneNumber &&
      value.email &&
      value.homeAddress &&
      value.claimType &&
      value.claimType === "motor" &&
      value.motorClaims &&
      value.motorClaims.includes("Theft") &&
      value.incidentLocation &&
      value.incidentTime &&
      value.incidentDate &&
      value.description &&
      value.regNumber &&
      value.policeReport &&
      value.damageFile &&
      (value.repairFile || value.approvedEstimate) &&
      value.licenseFile &&
      value.trueClaims === true
    ) {
      data = {
        name: `${value.insuredFirstName} ${value.insuredLastName}`,
        phoneNumber: value.phoneNumber,
        policyNumber: value.policyNumber,
        emailAddress: value.email,
        address: value.homeAddress,
        vehicleRegNumber: value.regNumber,
        driverLicense: value.licenseFile[0],
        type: value.claimType,
        subClaimTypes: value.motorClaims,
        incidentDate: toTimestamp(value.incidentDate, value.incidentTime),
        incidentLocation: value.incidentLocation,
        description: value.description,
        policeReport: value.policeReport[0],
        damageDocuments: value.damageFile,
        estimate: value.repairFile[0],
        useOfficialEstimate: value.approvedEstimate,
      };
      setIsLoading(true);
      setError({ ...error, submitError: "" });
    }

    if (
      value.insuredFirstName &&
      value.insuredLastName &&
      value.policyNumber &&
      value.phoneNumber &&
      value.email &&
      value.homeAddress &&
      value.claimType &&
      value.claimType === "motor" &&
      value.motorClaims &&
      value.incidentLocation &&
      value.incidentTime &&
      value.incidentDate &&
      value.description &&
      value.regNumber &&
      value.licenseFile &&
      value.damageFile
    ) {
      data = {
        name: `${value.insuredFirstName} ${value.insuredLastName}`,
        policyNumber: value.policyNumber,
        phoneNumber: value.phoneNumber,
        emailAddress: value.email,
        address: value.homeAddress,
        type: value.claimType,
        subClaimTypes: value.motorClaims,
        incidentDate: toTimestamp(value.incidentDate, value.incidentTime),
        incidentLocation: value.incidentLocation,
        description: value.description,
        vehicleRegNumber: value.regNumber,
        driverLicense: value.licenseFile[0],
        estimate: value.repairFile[0],
        useOfficialEstimate: value.approvedEstimate,
        damageDocuments: value.damageFile,
      };
      setIsLoading(true);
      setError({ ...error, submitError: "" });
    }

    if (
      value.insuredFirstName &&
      value.insuredLastName &&
      value.policyNumber &&
      value.phoneNumber &&
      value.email &&
      value.homeAddress &&
      value.claimType &&
      value.claimType === "tenant" &&
      value.tenantClaims &&
      value.incidentLocation &&
      value.incidentTime &&
      value.incidentDate &&
      value.description &&
      value.policeReport &&
      value.damageFile
    ) {
      data = {
        name: `${value.insuredFirstName} ${value.insuredLastName}`,
        policyNumber: value.policyNumber,
        phoneNumber: value.phoneNumber,
        emailAddress: value.email,
        address: value.homeAddress,
        type: value.claimType,
        subClaimTypes: value.tenantClaims,
        incidentDate: toTimestamp(value.incidentDate, value.incidentTime),
        incidentLocation: value.incidentLocation,
        description: value.description,
        policeReport: value.policeReport[0],
        estimate: value.repairFile[0],
        useOfficialEstimate: value.approvedEstimate,
        damageDocuments: value.damageFile,
      };
      setIsLoading(true);
      setError({ ...error, submitError: "" });
    }

    if (
      value.insuredFirstName &&
      value.insuredLastName &&
      value.policyNumber &&
      value.phoneNumber &&
      value.email &&
      value.homeAddress &&
      value.claimType &&
      value.claimType === "personal accident" &&
      value.incidentLocation &&
      value.incidentTime &&
      value.incidentDate &&
      value.description &&
      value.damageFile &&
      (value.repairFile || value.approvedEstimate)
    ) {
      data = {
        name: `${value.insuredFirstName} ${value.insuredLastName}`,
        policyNumber: value.policyNumber,
        phoneNumber: value.phoneNumber,
        emailAddress: value.email,
        address: value.homeAddress,
        type: value.claimType,
        incidentDate: toTimestamp(value.incidentDate, value.incidentTime),
        incidentLocation: value.incidentLocation,
        description: value.description,
        estimate: value.repairFile[0],
        useOfficialEstimate: value.approvedEstimate,
        damageDocuments: value.damageFile,
      };
      setIsLoading(true);
      setError({ ...error, submitError: "" });
    }

    if (
      value.insuredFirstName &&
      value.insuredLastName &&
      value.policyNumber &&
      value.phoneNumber &&
      value.email &&
      value.homeAddress &&
      value.claimType &&
      value.claimType === "burglary" &&
      value.incidentLocation &&
      value.incidentTime &&
      value.incidentDate &&
      value.description &&
      value.policeReport &&
      value.damageFile
    ) {
      data = {
        name: `${value.insuredFirstName} ${value.insuredLastName}`,
        policyNumber: value.policyNumber,
        phoneNumber: value.phoneNumber,
        emailAddress: value.email,
        address: value.homeAddress,
        type: value.claimType,
        incidentDate: toTimestamp(value.incidentDate, value.incidentTime),
        incidentLocation: value.incidentLocation,
        description: value.description,
        policeReport: value.policeReport[0],
        damageDocuments: value.damageFile,
        stolenItems: filterStolenItemsArray(),
        estimate: value.repairFile[0],
        useOfficialEstimate: value.approvedEstimate,
      };
      setIsLoading(true);
      setError({ ...error, submitError: "" });
    }

    if (
      value.insuredFirstName &&
      value.insuredLastName &&
      value.policyNumber &&
      value.phoneNumber &&
      value.email &&
      value.homeAddress &&
      value.claimType &&
      value.claimType === "fire & special perils" &&
      value.perilClaims &&
      value.incidentLocation &&
      value.incidentTime &&
      value.incidentDate &&
      value.description &&
      value.damageFile
    ) {
      data = {
        name: `${value.insuredFirstName} ${value.insuredLastName}`,
        policyNumber: value.policyNumber,
        phoneNumber: value.phoneNumber,
        emailAddress: value.email,
        address: value.homeAddress,
        type: value.claimType,
        incidentDate: toTimestamp(value.incidentDate, value.incidentTime),
        incidentLocation: value.incidentLocation,
        subClaimTypes: value.perilClaims,
        description: value.description,
        damageDocuments: value.damageFile,
        estimate: value.repairFile[0],
        useOfficialEstimate: value.approvedEstimate,
      };
      setIsLoading(true);
      setError({ ...error, submitError: "" });
    }

    if (
      value.insuredFirstName &&
      value.insuredLastName &&
      value.policyNumber &&
      value.phoneNumber &&
      value.email &&
      value.homeAddress &&
      value.claimType &&
      value.claimType === "fire & special perils" &&
      value.perilsClaim &&
      value.incidentLocation &&
      value.incidentTime &&
      value.incidentDate &&
      value.description &&
      value.damageFile
    ) {
      data = {
        name: `${value.insuredFirstName} ${value.insuredLastName}`,
        policyNumber: value.policyNumber,
        phoneNumber: value.phoneNumber,
        emailAddress: value.email,
        address: value.homeAddress,
        type: value.claimType,
        incidentDate: toTimestamp(value.incidentDate, value.incidentTime),
        incidentLocation: value.incidentLocation,
        subClaimTypes: [value.perilsClaim],
        description: value.description,
        damageDocuments: value.damageFile,
        estimate: value.repairFile[0],
        useOfficialEstimate: value.approvedEstimate,
      };
      setIsLoading(true);
      setError({ ...error, submitError: "" });
    }

    if (
      value.insuredFirstName &&
      value.insuredLastName &&
      value.policyNumber &&
      value.phoneNumber &&
      value.email &&
      value.homeAddress &&
      value.claimType &&
      value.claimType === "travels" &&
      // value.perilsClaim &&
      value.incidentLocation &&
      value.incidentTime &&
      value.incidentDate &&
      value.description &&
      value.damageFile
    ) {
      data = {
        name: `${value.insuredFirstName} ${value.insuredLastName}`,
        policyNumber: value.policyNumber,
        phoneNumber: value.phoneNumber,
        emailAddress: value.email,
        address: value.homeAddress,
        type: value.claimType,
        incidentDate: toTimestamp(value.incidentDate, value.incidentTime),
        incidentLocation: value.incidentLocation,
        // subClaimTypes: [value.perilsClaim],
        description: value.description,
        damageDocuments: value.damageFile,
        estimate: value.repairFile[0],
        useOfficialEstimate: value.approvedEstimate,
      };
      setIsLoading(true);
      setError({ ...error, submitError: "" });
    }

    if (
      value.insuredFirstName &&
      value.insuredLastName &&
      value.policyNumber &&
      value.phoneNumber &&
      value.email &&
      value.homeAddress &&
      value.otherClaimType &&
      value.incidentLocation &&
      value.incidentTime &&
      value.incidentDate &&
      value.description &&
      value.damageFile
    ) {
      data = {
        name: `${value.insuredFirstName} ${value.insuredLastName}`,
        policyNumber: value.policyNumber,
        phoneNumber: value.phoneNumber,
        emailAddress: value.email,
        address: value.homeAddress,
        type: value.otherClaimType,
        incidentDate: toTimestamp(value.incidentDate, value.incidentTime),
        incidentLocation: value.incidentLocation,
        description: value.description,
        damageDocuments: value.damageFile,
        estimate: value.repairFile[0],
        useOfficialEstimate: value.approvedEstimate,
      };
      setIsLoading(true);
      setError({ ...error, submitError: "" });
    }

    if (Object.keys(data).length === 0) {
      setIsLoading(false);
      setError({
        ...error,
        submitError:
          "Some fields have not been filled. Please fill the necessary fields.",
      });
    }

    const response = await axios({
      method: "POST",
      url: `${HeirsFetchPostsUrl}/claims/insurance`,
      data,
    });
    if (response && response.data.status === "success") {
      setIsLoading(false);
      setIsSuccessful(true);
    }
  };

  return {
    disabledDate,
    error,
    handleCheckedChange,
    handleClaimChange,
    handleClaimsFormSubmit,
    handleDateChange,
    handleDescriptionChange,
    handleEmailChange,
    handleDamageFileUpload,
    handleHomeClaimChange,
    handleInsuredNameChange,
    handleLicenseFileUpload,
    handleLocationChange,
    handleMotorClaimChange,
    handleNumberChange,
    handleOtherNumbersChange,
    handlePerilsChange,
    handlePerilClaimChange,
    handlePhoneNumberChange,
    handlePoliceFileUpload,
    handlePolicyNumberChange,
    handleRepairFileUpload,
    handleSelectChange,
    handleStolenItemsChange,
    handleTenantClaimChange,
    handleTimeChange,
    handleUploadChange,
    isDisabled,
    isLoading,
    isSuccessful,
    onDamageUpload,
    onLicenseUpload,
    onPoliceReportUpload,
    onRepairUpload,
    value,
  };
};

export default useClaims;
