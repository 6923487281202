import React from "react";
import Banner from "../../components/ConnectBanner";
// import Partnership from "../../components/Partnership";
import Inspiration from "../../components/Travels/Inspiration";
import Benefits from "../../components/Travels/Benefits";
import Connect from "../../components/Travels/Connect";
import Faqs from "../../components/Travels/Faqs";
import backgroundImage from "../../assets/images/student.png";
// import backgroundImage from "../../assets/images/InConn.png";

const Index = () => {
  const bannerPayload = {
    title: "Heirs Travel Insurance Platform",
    descriptions: [
      "Welcome to the Simple Life.",
      "Our travel insurance portal helps you conclude your travel insurance transactions faster without any delay.",
    ],
    backgroundImage,
    linkText: "Sign Up",
    linkPath: "https://travel.heirsinsurance.com/signup",
    link_Path: "https://travel.heirsinsurance.com/signin",
    // linkPath: "https://partners-app.staging.heirsinsurance.com/signup",
    // link_Path: "https://partners-app.staging.heirsinsurance.com/signin",
    link_Text: "Sign In",
  };
  return (
    <div>
      <Banner {...bannerPayload} />

      {/* <Partnership /> */}
      <Benefits />
      <Connect />
      {/* <Faqs /> */}
      <Inspiration />
    </div>
  );
};

export default Index;
