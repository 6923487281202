import React from "react";
import {
  Wrapper,
  Title,
  BannerContentWrapper,
  LinkWrapper,
  BannerContent,
} from "./styles";
import { Text } from "../../components/ProductBanner/styles";
const Banner = ({
  title,
  backgroundImage,
  descriptions,
  linkPath,
  linkText,
}) => {
  return (
    <Wrapper backgroundImage={backgroundImage}>
      <BannerContentWrapper>
        <BannerContent>
          <Title> {title}</Title>
          {descriptions.map((description, key) => (
            <Text key={key}>{description}</Text>
          ))}
          {linkText && linkPath && (
            <LinkWrapper>
              <a href={linkPath}>{linkText}</a>
            </LinkWrapper>
          )}
        </BannerContent>
      </BannerContentWrapper>
    </Wrapper>
  );
};

export default Banner;
