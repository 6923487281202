import React from "react";
import TravelInsurance from "../../components/TravelInsurance";
import backgroundImage from "../../assets/images/travel-insurance.png";
import Banner from "../../components/ProductBanner";

const Index = () => {
  const bannerPayload = {
    title: "Travel Insurance",
    descriptions: [
      "Whether you are travelling for business, leisure, school or pilgrimage, our Travel Insurance plans will cover the risks associated with travelling outside your country of residence, no matter where you go.",
      "Our affordable Travel Insurance plans - Standard Protection (Business/Tourism), Students Protection, and Pilgrimage Protection - ensure that you have a plan that fits any occasion. These plans will cover your medical emergencies, lost baggage, delayed or cancelled flights and many more.",
    ],
    backgroundImage,
    // linkPath: "https://www.youtube.com/channel/UCDrC_N0SMI_ZYtgxhQ24HwQ",
    linkPath: "#travel",
    // linkText: "Watch how it works",
    linkText: "See Details",
  };
  return (
    <div>
      <Banner {...bannerPayload} />

      <TravelInsurance />
    </div>
  );
};

export default Index;
