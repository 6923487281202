import React from "react";
import TravelBusiness from "../../components/TravelBusiness";
import backgroundImage from "../../assets/images/standard.png";
import Banner from "../../components/ProductBanner";

const Index = () => {
  const bannerPayload = {
    title: "Standard Travel Plans (Business/Tourism)",
    descriptions: [
      "Choose our affordable travel insurance to enjoy peace of mind during your journeys with comprehensive protection against medical emergencies, lost baggage and much more.",
    ],
    backgroundImage,
    // linkPath: "https://www.youtube.com/channel/UCDrC_N0SMI_ZYtgxhQ24HwQ",
    linkPath: "#travel",
    // linkText: "Watch how it works",
    linkText: "Learn More",
  };
  return (
    <div>
      <Banner {...bannerPayload} />

      <TravelBusiness />
    </div>
  );
};

export default Index;
