import React from "react";
import { Card, Avatar } from "antd";
import {
  TitleCardWrapper,
  TitleContent,
  StyledCard,
  QuoteWrapper,
} from "../style";
import quoteImage from "../../../assets/images/quote-light.png";

const { Meta } = Card;

const TestimonialCard = ({ firstName, lastName, message, avatarUrl }) => {
  const TitleHeader = ({ firstName, lastName }) => (
    <TitleCardWrapper>
      <QuoteWrapper>
        <img src={quoteImage} alt="quote" />
      </QuoteWrapper>
      <Avatar src={avatarUrl} />
      <TitleContent>
        <h2>
          {firstName} {lastName}
        </h2>
        {/* <h4>
          {firstName} {lastName}
        </h4> */}
      </TitleContent>
    </TitleCardWrapper>
  );

  const Description = ({ firstName, message }) => (
    <div>
      <span>@{firstName}</span>&nbsp;
      {message}
    </div>
  );

  return (
    <StyledCard bordered={false}>
      <Meta
        title={<TitleHeader firstName={firstName} lastName={lastName} />}
        description={<Description firstName={firstName} message={message} />}
      />
    </StyledCard>
  );
};

export default TestimonialCard;
