import styled from "styled-components";
import * as Colors from "../../utils/colors";
import { containerWrapper } from "../../utils/stylescheet";

export const Wrapper = styled("div")`
  background: ${Colors.PRIMARY};
  padding: 15px 0;
`;

export const IconWrapper = styled("div")`
  text-align: center;

  img {
    width: 100%;
  }
  .anticon {
    display: block;
  }

  svg {
    width: auto;
    height: 300px;
  }
`;

export const Content = styled("div")`
  padding: 35px 0;

  ${containerWrapper}
`;

export const Card = styled("div")`
  padding: 15px;
  background: ${Colors.WHITE};
  height: 100%;
  margin: 0 auto 30px;
  text-align: center;

  @media screen and (max-width: 767px) {
    width: 100%;
    height: auto;
    padding-bottom: 40px;
  }
`;

export const Text = styled("p")`
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  font-family: "Mulish Bold", sans-serif;
`;
