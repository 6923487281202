import React from "react";
import Section from "../Section";
import { Row, Col } from "antd";
import Icon from "@ant-design/icons";
import {
  Wrapper,
  Title,
  Content,
  Text,
  ContentWrapper,
  IconWrapper,
} from "./style";
import { ReactComponent as AlertFill } from "../../assets/images/alert-fill.svg";
import { Video } from "../ProductBanner/styles";
import ReactCountryFlag from "react-country-flag";

const imgcol = {
  height: "200px",
  width: "100%",
  overflow: "hidden",
};
const cols = {
  background: "#fff",
  border: "1px solid #cdcdcd",
};
const pstyle = {
  lineHeight: "18pt",
};

const StoryTeller = () => {
  return (
    <Wrapper>
      <ContentWrapper>
        <Title> </Title>
        <Row gutter={[24, 0]}>
          <Col lg={5} style={cols}>
            <Text>
              <table>
                <tr>
                  <td>
                    <div style={imgcol}>
                      <img
                        src="https://heirsdigitalform.azurewebsites.net/wp-content/uploads/2023/08/Ezra.png"
                        width="100%"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingBottom: "15px", lineHeight: "18pt" }}>
                    <span
                      style={{
                        paddingLeft: "5px",
                        fontWeight: "bold",
                        paddingBottom: "18px",
                        display: "inline-block",
                      }}
                    >
                      Ezra Million
                    </span>
                    <span style={{ display: "block" }}>
                      <ReactCountryFlag countryCode="ET" svg /> Ethopia
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingBottom: "15px" }}>
                    <p style={pstyle}>
                      <b>Gender:</b>{" "}
                    </p>
                    <p style={pstyle}>Male</p>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingBottom: "15px" }}>
                    <p style={pstyle}>
                      <b>Project Type:</b>{" "}
                    </p>
                    <p style={pstyle}>Investigative Magazine Issue & Podcast</p>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingBottom: "15px" }}>
                    <p style={pstyle}>
                      <b>Project Title:</b>{" "}
                    </p>
                    <p style={pstyle}>
                      African Cultural and Creative Entrepreneurship
                    </p>
                  </td>
                </tr>
              </table>
            </Text>
          </Col>
          <Col offset={0} lg={10}>
            <Text>
              <b>About: </b> <br />
              GETZ is an Ethiopian multimedia platform that collaborates with
              African creatives to showcase high-quality cultural content, with
              a primary focus on Ethiopia. Their broader mission is to establish
              open platforms for cultural exchange and free access to content
              that celebrates art, artists, and addresses relevant topics on a
              global scale.
            </Text>
            <Text>
              <b>Description of Project: </b>
              <br /> African Cultural and Creative Entrepreneurship is a curated
              multimedia experience primarily featuring an investigative
              magazine issue and podcast focusing on the entrepreneurial stories
              from artists and cultural practitioners working across the
              contemporary Ethiopian Creative Economy.
            </Text>
            <Text>
              <b>Creative Thought Behind Project: </b>
              <br />{" "}
              <p>
                Art, often seen as a luxury, is actually a powerful means of
                conveying abstract ideas, emotions, and a connection to various
                cultures. African artists, despite being frequently overlooked,
                play a vital role in shaping culture, fostering cultural
                exchange, and preserving cultural identities. It is crucial to
                recognize their contributions, particularly in a time when
                cultural content is readily available but often unnoticed.
              </p>
              <p>
                Through the Tony Elumelu Storytellers Fund, GETZ and partners
                plan to spotlight Ethiopian artists and cultural entrepreneurs
                shaping the Ethiopian Cultural Sector. This includes an
                investigative magazine piece exploring their voices,
                interactions, character profiles, discussions on creative
                economies, and a historical look at the representation of
                Ethiopian stories in the global media landscape.
              </p>
            </Text>
          </Col>
          <Col offset={0} lg={9}>
            <Text>
              <b>Project: </b>Podcast
              <br />
              <Video>
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/Y1JfAQWMCdA?si=HBGivYHgbfa3bBR_"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </Video>
            </Text>
            <Text>
              <b>Impact: </b>
              <br />{" "}
              <p>Over 50k people reached and collaboration opportunities.</p>
            </Text>
            <Text>
              <b>Lesson Learnt While Working on Project: </b>
              <p>
                African creative entrepreneurship spans arts, media, fashion,
                and more, offering opportunities amid challenges like limited
                access to resources and cultural barriers. Yet, with growing
                demand, digital platforms, innovation hubs, and international
                support, these entrepreneurs can drive economic and social
                development, enrich cultural diversity, and strengthen Africa's
                identity.
              </p>
            </Text>
          </Col>
        </Row>
      </ContentWrapper>
    </Wrapper>
  );
};

export default StoryTeller;
