import styled from "styled-components";
import * as Colors from "../../../utils/colors";

export const Wrapper = styled("div")`
  padding: 50px 0;
  background: ${Colors.GRAY_15};

  @media screen and (max-width: 575px) {
    padding: 20px 0;
  }
`;

export const Title = styled("h1")`
  font-size: 55px;
  line-height: 60px;
  margin-top: 30px;
  margin-bottom: 22px;

  @media screen and (min-width: 767px) {
    font-size: 35px;
    line-height: 30px;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 475px) {
    font-size: 25px;
    line-height: 25px;
    margin-top: 10px;
    margin-bottom: 5px;
  }
`;

export const TextMessage = styled("div")`
  @media screen and (min-width: 767px) {
    font-size: 1.5em;
  }

  @media screen and (max-width: 475px) {
    font-size: 1em;
  }
`;

export const TextContent = styled("div")`
  margin-right: 70px;

  a {
    color: ${Colors.WHITE};
    height: auto !important;
    padding: 10px 25px !important;
    border: none !important;
    background: ${Colors.PRIMARY};
    transition: none !important;
    border-radius: 0 !important;

    &:hover {
      height: auto !important;
      padding: 10px 25px !important;
      border: none !important;
      background: ${Colors.BLACK};
    }

    @media screen and (max-width: 768px) {
      & {
        padding: 6px 21px !important;
        font-size: 12px !important;
      }
    }
  }

  @media screen and (max-width: 991px) {
    margin-right: 0;
  }
`;

export const ContentWrapper = styled("div")`
  margin: auto;
  width: 95%;

  @media screen and (min-width: 576px) {
    width: 90%;
  }

  @media screen and (min-width: 768px) {
    width: 85%;
  }

  @media screen and (min-width: 992px) {
    width: 65%;
  }

  @media screen and (min-width: 1200px) {
    width: 65%;
  }

  @media screen and (min-width: 1600px) {
    width: 50%;
  }
`;

export const Content = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 300px;

    @media screen and (max-width: 991px) {
      width: 200px;
    }

    @media screen and (max-width: 767px) {
      width: 200px;
    }

    @media screen and (max-width: 575px) {
      width: 150px;
    }
  }

  @media screen and (max-width: 575px) {
    padding: 0 5px;
  }
`;
export const DownloadWrapper = styled("div")`
  margin-top: 30px;
  ${Title} {
    font-size: 13px;
    line-height: 15px;
    margin-bottom: 15px;
    text-align: left;
    font-weight: bold;
    text-transform: uppercase;
    color: ${Colors.TEXT_DEFAULT};
  }

  @media screen and (min-width: 992px) {
    bottom: 50px;
    left: 0;
    right: 0;
  }

  img {
    width: 120px;
    height: auto;
    margin-right: 15px;
  }
`;
