import styled from "styled-components";
import {
  DatePickerWrapper,
  InputWrapper,
  SelectWrapper,
  TimePickerWrapper,
} from "../../components/Input/style";
import { TextInputWrapper } from "../../components/Textarea/style";
import * as Colors from "../../utils/colors";
import { containerWrapper } from "../../utils/stylescheet";
import { SubTitle } from "../Complaint/bannerStyle";

export const Content = styled("div")`
  margin-top: 25px;

  @media screen and (max-width: 575px) {
    margin-top: 10px;
    margin: 0 auto 20px 100px;
  }
`;

export const FormWrapper = styled("form")`
  padding: 40px 0;
  text-align: center;
  ${containerWrapper}

  ${InputWrapper} {
    width: 100% !important;
    border: solid 1px ${Colors.DARK} !important;
  }

  ${SelectWrapper} {
    width: 100% !important;
    border: solid 1px ${Colors.DARK} !important;
  }

  ${TextInputWrapper} {
    width: 100% !important;
    border: solid 1px ${Colors.DARK} !important;
    min-height: 150px;
  }

  textarea {
    width: 100%;
    padding: 10px 20px;
    outline: none;
    border: solid 1px ${Colors.DARK} !important;
    border-radius: 5px;

    ::placeholder {
      color: #bfbfbf;
      opacity: 1;
    }

    :-ms-input-placeholder {
      color: #bfbfbf;
    }

    ::-ms-input-placeholder {
      color: #bfbfbf;
    }
  }
`;

export const ClaimFormWrapper = styled("div")`
  padding: 0 0 40px 0;
  text-align: center;
  ${containerWrapper}

  ${InputWrapper} {
    width: 100% !important;
    border: solid 1px ${Colors.DARK} !important;
  }

  ${SelectWrapper} {
    width: 100% !important;
    border: solid 1px ${Colors.DARK} !important;
    padding: 10px 0 !important;

    .ant-select-selector {
      padding-left: 25px;
      box-shadow: initial !important;
    }
  }

  ${DatePickerWrapper} {
    width: 100% !important;
    border: solid 1px ${Colors.DARK} !important;
  }

  ${TimePickerWrapper} {
    width: 100% !important;
    border: solid 1px ${Colors.DARK} !important;
  }

  ${TextInputWrapper} {
    width: 100% !important;
    border: solid 1px ${Colors.DARK} !important;
    min-height: 150px;
  }

  textarea {
    width: 100%;
    padding: 10px 20px;
    outline: none;
    border: solid 1px ${Colors.DARK} !important;
    border-radius: 5px;

    ::placeholder {
      color: #bfbfbf;
      opacity: 1;
    }

    :-ms-input-placeholder {
      color: #bfbfbf;
    }

    ::-ms-input-placeholder {
      color: #bfbfbf;
    }
  }

  #approvedEstimate .ant-form-item-control-input-content {
    text-align: left;
  }
`;

export const FormContentWrapper = styled("div")`
  width: 100%;
  margin: 0 auto 20px;

  small {
    color: ${Colors.PRIMARY};
  }

  .ant-form-item .ant-form-item {
    margin-bottom: 0;
  }

  @media screen and (min-width: 992px) {
    width: 60%;
  }
`;

export const Title = styled("h2")`
  text-align: center;
  margin: 15px 0 25px;
`;

export const Text = styled("p")`
  font-size: 14px;
`;

export const Wrapper = styled("div")`
  margin-top: 4.4%;
  background-image: ${({ backgroundImage }) =>
    backgroundImage ? "url(" + backgroundImage + ")" : ""};
`;

export const DetailsWrapper = styled("div")`
  padding-top: 60px;
  background: ${Colors.GRAY_35};
  position: relative;

  ${Title} {
    @media screen and (max-width: 767px) {
      font-size: 18px;
      line-height: 18px;
    }
  }

  ${Text} {
    word-break: break-word;

    @media screen and (max-width: 767px) {
      font-size: 12px;
      line-height: 14px;
    }
  }
`;

export const PhoneImageWrapper = styled("div")`
  padding: 0 10px;

  img {
    width: 100%;
    margin-right: 0;
  }
`;
export const LeftDetailsContentWrapper = styled("div")`
  padding-right: 25px;
  padding-bottom: 15px;
  border-right: solid 1px ${Colors.BLACK};
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
`;
export const RightDetailsContentWrapper = styled("div")`
  margin-left: 25px;

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;

  img {
    width: 60px;
    height: auto;
    margin-right: 10px;
    margin-bottom: 10px;

    @media screen and (min-width: 768px) {
      width: 80px;
    }

    @media screen and (min-width: 992px) {
      width: 120px;
    }
  }
`;
export const DetailsContentWrapper = styled("div")`
  ${containerWrapper}

  ${Title} {
    text-align: right;
    margin-bottom: 10px;
  }

  ${Text} {
    text-align: right;
  }
`;

export const DetailsRowWrapper = styled("div")``;

export const ClaimRatingInfoWrapper = styled("div")`
  text-align: initial;

  ${SubTitle} {
    margin-top: 5px;
  }
`;

export const ClaimRatingInfoContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-left: 20px;

  ${ClaimRatingInfoWrapper}:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const ClaimRatingCheckboxContainer = styled("div")`
  display: flex;
  flex-direction: column;

  .ant-checkbox-wrapper:not(:last-child) {
    margin-bottom: 10px;
  }

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px;
  }
`;

export const ClaimRatingFormWrapper = styled("div")`
  background-color: ${Colors.WHITE};
  padding: 20px;
  border-radius: 8px;
  box-shadow: 3px 3px 3px 1px #f38282eb;
`;

export const ClaimRatingContainer = styled("div")`
  background-color: ${Colors.PRIMARY};
  padding: 20px;
  border-radius: 8px;
`;
