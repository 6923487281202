import React from "react";
import Section from "../Section";
import { Row, Col } from "antd";
import Icon from "@ant-design/icons";
import {
  Wrapper,
  Title,
  Content,
  Text,
  ContentWrapper,
  IconWrapper,
} from "./style";
import { ReactComponent as AlertFill } from "../../assets/images/alert-fill.svg";
import { Video } from "../ProductBanner/styles";
import ReactCountryFlag from "react-country-flag";

const imgcol = {
  height: "200px",
  width: "100%",
  overflow: "hidden",
};
const cols = {
  background: "#fff",
  border: "1px solid #cdcdcd",
};
const pstyle = {
  lineHeight: "18pt",
};

const StoryTeller = () => {
  return (
    <Wrapper>
      <ContentWrapper>
        <Title> </Title>
        <Row gutter={[24, 0]}>
          <Col lg={5} style={cols}>
            <Text>
              <table>
                <tr>
                  <td>
                    <div style={imgcol}>
                      <img
                        src="https://heirsdigitalform.azurewebsites.net/wp-content/uploads/2023/08/Bolarinwa.png"
                        width="100%"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingBottom: "15px" }}>
                    <span style={{ paddingLeft: "5px", fontWeight: "bold" }}>
                      Bolarinwa Idowu Emmanuel
                    </span>
                    <span style={{ display: "block" }}>
                      <ReactCountryFlag countryCode="NG" svg /> Nigeria
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingBottom: "15px" }}>
                    <p style={pstyle}>
                      <b>Gender:</b>{" "}
                    </p>
                    <p style={pstyle}>Male</p>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingBottom: "15px" }}>
                    <p style={pstyle}>
                      <b>Project Type:</b>{" "}
                    </p>
                    <p style={pstyle}>Documentaries</p>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingBottom: "15px" }}>
                    <p style={pstyle}>
                      <b>Project Title:</b>{" "}
                    </p>
                    <p style={pstyle}>Birth of Santab</p>
                  </td>
                </tr>
              </table>
            </Text>
          </Col>
          <Col offset={0} lg={10}>
            <Text>
              <b>About: </b> <br />
              Bolarinwa (a.k.a Santa Bolero), a multi-award-winning media
              entrepreneur, and founder of Santab Productions Ltd embarked on a
              project to challenge popular entrepreneurial stereotypes and
              narratives about Africa in an audiovisual format.
            </Text>
            <Text>
              <b>Description of Project: </b>
              <br /> Bolarinwa's project titled 'Birth of Santab', highlights
              his grass to grace entrepreneurial journey. Since the launch of
              his project, he has been contacted and invited to university
              campuses in Nigeria to screen his project and share his insights
              with a much younger demographic. He plans to carry on at different
              university campuses to inspire undergraduates aspiring to become
              Entrepreneurs without having those stereotypes.
            </Text>
          </Col>
          <Col offset={0} lg={9}>
            <Text>
              <b>Project: Birth of Santab</b>
              <br />
              <Video>
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/8vzi5vhSn7g?si=3COwmJanFJFm_Xrb"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </Video>
            </Text>
          </Col>
        </Row>
      </ContentWrapper>
    </Wrapper>
  );
};

export default StoryTeller;
