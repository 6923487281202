import React from "react";
import { ButtonWrapper } from "./style";

const Button = ({
  type = "primary",
  shape = "round",
  size = "large",
  children,
  ...rest
}) => {
  return (
    <ButtonWrapper type={type} shape={shape} size={size} {...rest}>
      {children}
    </ButtonWrapper>
  );
};

export default Button;
