import styled from "styled-components";
import { Card } from "antd";
import * as Colors from "../../utils/colors";
import { lightGrayBackgroundGradient } from "../../utils/stylescheet";

export const Wrapper = styled("div")`
  padding: 15px 0;

  .ant-card-meta-description {
    color: ${Colors.TEXT_DEFAULT} !important;
  }

  ${lightGrayBackgroundGradient}
`;
export const TestimonialCardWrapper = styled("div")`
  padding: 0;
  margin-top: 50px;
`;

export const StyledCard = styled(Card)`
  width: 300px;
  margin: 15px 30px;

  border-radius: 10px;
  border: solid 1px #ebebeb47;
  box-shadow: 4px 6px 5px 1px #f476790d;

  .ant-card-meta-description {
    color: ${Colors.DARK};

    span {
      color: ${Colors.PRIMARY};
    }
  }
`;

export const TitleContent = styled("div")`
  font-size: 14px;

  h2 {
    margin: 0;
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
  }
  h4 {
    margin: 0;
    font-size: 13px;
    font-weight: 400;
    font-style: italic;
    color ${Colors.DARK}
  }
`;

export const QuoteWrapper = styled("div")`
  position: absolute;
  top: -19px;
  left: 24px;
  color: #ffdce3;
`;

export const TitleCardWrapper = styled("div")`
  margin: 15px 0;

  .ant-avatar {
    display: inline-block;
    vertical-align: middle;
  }

  ${TitleContent} {
    padding-left: 16px;
    display: inline-block;
    vertical-align: middle;
  }
`;
