import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  List,
  PlanChatTitle,
  PlanChatWrapper,
  Title,
  Wrapper,
  Text,
} from "../PlanPicker/styles";
import ShopInsurance from "../../assets/images/shop-insurance.png";
import { Modal, Col, Row } from "antd";
import { HeirsDashboardUrl } from "../../utils/constants";
import { Mixpanel } from "../../Mixpanel";

const Index = () => {
  const handleChatWithUs = (e) => {
    e.preventDefault();
    // window.fcWidget.open({});
    window.Freshbots.showWidget({});
  };

  const checkScrollTop = () => {
    const tableTop = document.getElementById("d-fixed");
    if (tableTop) {
      if (window.pageYOffset > 590) {
        tableTop.classList.add("p-fixed");
        document.querySelector("#d-fixed .ant-table-tbody").style.display =
          "none";
        document.querySelector(
          "#product-table .ant-table-thead"
        ).style.display = "none";
      } else {
        tableTop.classList.remove("p-fixed");
      }
    }
  };

  useEffect(() => {
    let p = document.getElementById("table");
    let p_prime = p.cloneNode(true);
    document.getElementById("d-fixed").appendChild(p_prime);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
  });

  const [modalVisible, setModalVisible] = useState(false);

  const showModal = () => {
    setModalVisible(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  return (
    <Wrapper id="shop">
      <div className="ant-table" id="ant-table">
        <div id="d-fixed" />
        <div id="product-table">
          <table id="table">
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell" colspan="3">
                  <img
                    src={ShopInsurance}
                    alt="home protect"
                    style={{
                      width: "65px",
                      height: "50px",
                      borderRadius: "60px 60px",
                    }}
                  />
                </th>
              </tr>
              <tr>
                <th>
                  <Title>
                    <h3 style={{ color: "#ff0000" }}>Shop Insurance Plan</h3>{" "}
                  </Title>
                </th>
                <th>
                  <Title>
                    <h4>Business Protect</h4>
                  </Title>
                  <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Business Protect - Buy Now")}
                    href={`${HeirsDashboardUrl}/businessowner-type?policy-type=Business%20Protect`}
                  >
                    Buy Now
                  </a>
                  <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Business Protect - Get Quote")}
                    href={`${HeirsDashboardUrl}/businessowner-type?policy-type=Business%20Protect`}
                    style={{ marginTop: "5px" }}
                  >
                    Get Quote
                  </a>
                </th>
                <th>
                  <Title>
                    <h4>Business Protect Plus</h4>
                  </Title>
                  <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Business Protect Plus - Buy Now")}
                    href={`${HeirsDashboardUrl}/businessowner-type?policy-type=Business%20Protect%20Plus`}
                  >
                    Buy Now
                  </a>
                  <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track(
                      "Business Protect Plus - Get Quote"
                    )}
                    href={`${HeirsDashboardUrl}/businessowner-type?policy-type=Business%20Protect%20Plus`}
                    style={{ marginTop: "5px" }}
                  >
                    Get Quote
                  </a>
                </th>
              </tr>
            </thead>

            <tbody className="ant-table-tbody">
              <tr>
                <td className="ant-table-cell">
                  <Text>Cost</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>From N7,350</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Based on the cost of your shop/stocks/contents</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Perfect for</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>
                    Shops with a total value of N2.1 million to N3 million
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text>
                    Shops with a total value of N3 million to N25 million
                  </Text>
                </td>
              </tr>
              <tr>
                <td>
                  <Text>Payment Frequency</Text>
                </td>
                <td>
                  <Text>Yearly</Text>
                </td>
                <td>
                  <Text>Yearly/half-yearly</Text>
                </td>
              </tr>
              <tr>
                <td colspan="3" style={{ textAlign: "center" }}>
                  <Title>
                    <h4>Coverage for You</h4>
                  </Title>
                </td>
              </tr>
              <tr>
                <td>
                  <Text>
                    Repairs shop and replaces lost/damaged goods in the shop
                  </Text>
                </td>
                <td>
                  <Text>Up to N3 million</Text>
                </td>
                <td>
                  <Text>Up to N25 million</Text>
                </td>
              </tr>
              <tr>
                <td>
                  <Text>
                    Replaces personal items belonging to the shop owner or
                    employee lost in an incident at the shop.
                  </Text>
                </td>
                <td>
                  <Text>Up to N150,000</Text>
                </td>
                <td>
                  <Text>Up to N250,000</Text>
                </td>
              </tr>
              <tr>
                <td>
                  <Text>
                    Medical expenses for injury to shop owner or employee caused
                    by an incident in the shop.
                  </Text>
                </td>
                <td>
                  <Text>Up to N50,000</Text>
                </td>
                <td>
                  <Text>Up to N100,000</Text>
                </td>
              </tr>
              <tr>
                <td>
                  <Text>
                    Damage from strike, riot, or civil commotion (excluding
                    religious and communal riots)
                  </Text>
                </td>
                <td>
                  <Text>Covered</Text>
                </td>
                <td>
                  <Text>Covered</Text>
                </td>
              </tr>
              <tr>
                <td colspan="3" style={{ textAlign: "center" }}>
                  <Title>
                    <h4>Exclusions (Benefits not covered)</h4>
                  </Title>
                </td>
              </tr>
              <tr>
                <td>
                  <Text>
                    Loss or damage caused by invasion and war(like) operations
                  </Text>
                </td>
                <td>
                  <Text>Not covered</Text>
                </td>
                <td>
                  <Text>Not covered</Text>
                </td>
              </tr>
              <tr>
                <td>
                  <Text>Wilful misconduct and arson</Text>
                </td>
                <td>
                  <Text>Not covered</Text>
                </td>
                <td>
                  <Text>Not covered</Text>
                </td>
              </tr>
              <tr>
                <td>
                  <Text>
                    Damage to any electrical appliance directly caused by
                    short-circuiting, excessive pressure, or leakage of
                    electricity
                  </Text>
                </td>
                <td>
                  <Text>Not covered</Text>
                </td>
                <td>
                  <Text>Not covered</Text>
                </td>
              </tr>
              <tr>
                <td>
                  <Text>Marine Insurance Risks</Text>
                </td>
                <td>
                  <Text>Not covered</Text>
                </td>
                <td>
                  <Text>Not covered</Text>
                </td>
              </tr>
              <tr>
                <td>
                  <Text>Pollution Damage/Liability</Text>
                </td>
                <td>
                  <Text>Not covered</Text>
                </td>
                <td>
                  <Text>Not covered</Text>
                </td>
              </tr>
              <tr>
                <td>
                  <Text>
                    Loss or damage caused by confiscation, commandeering or
                    destruction of/damage to building contents by government
                  </Text>
                </td>
                <td>
                  <Text>Not covered</Text>
                </td>
                <td>
                  <Text>Not covered</Text>
                </td>
              </tr>
              {/* <tr>
                <td className="ant-table-cell">
                  <Text>Document Requirements</Text>
                </td>
                <td className="ant-table-cell" colspan="2">
                  <Text>
                    <List>
                      <li>Duly Filled Proposal/KYC Form.</li>
                      <li>Photographs of Subject Matter.</li>
                      <li>Evidence of Payment.</li>
                      <li>
                        Valid Means of I.D- Driver license, Voter's card, NIMC,
                        National ID, International Passport.
                      </li>
                      <li>Certificate of incorporation (for company).</li>
                      <li>
                        Proof of residence - Utility Bill, Front page of
                        the customer's address (same address as indicated on the
                        form or portal), Local government levy.
                      </li>
                      <li>Valuation Report (Building).</li>
                      <li>Inventory of contents with values.</li>
                      <li>Purchase receipt.</li>
                    </List>
                  </Text>
                </td>
              </tr> */}
              <tr>
                <td colspan="3" style={{ textAlign: "center" }}>
                  <Text>
                    <a
                      href="https://forms.heirsinsurance.com/shop-insurance/"
                      target="blank"
                      style={{
                        background: "#ff0000",
                        color: "#fff",
                        display: "inline-block",
                        padding: "8px",
                        borderRadius: "5px",
                        width: "250px",
                        height: "auto",
                        margin: "0px 10px",
                      }}
                    >
                      Get Started
                    </a>
                    <a
                      onClick={showModal}
                      target="blank"
                      style={{
                        background: "#ff0000",
                        color: "#fff",
                        display: "inline-block",
                        padding: "8px",
                        borderRadius: "5px",
                        width: "100px",
                        height: "auto",
                        margin: "0px 10px",
                      }}
                    >
                      FAQs
                    </a>
                  </Text>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <PlanChatWrapper>
        <PlanChatTitle>
          Not Sure of the Right <br />
          Plan for you?
        </PlanChatTitle>
        <Link to="/" onClick={handleChatWithUs}>
          Chat with us
        </Link>
      </PlanChatWrapper>
      <Modal
        visible={modalVisible}
        onCancel={handleCancel}
        footer={null}
        style={{ padding: "0px" }}
      >
        <Row>
          <h1 style={{ color: "rgb(255,0,0)", paddingBottom: "10px" }}>
            Shop Insurance - FAQs
          </h1>
        </Row>
        <div style={{ height: "450px", overflowY: "auto" }}>
          <Row style={{ padding: "10px 2px" }}>
            <Col>
              <h3 style={{ fontWeight: "bold" }}>What is shop insurance?</h3>
            </Col>
            <Col>
              Our Shop insurance plan is for smart business owners who want to
              protect their shops/retail outlets and the assets in them. If your
              shop is damaged by fire, burglary, or other similar incidents, we
              will pay to repair the damage.
            </Col>
          </Row>
          <Row style={{ padding: "10px 2px" }}>
            <Col>
              <h3 style={{ fontWeight: "bold" }}>Who is eligible?</h3>
            </Col>
            <Col>
              Business owners with shops with a total value from N2.1 million to
              N25 million.
            </Col>
          </Row>
          <Row style={{ padding: "10px 2px" }}>
            <Col>
              <h3 style={{ fontWeight: "bold" }}>How much is it?</h3>
            </Col>
            <Col> Our Shop Insurance plan starts from N7,350 per year.</Col>
          </Row>
          <Row style={{ padding: "10px 2px" }}>
            <Col>
              <h3 style={{ fontWeight: "bold" }}>What are the benefits?</h3>
            </Col>
            <Col>
              <ol>
                <li>
                  Repairs your shop and replaces lost/damaged goods in the shop
                  if any of the stated risks occur.
                </li>
                <li>
                  Replaces lost personal items belonging to you (or your
                  employee)
                </li>
                <li>Medical expenses for you (or your employee)</li>
                <li>
                  Damage from strike, riot, or civil commotion (excluding
                  religious and communal riots)
                </li>
              </ol>
            </Col>
          </Row>
          <Row style={{ padding: "10px 2px" }}>
            <Col>
              <h3 style={{ fontWeight: "bold" }}>What is not covered?</h3>
            </Col>
            <Col>
              <ol>
                <li>
                  Loss or damage caused by invasion and war(like) operations.
                </li>
                <li>Wilful misconduct and arson.</li>
                <li>
                  Damage to any electrical appliance caused by short-circuiting,
                  excessive pressure, or electricity leakage.
                </li>
                <li>Marine insurance risks.</li>
                <li>Pollution damage/liability.</li>
                <li>
                  Loss or damage caused by confiscation, commandeering or
                  destruction of building contents by the government.
                </li>
              </ol>
            </Col>
          </Row>
          <Row style={{ padding: "10px 2px" }}>
            <Col>
              <h3 style={{ fontWeight: "bold" }}>
                How do I determine the value of my shop and its contents for
                insurance purposes?
              </h3>
            </Col>
            <Col>
              {" "}
              An inventory of the goods/content in your shop can be carried out.
            </Col>
          </Row>
          <Row style={{ padding: "10px 2px" }}>
            <Col>
              <h3 style={{ fontWeight: "bold" }}>
                Can I insure a rented shop or does it only apply to owned
                properties?
              </h3>
            </Col>
            <Col> Yes, you can insure a rented shop.</Col>
          </Row>
          <Row style={{ padding: "10px 2px" }}>
            <Col>
              <h3 style={{ fontWeight: "bold" }}>
                Is the shop's inventory covered under the policy?
              </h3>
            </Col>
            <Col> Yes, as well the equipment in your shop.</Col>
          </Row>
          <Row style={{ padding: "10px 2px" }}>
            <Col>
              <h3 style={{ fontWeight: "bold" }}>
                Are there any specific security requirements for the shop to be
                eligible for insurance coverage?
              </h3>
            </Col>
            <Col>
              {" "}
              Necessary burglary protections and other fire preventive measures
              should be in place.
            </Col>
          </Row>
          <Row style={{ padding: "10px 2px" }}>
            <Col>
              <h3 style={{ fontWeight: "bold" }}>
                Can I transfer my shop insurance to a new location if I relocate
                my business?
              </h3>
            </Col>
            <Col> Yes, you can, but you must inform us of such plans.</Col>
          </Row>
          <Row style={{ padding: "10px 2px" }}>
            <Col>
              <h3 style={{ fontWeight: "bold" }}>
                Is there a waiting period before I can make a claim?
              </h3>
            </Col>
            <Col> No, there is no waiting period.</Col>
          </Row>
        </div>
      </Modal>
    </Wrapper>
  );
};

export default Index;
