import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BlogContentPage from "../../components/BlogContentPage";
import { Paragraph, Text } from "../../components/ContentPage/styles";
import { data } from "../Media/data";
import { BlogHeading, ContentWrapper, ImageText, PostImage } from "./styles";

const MediaPost = () => {
  const { id } = useParams();

  const [post, setPost] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    data.map((item) => {
      if (item.id === id) {
        setPost(item);
      }
      return item;
    });
  }, [id]);

  return (
    <>
      {post && post.content ? (
        <BlogContentPage title={post.title}>
          <ContentWrapper>
            <Paragraph>
              <BlogHeading>{post.subtitle}</BlogHeading>
              <PostImage backgroundImage={post.image} />
              <ImageText>{post.imageDescription}</ImageText>
            </Paragraph>
            <Paragraph>
              <Text>
                <b>{post.textStart} </b>
                {post.firstText}
              </Text>
            </Paragraph>
            {post.content.map((text, index) => (
              <Paragraph key={index + 1}>
                <Text dangerouslySetInnerHTML={{ __html: `${text}` }}></Text>
              </Paragraph>
            ))}

            {/* <Paragraph>
            <Text>
              Visit &nbsp;
              <a href="http://www.heirsgeneralinsurance.com">
                www.heirsinsurance.com{" "}
              </a>
              or &nbsp;
              <a href="http://www.heirsgeneralinsurance.com">
                www.heirsinsurance.com{" "}
              </a>{" "}
              for more details.
            </Text>
          </Paragraph>
          <Paragraph>
            <Text>
              <b>For More Inquiries:</b>
            </Text>
            <Text>Ifesinachi Okpagu</Text>
            <Text>Chief Marketing Officer </Text>
            <Text>
              <a href="mailto:ifesinachi.okpagu@heirsgeneralinsurance.com">
                Ifesinachi.okpagu@heirsgeneralinsurance.com{" "}
              </a>
            </Text>
          </Paragraph> */}
          </ContentWrapper>
        </BlogContentPage>
      ) : (
        ""
      )}
    </>
  );
};

export default MediaPost;
