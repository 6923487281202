import React, { useEffect, useState } from "react";
import {
  PlanChatTitle,
  PlanChatWrapper,
  Text,
  Title,
  Wrapper,
  List,
} from "../PlanPicker/styles";

import flexi from "../../assets/images/Icon_Flexi&comprehensive_motor.png";
import { Link } from "react-router-dom";
import { HeirsDashboardUrl } from "../../utils/constants";
import { Mixpanel } from "../../Mixpanel";
import { Modal, Col, Row } from "antd";

const Index = () => {
  const [modalVisible, setModalVisible] = useState(false);

  const showModal = () => {
    setModalVisible(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const handleChatWithUs = (e) => {
    e.preventDefault();
    // window.fcWidget.open({});
    window.Freshbots.showWidget({});
  };

  const checkClickEvent = (e) => {
    if (e.target.id === "purchaseStatus") {
      setPurchaseStatus();
    }
  };

  const setPurchaseStatus = () => {
    localStorage.setItem("attempted_purchase", "true");
  };

  const checkScrollTop = () => {
    const tableTop = document.getElementById("d-fixed");
    if (tableTop) {
      if (window.pageYOffset > 590) {
        tableTop.classList.add("p-fixed");
        document.querySelector("#d-fixed .ant-table-tbody").style.display =
          "none";
        document.querySelector(
          "#product-table .ant-table-thead"
        ).style.display = "none";
      } else {
        tableTop.classList.remove("p-fixed");
      }
    }
  };

  useEffect(() => {
    let p = document.getElementById("table");
    let p_prime = p.cloneNode(true);
    document.getElementById("d-fixed").appendChild(p_prime);
  }, []);

  useEffect(() => {
    localStorage.setItem("last_accessed_product", window.location.pathname);
    window.addEventListener("scroll", checkScrollTop);
    window.addEventListener("click", checkClickEvent);
  });

  return (
    <Wrapper id="her-motor">
      <div className="ant-table" id="ant-table">
        <div id="d-fixed" />
        <div id="product-table">
          <table id="table">
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell" style={{ width: "50%" }}>
                  <img src={flexi} alt="flexi" />
                </th>
                <th className="ant-table-cell" style={{ width: "50%" }}>
                  <Title>Her Motor Insurance</Title>
                  <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Her Motor Insurance Buy")}
                    href={`${HeirsDashboardUrl}/motor-policy-type?policy-type=HER Motor`}
                  >
                    Buy Now
                  </a>
                  <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Her Motor Insurance quote")}
                    href={`${HeirsDashboardUrl}/motor-policy-type?policy-type=HER Motor`}
                    style={{ marginTop: "5px" }}
                  >
                    Get A Quote
                  </a>
                </th>
              </tr>
            </thead>

            <tbody className="ant-table-tbody">
              <tr>
                <td className="ant-table-cell" style={{ width: "50%" }}>
                  <Text>For Whom?</Text>
                </td>
                <td className="ant-table-cell" style={{ width: "50%" }}>
                  <Text>For Women</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" style={{ width: "50%" }}>
                  <Text>Cost</Text>
                </td>
                <td className="ant-table-cell" style={{ width: "50%" }}>
                  <Text>5% of car value</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" style={{ width: "50%" }}>
                  <Text>Minimum car value to purchase plan</Text>
                </td>
                <td className="ant-table-cell" style={{ width: "50%" }}>
                  <Text>N4,000,000</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Premium Frequency</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>
                    Yearly.{" "}
                    <i style={{ fontSize: "9pt" }}>
                      (For quarterly and half-yearly options, please click{" "}
                      <a
                        href="/"
                        onClick={handleChatWithUs}
                        style={{
                          display: "inline",
                          background: "#fff",
                          color: "#ff0000",
                          fontWeight: "bold",
                          lineHeight: "1pt",
                        }}
                      >
                        here
                      </a>{" "}
                      to chat with an agent)
                    </i>
                  </Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Excess buy back</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Free</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" colSpan="6">
                  <Text highlight>Coverage for You</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>
                    24 hours rescue and assistance during road emergencies
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text>
                    Covered (Limited to Lagos State, Port-Harcourt metropolis,
                    and Abuja metropolis.)
                  </Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>
                    Full replacement of vehicle and vehicle parts if stolen or
                    wrecked
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text>
                    Up to the value of the vehicle and subject to policy excess
                    and other conditions. <br />
                    <a
                      href="/faq"
                      target="blank"
                      style={{
                        background: "none",
                        display: "inline",
                        color: "#ff0000",
                      }}
                    >
                      What is Excess and betterment?
                    </a>
                  </Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Repairs your vehicle in the case of an accident.</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>
                    Up to the value of the vehicle and subject to policy Excess
                    and other conditions on policy. <br />{" "}
                    <a
                      href="/faq"
                      target="blank"
                      style={{
                        background: "none",
                        display: "inline",
                        color: "#ff0000",
                      }}
                    >
                      What is Excess and betterment?
                    </a>
                  </Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Pays your medical bills after an accident</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Up to N50,000</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Vehicle tracker</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Free for vehicles worth N5million and above.</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Towing Services (Interstate/Intrastate)</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Out of state: N30,000 / Within the state: N20,000</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Damage caused by riot, strike, & civil commotion</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Covered</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Damage caused by flood</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Up to N100,000</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" colSpan="6">
                  <Text highlight>Coverage for the Other Party</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Covers damage to the other vehicle</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Up to N3,000,000</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Medical bills payment</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Up to a reasonable limit</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>
                    Financial support to beneficiary if there is loss of life
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Unlimited</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Document Requirements</Text>
                </td>
                <td className="ant-table-cell" colspan="2">
                  <Text>
                    <List>
                      <li>Duly Filled Proposal/KYC Form.</li>
                      <li>Proof of Ownership.</li>
                      <li>Evidence of Payment.</li>
                      <li>Copy of the Vehicle License/Proof of Ownership.</li>
                      <li>Photographs of subject matter.</li>
                      <li>
                        Valid means of ID - Driver License, Voter's card, NIMC,
                        National ID, International Passport.
                      </li>
                      <li>
                        Proof of Residence - Utility Bill, Front page of the
                        customer's address (same address as indicated on the
                        form or portal), Local Government Levy.
                      </li>
                    </List>
                  </Text>
                </td>
              </tr>
              <tr>
                <td colspan="2" style={{ textAlign: "center" }}>
                  <Text>
                    <a
                      onClick={showModal}
                      target="blank"
                      style={{
                        background: "#ff0000",
                        color: "#fff",
                        display: "inline-block",
                        padding: "8px",
                        borderRadius: "5px",
                        width: "100px",
                        height: "auto",
                        margin: "0px 10px",
                      }}
                    >
                      FAQs
                    </a>
                  </Text>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <PlanChatWrapper>
        <PlanChatTitle>
          Not Sure of the Right <br />
          Plan for you?
        </PlanChatTitle>
        <Link to="/" onClick={handleChatWithUs}>
          Chat with us
        </Link>
      </PlanChatWrapper>
      <Modal
        visible={modalVisible}
        onCancel={handleCancel}
        footer={null}
        style={{ padding: "0px" }}
      >
        <Row>
          <h1 style={{ color: "rgb(255,0,0)", paddingBottom: "10px" }}>
            Her Motor - FAQs
          </h1>
        </Row>
        <div style={{ height: "450px", overflowY: "auto" }}>
          <Row style={{ padding: "10px 2px" }}>
            <Col>
              <h3 style={{ fontWeight: "bold" }}>What is Her Motor?</h3>
            </Col>
            <Col>
              Her Motor is a unique comprehensive motor insurance plan for
              women/female car owners. In addition to all comprehensive
              insurance benefits, it provides 24/7 road rescue and assistance to
              recipients of the plan.
            </Col>
          </Row>
          <Row style={{ padding: "10px 2px" }}>
            <Col>
              <h3 style={{ fontWeight: "bold" }}>What is unique about it?</h3>
            </Col>
            <Col>
              Her Motor offers 24/7 road emergency rescue and assistance.
            </Col>
          </Row>
          <Row style={{ padding: "10px 2px" }}>
            <Col>
              <h3 style={{ fontWeight: "bold" }}>How much is it?</h3>
            </Col>
            <Col>
              Her Motor costs 5% of the car's value yearly. You can pay your
              premium half-yearly or quarterly.
            </Col>
          </Row>
          <Row style={{ padding: "10px 2px" }}>
            <Col>
              <h3 style={{ fontWeight: "bold" }}>
                Is there any specific requirement to be eligible for this plan?
              </h3>
            </Col>
            <Col>
              Yes.
              <ol>
                <li>You must be a woman.</li>
                <li>
                  Your car's current market value must be at least N4,000,000.
                </li>
                <li>
                  Your car must be for private use only. The plan cannot be
                  purchased for a commercial vehicle.
                </li>
              </ol>
            </Col>
          </Row>
          <Row style={{ padding: "10px 2px" }}>
            <Col>
              <h3 style={{ fontWeight: "bold" }}>What does the plan cover?</h3>
            </Col>
            <Col>
              As a type of comprehensive insurance plan, Her Motor offers total
              coverage for you and your vehicle against damages/losses caused by
              accidents, floods, theft, riots, and related risks.
            </Col>
          </Row>
          <Row style={{ padding: "10px 2px" }}>
            <Col>
              <h3 style={{ fontWeight: "bold" }}>Does it cover floods?</h3>
            </Col>
            <Col>
              Yes, we will pay you up to N100,000 to repair car damage caused by
              a flood.
            </Col>
          </Row>
          <Row style={{ padding: "10px 2px" }}>
            <Col>
              <h3 style={{ fontWeight: "bold" }}>
                How does the 24/7 road emergency rescue and assistance work?
              </h3>
            </Col>
            <Col>
              We have partnered with AA Rescue, a vehicular recovery and rescue
              services firm. If you need assistance, call 0802 555 1111 and our
              partners will come to offer help and if necessary, take your
              vehicle to your desired location.
            </Col>
          </Row>
          <Row style={{ padding: "10px 2px" }}>
            <Col>
              <h3 style={{ fontWeight: "bold" }}>
                Is the road emergency rescue and assistance available
                nationwide?
              </h3>
            </Col>
            <Col>
              No, it is currently only available in Lagos, Abuja, and Port
              Harcourt. We are however working to expand its coverage.
            </Col>
          </Row>
          <Row style={{ padding: "10px 2px" }}>
            <Col>
              <h3 style={{ fontWeight: "bold" }}>
                What kind of emergencies does the road rescue and assistance
                cover?
              </h3>
            </Col>
            <Col>
              Any car-related issues, e.g. blown-out tyre, a dead battery, a car
              accident etc.
            </Col>
          </Row>
          <Row style={{ padding: "10px 2px" }}>
            <Col>
              <h3 style={{ fontWeight: "bold" }}>
                Is there an additional cost for the road emergency assistance
                service?
              </h3>
            </Col>
            <Col>
              No, there is not. This comes as a package when you purchase the
              plan
            </Col>
          </Row>
        </div>
      </Modal>
    </Wrapper>
  );
};

export default Index;
