import React from "react";
import Banner from "./Banner";
import backgroundImage from "../../assets/images/contact-bg.png";
import {
  Wrapper,
  LeftContentHeaderWrapper,
  LeftContentHeaderTitle,
  LeftContentHeaderText,
} from "./styles";
import Form from "./Form";
import ContactDetails from "./ContactDetails";
import DigitalChannels from "../../components/DigitalChannels/DigitalChannels";
import { Row, Col } from "antd";

const Index = () => {
  const bannerPayload = {
    title: "CONTACT US",
    backgroundImage,
  };
  return (
    <Wrapper>
      <Banner {...bannerPayload} />
      <LeftContentHeaderWrapper>
        <LeftContentHeaderTitle>We are fully digital</LeftContentHeaderTitle>
        <LeftContentHeaderText>
          Click any of the icons below to choose how you want to be served.
        </LeftContentHeaderText>
      </LeftContentHeaderWrapper>
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          minHeight: "90vh",
          width: "100%",
          overflow: "hidden",
        }}
      >
        <Col lg={12}>
          <DigitalChannels />
        </Col>
        <Col lg={12}>
          <Form />
        </Col>
      </Row>
      <ContactDetails />
    </Wrapper>
  );
};

export default Index;
