import React from "react";
import {
  Wrapper,
  Title,
  BannerContentWrapper,
  Content,
  LinkWrapper,
} from "./bannerStyle";
import { CaretRightFilled } from "@ant-design/icons";

const Banner = ({ title, backgroundImage, children, linkPath, linkText }) => {
  return (
    <Content backgroundImage={backgroundImage}>
      <Wrapper>
        <BannerContentWrapper>
          <Title> {title}</Title>
          {linkText && linkPath && (
            <LinkWrapper>
              <a href={linkPath}>
                {linkText} <CaretRightFilled />
              </a>
            </LinkWrapper>
          )}
          {children}
        </BannerContentWrapper>
      </Wrapper>
    </Content>
  );
};

export default Banner;
