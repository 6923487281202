import React from "react";
import Banner from "./Banner";
import backgroundImage from "../../components/ProductBanner";
import { Wrapper } from "./styles";
import Form from "./Form";

const Index = () => {
  const bannerPayload = {
    title: "Enjoy solid protection",
    descriptions: [
      "Solid Protection",
      "For yourself, business, and properties.",
      "Open to Tony Elumelu Foundation Entrepreneurs ONLY",
    ],
    linkPath2:
      "https://mcusercontent.com/b36906121853fa557377fbd4a/files/9cf5cdbc-cce1-687c-b014-e2f838b4dc83/HILxHLAxTEF_Brochure_II_1_1_.pdf",
    linkPath: "#tef",
    linkText: "Register Now",
    linkText2: "Download Brochure",
    backgroundImage,
  };
  return (
    <Wrapper>
      <Banner {...bannerPayload} />
      <Form />
    </Wrapper>
  );
};

export default Index;
