import React from "react";
import Banner from "../../components/ProductBanner";
import TenantsPlanPicker from "../../components/TenantsPlanPicker";
// import backgroundImage from "../../assets/images/tenant.png";
import backgroundImage from "../../assets/images/partnership-banner.png";

const Index = () => {
  const bannerPayload = {
    title: "Partnership Portal",
    descriptions: [
      "Our Partnership Portal solution creates a complete insurance ecosystem for Corporates, Brokers, and Individuals. It enables our partners to sell insurance, make referrals and earn commissions/bonuses. The partnership portal hosts the product APIs that allows for easy integration into any system.",
      "With an enriched payment system that leverages the use of a wallet, partners can seamlessly deposit funds and withdraw commissions to any bank account.",
    ],
    backgroundImage,
    // linkPath: "https://www.youtube.com/channel/UCDrC_N0SMI_ZYtgxhQ24HwQ",
    linkPath: "https://partners.heirsinsurance.com/",
    // linkText: "Watch how it works",
    linkText: "Partnership Portal",
  };
  return (
    <div>
      <Banner {...bannerPayload} />

      {/* <TenantsPlanPicker /> */}
    </div>
  );
};

export default Index;
