import React from "react";
import Section from "../Section";
import { Row, Col } from "antd";
import Icon from "@ant-design/icons";
import {
  Wrapper,
  Title,
  Content,
  Text,
  ContentWrapper,
  IconWrapper,
} from "./style";
import { ReactComponent as AlertFill } from "../../assets/images/alert-fill.svg";
import { Video } from "../ProductBanner/styles";
import ReactCountryFlag from "react-country-flag";

const imgcol = {
  height: "200px",
  width: "100%",
  overflow: "hidden",
};
const cols = {
  background: "#fff",
  border: "1px solid #cdcdcd",
};
const pstyle = {
  lineHeight: "18pt",
};

const StoryTeller = () => {
  return (
    <Wrapper>
      <ContentWrapper>
        <Title> </Title>
        <Row gutter={[24, 0]}>
          <Col lg={5} style={cols}>
            <Text>
              <table>
                <tr>
                  <td>
                    <div style={imgcol}>
                      <img
                        src="https://heirsdigitalform.azurewebsites.net/wp-content/uploads/2023/08/Marcelline.png"
                        width="100%"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingBottom: "15px" }}>
                    <span style={{ paddingLeft: "5px", fontWeight: "bold" }}>
                      Marcelline Akinyi Okech
                    </span>
                    <span style={{ display: "block" }}>
                      <ReactCountryFlag countryCode="KE" svg /> Kenya
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingBottom: "15px" }}>
                    <p style={pstyle}>
                      <b>Gender:</b>{" "}
                    </p>
                    <p style={pstyle}>Female</p>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingBottom: "15px" }}>
                    <p style={pstyle}>
                      <b>Project Type:</b>{" "}
                    </p>
                    <p style={pstyle}>Augmented Reality</p>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingBottom: "15px" }}>
                    <p style={pstyle}>
                      <b>Project Title:</b>{" "}
                    </p>
                    <p style={pstyle}>Sustainable Capitalism Portals</p>
                  </td>
                </tr>
              </table>
            </Text>
          </Col>
          <Col offset={0} lg={10}>
            <Text>
              <b>About: </b> <br />
              Marcelline Akinyi is an award-winning Nairobi-based artist using
              tech to craft augmented reality art. Through META pipeline, she
              showcases eco-friendly SMEs in East Africa, highlighting
              entrepreneurial climate solutions.
            </Text>
            <Text>
              <b>Description of Project: </b>
              <br /> Marcelline's project titled 'Sustainable Capitalism
              Portals' spotlights innovation and sustainability. Her project is
              an augmented reality art showcasing inspiring journeys of three
              East African businesses reshaping capitalism for a greener future.
              <ol>
                <li>
                  Cheveux Organique: From Uganda, is redefining hair care with
                  natural, plant-based extensions, replacing plastic with
                  eco-friendly banana fiber.{" "}
                </li>
                <li>
                  Juabar Design: From Tanzania, Juabar brings clean energy to
                  rural Africa through solar-powered charging kiosks, empowering
                  communities.
                </li>
                <li>
                  Gjenge Makers: Kenya's own pioneers craft biodegradable
                  building materials from recycled plastic waste, paving the way
                  for eco-conscious construction.{" "}
                </li>
              </ol>
            </Text>
          </Col>
          <Col offset={0} lg={9}>
            <Text>
              <b>Impact</b>
              <br /> Through the magic of Augmented Reality on platforms like
              Meta Studio, Marcelline has reached over 3,100 individuals,
              resonating with audiences from Nairobi to Paris, São Paulo to
              Santiago.
            </Text>
            <Text>
              <a href="https://www.instagram.com/ar/248575918020204/">
                Project
              </a>
            </Text>
            <Text>
              <b>Lesson Learnt While Working on Project: </b>
              <br /> This journey has taught us the power of storytelling
              through technology, encouraging more women to embrace AR's
              potential. We've also recognized the need for simplicity in
              storytelling, ensuring wider accessibility for our African
              audience. Challenges aside, our optimism for an inclusive
              augmented reality future remains unwavering.
            </Text>
            {/* <Video>
              <iframe
                width="520"
                height="315"
                src="https://www.youtube.com/embed/8vzi5vhSn7g?si=zG1fc6B1ETZMJNj3"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </Video> */}
          </Col>
        </Row>
      </ContentWrapper>
    </Wrapper>
  );
};

export default StoryTeller;
