import styled from "styled-components";
import { Button } from "antd";
import * as Colors from "../../../utils/colors";
import { containerWrapper } from "../../../utils/stylescheet";

export const IconWrapper = styled("span")`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: rgb(243 84 91 / 8%);
  text-align: center;
  margin-right: 10px;
  display: inline-block;
  padding-left: 7px;
  margin-top: 5px;

  svg {
    //width: 15px;
    //height: auto;
    fill: ${Colors.PRIMARY};
  }

  @media screen and (max-width: 991px) {
    display: none;
  }
`;

export const MenuContainer = styled("div")`
  width: calc(100% - 215px);
  float: left;

  .link-button {
    a {
      background: ${Colors.PRIMARY};
      color: ${Colors.WHITE};

      &:hover {
        background: ${Colors.BLACK};
        color: ${Colors.WHITE};
      }
    }
  }

  .ant-menu-horizontal {
    .ant-menu-submenu,
    .ant-menu-item {
      padding: 0 5px;
      margin: 0;
    }

    .leftMenu {
      margin-left: 10px;
    }
  }

  .ant-menu-submenu-title {
    padding: 10px 20px;
  }

  .ant-menu-item a,
  .ant-menu-submenu-title a {
    padding: 10px 15px;
  }

  .ant-menu-horizontal {
    border-bottom: none;
  }

  .leftMenu {
    float: left;
  }

  .rightMenu {
    float: right;
  }
`;

export const BarsMenu = styled(Button)`
  float: right;
  height: 32px;
  padding: 6px;
  margin-top: 8px;
  display: none;
  background: none;

  span {
    display: block;
    width: 20px;
    height: 2px;
    position: relative;
    background: ${Colors.PRIMARY};

    &:after,
    &:before {
      content: attr(x);
      width: 20px;
      position: absolute;
      top: -6px;
      left: 0;
      height: 2px;
      background: ${Colors.PRIMARY};
    }

    &:after {
      top: auto;
      bottom: -6px;
    }
  }

  @media (max-width: 991px) {
    display: inline-block;
  }
`;

export const Content = styled("div")`
  ${containerWrapper};

  @media (max-width: 767px) {
    padding: 0;
  }
`;

export const Wrapper = styled("nav")`
  background: ${Colors.WHITE};
  border-bottom: solid 1px ${Colors.GRAY_15};
  font-family: "Paralucent DemiBold", sans-serif;
  box-shadow: 0 4px 13px rgb(0 0 0 / 5%);
  position: fixed;
  overflow: hidden;
  left: 0;
  right: 0;
  z-index: 5;
  
  .promo {
    
    img {
      height: 100%
      @media (min-width: 375px) {
        height: 5vh
      }
      @media (max-width: 475px) {
        height: 5vh
      }
    }
  }
  
  .logo {
    float: left;
    width: 215px;

    a {
      display: inline-block;
      font-size: 20px;
      padding: 19px 0;
      text-transform: capitalize;
    }

    img {
      height: 30px;
    }
  }

  .ant-menu-horizontal {
    line-height: 70px;
  }

  .ant-drawer-body .ant-menu-horizontal > .ant-menu-item,
  .ant-drawer-body .ant-menu-horizontal > .ant-menu-submenu {
    display: inline-block;
    width: 100%;
  }

  .ant-drawer-body .ant-menu-horizontal {
    border-bottom: none;
  }

  .ant-drawer-body .ant-menu-horizontal > .ant-menu-item:hover {
    border-bottom-color: transparent;
  }

    @media (max-width: 575px) {
        padding: 0 15px;
    }

    @media screen and (max-width: 767px){
      padding: 0 15px;
    }
  
   @media (max-width: 991px) {
    .leftMenu,
    .rightMenu {
      display: none;
    }

    .logo a {
      margin-left: -20px;
      padding: 10px 20px;
    }
    
    ${MenuContainer} {
      .ant-menu-item,
     .ant-menu-submenu-title {
        padding: 1px 20px;
      }
  }
`;
