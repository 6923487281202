import React, { useEffect } from "react";
import {
  PlanChatTitle,
  PlanChatWrapper,
  Text,
  Title,
  Wrapper,
  List,
} from "../PlanPicker/styles";

import flexi from "../../assets/images/Icon_Flexi&comprehensive_motor.png";
import { Link } from "react-router-dom";
import { HeirsDashboardUrl } from "../../utils/constants";
import { Mixpanel } from "../../Mixpanel";

const Index = () => {
  const handleChatWithUs = (e) => {
    e.preventDefault();
    // window.fcWidget.open({});
    window.Freshbots.showWidget({});
  };

  const checkClickEvent = (e) => {
    if (e.target.id === "purchaseStatus") {
      setPurchaseStatus();
    }
  };

  const setPurchaseStatus = () => {
    localStorage.setItem("attempted_purchase", "true");
  };

  const checkScrollTop = () => {
    const tableTop = document.getElementById("d-fixed");
    if (tableTop) {
      if (window.pageYOffset > 690) {
        tableTop.classList.add("p-fixed");
        document.querySelector("#d-fixed .ant-table-tbody").style.display =
          "none";
        document.querySelector(
          "#product-table .ant-table-thead"
        ).style.display = "none";
      } else {
        tableTop.classList.remove("p-fixed");
      }
    }
  };

  useEffect(() => {
    let p = document.getElementById("table");
    let p_prime = p.cloneNode(true);
    document.getElementById("d-fixed").appendChild(p_prime);
  }, []);

  useEffect(() => {
    localStorage.setItem("last_accessed_product", window.location.pathname);
    window.addEventListener("scroll", checkScrollTop);
    window.addEventListener("click", checkClickEvent);
  });

  return (
    <Wrapper id="flexi">
      <div className="ant-table" id="ant-table">
        <div id="d-fixed" />
        <div id="product-table">
          <table id="table">
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell" style={{ width: "25%" }}>
                  <img src={flexi} alt="flexi" />
                </th>
                <th className="ant-table-cell" style={{ width: "25%" }}>
                  <Title>Flexi 15</Title>
                  <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Flexi 15 Buy")}
                    href={`${HeirsDashboardUrl}/motor-policy-type?policy-type=HEIRS Motor Flexi 15K`}
                  >
                    Buy Now
                  </a>
                  {/* <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Flexi 15 quote")}
                    href={`${HeirsDashboardUrl}/motor-policy-type?policy-type=HEIRS Motor Flexi 15K`}
                    style={{ marginTop: "5px" }}
                  >
                    Get A Quote
                  </a> */}
                </th>
                <th className="ant-table-cell" style={{ width: "25%" }}>
                  <Title>Flexi 23</Title>
                  <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Flexi 23 Buy")}
                    href={`${HeirsDashboardUrl}/motor-policy-type?policy-type=HEIRS Motor Flexi 23K`}
                  >
                    Buy Now
                  </a>
                  {/* <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Flexi 23 quote")}
                    href={`${HeirsDashboardUrl}/motor-policy-type?policy-type=HEIRS Motor Flexi 23K`}
                    style={{ marginTop: "5px" }}
                  >
                    Get A Quote
                  </a> */}
                </th>
                <th className="ant-table-cell" style={{ width: "25%" }}>
                  <Title> Flexi 40</Title>
                  <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Flexi 40 Buy")}
                    href={`${HeirsDashboardUrl}/motor-policy-type?policy-type=HEIRS Motor Flexi 40K`}
                  >
                    Buy Now
                  </a>
                  {/* <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Flexi 40 quote")}
                    href={`${HeirsDashboardUrl}/motor-policy-type?policy-type=HEIRS Motor Flexi 40K`}
                    style={{ marginTop: "5px" }}
                  >
                    Get A Quote
                  </a> */}
                </th>
              </tr>
            </thead>

            <tbody className="ant-table-tbody">
              <tr>
                <td className="ant-table-cell" style={{ width: "25%" }}>
                  <Text>Cost</Text>
                </td>
                <td className="ant-table-cell" style={{ width: "25%" }}>
                  <Text>N15,000</Text>
                </td>
                <td className="ant-table-cell" style={{ width: "25%" }}>
                  <Text>N23,000</Text>
                </td>
                <td className="ant-table-cell" style={{ width: "25%" }}>
                  <Text>N40,000</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>For Whom?</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Anyone</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Anyone</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Anyone</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Premium Frequency</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Yearly</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Yearly</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Yearly</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Benefits</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Policy is excess-free</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Policy is excess-free</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Policy is excess-free</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" colSpan="6">
                  <Text highlight>Coverage for You</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>
                    Replacement of vehicle and vehicle parts if stolen or
                    wrecked
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Up to N350,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Up to N500,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Up to N1M</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Repairs your vehicle in the case of an accident.</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Up to N350,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Up to N500,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Up to N1M</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Pays your medical bills after an accident</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not covered</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Vehicle tracker</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not covered</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Towing Services (Interstate/Intrastate)</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Up to N15,000 /N10,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Up to N25,000/ N15,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Up to N30,000/ N20,000</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>
                    Covers damage caused by riot, strike,& civil commotion
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Covered</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Flood Damage Cover</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Covered up to a maximum of limit N100,000.00 </Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Covered up to a maximum of limit N100,000.00 </Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Covered up to a maximum limit of N100,000.00 </Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" colSpan="6">
                  <Text highlight>Coverage for the Other Party</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Covers damage to the other vehicle</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Up to N1m</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Up to N1m</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Up to N1m</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Medical bills payment</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Unlimited amount (subject to reasonability)</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Unlimited amount (subject to reasonability)</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Unlimited amount (subject to reasonability)</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>
                    Financial support paid to beneficiary in the case of death
                    or permanent disability
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Unlimited</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Unlimited</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Unlimited</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" colSpan="6">
                  <Text highlight>Exclusions (Benefits not covered)</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Mechanical breakdown</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Damage to vehicle caused by wear and tear</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Tyre damage, unless resulting to accident</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Rescue and assistance during road emergencies</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Damage caused by war</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Document Requirements</Text>
                </td>
                <td className="ant-table-cell" colspan="3">
                  <Text>
                    <List>
                      <li>Duly Filled Proposal/KYC Form.</li>
                      <li>Proof of Ownership.</li>
                      <li>Evidence of Payment.</li>
                      <li>Copy of the Vehicle License/Proof of Ownership.</li>
                      <li>Photographs of subject matter.</li>
                      <li>
                        Valid means of ID - Driver License, Voter's card, NIMC,
                        National ID, International Passport.
                      </li>
                      <li>
                        Proof of Residence - Utility Bill, Front page of the
                        customer's address (same address as indicated on the
                        form or portal), Local Government Levy.
                      </li>
                    </List>
                  </Text>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <PlanChatWrapper>
        <PlanChatTitle>
          Not Sure of the Right <br />
          Plan for you?
        </PlanChatTitle>
        <Link to="/" onClick={handleChatWithUs}>
          Chat with us
        </Link>
      </PlanChatWrapper>
    </Wrapper>
  );
};

export default Index;
