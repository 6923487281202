import React from "react";
import Banner from "./Banner";
import CorporateSocial from "../../components/CorporateSocial";
import CSR from "../../components/CorporateSocialTEFList";
import SubscribeForm from "../../components/SubscribeForm";
import backgroundImage from "../../assets/images/Storeyteller.png";

const Index = () => {
  const bannerPayload = {
    title: "Tony Elumelu Storytellers Fund",
    descriptions: [" "],
    backgroundImage,
    // linkPath: "http://recruitment.heirsinsurance.com/",
    linkText: "Read More",
  };
  const subscribeFormPayload = {
    title: "SIGN UP FOR EMAIL UPDATES",
    description:
      "Stay in touch to get exclusive news and about recent openings in our company.",
  };
  return (
    <div>
      <Banner {...bannerPayload} />

      <CorporateSocial />

      <CSR />

      {/* <SubscribeForm {...subscribeFormPayload} /> */}
    </div>
  );
};

export default Index;
