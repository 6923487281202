import React from "react";
import Section from "../Section";
import { Row, Col } from "antd";
import Icon from "@ant-design/icons";
import {
  Wrapper,
  Title,
  Content,
  Text,
  ContentWrapper,
  IconWrapper,
} from "./style";
// import Button from "../Button";
import { ReactComponent as AlertFill } from "../../assets/images/alert-fill.svg";

const FraudAlert = () => {
  return (
    <Wrapper>
      <ContentWrapper>
        <Title>Fraud Alert</Title>
        <Row gutter={[24, 0]}>
          <Col lg={14}>
            <Text>
              Several incidents involving organisations and individuals
              fraudulently claiming to recruit on behalf of the Heirs General
              Insurance have recently been identified. The scammers' goal is to
              extort money from candidates by asking for payment upfront for
              fake ancillary costs (travel agency, visa fees etc.) or to obtain
              personal and financial information (bank account details, personal
              address, etc). Please be advised that Heirs General Insurance will
              never request payment, nor will it ask for financial information
              during the recruitment and selection process. <br /> <br />
              We take this situation very seriously and implement all possible
              measures to identify and stop this type of fraud.
            </Text>
          </Col>
          <Col offset={3} lg={6}>
            <IconWrapper>
              <Icon component={AlertFill} style={{ fontSize: "200px" }} />
            </IconWrapper>
          </Col>
        </Row>
      </ContentWrapper>
    </Wrapper>
  );
};

export default FraudAlert;
