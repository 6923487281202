import React, { useEffect, useState } from "react";
import { List } from "antd";
import { AnswerItemWrapper, AnswersWrapper, Text, Title } from "./styles";
import { useHistory } from "react-router-dom";
import { data } from "./data";

const Media = () => {
  const history = useHistory();
  const [posts, setPosts] = useState([]);

  const handlePostDisplay = (index) => {
    history.push(`/media/${index}`);
  };

  useEffect(() => {
    setPosts(data);
  }, []);

  return (
    <AnswersWrapper>
      <List
        // pagination={{
        //   position: "bottom",
        //   total: 100,
        //   pageSize: 10,
        //   current: 1,
        // }}
        grid={{
          gutter: 64,
          xs: 1,
          sm: 1,
          md: 1,
          lg: 2,
          xl: 2,
          xxl: 2,
        }}
        dataSource={posts}
        renderItem={(item, index) => (
          <List.Item>
            <AnswerItemWrapper onClick={() => handlePostDisplay(index + 1)}>
              <img src={item.image} alt="lagos" />
              <Text>{item.textStart}</Text>
              <Title>{item.title}</Title>
            </AnswerItemWrapper>
          </List.Item>
        )}
      />
    </AnswersWrapper>
  );
};

export default Media;
