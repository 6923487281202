import React, { useState } from "react";
import { Row, Col } from "antd";
import {
  Wrapper,
  ItemWrapper,
  Title,
  ListWrapper,
  ListItemWrapper,
  Text,
} from "./styles";
import OurValues from "../../assets/images/csr.png";
import { CaretRightOutlined } from "@ant-design/icons";
import { useMediaQuery } from "./mediacss";

const Index = () => {
  const [activeTab, setActiveTab] = useState(0);
  const isMedia = useMediaQuery("(min-width: 500px)");

  return (
    <Wrapper>
      <Row gutter={[16, 0]}>
        <Col lg={14}>
          <ItemWrapper active={activeTab === 0} style={{ width: "95%" }}>
            {/* <Title>OUR CULTURE</Title> */}

            <Text>
              17 young creatives across Africa selected from 3,600 entries
              received USD30,000 in grants (between $500 to $2,000 each) to
              develop projects that shine a light on entrepreneurship in Africa
              and challenge the negative stereotypes in Africa.
            </Text>
            <Text>
              The finalists represent 12 African countries, with 60% female and
              40% male representation. Working with Africa No Filter (ANF), the
              grantees worked on projects ranging from films to visual arts,
              journalism and digital content creation.
            </Text>
            <Text>
              This project is part of our group company's commitment to
              redefining an African success story that is powered by Africa's
              youth and their creative ideas.
            </Text>
          </ItemWrapper>
        </Col>
        <Col lg={10}>
          <img
            src={OurValues}
            alt={"Our Values"}
            style={styles.item(isMedia)}
          />
        </Col>
      </Row>
    </Wrapper>
  );
};

const styles = {
  item: (isMedia) => ({
    width: isMedia ? "600px" : "100%",
  }),
};

export default Index;
