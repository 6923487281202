import React from "react";
import ShopInsurance from "../../components/ShopInsurance";
import backgroundImage from "../../assets/images/shop-insurance.png";
import Banner from "../../components/ProductBanner";

const Index = () => {
  const bannerPayload = {
    title: "Shop Insurance",
    descriptions: [
      "As a small or medium-sized business owner, your shop is a valuable investment. Any unfortunate incident could result in loss of property, or even worse, business closure.",
      "Our Shop Insurance is for smart business owners who want to protect their shops or retail outlets, and the assets in them.",
      "If your shop is damaged by fire, burglary, and other similar risks, Heirs General Insurance will pay to repair the damage. This ensures that your business can survive any incidents.",
    ],
    backgroundImage,
    // linkPath: "https://www.youtube.com/channel/UCDrC_N0SMI_ZYtgxhQ24HwQ",
    linkPath: "#shop",
    // linkText: "Watch how it works",
    linkText: "Learn More",
  };
  return (
    <div>
      <Banner {...bannerPayload} />

      <ShopInsurance />
    </div>
  );
};

export default Index;
